import React, { Component } from "react";

import { useState } from "react";
// import { Modal, Button, Form } from "react-bootstrap";
import { Book, PlusCircle } from "react-feather";
import Syllabus_data from "../../../api/Syllabus_data.json";
import SyllabusModal from "./SyllabusModal";
import { useTranslation } from "react-i18next";

export class Syllabus extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    this.state = {
      isTodoModelShow: false,
      isTagModelShow: false,
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | Syllabus";
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isTodoModelShow: false,
      isTagModelShow: false,
    });
  };
  openCloseTodoModel = () => {
    // this.setInitialState();
    this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {});
  };
  openCloseTagModel = () => {
    // this.setInitialState();
    this.setState({ isTagModelShow: !this.state.isTagModelShow });
  };
  //#endregion

  //#region Render
  render() {
    const { t } = this.props;
    return (
      <>
        {/* <!-- Container-fluid starts--> */}
        <div className="row syllabus-sec">
          <div className="col-md-12">
            <div className="card br-15">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">{t("Syllabusss")}</h5>

                  <a
                    type="button"
                    data-bs-toggle="modal"
                    onClick={this.openCloseTodoModel}
                    data-bs-target="#exampleModal"
                    className="btn btn-edsys-primary"
                  >
                    <PlusCircle className="me-2" />
                    <span></span>
                  </a>
                  {/* {Modal Start} */}
                  {this.state.isTodoModelShow ? (
                    <SyllabusModal onClose={this.openCloseTodoModel} />
                  ) : null}
                  {this.state.isTagModelShow ? (
                    <SyllabusModal onClose={this.openCloseTagModel} />
                  ) : null}
                  {/* modal End */}
                </div>
              </div>
              <div className="card-body">
                <form
                  className="form-bookmark needs-validation"
                  id="bookmark-form"
                  novalidate=""
                >
                  <div className="form-row row">
                    <div className="form-group col-md-6">
                      <label htmlFor="task-title">Select Class</label>
                      <select className="js-example-basic-single form-select">
                        <option value="task">5th A</option>
                        <option value="task">5th B</option>
                        <option value="task">5th C</option>
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Select Subject</label>
                      <select className="js-example-basic-single form-select">
                        <option value="task">English</option>
                        <option value="task">Maths</option>
                        {/* <option value="H">Hello</option> */}
                      </select>
                    </div>
                    {/* <div className="form-group col-md-6">
                  <label for="task-title">Select Subject</label>
                  <select style={{ color: '#242934', fontWeight: 'bold', fontSize: '16px' }} className="js-example-basic-single form-select">
                    <option style={{ backgroundColor: '#78266a', color: 'white', hover: 'white', fontSize: '16px' }} value="task">English</option>
                    <option style={{ backgroundColor: '#78266a', color: 'white', hover: 'white', fontSize: '16px' }} value="task">Maths</option>
                  </select>
                </div> */}
                  </div>
                </form>
                {Syllabus_data.map((postDetail, index) => {
                  return (
                    <div className="lessons d-flex align-items-center">
                      <div className="flex-shrink-0 icon-tile">
                        <i className="icon-book"></i>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="lession-title">
                          {postDetail.lesson}
                          <span>{postDetail.title}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </>
    );
  }
}

export default Syllabus;
