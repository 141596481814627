import React, { useEffect, useState } from "react";
import watch_icon from "../../assets/images/watch_icon.svg";
import video_icon_white from "../../assets/images/video_icon_white.svg";
import schedule_icon_white from "../../assets/images/schedule_icon_white.svg";
import { Link } from "react-router-dom";

function SchoolTvChannel() {
  return (
    <>
      <div class="SchoolTvChannel announcementnew">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Scheduled Program</label>
                <div className="search-wrapper"></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <Link to="/ScheduledPrograms">
                  <div className="program-card color-1">
                    <div className="pc-class">
                      <p className="class-name">10D</p>
                      <p className="sub">th</p>
                    </div>
                    <div className="pc-details sctv-details">
                      <div className="pc-class-details">
                        <p className="title">Go To Classroom</p>
                        <p className="subtitle">Social Studies Class D</p>
                        <div className="time-wrapper">
                          <img src={watch_icon} alt="" />
                          <p className="time">01:30 PM - 2:30 PM</p>
                        </div>
                      </div>
                      <div className="video-links">
                        <div className="video">
                          <img src={video_icon_white} alt="" />
                        </div>
                        <div className="calendar">
                          <img src={schedule_icon_white} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6">
                  <Link to="/ScheduledPrograms">
                  <div className="program-card color-2">
                    <div className="pc-class">
                      <p className="class-name">6C</p>
                      <p className="sub">th</p>
                    </div>
                    <div className="pc-details sctv-details">
                      <div className="pc-class-details">
                        <p className="title">Go To Classroom</p>
                        <p className="subtitle">Social Studies Class D</p>
                        <div className="time-wrapper">
                          <img src={watch_icon} alt="" />
                          <p className="time">01:30 PM - 2:30 PM</p>
                        </div>
                      </div>
                      <div className="video-links">
                        <div className="video">
                          <img src={video_icon_white} alt="" />
                        </div>
                        <div className="calendar">
                          <img src={schedule_icon_white} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6">
                  <Link to="/ScheduledPrograms">
                  <div className="program-card color-3">
                    <div className="pc-class">
                      <p className="class-name">9B</p>
                      <p className="sub">th</p>
                    </div>
                    <div className="pc-details sctv-details">
                      <div className="pc-class-details">
                        <p className="title">Go To Classroom</p>
                        <p className="subtitle">Social Studies Class D</p>
                        <div className="time-wrapper">
                          <img src={watch_icon} alt="" />
                          <p className="time">01:30 PM - 2:30 PM</p>
                        </div>
                      </div>
                      <div className="video-links">
                        <div className="video">
                          <img src={video_icon_white} alt="" />
                        </div>
                        <div className="calendar">
                          <img src={schedule_icon_white} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6">
                  <Link to="/ScheduledPrograms">
                  <div className="program-card color-4">
                    <div className="pc-class">
                      <p className="class-name">7A</p>
                      <p className="sub">th</p>
                    </div>
                    <div className="pc-details sctv-details">
                      <div className="pc-class-details">
                        <p className="title">Go To Classroom</p>
                        <p className="subtitle">Social Studies Class D</p>
                        <div className="time-wrapper">
                          <img src={watch_icon} alt="" />
                          <p className="time">01:30 PM - 2:30 PM</p>
                        </div>
                      </div>
                      <div className="video-links">
                        <div className="video">
                          <img src={video_icon_white} alt="" />
                        </div>
                        <div className="calendar">
                          <img src={schedule_icon_white} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6">
                  <Link to="/ScheduledPrograms">
                  <div className="program-card color-5">
                    <div className="pc-class">
                      <p className="class-name">5A</p>
                      <p className="sub">th</p>
                    </div>
                    <div className="pc-details sctv-details">
                      <div className="pc-class-details">
                        <p className="title">Go To Classroom</p>
                        <p className="subtitle">Social Studies Class D</p>
                        <div className="time-wrapper">
                          <img src={watch_icon} alt="" />
                          <p className="time">01:30 PM - 2:30 PM</p>
                        </div>
                      </div>
                      <div className="video-links">
                        <div className="video">
                          <img src={video_icon_white} alt="" />
                        </div>
                        <div className="calendar">
                          <img src={schedule_icon_white} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchoolTvChannel;
