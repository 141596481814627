import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ViewEvalutionSkelton from "./ViewEvalutionSkelton";

export default function ViewEvaluationData() {
  const { t } = useTranslation();
  const [evdata, setevData] = useState("");
  const [evname, setEvName] = useState("");
  const [evCode, setevCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    evaluationView();
  }, []);
  const evaluationView = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      evaluation_id: getData,
    });
    simplePostCall(ApiConfig.EVALUTION_VIEW, RequestBody)
      .then((data) => {
        if (data.data) {
          setevData(data.data[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
        setIsLoading(false);
      });
  };

  const EvaluationUpdate = () => {
    // if (evname === "" && evCode === "") {
    //   // If no changes, simply navigate back
    //   navic("/EvaluationType");
    //   return;
    // }
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      evaluation_type_id: getData,
      evaluation_type_name: evname ? evname : evdata?.evaluation_type_name,
      evaluation_type_code: evCode ? evCode : evdata?.evaluation_type_code,
    });
    simplePostCall(ApiConfig.EVALUTION_EDIT, RequestBody)
      .then((data) => {
        if (data) {
          toast.success(data.message);
          navic("/EvaluationType");
        } else {
          toast.error("Failed to create syllabus.");
        }
      })
      .catch((err) => {
        // setLoading(false);
        toast.error("API request failed.");
        console.log("API request failed", err);
      });
  };
  const location = useLocation();
  const getData = location.state;
  const navic = useNavigate();
  return (
    <>
      {isLoading ? (
        <ViewEvalutionSkelton />
      ) : (
        <div>
          {/* <div className="main-sectionn ">
        <div className="col-md-12">
          <div className="announcementCreatee">
            <label className="edit-head">Edit Evaluation</label>
          </div>
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Evaluation Name</th>
                  <td scope="col">data1</td>
                </tr>
                <tr>
                  <th scope="col">Evaluation Code</th>
                  <td scope="col">data2</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div> */}
          <div className="main-sectionn ">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="edit-head">Update Evalution</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 row">
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Evaluation Name")}</label>
                  <input
                    className="form-control "
                    type="text"
                    defaultValue={evdata?.evaluation_type_name}
                    onChange={(e) => {
                      setEvName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Evaluation Code")}</label>
                  <input
                    className="form-control "
                    type="text"
                    defaultValue={evdata?.evaluation_type_code}
                    onChange={(e) => {
                      setevCode(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="create-but-1">
                <button
                  className="btn btn-primary  crbt"
                  type="button"
                  onClick={() => navic("/EvaluationType")}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="btn btn-primary  crbt"
                  type="button"
                  onClick={() => EvaluationUpdate()}
                >
                  {t("Update")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
