import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trash2 } from "react-feather";
import { Modal } from "react-bootstrap";
import subtopicicon from "../../assets/images/assignment/subtopicicon.svg";
import "../../assets/css/tabstyles.css";
import "../../assets/css/OfflineAssesment.css";
import Params from "../../config/Params";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";

const LessonTopicAssesmentSubject = () => {
  const [show, setShow] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [Assignment, setAssignment] = useState([]);
  const [openMode, setOpenMode] = useState(false);

  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    document.title = "Edsysy | Assignment";
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isloading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  //..........Filter Api's...........//
  useEffect(async () => {
    OffAss();
    GradeListApi();
    // SubjectListApi();

    console.log(location?.state, "stateeee");
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.AcademicYr,

      term: location?.state?.TermId,
    }));
  }, []);

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location?.state?.assesmentId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_name,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  const SubjectListApi = (classid) => {
    console.log(classid, "classid");

    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: [classid.value],
      assessment_offline_id: location?.state?.assesmentId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data, "gardelist");
          const newData = data.list?.map((item) => {
            return {
              label: item.subject_name,
              value: item.assessment_subjects_subject_id,
              ...item,
            };
          });
          setSubjectList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleGradeChange = (grade) => {
    console.log("working");

    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: grade,
      subject: null,
    }));
    SubjectListApi(grade);
  };
  const handleSubjectChange = (grade) => {
    console.log("working");

    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: grade,
    }));
  };
  console.log(location?.state?.AcademicYr, "yearrrr");

  const [formData, setFormData] = useState({
    examName: "",
    academicYear: {
      label: location?.state?.AcademicYr?.label,
      value: location?.state?.AcademicYr?.value,
    },
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });

  const DeleteAssignment = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_id: deleteId,
    });
    simplePostCall(ApiConfig.FORMATIVE_ASSESSMENT_DELETE, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          toast.success(data.message);
          OffAss(page);
          console.log("message", data.message);
        } else {
          toast.error("something went wrong");
          OffAss(page);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const OffAss = () => {
    const details = {};

    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      subject_id: formData?.subject?.value,
      grade_id: formData?.grade?.value,
      assessment_offline_id: location?.state?.assesmentId,
    });
    simplePostCall(ApiConfig.FORMATIVE_SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.data, "hiiiii");
          console.log(data.message, "message");
          setAssignment(data.data);
          setIsloading(false);
        } else {
          setAssignment([]);

          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  console.log(Assignment, "AssignmentData");
  return (
    <>
      <div className="Forum library">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="col-md -6">Lesson/Topic Assesment Subjects</h5>
                </div>
              </div>

              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div className="offline_border"></div>
                  <div className="assessment_topdiv">
                    <div className="col-md-12 row  headoffline">
                      <div className="col-md-3 ">
                        <div className="">Academic Year</div>

                        <div
                          style={{
                            marginTop: 10,
                            marginLeft: 10,
                            fontFamily: "bold",
                            color: "black",
                            fontSize: 17,
                          }}
                        >
                          {formData.academicYear.label}
                        </div>
                      </div>

                      <div className="col-md-3 ">
                        <div className="">Select Grade</div>
                        <Select
                          class="js-example-basic-single form-select"
                          name="grade"
                          value={formData.grade}
                          onChange={handleGradeChange}
                          options={gradeList}
                        />
                      </div>
                      <div className="col-md-3 ">
                        <div className="">Select Subject</div>
                        <Select
                          class="js-example-basic-single form-select"
                          name="grade"
                          value={formData.subject}
                          onChange={handleSubjectChange}
                          options={subjectList}
                        />
                      </div>
                      <button
                        className="view-mark-list-button col-md-3"
                        style={{}}
                        onClick={() => OffAss()}
                      >
                        View List
                      </button>
                    </div>
                  </div>

                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <div className="card-body">
                            {console.log(Assignment, "ASSIIGNNN")}
                            <InfiniteScroll
                              dataLength={Assignment?.length}
                              // next={onPageLoad}
                              hasMore={Assignment?.length !== TotalCourses}
                              style={{
                                overflow: "hidden",
                              }}
                              loader={null}
                            >
                              <div className="row">
                                {Assignment && Assignment?.length > 0 ? (
                                  Assignment?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4" style={{}}>
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />
                                              <h6 className="flex-grow-1">
                                                {assessment.subject_name}
                                              </h6>
                                              <div
                                                style={{
                                                  borderRadius: "10%",
                                                  marginRight: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "#772569",
                                                  }}
                                                >
                                                  {assessment.assessment_status ===
                                                  "ready for apporval"
                                                    ? "Ready To Approve"
                                                    : assessment.assessment_status ===
                                                      "draft"
                                                    ? "draft"
                                                    : assessment.assessment_status ===
                                                      "published"
                                                    ? "published"
                                                    : ""}
                                                </p>
                                              </div>
                                              <div className="class">
                                                {assessment.class_name}
                                              </div>
                                            </div>
                                          </div>

                                          <h5
                                            className="name card-body"
                                            style={{ color: "black" }}
                                            onClick={() => {
                                              console.log(
                                                location?.state?.assesmentName,
                                                "nycc",
                                              );
                                              navigation(`/StudentMarkList`, {
                                                state: {
                                                  offlineId:
                                                    assessment.assessment_offline_id,
                                                  assesmentIds:
                                                    assessment.assessment_id,
                                                  SemExam:
                                                    assessment.assessment_title,
                                                  academicYear: {
                                                    label:
                                                      assessment.assessment_offline_academic_year,
                                                    value:
                                                      assessment.assessment_offline_academic_year_id,
                                                  },
                                                  grade: {
                                                    label:
                                                      assessment.class_name,
                                                    value: assessment.grade_id,
                                                  },

                                                  subject: {
                                                    label:
                                                      assessment.subject_name,
                                                    value:
                                                      assessment.subject_id,
                                                  },
                                                  startDate:
                                                    assessment.assessment_offline_start_date,
                                                },
                                              });
                                            }}
                                          >
                                            {assessment.assessment_title}
                                          </h5>
                                          {/* </Link> */}

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {assessment.assessment_offline_start_date
                                                  ?.split("-")
                                                  .reverse()
                                                  .join("-")}
                                              </h5>

                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div>
                                                      <Link type="button"></Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2
                                                          onClick={() => [
                                                            setOpenMode(true),
                                                            setDeleteId(
                                                              assessment.assessment_id,
                                                            ),
                                                          ]}
                                                        />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  // Message to display when Assignment array is empty
                                  <div className="text-center mt-4">
                                    <h4>No Assessment Found</h4>
                                  </div>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          centered
          show={openMode}
          onHide={() => setOpenMode(false)}
          animation={true}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="borderModal"></div>
            <p>Are you sure you want to delete ?</p>

            {/* <div className="borderModalBelow"></div> */}
          </Modal.Body>
          <div className="borderModal"></div>
          <Modal.Footer>
            <div className="modalBtns">
              <button className="cancelBtn" onClick={() => setOpenMode(false)}>
                Cancel
              </button>
              <button
                className="YesBtn"
                onClick={() => [setOpenMode(false), DeleteAssignment()]}
              >
                Delete
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LessonTopicAssesmentSubject;
