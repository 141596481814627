import React, { useState } from "react";
import LectureDetails from "./LectureDetails";

const TimetableListView = (props) => {
  console.log(props, "props inside");
  const { Periods, Lectures } = props.data;
  const weekDaysColor = {
    MondayColor: [{ bgColor: "1" }],
    TuesdayColor: [{ bgColor: "2" }],
    WednesdayColor: [{ bgColor: "3" }],
    ThursdayColor: [{ bgColor: "4" }],
    FridayColor: [{ bgColor: "5" }],
  };
  const {
    MondayColor,
    TuesdayColor,
    WednesdayColor,
    ThursdayColor,
    FridayColor,
  } = weekDaysColor;

  const { Monday, Tuesday, Wednesday, Thursday, Friday } = Lectures;

  return (
    <div className="row assignment-sec">
      {Periods ? (
        Periods.map((period, i) => {
          console.log("period", period);
          if (period.isInterval && i < Periods.length - 1) {
            return (
              <div className="col-md-12">
                <div className="break-row">
                  <span className="break-time">
                    {period.startTime} - Break - {period.endTime}
                  </span>
                </div>
              </div>
            );
          } else {
            // var filteredlist = Lectures.filter(
            //   (lecture) => lecture.Period === period.PeriodId,
            // )
            // if (filteredlist) {
            //   return
            // <LectureDetails lecture={filteredlist} period={period} />
            // }
            return (
              <div class="tt-row">
                <LectureDetails
                  lecture={Monday[i]}
                  period={period}
                  Color={MondayColor}
                />
                <LectureDetails
                  lecture={Tuesday[i]}
                  period={period}
                  Color={TuesdayColor}
                />
                <LectureDetails
                  lecture={Wednesday[i]}
                  period={period}
                  Color={WednesdayColor}
                />
                <LectureDetails
                  lecture={Thursday[i]}
                  period={period}
                  Color={ThursdayColor}
                />
                <LectureDetails
                  lecture={Friday[i]}
                  period={period}
                  Color={FridayColor}
                />
              </div>
            );
          }
        })
      ) : (
        <tr>
          <td>
            <div className="details-bookmark text-center">
              <span>No records found.</span>
            </div>
          </td>
        </tr>
      )}
    </div>
  );
};

export default TimetableListView;
