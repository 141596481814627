import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import SyncLoader from "react-spinners/SyncLoader";

export default function Reports() {
  const windowHeight = useRef(window.innerHeight);
  console.log(windowHeight, "windowHeight");

  const [maxMark, setMaxMark] = useState("");
  const [report, setReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState("");
  const [openMode, setOpenMode] = useState(false);
  const [errors, setError] = useState(false);
  const [gradename, setGradeName] = useState([]);
  const [evalution, setEvaluation] = useState([]);
  const [students, setStudents] = useState([]);
  const [studId, setStudId] = useState("");
  const [formData, setFormData] = useState({
    duration: "",
    maxMark: "",
    classname: "",
    division_id: "",
    class_id: "",
    gradingName: "",
    gradingId: "",
    grading: "",
    student_id: "",
    students: "",
  });
  console.log(formData.student_id, "formData.student_id");

  const { t } = useTranslation();
  const navic = useNavigate();
  useEffect(() => {
    AcademicYesrList();
    ClassList();
  }, []);
  const createReportData = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      academic_year_id: formData.gradingId,
      student_id: studId,
      class_id: formData.division_id,
      format: "pdf",
    });
    simplePostCall(ApiConfig.STUDENTS_REPORT, newRequestBody)
      .then((data) => {
        if (data.result) {
          setPdf(data.file_path);
          setReport(data.result);
          setLoading(false);
          setOpenMode(true);
        } else {
          toast.error(data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const AcademicYesrList = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "garding Appi");
        if (data.data) {
          var gradelists = [];
          data.data.map((grades) => {
            gradelists.push({
              label: grades.academic_year_name,
              value: grades.academic_year_id,
              ...grades,
            });
          });
          setGradeName({ ...gradename, gradelists: gradelists });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  function onAcademicChange(grading) {
    console.log(grading, "grading");
    if (formData.gradingName?.value !== grading.value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gradingName: grading,
        gradingId: grading.academic_year_id,
      }));
    }
  }
  const ClassList = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data, "Evalution Data");
        if (data.result) {
          var evaluationList = [];
          data.list?.map((grades) => {
            console.log(grades, "grades");

            evaluationList.push({
              label: grades.class_name + "-" + grades.class_division_name,
              value: grades.class_division_id,
              class_id: grades.class_id,
              ...grades,
            });
          });
          setEvaluation({ ...evalution, evaluationList: evaluationList });
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
      });
  };
  function onEvaluatuionChange(clsid) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      classname: clsid,
      division_id: clsid.class_division_id,
      class_id: clsid.class_id,
      students: "",
    }));
    StudentsList(clsid);
  }
  const onStudentChange = (stuid) => {
    console.log(stuid, "stuid");

    setFormData((prevFormData) => ({
      ...prevFormData,
      students: stuid,
      student_id: stuid.student_id,
    }));
    setStudId(stuid.student_id);
  };
  const StudentsList = (classnew) => {
    console.log(classnew, "classnew");
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classnew.class_id,
      section_id: classnew.value,
    });
    simplePostCall(ApiConfig.STUDENT_LIST_IN_STUDENTMANAG, newRequestBody)
      .then((data) => {
        console.log(data, "students Data");
        if (data.result) {
          var evaluationList = [];
          data.data?.map((grades) => {
            evaluationList.push({
              label: grades.student_name,
              value: grades.class_division_id,
              class_id: grades.class_id,
              ...grades,
            });
          });
          setStudents({ ...evalution, evaluationList: evaluationList });
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
      });
  };

  const OnLoading = () => {
    createReportData();
    setLoading(true);

    // setTimeout(() => {
    //   setOpenMode(true);
    // }, 3000);
  };
  return (
    <div>
      <div className="main-sectionn ">
        <div className="col-md-12">
          <div className="announcementCreatee">
            <label className="edit-head">Reports</label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 row">
            <div className="form-group col-md-12">
              <label for="task-title">{t("Academic Year")}</label>
              <Select
                class="js-example-basic-single form-select"
                name="gradingName"
                value={formData.gradingName}
                onChange={onAcademicChange}
                options={gradename.gradelists}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.terms ? "red" : "#ced4da",
                  }),
                }}
              />
              {errors && !formData.gradingName ? (
                <p className="val-form">Academic year is required...</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label for="task-title">{t("Grade")}</label>
              <Select
                class="js-example-basic-single form-select"
                name="gradingName"
                value={formData.evaluationName}
                onChange={onEvaluatuionChange}
                options={evalution.evaluationList}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.terms ? "red" : "#ced4da",
                  }),
                }}
              />

              {errors && !formData.evaluationName ? (
                <p className="val-form">Grade Name is required...</p>
              ) : null}
            </div>

            <div className="form-group col-md-12">
              <label for="task-title">{t("Student")}</label>
              <Select
                class="js-example-basic-single form-select"
                name="gradingName"
                value={formData.students}
                onChange={onStudentChange}
                options={students.evaluationList}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.terms ? "red" : "#ced4da",
                  }),
                }}
              />
              {errors && !maxMark ? (
                <p className="val-form">Student Name is required...</p>
              ) : null}
            </div>
          </div>
          <div className="create-but-1">
            <button
              className="btn btn-primary  crbt"
              type="button"
              onClick={() => OnLoading()}
            >
              {loading ? (
                <SyncLoader color="white" size={13} />
              ) : (
                t("View Report")
              )}
            </button>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        centered
        show={openMode}
        onHide={() => [setLoading(false), setOpenMode(false)]}
        animation={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Reports of Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="borderModal"></div>

          <div
            style={{
              display: "flex",
              height: `${windowHeight.current - 242}px`,
            }}
            className="borderModalBelow"
          >
            <iframe style={{ flex: 1, width: "100%" }} src={pdf} />
          </div>
        </Modal.Body>
        <div className="borderModal"></div>
        <Modal.Footer>
          <div className="modalBtns">
            <button
              className="cancelBtn"
              onClick={() => [setLoading(false), setOpenMode(false)]}
            >
              Cancel
            </button>
            {/* <button className="YesBtn" onClick={() => [setOpenMode(false)]}>
              Delete
            </button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
