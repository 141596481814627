import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function EditSyllabusSkelton() {
  return (
    <div>
      <div className="main-sectionn ">
        <div className="col-md-12">
          <div className="announcementCreatee">
            <label className="edit-head">
              <Skeleton width={400} height={30} />
            </label>
          </div>
        </div>
        <form>
          <div className="col-md-12">
            <div className="row g-ap">
              <div className="col-md-6">
                <label>
                  <Skeleton width={200} height={30} />
                </label>
                <Skeleton width={200} height={30} />
              </div>
              <div className="col-md-6">
                <label>
                  <Skeleton width={200} height={30} />
                </label>
                <Skeleton width={200} height={30} />
              </div>
              <div className="col-md-6">
                <label>
                  <Skeleton width={200} height={30} />
                </label>
                <Skeleton width={200} height={30} />
              </div>
              <div className="col-md-6">
                <label>
                  <Skeleton width={200} height={30} />
                </label>
                <Skeleton width={200} height={30} />
              </div>
              <div className="col-md-6">
                <label>
                  <Skeleton width={200} height={30} />
                </label>
                <Skeleton width={200} height={30} />
              </div>
            </div>
          </div>
          <div className="col-md-12 edit-but">
            <Skeleton width={100} height={30} />
          </div>
        </form>
      </div>
    </div>
  );
}
