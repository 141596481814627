import React, { useEffect, useState } from "react";
import Select from "react-select";
import Params from "../../../config/Params";
import { useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import { toast } from "react-toastify";
import NewSyllabusSkelton from "./NewSyllabusSkelton";
import EditSyllabusSkelton from "./EditSyllabusSkelton";

export default function EditSyallabuss() {
  const [tableDataStore, setTabledataStore] = useState([]);
  const [AccdamicYear, setAccdamicYear] = useState("");
  const [Year, setYear] = useState([]);
  const [classname, setClassname] = useState("");
  const [Selectedclass, setSelectedclass] = useState([]);
  const [curriculumState, setCurriculumState] = useState("");
  const [CurriculamsListss, setCurriculamsListss] = useState([]);
  const [subjects, setSubjects] = useState("");
  const [stSubject, setstSubject] = useState([]);
  const [editclassName, setEditClassName] = useState("");
  const [editYear, seteditYear] = useState("");
  const [editcarriculam, seteditCarriculam] = useState("");
  const [syllabusName, setSyllabusName] = useState("");
  const [editSubject, seteditSubject] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // <----------Table Data Show----------->
  const location = useLocation();
  const getData = location.state;
  const navic = useNavigate();
  const syllabusViewDATA = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_id: getData,
    });
    simplePostCall(ApiConfig.EDIT_VIEW_DATA, RequestBody)
      .then((data) => {
        console.log(data, "data passed");
        if (data.list) {
          setIsLoading(false);
          // setLoading(false);
          console.log(data.list, "SAMPPLE");
          setTabledataStore(data.list[0]);
        } else {
          // setLoading(false);
          console.log("NO DATA in view");
        }
      })
      .catch((err) => {
        // setLoading(false);
        // console.log("api res fail", err);
      });
  };

  useEffect(() => {
    syllabusViewDATA();
    yearList();
    classList();
    getCurriculumList();
  }, []);
  // <-----------Upadate Data -------->
  const updateSyalabusData = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_id: getData,
      academic_year_id: editYear ? editYear : tableDataStore.academic_year_id,
      class_id: editclassName ? editclassName : tableDataStore.class_id,

      curriculum_id: editcarriculam
        ? editcarriculam
        : tableDataStore.curriculum_id,
      syllabus_name: syllabusName ? syllabusName : tableDataStore.syllabus_name,
      subject_id: editSubject ? editSubject : tableDataStore.subject_id,
    });
    {
      console.log(
        RequestBody,
        "RequestBody",
        editYear,
        "editYear",
        tableDataStore.academic_year_name,
        "tableDataStore.academic_year_name",
      );
    }
    if (
      editYear ||
      editclassName ||
      editcarriculam ||
      syllabusName ||
      editSubject
    ) {
      simplePostCall(ApiConfig.TABLE_EDIT_VIEW, RequestBody)
        .then((data) => {
          console.log(data, "update data data......");
          if (data.result) {
            console.log(data, "fulldatas");
            navic("/NewSyllabus");
            toast.success("Syllabus updated successfully!");
          } else {
            toast.error("Failed to update syllabus.");
          }
        })
        .catch((err) => {
          console.log("API request failed", err);
        });
    } else {
      // If no edits were made, navigate back
      navic("/NewSyllabus");
    }
  };
  // <------------------------------->
  // ---------AcadamicYear Api----------
  function yearList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.YEAR_LIST, newRequestBody)
      .then((data) => {
        // console.log(data, "YEAR data Lists");
        if (data.result) {
          var yearlist = [];
          if (data.data?.length > 0) {
            data.data.map((clases, index) => {
              yearlist.push({
                label: clases.academic_year_name,
                value: clases.academic_year_id,
              });
            });
          }
          setAccdamicYear({ ...AccdamicYear, yearlist: yearlist });
          // console.log(AccdamicYear, "AccYear");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ---------AcadamicYear----------
  const onYearChange = (selecte) => {
    // console.log(selecte, "yearss");
    setYear(selecte.value);
    seteditYear(selecte.value);
    console.log(editYear, "edit year");
  };
  // ------------------------------
  // ---------classListApi----------
  function classList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        // setClassess(data.list);
        console.log(data, "data Lists");
        if (data.result) {
          var classlists = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              classlists.push({
                label: clases.class_name,
                value: clases.class_division_id,
                class_id: clases.class_id,
              });
            });
          }
          setClassname({ ...classname, classlists: classlists });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // -----------------------------------
  // ---------classList----------
  function onGradeChange(selected) {
    console.log(selected, "selected");
    let temp = selected.value;
    setSelectedclass(selected.class_id);
    setEditClassName(selected.class_id);
    console.log(editclassName, "editCalssName");
    subjectList(temp);
  }
  // ------------------------------
  // ---------Curriculam ----------
  const onCurriculamChange = (curriculums) => {
    setCurriculamsListss(curriculums.value);
    seteditCarriculam(curriculums.value);
  };
  // ------------------------------
  // <-------------Syllabus/curriculum Api---------->

  function getCurriculumList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        console.log(data, "YEAR data Lists");
        if (data.result) {
          var curriculums = [];
          if (data?.curriculum_list.length > 0) {
            data.curriculum_list.map((clases, index) => {
              console.log(clases, "curicullam");
              curriculums.push({
                label: clases.curriculum_name,
                value: clases.curriculum_id,
              });
            });
          }
          console.log(curriculums, "curriculums");
          setCurriculumState({ ...curriculumState, curriculums: curriculums });
          console.log(curriculumState, "AccYear");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ----------------------------------------------
  // ---------Subject Api----------
  function subjectList(class_id) {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: class_id,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data, "subjects");
        if (data.result) {
          var subjectlist = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              subjectlist.push({
                label: clases.subject_name,
                value: clases.subject_id,
              });
            });
          }
          setSubjects({ ...subjects, subjectlist: subjectlist });
          console.log(subjects, "subjects");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ---------Subject----------
  const onSubjectChange = (subjectList) => {
    setstSubject(subjectList.value);
    seteditSubject(subjectList.value);

    console.log(subjectList);
  };
  // ------------------------------

  return (
    <>
      {isLoading ? (
        <EditSyllabusSkelton />
      ) : (
        <div className="main-sectionn ">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="edit-head">Update Master Syllabus:</label>
            </div>
          </div>
          <form>
            <div className="col-md-12">
              <div className="row g-ap">
                <div className="col-md-6">
                  <label>Syllabus Name</label>
                  <input
                    type="text"
                    className="textar"
                    defaultValue={tableDataStore.syllabus_name}
                    onChange={(e) => {
                      setSyllabusName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label>Academic Year</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={tableDataStore.academic_year_name}
                    value={AccdamicYear.label}
                    onChange={onYearChange}
                    options={AccdamicYear.yearlist}
                  ></Select>
                </div>
                <div className="col-md-6">
                  <label>Syllabus</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={tableDataStore.curriculum_name}
                    value={curriculumState.label}
                    onChange={onCurriculamChange}
                    options={curriculumState?.curriculums}
                  ></Select>
                </div>
                <div className="col-md-6">
                  <label>Class</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={tableDataStore.class_name}
                    value={classname.label}
                    onChange={onGradeChange}
                    options={classname.classlists}
                  ></Select>
                </div>
                <div className="col-md-6">
                  <label>Subject</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={tableDataStore.subject_name}
                    value={subjects.label}
                    onChange={onSubjectChange}
                    options={subjects.subjectlist}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="col-md-12 edit-but">
              <button
                type="button"
                className="buttcreate1"
                onClick={() => updateSyalabusData()}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
