import React, { useEffect } from "react";
import Punch from "../myattendance/Punch";
import PunchDetails from "../myattendance/PunchDetails";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MyAttendance() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Edsys | My Attendance ";
  }, []);
  return (
    <>
      {/* Container-fluid starts */}
      <div class="row my-attendance">
        <div class="col-md-12 text-end app-le">
          <Link to="/EmpLeaves" class="btn-apply-leave">
            {t("Apply Leave")}
          </Link>
        </div>
        <div class="col-md-12">
          <div class="card profile-greeting">
            <div class="confetti">
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
            </div>
            <div class="card-body">
              <div class="row edsys-check-in">
                <Punch />
                <PunchDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-fluid Ends  */}
    </>
  );
}

export default MyAttendance;
