import React, { useState } from "react";

const MultiColorProgressBar = ({ readings }) => {
  const [first, setfirst] = useState(readings);
  console.log(first, "first");
  // Calculate cumulative values for each item
  const calculateCumulativeValues = () => {
    let cumulativeValue = 0;
    return readings.map((item) => {
      cumulativeValue += item.value;
      return cumulativeValue.toFixed(2); // Cumulative percentage rounded to 2 decimal places
    });
  };
  {
    console.log(readings, "readings");
  }
  // Get cumulative values for each item
  const cumulativeValues = calculateCumulativeValues();

  // Generate the components for values, calibrations, bars, and legends
  const values = readings.map((item, i) => (
    <div
      className="value"
      style={{ color: item.color, width: item.value + "%" }}
      key={i}
    >
      <span className="fontsp">{cumulativeValues[i]}%</span>
    </div>
  ));

  const calibrations = readings.map((item, i) => (
    <div
      className="graduation"
      style={{ color: item.color, width: item.value + "%" }}
      key={i}
    >
      <span>|</span> {/* This span will show initially and then update */}
    </div>
  ));
  const calibrationss = readings.map((item, i) => (
    <div
      className="graduation"
      style={{ color: item.color, width: item.startPercentage + "%" }}
      key={i}
    >
      <span>|</span> {/* This span will show initially and then update */}
    </div>
  ));

  const bars = readings.map((item, i) => (
    <div
      className="bar"
      style={{ backgroundColor: item.color, width: item.value + "%" }}
      key={i}
    >
      <div className="gradee">{item.slabCode}</div>
    </div>
  ));

  const legends = readings.map((item, i) => (
    <div className="legend" key={i}>
      <span className="dot" style={{ color: item.color }}>
        ●
      </span>
      <span className="label">{item.name}</span>
    </div>
  ));

  return (
    <div>
      <div>
        {/* <span className="">0</span> */}
        <span className="line">
          <div style={{ fontSize: "11px" }}>
            {readings[0]?.startPercentage}%
          </div>
          <div>|</div>
        </span>
      </div>
      <div className="multicolor-bar">
        <div className="values">{values}</div>
        <div className="scale">{calibrations}</div>
        {/* <div className="scale">{calibrationss}</div> */}

        <div className="bars">{bars}</div>
        <div className="legends" style={{ marginTop: "12px", gap: "10px" }}>
          {legends}
        </div>
      </div>
    </div>
  );
};

export default MultiColorProgressBar;
