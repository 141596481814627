import React, { useState, useEffect } from "react";
import Params from "../../../config/Params";
import AssignmentStepOne from "./MultiStep/AssignmentStepOne";
import AssignmentStepTwo from "./MultiStep/AssignmentStepTwo";
import ApiConfig from "../../../api/ApiConfig";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { toast } from "react-toastify";
import { simplePostCall } from "../../../api/ApiServices";
import { useParams, useRoutes } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function CreateAssignment() {
  const { t } = useTranslation();

  const { assignmentDetailsState, setAssignmentDetailsState } =
    useContext(AppContext);
  console.log("assignmentDetailsState", assignmentDetailsState);
  const [ssigmentdetails, setssigmentdetails] = useState();
  const { id } = useParams();
  console.log(id, "IDDDDD");

  const [state, setState] = useState(0);
  useEffect(() => {
    getAssignments();
  }, []);

  function handleNext() {
    if (state !== 2) {
      setState(state + 1);
    } else {
      return;
    }
  }
  function handlePrevious() {
    if (state !== 0) {
      setState(state - 1);
    } else {
      return;
    }
  }

  function handleAssisment(stateData) {
    debugger;
    if (id) {
      UpdatesaveAssignmentInfo(stateData);
    } else {
      saveAssignmentInfo(stateData);
    }
  }
  // console.log(stateData.lessons, "stateData.lessons");
  const [studentid, setstudentid] = useState([]);

  function UpdatesaveAssignmentInfo(stateData) {
    setAssignmentDetailsState({ ...assignmentDetailsState, isloading: true });
    const lessons = assignmentDetailsState.lessons.map(
      (lesson) => lesson.value,
    );
    const topic = assignmentDetailsState.topics.map((topics) => topics.value);
    console.log(lessons, "lessons");
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assignment_id: id,
      title: stateData.assignment_title,
      description: stateData.assignment_description,
      class_division_id:
        ssigmentdetails.assignment_class_division_id ||
        stateData.class_division_id,
      // students: ssigmentdetails.assignment_students || stateData.students,
      students:
        Array.isArray(stateData.students) &&
        stateData.students.length === 1 &&
        typeof stateData.students[0] === "string"
          ? studentid
          : stateData.students,
      subject: ssigmentdetails.assignment_subject_id || stateData.subject,
      submission_date: stateData.submission_date,
      curriculum: ssigmentdetails.assignment_curriculum || stateData.curriculum,
      // lesson: stateData.lessons || lessons,
      lesson:
        stateData.lessons.length === 1 &&
        typeof stateData.lessons[0] === "string"
          ? stateData.lessons
          : lessons,

      // topic: stateData.topics || assignmentDetailsState.topics,
      topic:
        stateData.topics.length === 1 && typeof stateData.topics[0] === "string"
          ? stateData.topics
          : topic,
      submission_mode: stateData.submission_mode == "Any time" ? "Yes" : "no",
      type: stateData.assignment_type,
      review_by_id: stateData.review_by_user_id,
    });

    simplePostCall(ApiConfig.UPDATE_ASSIGNMENT_API, newRequestBody)
      .then((data) => {
        // console.log("from UPDATE_ASSIGNMENT_API Api", data, newRequestBody);
        if (data.result) {
          setAssignmentDetailsState({
            ...assignmentDetailsState,
            isloading: false,
            assignment_id: data.assignment_id,
          });
          toast.success(data.message);
        } else {
          setAssignmentDetailsState({
            ...assignmentDetailsState,
            isloading: false,
          });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setAssignmentDetailsState({
          ...assignmentDetailsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }

  function saveAssignmentInfo(stateData) {
    setAssignmentDetailsState({ ...assignmentDetailsState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      title: stateData.assignment_title,
      description: stateData.assignment_description,
      class_division_id: stateData.class_division,
      students: stateData.students,
      subject: stateData.subject,
      submission_date: stateData.submission_date,
      curriculum: stateData.curriculum,
      lesson: stateData.lessons,
      topic: stateData.topics,
      submission_mode: stateData.submission_mode == "Any time" ? "Yes" : "no",
      type: stateData.assignment_type,
      review_by_id: stateData.review_by_user_id,
    });
    simplePostCall(ApiConfig.ASSIGNMENT_CREATE_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setAssignmentDetailsState({
            ...assignmentDetailsState,
            isloading: false,
            assignment_id: data.assignment_id,
          });
          toast.success(data.message);
        } else {
          setAssignmentDetailsState({
            ...assignmentDetailsState,
            isloading: false,
          });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setAssignmentDetailsState({
          ...assignmentDetailsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }
  const [classuid, setclassuid] = useState(36);
  function getAssignments() {
    // setAssignmentDetailsState.isloading (true)
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assignment_id: id,
    });

    simplePostCall(ApiConfig.ASSIGNMENT_DETAILS_API, newRequestBody)
      .then((data) => {
        setclassuid(data.assignment_details.assignment_class_division_id);
        console.log(
          data,
          "dataq from ASSIGNMENT_DETAILS_API",
          newRequestBody,
          ApiConfig.ASSIGNMENT_DETAILS_API,
        );
        let user_profile = data.assignment_details;
        let user_profileSt = data.assignment_students;
        const Stud_Name = user_profileSt.map((item) => item.student_name);
        const Stud_id = user_profileSt.map((item) => item.student_id);
        let user_lesson = data.lesson;
        const topicIds = [];
        user_lesson.forEach((lesson) => {
          // Iterate over each topic in the lesson
          lesson.topics.forEach((topic) => {
            // Push the topic_id to the topicIds array
            topicIds.push({ value: topic.topic_id, label: topic.topic_name });
          });
        });

        const Stud_Lesson = user_lesson.map((item) => ({
          value: item.lesson_id,
          label: item.lesson_name,
        }));

        console.log("user_profile", topicIds, user_profileSt);
        setssigmentdetails(user_profile);
        setstudentid(Stud_id);
        setAssignmentDetailsState({
          assignment_title: user_profile.assignment_title,
          assignment_description: user_profile.assignment_description,
          curriculum: user_profile.assignment_curriculum_name,
          class_division: user_profile.assignment_class,
          subject: user_profile.assignment_subject,
          assignment_type: user_profile.assignment_type,
          submission_mode: user_profile.assignment_submission_mode,
          submission_date: user_profile.assignment_submission_date,
          review_by_user_id: user_profile.assignment_assigned_by,
          students: Stud_Name,
          lessons: Stud_Lesson,
          topics: topicIds,
        });
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  return (
    <>
      {assignmentDetailsState.isloading ? (
        <SpinnerCmp loading={assignmentDetailsState.isloading} />
      ) : (
        <>
          <div className="row assignment-sec">
            <div className="col-sm-12">
              {/*<MultiStep activeStep={0} showNavigation={true} steps={this.state.steps} />*/}

              <div class="justify-content-center">
                <div class="">
                  <div class="card progress_bar_card px-0 pt-4 pb-0 mt-3 mb-3">
                    <div id="msform">
                      <ul id="progressbar">
                        <li
                          class={"active"}
                          id="account"
                          style={{ width: "50%" }}
                        >
                          {id ? (
                            <strong>Edit Assignment</strong>
                          ) : (
                            <strong>{t("Create Assignment")}</strong>
                          )}
                        </li>
                        <li
                          class={state > 0 ? "active" : ""}
                          id="personal"
                          style={{ width: "50%" }}
                        >
                          <strong>{t("Add Tasks")}</strong>
                        </li>
                      </ul>
                      <div class="progress">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: 50 * (state + 1) + "%" }}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {state === 0 ? (
                <AssignmentStepOne
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  saveAssignmentInfo={handleAssisment}
                  assignmentDetails={assignmentDetailsState}
                  classid={classuid}
                  id={id}
                />
              ) : state === 1 ? (
                <AssignmentStepTwo
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  assignmentDetails={assignmentDetailsState}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
