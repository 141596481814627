import React from "react";
import Skeleton from "react-loading-skeleton";

export default function EvaluationSkelton() {
  return (
    <div>
      <div className="evaluation">
        <div className="evalHead">
          <h5 className="labelCreate">
            <Skeleton width={200} height={30} />
          </h5>
          <div>
            <div>
              <Skeleton width={200} height={30} />
            </div>
          </div>
        </div>
        <div className="table">
          <table class=" table-dark table-borderless newTable ">
            <thead className="tableHea">
              <tr>
                <th>
                  <Skeleton width={150} />
                </th>
                <th>
                  <Skeleton width={150} />
                </th>
                <th>
                  <Skeleton width={150} />
                </th>
                <th></th>
              </tr>
            </thead>
            {Array(5)
              .fill()
              .map((_, index) => (
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={150} />
                    </td>
                    <td>
                      <Skeleton width={150} />
                    </td>
                    <td>
                      <Skeleton width={150} />
                    </td>
                    <td className="iconSec">
                      <div>
                        <Skeleton width={70} />
                      </div>
                      <div>
                        <Skeleton width={70} />
                      </div>

                      <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1
                                class="modal-title fs-5"
                                id="staticBackdropLabel"
                              ></h1>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              Are you sure you want to delete this item?
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}
