import React from "react";
import Skeleton from "react-loading-skeleton";

export default function NewSyllabusSkelton() {
  return (
    <div className="row syllabus-sec">
      <div className="col-md-12">
        <div className="card br-15">
          <div className="card-header pb-0">
            <div className="d-flex">
              <h5 className="flex-grow-1">
                <Skeleton width={200} height={30} />
              </h5>

              <div>
                <div className="btnn">
                  <Skeleton width={200} height={30} />
                </div>
              </div>

              <a
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <span>
                  <Skeleton width={200} height={30} />
                </span>
              </a>
            </div>
          </div>

          <div className="syllabus-pro1">
            <div className="tableCreate">
              <table class="table table-dark table-borderless newTable ">
                <thead className="tableHead">
                  <th>
                    <Skeleton width={150} />
                  </th>
                  <th className="sYna">
                    <Skeleton width={150} />
                  </th>
                  <th>
                    <Skeleton width={150} />
                  </th>
                  <th>
                    <Skeleton width={150} />
                  </th>
                  <th>
                    <Skeleton width={150} />
                  </th>
                  <th>
                    <Skeleton width={150} />
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <div>
                        <Skeleton width={200} />
                      </div>
                    </td>
                    <td>
                      <div></div>
                    </td>
                    <td>
                      <div>
                        {/* syllabus */}
                        <Skeleton width={200} />
                      </div>
                    </td>
                    <td>
                      <div>
                        {/* class */}
                        <Skeleton width={200} />
                      </div>
                    </td>
                    <td>
                      <div>
                        {/* subject */}
                        <Skeleton width={200} />
                      </div>
                    </td>
                    <td>
                      <div>
                        <Skeleton width={200} />
                      </div>
                    </td>
                  </tr>
                  {Array(5)
                    .fill()
                    .map((_, index) => (
                      <tr className="secroW tab-text-color">
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={20} height={20} />
                        </td>
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td style={{ color: "#666970", fontWeight: "500" }}>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td>
                          <div className="iconetable">
                            <div className="menuicone">
                              <Skeleton width={20} height={20} />
                            </div>
                            <div className="menuicone1">
                              <Skeleton width={20} height={20} />
                              {/* {console.log(item, "valuesss")} */}
                            </div>
                            {/* // onClick={() => deleteData(item.syllabusId)} */}
                            <button
                              type="submit"
                              className="menuicone2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <Skeleton width={20} height={20} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
