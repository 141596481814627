import React, { useEffect, useState } from "react";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { useLocation, useNavigate } from "react-router-dom";
import MultiColorProgressBar from "./Multiprogress";

export const GradeListView = () => {
  const [allGrades, setAllGrades] = useState([]); // State to store all grade slab values
  const location = useLocation();
  const navigate = useNavigate();
  const getData = location.state;

  const Gradewise = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: getData,
    });
    simplePostCall(ApiConfig.GRADEWISE_LIST_VIEW, RequestBody)
      .then((data) => {
        console.log(data, "chart data");
        if (data.list && data.list.length > 0) {
          const colors = [
            "#2F3C7E",
            "#F96167",
            "#6ab04c",
            "#e056fd",
            "#f0932b",
            "#95afc0",
            "#4834d4",
          ];

          // Flatten the array of grade slabs
          const accumulatedGrades = data.list.flatMap((item) =>
            item.grade_slab.map((slab, slabIndex) => {
              // Calculate the value and log it
              const value =
                slab.assessment_grading_slab_end_percentage -
                slab.assessment_grading_slab_start_percentage;

              console.log("Value:", value); // Console log the calculated value
              return {
                value,
                name: slab.assessment_grading_slab_name,
                startPercentage: slab.assessment_grading_slab_start_percentage,
                endPercentage: slab.assessment_grading_slab_end_percentage,
                slabCode: slab.assessment_grading_slab_code,
                color: colors[slabIndex % colors.length],
              };
            }),
          );
          // Update state with accumulatedGrades
          setAllGrades(accumulatedGrades);
          console.log(allGrades, "all grades");
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
      });
  };

  useEffect(() => {
    Gradewise();
  }, []);

  return (
    <div className="evaluationn">
      <div className="evalHea">
        <h5 className="labelCreate">Grading View</h5>
        {/* <div className="createBut">
          <div className="CreateB" onClick={() => navigate("/createGradelist")}>
            Create Grade List
          </div>
        </div> */}
      </div>
      <div className="charts">
        <MultiColorProgressBar readings={allGrades} />
      </div>
    </div>
  );
};
