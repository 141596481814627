import React from 'react';
import AddEditTag from './AddEdit_Tags';
import AddEditTodo from './AddEdit_To_do';
import { CheckCircle, PlusCircle } from "react-feather";
import TodoDetails from './To_do_Details';
import { simplePostCall } from '../../api/ApiServices';
import Params from '../../config/Params';
import ApiConfig from '../../api/ApiConfig';
import SpinnerCmp from '../../components/SpinnerCmp';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

export class TodoMenus extends React.Component {
    //#region constructor
    constructor(props) {
        super(props);
        this.state = {
            isTodoModelShow: false,
            isTagModelShow: false,
            isLoading: true,
            todo_list: [],
            detailModal: {
                title: "Today's Tasks",
                title_val: "today",
                todoList: []
            },
            todo: [],
        };
    }
    //#endregion

    //#region Life Cycle Methodes
    componentDidMount() {
        this.getTodoList();
    }
    //#endregion

    //#region custom Methods
    setInitialState = () => {
        this.setState({
            isTodoModelShow: false,
            isTagModelShow: false
        })
    }
    openCloseTodoModel = (todo = []) => {
        // this.setInitialState();
        this.setState({ isTodoModelShow: !this.state.isTodoModelShow, todo: todo }, () => {
        });
    };
    openCloseTagModel = () => {
        // this.setInitialState();
        this.setState({ isTagModelShow: !this.state.isTagModelShow })
    };
    onTabClick = (event) => {
        const name = event.currentTarget.dataset.value;
        const { detailModal } = this.state;
        switch (name.toLowerCase()) {
            case "todaytask":
                detailModal.title = "Today's Tasks";
                detailModal.title_val = "today";
                detailModal.todoList = this.state.todo_list.today_todos;
                break;
            case "delayed":
                detailModal.title = "Delayed Tasks";
                detailModal.title_val = "delayed";
                detailModal.todoList = this.state.todo_list.delayed_todos;
                break;
            case "upcoming":
                detailModal.title = "Upcoming Tasks";
                detailModal.title_val = "upcoming";
                detailModal.todoList = this.state.todo_list.upcoming_todos;
                break;
            case "weekly":
                detailModal.title = "This Week Tasks";
                detailModal.title_val = "week";
                detailModal.todoList = this.state.todo_list.this_week_todos;
                break;
            case "monthly":
                detailModal.title = "This Month Tasks";
                detailModal.title_val = "month";
                detailModal.todoList = this.state.todo_list.this_month_todos;
                break;
        }
        this.setState({ detailModal: detailModal });
    };
    //#endregion

    getTodoList() {
        this.setState({ ...this.state, isLoading: true });
        const newRequestBody = {
            api_key: Params.api_key,
            customer_id: Params.customer_id,
            user_id: Params.teacher_id
        };
        simplePostCall(ApiConfig.TODO_LIST_API, JSON.stringify(newRequestBody))
            .then((data) => {

                if (data.result) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        todo_list: data.todo_list,
                        detailModal: {
                            title: "Today's Tasks",
                            title_val: "today",
                            todoList: data.todo_list.today_todos
                        }
                    });
                } else {
                    this.setState({ ...this.state, isLoading: false });
                    toast.error(data.message);
                }
            })
            .catch((error) => {
                this.setState({ ...this.state, isLoading: false });
                console.log("api response", error);
            });
    }

    //#region Render
    render() {
        const { t } = this.props;
        //#(region return
        return (
            <>
                <div className="col-xl-3 box-col-4 xl-30">
                    <div className="email-sidebar">
                        <a className="btn btn-primary email-aside-toggle" href="javascript:void(0)">bookmark filter</a>
                        <div className="email-left-aside">
                            <div className="card to-do-scroll">
                                <div className="card-body">
                                    <div className="email-app-sidebar left-bookmark">
                                        <h5 className="mb-3">{t('To-Do')}</h5>
                                        <ul className="nav main-menu" role="tablist" style={{
                                            cursor: 'pointer'
                                        }}>
                                            <li className="nav-item">
                                                <button className="btn btn-primary btn-block" type="button" style={{ width: "100%" }} data-bs-toggle="modal" onClick={this.openCloseTodoModel} data-bs-target="#dvAddEditTodo"><CheckCircle className="me-2" />{t('New Task')}</button>
                                            </li>
                                            <li className="nav-item"><span className="main-title mt-3">{t('Views')}</span></li>
                                            <li><a id="pills-created-tab" className={this.state.detailModal.title_val == "today" ? "show active" : "show"} onClick={(event) => this.onTabClick(event)} data-bs-toggle="pill" data-value="todaytask" role="tab" aria-controls="pills-todaytask" aria-selected={this.state.detailModal.title_val == "today" ? "true" : "false"}><span className="title">{t('Today Tasks')}</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-delayed-tab" data-bs-toggle="pill" data-value="delayed" role="tab" aria-controls="pills-delayed" aria-selected="false"><span className="title">{t('Delayed Tasks')}</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-upcoming-tab" data-bs-toggle="pill" data-value="upcoming" role="tab" aria-controls="pills-upcoming" aria-selected="false"><span className="title">{t('Upcoming Tasks')}</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-weekly-tab" data-bs-toggle="pill" data-value="weekly" role="tab" aria-controls="pills-weekly" aria-selected="false"><span className="title">{t('This week tasks')}</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-monthly-tab" data-bs-toggle="pill" data-value="monthly" role="tab" aria-controls="pills-monthly" aria-selected="false"><span className="title">{t('This month tasks')}</span></a></li>
                                            <li>
                                                <hr />
                                            </li>
                                            {
                                                /*<li><span className="main-title"> Tags<span className="pull-right"><a data-bs-toggle="modal" data-bs-target="#createtag" onClick={this.openCloseTagModel}><PlusCircle /></a></span></span></li>
                                                <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-notification-tab" data-bs-toggle="pill" data-value="notification" role="tab" aria-controls="pills-notification" aria-selected="false"><span className="title"> Notification</span></a></li>
                                                <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-newsletter-tab" data-bs-toggle="pill" data-value="newsletter" role="tab" aria-controls="pills-newsletter" aria-selected="false"><span className="title"> Newsletter</span></a></li>*/
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isTodoModelShow ? <AddEditTodo onClose={this.openCloseTodoModel} todo={this.state.todo} /> : null}
                        {/*this.state.isTagModelShow ? <AddEditTag onClose={this.openCloseTagModel} /> : null*/}
                    </div>
                </div >
                <div className="col-xl-9 col-md-12 box-col-8 xl-70">
                    {this.state.isLoading == false ? <TodoDetails data={this.state.detailModal} openCloseTodoModel={this.openCloseTodoModel} /> : <SpinnerCmp />}
                </div>
            </>
        );
    }
}
export default withTranslation() (TodoMenus);