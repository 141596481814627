import React, { useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import SpinnerCmp from "../../components/SpinnerCmp";
import Params from "../../config/Params";
import { useTranslation } from "react-i18next";
import moment from "moment";

function PunchDetails() {
  const { t } = useTranslation();

  const [punchDetails, setPunchDetails] = useState([]);
  const [academicMonths, setAcademicMonths] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [isloading, setIsloading] = useState(true);
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const d = new Date();
  var mm = d.getUTCMonth() + 1;
  const loadPunchDetails = (paramDate) => {
    setIsloading(true);

    const newParams = {
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      employee_id: Params.teacher_id,
      date: date ? date : paramDate,
    };
    simplePostCall(ApiConfig.ATTENDANCE_API, JSON.stringify(newParams))
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          setPunchDetails(data.attendance);
          setAcademicMonths(data.academic_months);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("api response", error);
      });
  };
  useEffect(() => {
    const date = d.getFullYear() + "-" + (mm < 10 ? "0" + mm : mm) + "-01";
    setDate(date);
    loadPunchDetails(date);
  }, []);
  useEffect(() => {
    loadPunchDetails();
  }, [date]);

  const onMonthChange = (month) => {
    setIsloading(true);

    var dateArr = date.split("-");
    const paramDate =
      dateArr[0] + "-" + (month < 10 ? "0" + month : month) + "-" + dateArr[2];
    setDate(paramDate);
    loadPunchDetails(paramDate);
  };

  return (
    <div className="col-md-7 punch-review">
      <h4>{t("Punch Details")}</h4>
      <div
        className="select-month d-flex nav nav-pills"
        id="v-pills-tab"
        role="tablist"
        style={{ whiteSpace: "nowrap" }}
      >
        {academicMonths.length > 0
          ? academicMonths.map((month, index) => {
              return (
                <div
                  class={"months" + (index + 1 === mm ? " active" : "")}
                  id={"pills-" + month + "-tab"}
                  data-bs-toggle="pill"
                  href="#pills-jan"
                  role="tab"
                  aria-controls={"pills-" + month}
                  aria-selected={index === 0 ? "true" : "false"}
                  onClick={() => {
                    // onMonthChange(index + 1);
                    setDate(month.academic_month);
                  }}
                >
                  {month.display_academic_month}
                </div>
              );
            })
          : null}
      </div>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-mar"
          role="tabpanel"
          aria-labelledby="pills-mar-tab"
        >
          {isloading ? (
            <SpinnerCmp loading={isloading} />
          ) : (
            <div id="march-2021" className="punch-status">
              {punchDetails.length > 0 ? (
                punchDetails.map((obj, index) => {
                  let todaysDateStr = new Date()
                    .toLocaleDateString()
                    .replace(new RegExp("/", "g"), "-");

                  const percent =
                    obj.status_punch > 0
                      ? Math.round(
                          (Math.abs(
                            new Date(obj.date + " " + obj.timein) -
                              new Date(obj.date + " " + obj.timeout),
                          ) /
                            1000 /
                            60 /
                            60 /
                            9) *
                            100,
                        )
                      : 100;
                  const barPersentStyle =
                    (percent || percent === 0) && percent <= 100
                      ? percent
                      : 100;

                  const classProgress =
                    obj.status_punch > 0
                      ? "attend"
                      : obj.remarks.toLowerCase() === "weekend"
                      ? "holiday"
                      : "leave";

                  return (
                    <div key={index} className="row g-0">
                      <div className="col-1">
                        <div className="date-tile">
                          <div className="date">{obj.day}</div>
                          <div className="day">{obj.dayname.toUpperCase()}</div>
                        </div>
                      </div>
                      <div className="col-11">
                        <div className="punch-progress">
                          {obj.remarks === "Present" ? (
                            <p className="m-0 p-0">
                              {obj.punched_percentage + "%"}
                            </p>
                          ) : obj.remarks === "Missed Punch out" ? (
                            <p className="m-0 p-0">{obj.remarks}</p>
                          ) : (
                            ""
                          )}
                          <div className="progress ">
                            <div
                              className={"progress-bar " + classProgress}
                              role="progressbar"
                              style={{
                                width: barPersentStyle + "%",
                                ariaValuenow: "100",
                                ariaValuemin: "0",
                                ariaValuemax: "100",
                              }}
                            ></div>
                            {obj.status_punch > 1 &&
                            percent > 50 &&
                            percent < 100 ? (
                              <div
                                className={"progress-bar " + classProgress}
                                role="progressbar"
                                style={{
                                  width: barPersentStyle - 100 + "%",
                                  ariaValuenow: "100",
                                  ariaValuemin: "0",
                                  ariaValuemax: "100",
                                }}
                              ></div>
                            ) : null}
                          </div>
                          <div className="d-flex justify-content-between working-hrs">
                            {obj.status_punch > 0 ? (
                              <div className="start-time">{obj.timein}</div>
                            ) : (
                              <div className="start-time">{obj.remarks}</div>
                            )}
                            <div
                              className="end-time"
                              style={{
                                marginLeft:
                                  obj.status_punch > 1 &&
                                  percent > 50 &&
                                  percent < 100
                                    ? "80px"
                                    : "",
                              }}
                            >
                              {obj.status_punch === 2 ? obj.timeout : ""}
                            </div>

                            {obj.status_punch === 1 &&
                            new Date(obj.date) > new Date(todaysDateStr) ? (
                              <div className="leave-stat">{obj.remarks}</div>
                            ) : null}

                            {obj.status_punch > 1 &&
                            percent > 50 &&
                            percent < 100 ? (
                              <div className="leave-stat">
                                Second Half Leave
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row g-0">
                  <div className="col-11">
                    <div className="punch-progress">
                      <p>Records not found!</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PunchDetails;
