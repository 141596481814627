import React, { useState } from "react";
import Calendar from "@toast-ui/react-calendar";
// import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import styled from "styled-components";
const AcademicCalender = () => {
  const [value, onChange] = useState(new Date());
  return (
      <>
        {/* <!-- Container-fluid starts--> */}
        <div className="row syllabus-sec">
          <div className="col-md-12">
            <div className="card ">
            <div className="card-body ">
              <Calendar
                disableDblClick={true}
                disableClick={false}
                // isReadOnly={true}
              />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </>
  );
};

export default AcademicCalender;

const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */

  width: 100%;
  max-width: 600%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 13px;
`;
