import React, { useEffect, useState } from "react";
// import { Image } from 'react'
import header_icon from "../../assets/images/e-learning/header-icon.svg";
import { Link, useParams, useNavigate } from "react-router-dom";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

function ImgVideoGallary() {
  const { t } = useTranslation();

  const [DetailsList, setDetailsList] = useState([]);
  const [detailsType, setDetailsType] = useState([]);
  console.log("detailsType", detailsType);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
    document.title = "Edsys | Image Video Gallary ";
  }, []);

  const params = useParams();
  let id = params.id;

  function getDetails() {
    setLoading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      resource_id: id,
    };
    simplePostCall(ApiConfig.E_LEARNING_DETAILS, JSON.stringify(newRequestBody))
      .then((data) => {
        // if (data.result) {
        setLoading(false);

        setDetailsList(data.datas);
        setDetailsType(data.datas);
        // }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <div class="row e-learning-gallery">
        {loading ? (
          <SpinnerCmp />
        ) : (
          <div class="col-md-12">
            <div class="card br-15">
              {DetailsList.map((postDetail, index) => {
                console.log("postDetail", postDetail);
                return (
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1 gallery-head">
                        <h4>{postDetail.student_name}</h4>
                        <h6>
                          {/* {t("Lesson")} */}
                          <span>{postDetail.resources_name}</span>
                        </h6>
                        <h6>
                          {t("Name")}:<span>{postDetail.subject_name}</span>
                        </h6>
                        <h6>
                          Description:
                          <span>{postDetail.resources_description}</span>
                        </h6>
                      </div>

                      <div class="topic-ico">
                        <img src={header_icon} />
                      </div>
                    </div>
                    <div class="gallery-sec">
                      {detailsType.map((item, index) => {
                        if (item.type === "Youtube") {
                          var embededurl = item.resources_content;
                          var newurl = embededurl.replace(
                            "/watch?v=",
                            "/embed/",
                          );
                        }
                        return (
                          <div class="gallery-sec">
                            <div id="gallery">
                              {item &&
                              item.type === "image" &&
                              item.resources_content ? (
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={item.resources_content}
                                ></img>
                              ) : item.type === "Youtube" ? (
                                <iframe
                                  style={{ width: "100%", height: "600px" }}
                                  src={newurl}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              ) : item.type === "Video" ? (
                                <iframe
                                  style={{ width: "100%", height: "800px" }}
                                  src={item.upload_file}
                                  title="Video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              ) : item.type === "Vimeo" ? (
                                <ReactPlayer
                                  url={item.resources_content}
                                  style={{ width: "100%", height: "800px" }}
                                  controls
                                />
                              ) : item.type === "Google Drive" ? (
                                <iframe
                                  style={{ width: "100%", height: "800px" }}
                                  src={item.resources_content}
                                  title="Google Drive"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              ) : item.type === "Presentation" ? (
                                <iframe
                                  style={{ width: "100%", height: "800px" }}
                                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${item.upload_file}`}
                                  title=""
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              ) : item.type === "audio" ? (
                                <audio
                                  width={"100%"}
                                  controls
                                  src={item.resources_content}
                                />
                              ) : item.type === "PDF" ? (
                                <iframe
                                  style={{ width: "100%", height: "800px" }}
                                  src={item.upload_file}
                                ></iframe>
                              ) : item.type === "TEXT" ? (
                                <p>{item.resources_content}</p>
                              ) : item.type === "URL" ? (
                                <iframe
                                  style={{ width: "100%", height: "800px" }}
                                  src={item.resources_content}
                                ></iframe>
                              ) : (
                                <p>File is not available</p>
                              )}
                              {console.log(
                                item.type,
                                "item.type",
                                item.resources_content,
                                item.upload_file,
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
}

export default ImgVideoGallary;
