import React, { Component } from "react";
import downICon from "../..//../assets/images/svg/downicon.svg";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CheckSquare,
  XSquare,
} from "react-feather";
import PresentTab from "./PresentTab";
import AbsentTab from "./AbsentTab";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";

export class Attendance extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    
    this.state = {
      isAbsentTab: false,
      isPresentTab: false,
      timeTable: [],
      selectPeriod: [],
      selectedPeriodValue: "",
      selectSubject: {},
      selectSubjectValue: "",
      attendanceList: [],
      attendanceStatus: "",
      
      // isFromReview: window.location.href.split('/')[window.location.href.split('/').length - 1]
      
      //this.props.isFromReview?this.props.isFromReview:true
    };
    console.log(this.props);
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | Attendance";
    this.getTimeTable();
    this.getAttendanceList();
  }

  //#endregion
  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isAbsentTab: false,
      isPresentTab: false,
      timeTable: [],
      selectPeriod: [],
      selectedPeriodValue: "",
      selectSubject: {},
      selectSubjectValue: "",
      attendanceList: [],
      attendanceStatus: "",
    });

  };

  onValueChange(event, id) {

    this.setState({
      attendanceStatus: event.target.value
    });
    this.takeAttendance(id)
  }
  openAbsentTab = () => {
    // this.setInitialState();
    this.setState({ isAbsentTab: true }, () => {});
  };
  openPresentTab = () => {
    // this.setInitialState();
    this.setState({ isPresentTab: true }, () => {});
  };

  getTimeTable = () => {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      date: "2022-04-06",
    };
    simplePostCall(ApiConfig.TIMETABLE, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          this.setState({
            ...this.state,
            isloading: false,
            timeTable: data.timetable_details,
          });
        } else this.setState({ ...this.state, isloading: false });
      })
      .catch((error) => {
        // this.setState({ ...this.state, isloading: false });
        console.log("api response", error);
      });
  };
  getAttendanceList = () => {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: "ccs20",
      section_id: this.state.selectSubjectValue,
      period_id: this.state.selectedPeriodValue,
    };
    simplePostCall(ApiConfig.ATTENDANCE_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data) {
          this.setState({
            ...this.state,
            isloading: false,
            attendanceList: data.list,
          });
        } else this.setState({ ...this.state, isloading: false });
      })
      .catch((error) => {
        // this.setState({ ...this.state, isloading: false });
        console.log("api response", error);
      });
  };

  takeAttendance = (id) => {
    let { attendanceStatus } = this.state
    let newRequestBody = {
      api_key: Params.api_key,
    customer_id: Params.customer_id,
    attendance_id: id,
    status: attendanceStatus
    };
    simplePostCall(ApiConfig.TAKE_ATTENDANCE, JSON.stringify(newRequestBody))
      .then((data) => {
        // if (data) {
        //   this.setState({
        //     ...this.state,
        //     isloading: false,
        //     attendanceList: data.list,
        //   });
        // } else this.setState({ ...this.state, isloading: false });
      })
      .catch((error) => {
        // this.setState({ ...this.state, isloading: false });
        console.log("api response", error);
      });
  };

  handleOnChange = (e,item) => {
    console.log(e)
    console.log(item)
    let { attendanceList } = this.state;

    let data =attendanceList.map(
      (data) => {
        if (data.id === item.id) {
          return {
            ...data,
            checked:
              e.target.checked,
          };
        } else return data;
      },
      
    );
    console.log("data",data);
    this.setState({ attendanceStatus :data });
  };







  render() {
    return (
      <div class="">
        {/* <!-- Container-fluid starts--> */}
        <div class="row">
          <div class="col-sm-12">
            <div class="card br-15">
              <div class="card-header br-15 pb-0">
                <h5>Attendance</h5>
              </div>
              <div class="card-body attend-sec ">
                <ul
                  class="nav nav-tabs search-list"
                  id="top-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link "
                      id="all-link"
                      onClick={this.openPresentTab}
                      data-bs-toggle="tab"
                      href="#all-links"
                      role="tab"
                      aria-selected="true"
                    >
                      <CheckSquare />
                      Present
                    </a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link atnd active"
                      id="image-link"
                      data-bs-toggle="tab"
                      href="#image-links"
                      role="tab"
                      aria-selected="false"
                    >
                      <i class="icofont icofont-tasks"></i>Attendance
                    </a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="video-link"
                      onClick={this.openAbsentTab}
                      data-bs-toggle="tab"
                      href="#video-links"
                      role="tab"
                      aria-selected="false"
                    >
                      <XSquare />
                      Absent
                    </a>
                    <div class="material-border"></div>
                  </li>
                </ul>
                <div class="tab-content" id="top-tabContent">
                  <div
                    class="search-links tab-pane fade"
                    id="all-links"
                    role="tabpanel"
                    aria-labelledby="all-link"
                  >
                    {this.state.isPresentTab ? (
                      <PresentTab onClose={this.openPresentTab} />
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade show active"
                    id="image-links"
                    role="tabpanel"
                    aria-labelledby="image-link"
                  >
                    <div
                      class="mark-sec"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseicon"
                      aria-expanded="true"
                      aria-controls="collapse11"
                    >
                      <img src={downICon} />
                      <div>
                        <ChevronDown />{" "}
                      </div>
                    </div>
                    <div
                      class="collapse"
                      id="collapseicon"
                      aria-labelledby="collapseicon"
                      data-bs-parent="#accordionoc"
                    >
                      <div class="mark-all-sec">
                        <div class="option-group">
                          <div class="option-container">
                            <input
                              class="option-input"
                              checked
                              id="option-1"
                              type="radio"
                              name="options"
                            />
                            <input
                              class="option-input"
                              id="option-2"
                              type="radio"
                              name="options"
                            />

                            <label class="option" for="option-1">
                              <span class="option__indicator"></span>
                              <span class="option__label">
                                <sub>mark all</sub>Absent
                              </span>
                            </label>

                            <label class="option" for="option-2">
                              <span class="option__indicator"></span>
                              <span class="option__label">
                                <sub>mark all</sub>Present
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row row">
                      <div className="form-group col-md-6">
                        <label htmlFor="task-title">Select Period</label>
                        <select
                          //   className="js-example-basic-single form-select"
                          className="form-select"
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              selectedPeriodValue: e.target.value,
                            })
                          }
                          name="selectClassId"
                        >
                          <option value="" selected>
                            Please Select Period
                          </option>
                          {this.state.timeTable.length > 0
                            ? this.state.timeTable.map((period, index) => {

                                return (
                                  <>
                                    <option value={period.period_no.period_id}>
                                      {period.period_no.period_name}
                                    </option>
                                  </>
                                );
                              })
                            : null}
                        </select>
                      </div>

                      {this.state.selectedPeriodValue.length > 0 && (
                        <div className="form-group col-md-6">
                          <label for="task-title">Select Section</label>
                          <select
                            //   className="js-example-basic-single form-select"
                            className="form-select"
                            name="selectSectionId"
                            onChange={(e) =>
                              this.setState(
                                {
                                  selectSubjectValue: e.target.value,
                                },
                                this.getAttendanceList()
                              )
                            }
                          >
                            <option value="" selected>
                              Please Select Period
                            </option>
                            {this.state.timeTable.length > 0
                              ? this.state.timeTable.map((subject, index) => {
                                  

                                  return (
                                    <>
                                      <option
                                        value={
                                          subject.teacher_subject.section_id
                                        }
                                      >
                                        {subject.teacher_subject.subject_name}
                                      </option>
                                    </>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      )}
                    </div>
                    <div class="atnd-scroll">
                      <div class="std-container">
                        <ul class="responsive-table">
                          {this.state.attendanceList.map((item, index) => {
                            return (
                              <li class="table-row" key={"item" + index}>
                                <div
                                  class="col col-1 avatar"
                                  data-label="Student Image"
                                >
                                  <img
                                    src={
                                      ApiConfig.BASE_URL +
                                      item.student_profilepicture
                                    }
                                  />
                                </div>
                                <div
                                  class="col col-9 std-name"
                                  data-label="Student Name"
                                >
                                  {item.student_name}
                                </div>
                                <div
                                  class="col col-2 t-switch"
                                  data-label="Action"
                                >
                                  <div class="switch-toggle switch-3 switch-candy">
                                    <input
                                      class="ab-pr"
                                      id={"on1" + index}
                                      name={"state-d1" + +index}
                                      type="radio"
                                      // checked={
                                      //   item.attendance_classroom_status ===
                                      //   "ABSENT"
                                      //     ? true
                                      //     : false
                                      // }
                                      value="ABSENT"
                                      // onChange={(e) =>
                                      //   this.setState({
                                      //     ...this.state,
                                      //     attendanceStatus: e.target.value,
                                      //   }, this.takeAttendance(item.attendance_classroom_id))
                                      // }
                                      onChange={this.onValueChange(item.attendance_classroom_id)}
                                    />
                                    <label
                                      class="ab-pr absent"
                                      for={"on1" + index}
                                    >
                                      Absent
                                    </label>

                                    {/* <input
                                  class="ab-pr"
                                  id={"na1" + index}
                                  name={"state-d2" + index}
                                  type="radio"
                                  checked={item.attendance_classroom_status === "PRESENT" ? true : false}
                                />
                                <label
                                  for={"na1" + index}
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label> */}

                                    <input
                                      class="ab-pr"
                                      id={"off1" + index}
                                      name={"state-d1" + index}
                                      type="radio"
                                      value="PRESENT"
                                      // checked={
                                      //   item.attendance_classroom_status ===
                                      //   "PRESENT"
                                      //     ? true
                                      //     : false
                                      // }
                                      // onChange={(e) =>
                                      //   this.setState({
                                      //     ...this.state,
                                      //     attendanceStatus: e.target.value,
                                      //   }, this.takeAttendance(item.attendance_classroom_id))
                                      // }
                                      onChange={this.onValueChange(item.attendance_classroom_id)}

                                    />
                                    <label
                                      class="ab-pr present"
                                      for={"off1" + index}
                                      onclick=""
                                    >
                                      Present
                                    </label>

                                    <a></a>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {/* <div class="students-sort">
                        <div id="alph-b" class="sort-alph">
                          B
                        </div>
                      </div>
                      <div class="std-container">
                        <ul class="responsive-table">
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b5} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Bella Ellison
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on5"
                                  name="state-d5"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on5"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na5"
                                  name="state-d5"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na5"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off5"
                                  name="state-d5"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off5"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b6} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Blake Frank
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on6"
                                  name="state-d6"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on6"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na6"
                                  name="state-d6"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na6"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off6"
                                  name="state-d2"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off6"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b7} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Bernadette Connor
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on7"
                                  name="state-d7"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on7"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na7"
                                  name="state-d7"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na7"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off7"
                                  name="state-d7"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off7"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b8} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Betty Jacob
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on8"
                                  name="state-d8"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on8"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na8"
                                  name="state-d8"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na8"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off8"
                                  name="state-d8"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off8"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="students-sort">
                        <div id="alph-c" class="sort-alph">
                          C
                        </div>
                      </div>
                      <div class="std-container">
                        <ul class="responsive-table">
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b1} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Carolyn Ellison
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on9"
                                  name="state-d9"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on9"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na9"
                                  name="state-d9"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na5"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off9"
                                  name="state-d9"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off9"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b2} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Charles Frank
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on10"
                                  name="state-d10"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on10"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na10"
                                  name="state-d10"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na10"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off10"
                                  name="state-d10"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off10"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b3} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Chloe Connor
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on11"
                                  name="state-d11"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on11"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na11"
                                  name="state-d11"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na11"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off11"
                                  name="state-d11"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off11"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b4} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Claire Jacob
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on12"
                                  name="state-d12"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on12"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na12"
                                  name="state-d12"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na12"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off12"
                                  name="state-d12"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off12"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="video-links"
                    role="tabpanel"
                    aria-labelledby="video-link"
                  >
                    <div class="std-container">
                      {this.state.isPresentTab ? (
                        <AbsentTab onClose={this.openAbsentTab} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    );
  }
}

export default Attendance;
