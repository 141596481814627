import React from 'react'

const EditResource = props => {
    const displayStyle={
        display:"block",
        boxShadow:"inset 5000px 1000px 0px 20px rgb(0 0 0 / 13%)"
    }
    return (
        <div class="modal fade assignment-sec show " style={displayStyle} id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Resource</h5>
              <button class="btn-close" type="button" onClick={props.onClose} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body datetime-picker modal-scroll">
              <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="task-title">Category</label>  
                        <select class="js-example-basic-single form-select">
                          <option value="task">Pdf</option>
                          <option value="task">YouTube</option>
                        </select>
                    </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Category</label>  
                    <select class="js-example-basic-single form-select">
                      <option value="task">Reference Book</option>
                      <option value="task">Book</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Curriculum</label>  
                    <select class="js-example-basic-single form-select">
                      <option value="task">CBSE</option>
                      <option value="task">ICSE</option>
                    </select>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">Grade</label>  
                      <select class="js-example-basic-single form-select">
                        <option value="task">10A</option>
                        <option value="task">10B</option>
                        <option value="task">10C</option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label for="task-title">Subject</label>  
                      <select class="js-example-disabled-results form-select" id="bm-collection">
                        <option value="general">Physics</option>
                        <option value="general">Chemistry</option>
                        <option value="general">Biolody</option>
                        <option value="general">Mathematics</option>
                        <option value="general">Social Studies</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Lesson</label>  
                    <select class="js-example-basic-single form-select">
                      <option value="lesson1">Lesson 1</option>
                      <option value="lesson2">Lesson 2</option>
                      <option value="lesson3">Lesson 3</option>
                      <option value="lesson4">Lesson 4</option>
                      <option value="lesson5">Lesson 5</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Topic</label>  
                    <select class="js-example-basic-single form-select">
                      <option value="task">Assignment Title-1</option>
                      <option value="task">Assignment Title-2</option>
                      <option value="task">Assignment Title-3</option>
                      <option value="task">Assignment Title-4</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Name</label>
                    <input class="form-control" id="task-title" type="text" required="" autocomplete="off" />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="sub-task">Description</label>
                    <textarea class="form-control" required="" autocomplete="off">  </textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Enter YouTube URL</label>
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon3">https://www.youtube.com/</span>
                        <input class="form-control" id="task-title" type="text" required="" autocomplete="off" />
                      </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="Bookmark" onclick="submitBookMark()" type="submit">Save as Draft</button>
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Save and Publish</button>
            </div>
          </div>
        </div>
    </div>
    )
}
export default  EditResource
