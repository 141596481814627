import React, { useState, useEffect } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import { toast } from "react-toastify";
import Select from "react-select";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next"; 

const AddEditPushNotification = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [gradeState, setGradeState] = useState({
    isloading: true,
    grades: [],
  });
  const [studentsState, setStudentsState] = useState({
    isloading: false,
    students: [],
  });
  const [state, setState] = useState({
    isloading: false,
    selected_grade_ids: [],
    selectedGrades: [],
    selected_student_ids: [],
    selectedStudents: [],
    message: "",
    selectedBoardedOrNotBoarded: [
      { label: "Already boarded", value: "boarded" },
    ],
    boarded_or_not_boarded: "boarded",
  });

  function getGradeList() {
    setGradeState({ ...gradeState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          //var grades = [{ label: "All", value: "all" }];
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name + " " + grade.class_division_name,
                value: grade.class_division_id,
              });
            });
          }
          setGradeState({ ...gradeState, grades: grades, isloading: false });
        } else {
          setGradeState({ ...gradeState, grades: grades, isloading: false });
        }
      })
      .catch((error) => {
        setGradeState({ ...gradeState, grades: [], isloading: false });
        console.log("api response", error);
      });
  }

  function onGradeChange(selectedOption) {
    var selected_grades = [];
    if (selectedOption.length > 0) {
      selectedOption.map((grade, index) => {
        selected_grades.push(grade.value);
      });

      getStudents(selected_grades);
    }
    setState({
      ...state,
      selected_grade_ids: selected_grades,
      selectedGrades: selectedOption,
    });
  }

  function onBoardedOrNotBoardedChange(selectedOption) {
    setState({
      ...state,
      boarded_or_not_boarded: selectedOption.value,
      selectedBoardedOrNotBoarded: selectedOption,
    });
  }

  function getStudents(selected_class_division) {
    setStudentsState({ ...studentsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      section_ids: selected_class_division,
    });

    simplePostCall(
      ApiConfig.STUDENTS_LIST_BY_SELECTED_CLASSES_API,
      newRequestBody
    )
      .then((data) => {
        if (data.result) {
          var students = [];
          if (data.list.length > 0) {
            data.list.map((student, index) => {
              students.push({
                label: student.student_name,
                value: student.student_id,
              });
            });
          }
          setStudentsState({
            ...studentsState,
            isloading: false,
            students: students,
          });
        } else {
          setStudentsState({ ...studentsState, isloading: false });
        }
      })
      .catch((error) => {
        setStudentsState({ ...studentsState, isloading: false });
        console.log("api response", error);
      });
  }

  function onStudentChange(selectedOption) {
    var selected_students = [];
    if (selectedOption.length > 0) {
      selectedOption.map((grade, index) => {
        selected_students.push(grade.value);
      });
    }
    setState({
      ...state,
      selected_student_ids: selected_students,
      selectedStudents: selectedOption,
    });
  }

  function savePushNotification() {
    if (
      state.selected_grade_ids.length == 0 ||
      state.selected_student_ids.length == 0 ||
      state.message == ""
    ) {
      toast.error("All fields are mandatory");
    } else {
      setState({ ...state, isloading: true });
      let newRequestBody = JSON.stringify({
        customer_id: Params.customer_id,
        api_key: Params.api_key,
        employee_id: Params.teacher_id,
        class_ids: state.selected_grade_ids,
        student_ids: state.selected_student_ids,
        message: state.message,
      });

      simplePostCall(ApiConfig.ADD_PARENT_PUSH_NOTIFICATION, newRequestBody)
        .then((data) => {
          if (data.result) {
            toast.success(data.message);
            setTimeout(() => {
              window.location.replace("/push-notification");
            }, 2000);
          } else {
            setState({ ...state, isloading: false });
          }
        })
        .catch((error) => {
          setState({ ...state, isloading: false });
          console.log("api response", error);
        });
    }
  }

  useEffect(() => {
    getGradeList();
  }, []);

  return (
    <div
      className="modal fade modal-bookmark show  testingll"
      style={{ display: "block" }}
      id="exampleModalSMS"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <SpinnerCmp
          loading={
            gradeState.isloading || studentsState.isloading || state.isloading
          }
        />
        <div
          className="modal-content"
          style={{
            display:
              gradeState.isloading || studentsState.isloading || state.isloading
                ? "none"
                : "",
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.data.mode === "view"
                ? "View"
                : props.data.mode === "edit"
                ? `${t('Edit')}`
                : `${t('Create')}`}{" "}
             {t("Push Notification")}
            </h5>
            <button
              className="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>
          </div>
          <div className="modal-body datetime-picker modal-scroll">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              /* noValidate="" */
            >
              <div className="form-row">
                <div className="form-group col">
                  <label htmlFor="task-title">{t("Grade")}</label>
                  <Select
                    className="js-example-basic-single form-control"
                    value={state.selectedGrades}
                    onChange={onGradeChange}
                    options={gradeState.grades}
                    isMulti={true}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="task-title">
                   {t("Boarded or not boarded students")}
                  </label>
                  <Select
                    className="js-example-basic-single form-control"
                    value={state.selectedBoardedOrNotBoarded}
                    onChange={onBoardedOrNotBoardedChange}
                    options={[
                      { label: "Already boarded", value: "boarded" },
                      { label: "Not yet boarded", value: "notboarded" },
                    ]}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="task-title">{t('Students')}</label>
                  <Select
                    className="js-example-basic-single form-control"
                    value={state.selectedStudents}
                    onChange={onStudentChange}
                    options={studentsState.students}
                    isMulti={true}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="task-title">{t("Message")}</label>
                  <textarea
                    className="form-control"
                    id="task-title"
                    type="text"
                    required=""
                    autoComplete="off"
                    name="email_message"
                    value={state.message}
                    onChange={(e) => {
                      setState({ ...state, message: e.target.value });
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {props.data.mode !== "view" ? (
              <button
                className="btn btn-secondary"
                id="Bookmark"
                type="submit"
                onClick={savePushNotification}
              >
                {t("Send")}
              </button>
            ) : null}
            <button
              className="btn btn-primary"
              type="button"
              onClick={props.onClose}
              data-bs-dismiss="modal"
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditPushNotification;
