import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import eye from "../assets/images/eye.png";

function DashboardAssessments() {
  const { t } = useTranslation();
  const [state, setState] = useState({
    assessment_data: [],
  });

  const location = useLocation();

  function setData() {
    const { assessment_data } = location.state;
    setState({ assessment_data: assessment_data });
  }

  useEffect(() => {
    setData();
  }, []);
  console.log("state.assessment_data", state.assessment_data);
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <h5>Assessments</h5>
          </div>
          <div className="table-responsive" style={{ paddingBottom: "10px" }}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("Title")} </th>
                  <th scope="col">{t("Grade")}</th>
                  <th scope="col">{t("Pending")}</th>
                  <th scope="col">{t("Attended")}</th>
                  <th scope="col">{t("Total")}</th>
                  <th scope="col">{t("View")}</th>
                </tr>
              </thead>
              <tbody>
                {state.assessment_data && state.assessment_data.length > 0 ? (
                  state.assessment_data.map((assessments, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{assessments.assessment_title}</td>
                        <td>{assessments.assessment_class}</td>
                        <td>{assessments.assessment_pending}</td>
                        <td>{assessments.assessment_attended}</td>
                        <td>{assessments.assessment_total}</td>
                        <td>
                          <Link
                            to={`/AssessmentDetails/${assessments.assessment_id}`}
                            className="col-md-4"
                          >
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={eye}
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th scope="row">No records found</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAssessments;
