import React, { useContext, useEffect, useState } from "react";
import { ArrowLeft, Delete, Minus, Plus } from "react-feather";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiConfig from "../../../api/ApiConfig";
import { multipartPostCall, simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import { AppContext } from "../../../context/AppContext";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import Sortable, { SortableItem } from "react-easy-sort";

function CreateQuestion(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;
  let teacher_id = JSON.stringify(Params.teacher_id);
  let customer_id = JSON.stringify(Params.customer_id);
  const { setState, seAssessmentIdQustion } = useContext(AppContext);
  const [requirements, setrequirements] = useState([{ id: 1, inputValue: "" }]);
  const [requirements2, setRequirements2] = useState([
    { id: 1, inputValue: "" },
  ]);

  console.log("requirements ===>", requirements);
  const [choisedata, setChoiseData] = useState([]);
  const [choisedata2, setChoiseData2] = useState([]);

  console.log("choisedata ===>", choisedata);

  var subjects = [];
  const [createQuestionState, setCreateQuestionState] = useState({
    isloading: false,
    title: "gf",
    instructions: "gf",
    question_type: "selection",
    question: "g",
    choices: [],
    answer: "1",
    clarification: "df",
    score: "g",
    negative: "",
  });
  console.log(createQuestionState);
  useEffect(() => {
    seAssessmentIdQustion(id);
    document.title = "Edsys | CreateQuestion ";
  }, []);
  useEffect(() => {
    const choiceArry2 = requirements2.map((value) => value.inputValue);
    const choiceArry3 = requirements2.map((value, index) => ({
      id: index,
      label: value.inputValue,
    }));
    setChoiseData2(choiceArry2);
    setmatchanswer(choiceArry3);
  }, [requirements2]);
  const [sortedItems, setSortedItems] = useState([]);
  const [answers, setAnswers] = useState([]);
  const handleCreateQuestion = async (e) => {
    setCreateQuestionState({ ...createQuestionState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: customer_id,
      api_key: Params.api_key,
      teacher_id: teacher_id,
      assessment_id: id,
      title: createQuestionState.title,
      instructions: createQuestionState.instructions,
      // question_type:
      //   createQuestionState.question_type == 1 ? "upload" : "selection",
      question_type: createQuestionState.question_type,
      question: createQuestionState.question,
      choices:
        createQuestionState.question_type == "order" ? choisedata2 : choisedata,
      choices1:
        createQuestionState.question_type == "associate" ? choisedata2 : "",
      // choices1: ["tiger", "hen", "apple"],
      answer:
        createQuestionState.question_type == "associate" ||
        createQuestionState.question_type == "order"
          ? first
          : createQuestionState.answer,
      // answer: ["two"],
      clarification: createQuestionState.clarification,
      score: createQuestionState.score,
      difficulty_level: difficulty,
      negativeScore: createQuestionState.negative,
    });

    console.log(newRequestBody, "newRequestBody");
    simplePostCall(ApiConfig.CREATE_ASSESSMENT_QUESTION_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setCreateQuestionState({ ...createQuestionState, isloading: false });
          toast.success(data.message);
          navigate("/create-assessment");

          setState(1);
        } else {
          setCreateQuestionState({ ...createQuestionState, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setCreateQuestionState({ ...createQuestionState, isloading: false });
        console.log("api response", error);
      });
  };

  useEffect(() => {
    let choiceArry = [];
    const data = requirements.map((value, index) => {
      console.log("value", value);
      choiceArry.push(value.inputValue);
    });
    console.log("choiceArry", choiceArry);
    setChoiseData(choiceArry);
    setSortedItems(choiceArry);
  }, [requirements]);
  // const onSortEnd = (oldIndex, newIndex) => {
  //   const updatedItems = [...requirements];
  //   // const movedItem = updatedItems.splice(oldIndex, 1)[0];
  //   updatedItems.splice(newIndex, 0, movedItem);
  //   setrequirements(updatedItems);
  //   // const updatedItems = [...items];
  //   // const [movedItem] = updatedItems.splice(oldIndex, 1);
  //   // updatedItems.splice(newIndex, 0, movedItem);
  //   setItems(updatedItems);

  // };
  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const onRequirementAdd = () => {
    const newRequirement = {
      id: Math.floor(Math.random() * 100 + 1),
      inputValue: "",
    };
    setrequirements([...requirements, newRequirement]);
    setRequirements2([...requirements2, newRequirement]); // add newRequirement to requirements2
  };

  const onRemoveRequirement = (id) => {
    setrequirements(requirements.filter((req) => req.id !== id));
    setRequirements2(requirements2.filter((req) => req.id !== id)); // remove from requirements2 as well
  };

  const onRequirementValueChange = (e, id) => {
    if (
      createQuestionState.question_type == "associate" ||
      createQuestionState.question_type == "order"
    ) {
      const newRequirements = requirements.map((req) =>
        req.id === id ? { ...req, inputValue: e.target.value } : req,
      );
      setrequirements(newRequirements);
    } else {
      let changeRequirement = requirements.map((each) => {
        if (id.id === each.id) {
          return {
            id: each.id,

            inputValue: e.target.value,
          };
        } else {
          return {
            ...each,
          };
        }
      });

      setrequirements(changeRequirement);
    }
  };

  const onRequirementValueChange2 = (e, id) => {
    const newRequirements2 = requirements2.map((req) =>
      req.id === id ? { ...req, inputValue: e.target.value } : req,
    );
    setRequirements2(newRequirements2);
  };

  const indexToAlpha = (index) => {
    let alpha = "";
    while (index >= 0) {
      alpha = String.fromCharCode((index % 26) + 97) + alpha;
      index = Math.floor(index / 26) - 1;
    }
    return alpha;
  };
  const indexToAlphauppercase = (index) => {
    let alpha = "";
    while (index >= 0) {
      alpha = String.fromCharCode((index % 26) + 65) + alpha;
      index = Math.floor(index / 26) - 1;
    }
    return alpha;
  };
  const [selected, setSelected] = useState([]);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    setCreateQuestionState({
      ...createQuestionState,
      answer: selectedOptions?.map((option) => option?.value),
    });
    console.log(
      selectedOptions.map((option) => option.value),
      "dfdfdfdf",
    );
  };
  const [items, setItems] = useState(["Item 1", "Item 2", "Item 3", "Item 4"]);
  const [matchanswer, setmatchanswer] = useState([]);
  const [first, setfirst] = useState([]);
  const onSortEnd = (oldIndex, newIndex) => {
    const updatedItems = [...matchanswer];
    const [movedItem] = updatedItems.splice(oldIndex, 1);
    updatedItems.splice(newIndex, 0, movedItem);
    setmatchanswer(updatedItems);
    const labels = updatedItems.map((item) => item.label);
    setfirst(labels);
  };
  console.log(matchanswer, "matchanswer", first);
  const [difficulty, setDifficulty] = useState("");

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
    console.log("Selected difficulty:", event.target.value);
  };
  console.log(difficulty, "statusstatus");
  const statusdata = [
    { value: "true", label: "True" },
    { value: "false", label: "False" },
  ];
  return (
    <>
      {createQuestionState.isloading ? (
        <SpinnerCmp loading={createQuestionState.isloading} />
      ) : (
        <div style={{ marginLeft: "0px" }} class="page-body  create-ques">
          {/* <!-- Container-fluid starts--> */}
          {console.log(choisedata2, "choisedata2")}

          <div class="row assignment-sec">
            <div class="col-sm-12">
              <div class="card br-15 assessment-sec">
                <div class="card-header pb-0">
                  <h5>
                    <Link to="/create-assessment">
                      <ArrowLeft />
                    </Link>{" "}
                    {t("Create New Question")}
                  </h5>
                </div>
                <div class="card-body datetime-picker">
                  <form
                    class="form-bookmark needs-validation"
                    id="bookmark-form"
                    novalidate=""
                  >
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="task-title">
                          {t("Select Question Type")}
                        </label>
                        <select
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              question_type: e.target.value,
                            });
                          }}
                          class="js-example-basic-single form-select"
                        >
                          <option value="selection">
                            Multiple Choice Questions (Single Answer)
                          </option>
                          <option value="multipleselection">
                            Multiple Choice Questions (Multiple Answer)
                          </option>

                          <option value="associate">Match the following</option>
                          <option value="order">Sort the following</option>
                          <option value="textentry">True or False</option>
                        </select>
                      </div>
                      <div class="form-group col-md-12">
                        <label for="task-title">{t("Assessment Title")}</label>
                        <input
                          class="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              title: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="sub-task">{t("Instructions")}</label>
                        <textarea
                          class="form-control"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              instructions: e.target.value,
                            });
                          }}
                        >
                          {" "}
                        </textarea>
                        {console.log(createQuestionState.negative, "negative")}
                      </div>
                      <div class="form-group col-md-12">
                        <label for="task-title">{t("Question")}</label>
                        <input
                          class="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              question: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {console.log(
                        createQuestionState.question_type,
                        "typeeee",
                      )}
                      {createQuestionState.question_type === "associate" ? (
                        <div class="row">
                          <div class="form-group col">
                            <label for="task-title">{t("Create Option")}</label>

                            <div class="multi-field-wrapper">
                              {requirements &&
                                requirements.length > 0 &&
                                requirements.map((eachRequirement, index) => {
                                  console.log(eachRequirement);
                                  return (
                                    <div key={index} class="multi-fields">
                                      <p style={{}}>
                                        Option {indexToAlphauppercase(index)}
                                      </p>
                                      <div
                                        style={{
                                          height: "45px",
                                        }}
                                        class="multi-field d-flex"
                                      >
                                        {console.log(
                                          eachRequirement,
                                          "eachRequirement",
                                        )}
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="stuff[]"
                                          value={eachRequirement.inputValue}
                                          onChange={(e) =>
                                            onRequirementValueChange(
                                              e,
                                              eachRequirement.id,
                                            )
                                          }
                                          placeholder="Add Option"
                                        />
                                        <div
                                          class="remove-field"
                                          style={{
                                            height: "57px",
                                            width: "20px",
                                          }}
                                        >
                                          {index === 0 ? (
                                            <></>
                                          ) : (
                                            <button
                                              className="btn requiremts-remove-btn"
                                              style={{
                                                alignSelf: "center",

                                                // marginBottom: "0px",
                                              }}
                                              onClick={() =>
                                                onRemoveRequirement(
                                                  eachRequirement.id,
                                                )
                                              }
                                            >
                                              {/* <Delete /> */}
                                            </button>
                                          )}
                                          {/* <Minus /> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              <div type="button" class="add-field">
                                {/* <Plus /> */}
                                <span
                                  style={{
                                    top: "0px",
                                  }}
                                  onClick={onRequirementAdd}
                                >
                                  {t("Add Option")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col">
                            <label for="task-title"> </label>
                            <div style={{ marginTop: "10px" }}></div>
                            {/* <label for="task-title"> </label> */}

                            <div class="multi-field-wrapper">
                              {requirements2 &&
                                requirements2.length > 0 &&
                                requirements2.map((eachRequirement, index) => {
                                  console.log(eachRequirement);
                                  return (
                                    <div key={index} class="multi-fields">
                                      <p style={{}}>
                                        Option {indexToAlpha(index)}
                                      </p>
                                      <div
                                        style={{
                                          height: "45px",
                                        }}
                                        class="multi-field d-flex"
                                      >
                                        {console.log(
                                          eachRequirement,
                                          "eachRequirement",
                                        )}
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="stuff[]"
                                          value={eachRequirement.inputValue}
                                          onChange={(e) =>
                                            onRequirementValueChange2(
                                              e,
                                              eachRequirement.id,
                                            )
                                          }
                                          placeholder="Add Option"
                                          // style={{ width: '95%' }}
                                        />
                                        <div
                                          class="remove-field"
                                          style={{
                                            height: "57px",
                                            width: "20px",
                                          }}
                                        >
                                          {index === 0 ? (
                                            <></>
                                          ) : (
                                            <button
                                              className="btn requiremts-remove-btn"
                                              style={{
                                                alignSelf: "center",

                                                // marginBottom: "0px",
                                              }}
                                              onClick={() =>
                                                onRemoveRequirement(
                                                  eachRequirement.id,
                                                )
                                              }
                                            >
                                              <Delete />
                                            </button>
                                          )}
                                          {/* <Minus /> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      ) : createQuestionState.question_type === "order" ? (
                        <div class="form-group col">
                          <label for="task-title">{}</label>

                          <div class="multi-field-wrapper">
                            {requirements2 &&
                              requirements2.length > 0 &&
                              requirements2.map((eachRequirement, index) => {
                                console.log(eachRequirement);
                                return (
                                  <div key={index} class="multi-fields">
                                    <p style={{}}>
                                      Option {indexToAlpha(index)}
                                    </p>
                                    <div
                                      style={{
                                        height: "45px",
                                      }}
                                      class="multi-field d-flex"
                                    >
                                      {console.log(
                                        eachRequirement,
                                        "eachRequirement",
                                      )}
                                      <input
                                        class="form-control"
                                        type="text"
                                        name="stuff[]"
                                        value={eachRequirement.inputValue}
                                        onChange={(e) =>
                                          onRequirementValueChange2(
                                            e,
                                            eachRequirement.id,
                                          )
                                        }
                                        placeholder="Add Option"
                                        // style={{ width: '95%' }}
                                      />
                                      <div
                                        class="remove-field"
                                        style={{
                                          height: "57px",
                                          width: "20px",
                                        }}
                                      >
                                        {index === 0 ? (
                                          <></>
                                        ) : (
                                          <button
                                            className="btn requiremts-remove-btn"
                                            style={{
                                              alignSelf: "center",

                                              // marginBottom: "0px",
                                            }}
                                            onClick={() =>
                                              onRemoveRequirement(
                                                eachRequirement.id,
                                              )
                                            }
                                          >
                                            <Delete />
                                          </button>
                                        )}
                                        {/* <Minus /> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            <div type="button" class="add-field">
                              {/* <Plus /> */}
                              <span
                                style={{
                                  top: "0px",
                                }}
                                onClick={onRequirementAdd}
                              >
                                {t("Add Option")}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : createQuestionState.question_type ===
                        "textentry" ? null : (
                        <div class="form-group col-md-12">
                          <label for="task-title">{t("Create Option")}</label>

                          <div class="multi-field-wrapper">
                            {requirements &&
                              requirements.length > 0 &&
                              requirements.map((eachRequirement, index) => {
                                console.log(eachRequirement);
                                return (
                                  <div key={index} class="multi-fields">
                                    <p style={{}}>
                                      Option {indexToAlpha(index)}
                                    </p>
                                    <div
                                      style={{
                                        height: "45px",
                                      }}
                                      class="multi-field d-flex"
                                    >
                                      {console.log(
                                        eachRequirement,
                                        "eachRequirement",
                                      )}
                                      <input
                                        class="form-control"
                                        type="text"
                                        name="stuff[]"
                                        value={eachRequirement.inputValue}
                                        onChange={(e) => {
                                          onRequirementValueChange(
                                            e,
                                            eachRequirement,
                                          );
                                          // setrequirements(
                                          //   {
                                          //     ...requirements,inputValue:e.target.value,
                                          //   }
                                          // )
                                        }}
                                        placeholder="Add Option"
                                        // style={{ width: '95%' }}
                                      />
                                      {/* <div
                                        class="remove-field"
                                        style={{
                                          height: "57px",
                                          width: "20px",
                                        }}
                                      > */}
                                      {index === 0 ? (
                                        <></>
                                      ) : (
                                        <button
                                          className="btn requiremts-remove-btn"
                                          style={{
                                            alignSelf: "center",

                                            marginBottom: "30px",
                                          }}
                                          onClick={() =>
                                            onRemoveRequirement(
                                              eachRequirement.id,
                                            )
                                          }
                                        >
                                          <Delete />
                                        </button>
                                      )}
                                      {/* <Minus /> */}
                                    </div>
                                    {/* </div> */}
                                  </div>
                                );
                              })}
                            <div type="button" class="add-field">
                              {/* <Plus /> */}
                              <span
                                style={{
                                  top: "0px",
                                }}
                                onClick={onRequirementAdd}
                              >
                                {t("Add Option")}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* {createQuestionState.question_type === "order" && (
                        <div class="form-group col-md-12">
                          <label for="task-title">{t("Create Options")}</label>
                          <Sortable onSortEnd={onSortEnd}>
                            {requirements.map((eachRequirement, index) => (
                              <SortableItem key={eachRequirement.id}>
                                <div
                                  style={{
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    margin: "4px",
                                    cursor: "pointer",
                                  }}
                                  draggable
                                  onDragStart={(e) => {
                                    e.dataTransfer.setData("text/plain", index);
                                  }}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                  }}
                                  onDrop={(e) => {
                                    e.preventDefault();
                                    const draggedIndex =
                                      e.dataTransfer.getData("text/plain");
                                    onSortEnd(parseInt(draggedIndex), index);
                                  }}
                                >
                                  Option {indexToAlpha(index)}
                                </div>
                              </SortableItem>
                            ))}
                          </Sortable>
                        </div>
                      )} */}

                      {/* <h5>{t("Provide Answers")}</h5>
                      <div>
                        {sortedItems.map((item, index) => (
                          <div key={index} style={{ margin: "8px 0" }}>
                            <label>{String.fromCharCode(65 + index)}</label>
                            <input
                              type="text"
                              value={answers[index] || ""}
                              onChange={(e) =>
                                handleAnswerChange(index, e.target.value)
                              }
                              style={{
                                marginLeft: "8px",
                                padding: "4px",
                                width: "200px",
                              }}
                            />
                          </div>
                        ))}
                      </div> */}

                      {/*  */}
                      <div class="row">
                        <div class="form-group col">
                          <label for="task-title">
                            {t("Select Correct Answer")}
                          </label>

                          {console.log(choisedata, "kjkjkjkj")}
                          {/* <input
                            class="form-control"
                            id="task-title"
                            type="text"
                            required=""
                            autocomplete="off"
                            onChange={(e) => {
                              setCreateQuestionState({
                                ...createQuestionState,
                                answer: e.target.value,
                              });
                            }}
                          /> */}

                          {createQuestionState.question_type == "selection" ? (
                            <select
                              style={{
                                display: createQuestionState.question_type
                                  ? "block"
                                  : "none",
                              }}
                              onChange={(e) => {
                                setCreateQuestionState({
                                  ...createQuestionState,
                                  answer: e.target.value,
                                });
                              }}
                              class="js-example-basic-single form-select"
                            >
                              <option
                                style={{
                                  display: "none",
                                }}
                                value=""
                              >
                                Select Option
                              </option>
                              {console.log(
                                choisedata,
                                "choisedata",
                                createQuestionState.answer,
                                "answerrrr",
                              )}
                              {choisedata?.map((item, index) => {
                                return (
                                  <option value={choisedata[index]}>
                                    Option {indexToAlpha(index)}
                                  </option>
                                );
                              })}
                            </select>
                          ) : createQuestionState.question_type ==
                            "multipleselection" ? (
                            <MultiSelect
                              style={{
                                display: createQuestionState.question_type
                                  ? "block"
                                  : "none",
                                zIndex: 1000,
                              }}
                              options={choisedata?.map((fruit, index) => ({
                                label: `Option ${indexToAlpha(index)}`,
                                // label: `Option ${indexToAlpha(index)}`,
                                value: fruit || index,
                              }))}
                              value={selected}
                              onChange={handleChange}
                              labelledBy="Select"
                              class="js-example-basic-single form-select"
                            />
                          ) : createQuestionState.question_type ==
                              "associate" ||
                            createQuestionState.question_type == "order" ? (
                            <Sortable onSortEnd={onSortEnd}>
                              {matchanswer.map((item, index) => (
                                <SortableItem key={index}>
                                  <div
                                    style={{
                                      padding: "8px",
                                      border: "1px solid #ced4da",
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {/* {item.label} */}
                                    Option {indexToAlpha(item.id)}
                                  </div>
                                </SortableItem>
                              ))}
                            </Sortable>
                          ) : createQuestionState.question_type ==
                            "textentry" ? (
                            <select
                              style={{
                                display: createQuestionState.question_type
                                  ? "block"
                                  : "none",
                              }}
                              onChange={(e) => {
                                setCreateQuestionState({
                                  ...createQuestionState,
                                  answer: e.target.value,
                                });
                              }}
                              className="js-example-basic-single form-select"
                            >
                              <option
                                style={{
                                  display: "none",
                                }}
                                value=""
                              >
                                Select Option
                              </option>
                              {statusdata.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                        {console.log(
                          createQuestionState.question_type,
                          "order",
                        )}
                        <div class="form-group col">
                          <label for="task-title">{t("Score")}</label>
                          <input
                            class="form-control"
                            id="task-title"
                            type="text"
                            required=""
                            autocomplete="off"
                            onChange={(e) => {
                              setCreateQuestionState({
                                ...createQuestionState,
                                score: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div class="form-group col-md-12 add-qn">
                        <a href="#">{t('Add Question')}</a>
                      </div> */}
                      <div class="form-group col-md-12">
                        <label for="sub-task">
                          {t("Answer Clarification")}
                        </label>
                        <textarea
                          class="form-control"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              clarification: e.target.value,
                            });
                          }}
                        >
                          {" "}
                        </textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <label for="task-title">Dificuty Level</label>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="radio-button">
                              <div class="inputGroup">
                                <input
                                  id="radio1"
                                  name="radio"
                                  type="radio"
                                  onChange={handleDifficultyChange}
                                  value="easy"
                                />
                                <label for="radio1">Easy</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="radio-button">
                              <div class="inputGroup">
                                <input
                                  id="radio2"
                                  name="radio"
                                  type="radio"
                                  value="normal"
                                  onChange={handleDifficultyChange}
                                />
                                <label for="radio2">Normal</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="radio-button">
                              <div class="inputGroup">
                                <input
                                  id="radio3"
                                  name="radio"
                                  type="radio"
                                  value="difficult"
                                  onChange={handleDifficultyChange}
                                />
                                <label for="radio3">Difficult</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-12">
                        <label for="task-title">{t("Negative Score")}</label>
                        <input
                          class="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateQuestionState({
                              ...createQuestionState,
                              negative: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    {t("Cancel")}
                  </button>
                  <Link to="#">
                    <button
                      onClick={handleCreateQuestion}
                      class="btn btn-primary ml-2"
                      id="Bookmark"
                      type="submit"
                    >
                      {t("Create")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </div>
      )}
    </>
  );
}
export default CreateQuestion;
