import React, { useEffect, useState } from "react";
import "./AcademicCalendar.css";
import moment from "moment";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const AcademicCalendar = () => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [weekend, setWeekend] = useState([]);
  const [vacationList, setVacationList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [weekCount, setWeekCount] = useState(0);
  const [month, setMonth] = useState(parseInt(moment().format("M")));
  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [monthName, setMonthName] = useState(moment(month, "M").format("MMM"));
  const [lastDay, setLastDay] = useState(
    parseInt(
      moment([year, month - 1])
        .endOf("month")
        .format("D"),
    ),
  );
  const [dates, setDates] = useState([]);

  useEffect(() => {
    AcademicCalendarApi();
  }, []);

  useEffect(() => {
    updateCalendar(year, month);
  }, [month, year]);

  const AcademicCalendarApi = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.ACADEMICCALENDAR, requestBody)
      .then((data) => {
        // console.log(data);
        if (data.result) {
          setWeekend(data.weekend);
          setHolidayList(data.student_holidat_exec);
          eventDays(data.school_event);
          vacationDays(data.vaction_exec);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const eventDays = (events) => {
    let days = [];
    events.map((item) => {
      const startDate = moment(item.school_event_start_date).format("YYYY-M-D");
      const endDate = moment(item.school_event_end_date).format("YYYY-M-D");

      if (
        moment(startDate, "YYYY-M-D").isSame(moment(endDate, "YYYY-M-D"), "day")
      ) {
        days.push({ event_name: item.school_event_name, date: endDate });
      } else {
        let date = startDate;
        days.push({ event_name: item.school_event_name, date: date });
        do {
          date = moment(date).add(1, "day");
          days.push({
            event_name: item.school_event_name,
            date: date.format("YYYY-M-D"),
          });
        } while (moment(date).isBefore(endDate));
      }
    });
    setEventList(days);
  };

  const vacationDays = (vacation) => {
    let days = [];
    vacation.map((item) => {
      const startDate = moment(item.vacation_start_date).format("YYYY-M-D");
      const endDate = moment(item.vacation_end_date).format("YYYY-M-D");

      if (
        moment(startDate, "YYYY-M-D").isSame(moment(endDate, "YYYY-M-D"), "day")
      ) {
        days.push({ vacation_name: item.vacation_name, date: endDate });
      } else {
        let date = startDate;
        days.push({ vacation_name: item.vacation_name, date: date });
        do {
          date = moment(date).add(1, "day");
          days.push({
            vacation_name: item.vacation_name,
            date: date.format("YYYY-M-D"),
          });
        } while (moment(date).isBefore(endDate));
      }
    });
    setVacationList(days);
  };

  const generateDates = (year, month) => {
    const daysInMonth = moment([year, month - 1]).daysInMonth();
    return Array(daysInMonth)
      .fill(null)
      .map((_, i) => {
        const date = moment(`${year}-${month}-${i + 1}`, "YYYY-MM-D");
        return {
          day: i + 1,
          date: date.format("YYYY-MM-DD"),
          weekDay: date.format("ddd"),
          weekDayNum: date.day(),
        };
      });
  };

  const updateCalendar = (year, month) => {
    setMonth(month);
    setYear(year);
    setMonthName(moment(month, "M").format("MMM"));
    setLastDay(
      moment([year, month - 1])
        .endOf("month")
        .date(),
    );
    const newDates = generateDates(year, month);
    setDates(newDates);

    let firstWeek = 7 - newDates[0].weekDayNum;
    let weeks = Math.floor((lastDay - firstWeek) / 7);
    let addOn = (lastDay - firstWeek) % 7 === 0 ? 1 : 2;
    weeks += addOn;
    setWeekCount(weeks);
  };

  const nextMonth = () => {
    if (month === 12) {
      updateCalendar(year + 1, 1);
    } else {
      updateCalendar(year, month + 1);
    }
  };

  const prevMonth = () => {
    if (month === 1) {
      updateCalendar(year - 1, 12);
    } else {
      updateCalendar(year, month - 1);
    }
  };

  return (
    <div className="container mt-5">
      <div style={{ flexDirection: "row" }}>
        <button onClick={prevMonth}>Prev</button>
        <button onClick={nextMonth}>Next</button>
      </div>
      <h2 className="text-center table">
        {monthName} {year}
      </h2>
      <table className="table table-responsive">
        <thead>
          <tr>
            {days.map((day) => (
              <th key={day} className="text-center">
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array(weekCount)
            .fill(null)
            .map((_, weekIndex) => (
              <tr key={weekIndex}>
                {days.map((day, dayIndex) => {
                  const dateIndex =
                    weekIndex * 7 + dayIndex - dates[0].weekDayNum;
                  const date =
                    dateIndex >= 0 && dateIndex < dates.length
                      ? dates[dateIndex].day
                      : moment(dates[0].date, "YYYY-MM-DD")
                          .add(dateIndex, "days")
                          .format("D");
                  const isToday = moment(
                    `${year}-${month}-${date}`,
                    "YYYY-MM-D",
                  ).isSame(moment(), "day");
                  const isWeekend = weekend.includes(day.toLowerCase());
                  const fullDate =
                    dateIndex >= 0 && dateIndex < dates.length
                      ? `${year}-${month}-${date}`
                      : moment(dates[0].date, "YYYY-MM-DD")
                          .add(dateIndex, "days")
                          .format("YYYY-M-D");
                  return (
                    <td
                      key={dayIndex}
                      className={
                        dateIndex >= 0 && dateIndex < dates.length
                          ? `text-center`
                          : `text-center otherMonth`
                      }
                    >
                      <span>{date}</span>
                      {isToday &&
                        dateIndex >= 0 &&
                        dateIndex < dates.length && (
                          <React.Fragment>
                            <br />
                            <span className="badge bg-primary m-1">Today</span>
                          </React.Fragment>
                        )}
                      {isWeekend && (
                        <React.Fragment>
                          <br />
                          <span className="badge bg-secondary m-1">
                            Weekend
                          </span>
                        </React.Fragment>
                      )}
                      {vacationList.some((item) => item.date === fullDate) && (
                        <React.Fragment>
                          <br />{" "}
                          <span className="badge bg-danger m-1">
                            {
                              vacationList.find(
                                (item) => item.date === fullDate,
                              ).vacation_name
                            }
                          </span>
                        </React.Fragment>
                      )}
                      {eventList.some((item) => item.date == fullDate) && (
                        <React.Fragment>
                          <br />
                          <span className="badge bg-info m-1">
                            {
                              eventList.find((item) => item.date == fullDate)
                                .event_name
                            }
                          </span>
                        </React.Fragment>
                      )}
                      {holidayList.some(
                        (item) =>
                          moment(item.holiday_date).format("YYYY-M-D") ===
                          fullDate,
                      ) && (
                        <React.Fragment>
                          <br />
                          <span className="badge bg-success m-1">
                            {
                              holidayList.find(
                                (item) =>
                                  moment(item.holiday_date).format(
                                    "YYYY-M-D",
                                  ) === fullDate,
                              ).holiday_name
                            }
                          </span>
                        </React.Fragment>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center table">
        <span className="badge bg-primary m-1">Today</span>
        <span className="badge bg-secondary m-1">Weekend</span>
        <span className="badge bg-success m-1">Holiday</span>
        <span className="badge bg-danger m-1">Vacation</span>
        <span className="badge bg-info m-1">Events</span>
      </div>
    </div>
  );
};

export default AcademicCalendar;
