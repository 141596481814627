import React, { useEffect, useState } from 'react'
import {
  Video,
  FileText,
  Image,
  File,
  Headphones,
  Trash2,
  Edit3,
  Eye,
  PlusCircle,
} from 'react-feather'
import announcement_data from '../../../api/announcement_data.json'
import ApiConfig from '../../../api/ApiConfig'
import { simplePostCall } from '../../../api/ApiServices'
import SpinnerCmp from '../../../components/SpinnerCmp'
import Params from '../../../config/Params'
import '../../../assets/css/select2.css'
import AddEditAnnouncement from './AddEditAnnouncement'
import ConfirmationModel from '../../../components/ConfirmationModel'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next"; 
import filter from "../../../assets/images/filter.svg";
import ReactSelect from 'react-select'
import CommunicationSkeleton from '../../../components/skeletons/CommunicationSkeleton'
import { useMemo } from 'react'


function Announcement() {
  const { t } = useTranslation();
  const toastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const [announcement_data, setAnnouncement_data] = useState([])
  const [deleteObj, setDeleteObj] = useState({
    isConfimationModal: false,
    deleteId: 0,
  })
  const [filterData, setfilterData] = useState([
    
  ])
  console.log(filterData)
  const navigate = useNavigate()

  const [addEditModelData, setAddEditModelData] = useState({
    mode: '',
    editData: {},
    isModel: false,
  })
  const [deleteId, setDeleteId] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)
  const [isNextPage, setIsNextPage] = useState(false)
  const [attendance_date, setAttendance_date] = useState({
    today_data: '',
  })
  console.log("attendance_date",attendance_date);
  const [State, setState] = useState({
   
    Subject:"",
    Topic:""
  })
  console.log("State",State);
 

  const [isloading, setIsloading] = useState(true)

  const postAPICall = (uri, dataObj, actionFor) => {
  
    simplePostCall(uri, JSON.stringify(dataObj))
      .then((data) => {
        console.log("data",data);
        setIsloading(false)
        if (data.result) {
          if (actionFor === 'announcement') {
            
            setAnnouncement_data(data.list)
           
            setIsNextPage(data.next_page)
          } else if (actionFor === 'delete') {
            setDeleteObj({ isConfimationModal: false, deleteId: 0 })
            getAnnouncements()
          }
        }
      })
      .catch((error) => {
        setIsloading(false)
        console.log('api response', error)
      })
  }
  function getAnnouncements() {
    postAPICall(
      ApiConfig.ANNOUNCEMENT_API,
      {
        ...Params,
        employee_id: Params.teacher_id,
        page: pageIndex,
      },
      'announcement',
    )
  }
  const onActionHandle = (action, obj) => {
    switch (action && action.toLowerCase()) {
      case 'edit':
        setAddEditModelData({ mode: 'edit', editData: obj, isModel: true })
        break
      case 'delete':
        setDeleteObj({
          isConfimationModal: true,
          deleteId: obj.announcement_id,
        })
        break
      case 'view':
        setAddEditModelData({ mode: 'view', editData: obj, isModel: true })
        break
      default:
        break
    }
  }
  const onActionHandleCallback = (resonse) => {
    console.log(resonse)
    if (resonse.isConfirm) {
      var data = postAPICall(
        ApiConfig.ANNOUNCEMENT_DELETE_API,
        {
          customer_id: Params.customer_id,
          api_key: Params.api_key,
          user_id: Params.teacher_id,
          announcement_id: resonse.id,
        },
        'delete',
      )
      toast.success(
        resonse.isConfirm === true && 'Announcement Deleted Successfully',
      )
    } else {
      // toast.success(data.message, toastOptions)
      setDeleteObj({ isConfimationModal: false, deleteId: 0 })
    }
  }
  const onHandleAdd = () => {
    setAddEditModelData({ mode: 'add', editData: {}, isModel: true })
  }
  const loadContentImage = (type) => {
    if (type === 'text') return <FileText />
    else if (type === 'image') return <Image />
    else if (type === 'pdf') return <File />
    else if (type === 'audio') return <Headphones />
    else return <File />
  }
  useEffect(() => {
    document.title = 'Edsys | Announcement '
    getAnnouncements()
  }, [])
  useEffect(() => {
    getAnnouncements()
  }, [pageIndex])





  //// Filter Api ///

  const topic_options = useMemo(
    () =>
      [...new Set(announcement_data.map((item) => item.announcement_content))].map(
        (type) => {
          return { label: type, value: type };
        }
      ),
    [announcement_data]
  );
  const namesOption = useMemo(
    () =>
      [...new Set(announcement_data.map((item) => item.announcement_type))].map(
        (type) => {
          return { label: type, value: type };
        }
      ),
    [announcement_data]
  );
  // useEffect(() => {
    

  //   let filterAnnouncement = announcement_data.map((Data) => {
  //     return Data.announcement_type
  //   })
  //   setfilterData(filterAnnouncement)
   
  // }, [announcement_data])


  function HandelFilterApi() {

    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      employee_id: Params.teacher_id,
      page:"",
      content:State.Topic,
      type:State.Subject,
      date:attendance_date.today_data,
    })

    simplePostCall(ApiConfig.FILTER_ANANCMENT, newRequestBody)
      .then((data) => {

        if (data.result) {
          
          // if (actionFor === 'announcement') {
            
            setAnnouncement_data(data.list)
           
          //   setIsNextPage(data.next_page)
          // } else if (actionFor === 'delete') {
          //   setDeleteObj({ isConfimationModal: false, deleteId: 0 })
          //   getAnnouncements()
          // }
        }}
      
      )
      .catch((error) => {
      
        console.log('api response', error)
      })
  }

 
  function onSubjectChange(selectedOption) {
    console.log(selectedOption)
    setState({
      ...State,
      Subject: selectedOption.value,
     
    });
   

  }
  function onTopicChange(selectedOption) {
    console.log(selectedOption)
    setState({
      ...State,
      Topic: selectedOption.value,
     
    });
   

  }
  return (
    <>
      {/* Container-fluid starts */}
      <div class="row announcement assignment-sec">
        <div className="col-sm-12">
          {isloading ? (
            <CommunicationSkeleton loading={isloading} />
          ) : (
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">{t('Announcement')}</h5>
                  <a
                    type="button"
                    data-bs-toggle="modal"
                    onClick={onHandleAdd}
                    data-bs-target="#exampleModal"
                    className="btn btn-edsys-primary"
                  >
                    <PlusCircle className="me-2" />
                    <span>{t('Create Announcement')}</span>
                  </a>
                  {addEditModelData.isModel ? (
                    <AddEditAnnouncement
                      data={addEditModelData}
                      onClose={(isAdded) => {
                        if (isAdded) getAnnouncements()
                        setAddEditModelData({ isModel: false })
                      }}
                    />
                  ) : null}
                </div>
              </div>
              {deleteObj.isConfimationModal ? (
                <ConfirmationModel
                  id={deleteObj.deleteId}
                  callback={onActionHandleCallback}
                />
              ) : null}

              <div className="card-body">
                  <div className="row">
                  <div className="col-md-4">
                      <div class="card-body p-0">
                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        // value={dateData}
                        onChange={(e) =>
                          setAttendance_date({
                            ...attendance_date,
                            today_data: e.target.value,
                          })
                        }
                        name="employee_leave_from_date"
                        // value={state.employee_leave_from_date}
                      />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          // value={state.selectedCurriculum}
                          onChange={onSubjectChange}
                          options={namesOption}
                          placeholder="filter By Subject..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          // value={state.selectedCurriculum}
                          onChange={onTopicChange}
                          options={topic_options}
                          placeholder="filter By Topic..."
                        />
                      </div>
                      <div className="col text-end">
                        <div class="card-body p-0">
                          <img onClick={() => HandelFilterApi()} src={filter} alt="" />
                        </div>
                      </div>
                    </div>
                  {announcement_data.map((announcement, index) => {
                    return (
                      <div className="col-md-4 mt-4" key={index}>
                        <div class="card stack-card br-15">
                          <div class="card-body">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0">
                                <div class="ico-n">
                                  {/* <img src={announcement.icon_set} /> */}
                                  {loadContentImage(
                                   announcement.announcement_content_type && announcement.announcement_content_type.toLowerCase(),
                                  )}
                                  {/* <Image /> */}
                                </div>
                              </div>
                              {/* <Link
                                  to="/ViewAnnouncement"
                                  state={announcement}
                                > */}
                              <div
                                onClick={() => {
                                  navigate('/ViewAnnouncement', {
                                    state: announcement,
                                  })
                                }}
                                className="flex-grow-1 ms-3"
                              >
                                {announcement.announcement_content.length > 50
                                  ? announcement.announcement_content.slice(
                                      0,
                                      49,
                                    ) + '...'
                                  : announcement.announcement_content}
                              </div>
                              {/* </Link> */}
                            </div>

                            <div className=" card-footer">
                              <div className=" date-footer d-sm-flex">
                                {announcement.announcement_date}

                                <div className="action-list">
                                  <ul className="list-unstyled">
                                    <li>
                                      <div
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal2"
                                        className="setting-secondary"
                                      >
                                        <Link
                                          to="/ViewAnnouncement"
                                          state={announcement}
                                        >
                                          <Eye height={16} />
                                        </Link>
                                      </div>
                                    </li>
                                    {Params.teacher_id ===
                                    announcement.announcement_user_id ? (
                                      <>
                                        <li>
                                          <div
                                            onClick={() =>
                                              onActionHandle(
                                                'edit',
                                                announcement,
                                              )
                                            }
                                            name="edit"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal2"
                                            className="setting-secondary"
                                          >
                                            <Edit3 />
                                          </div>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              onActionHandle(
                                                'delete',
                                                announcement,
                                              )
                                            }
                                            name="delete"
                                            data-bs-toggle="modal"
                                            data-original-title="test"
                                            data-bs-target="#deleteWarning"
                                            className="setting-secondary"
                                          >
                                            <Trash2 />
                                          </a>
                                        </li>
                                      </>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setPageIndex(pageIndex - 1)
                    // getAnnouncements(pageIndex+1);
                  }}
                  disabled={pageIndex === 1}
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (isNextPage) {
                      setPageIndex(pageIndex + 1)
                      // getAnnouncements(pageIndex+1);
                    }
                  }}
                  disabled={!isNextPage}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Container-fluid Ends */}
    </>
  )
}

export default Announcement
