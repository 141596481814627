import React, { useEffect, useState } from "react";
import { Video, ExternalLink } from "react-feather";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Params from "../config/Params";
import SpinnerCmp from "../components/SpinnerCmp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import add_btn from "../assets/images/add_btn.svg";
import part_1 from "../assets/images/part_1.svg";
import part_2 from "../assets/images/part_2.svg";
import part_3 from "../assets/images/part_3.svg";
import part_4 from "../assets/images/part_4.svg";

function VideoConferenceNew() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div class="video-conf announcementnew assignment-sec">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Video Conference</label>
                <button onClick={() => setModalShow(true)}>
                  <img src={add_btn} alt="" />
                  Create Meeting
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 ">
              <div className="vc-card">
                <div className="vc-header">
                  <div className="row g-0">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-6">
                      <div className="vc-title">Teacher Meeting</div>
                      <div className="vc-time">
                        Starts at 22-09-2022, 10:00 AM
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <p className="vc-class">6th D</p>
                    </div>
                  </div>
                </div>
                <div className="participant">
                  <div className="row g-0">
                    <div className="col-lg-9 col-md-8 col-sm-6 col-6">
                      <p className="part-title">Participant</p>
                      <div className="part-img-box">
                        <img src={part_1} alt="" />
                        <img src={part_2} alt="" />
                        <img src={part_3} alt="" />
                        <img src={part_4} alt="" />
                        <span class="part-count">+25</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  d-flex align-items-end">
                      <button className="join-btn">Join Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoConferenceNew;
