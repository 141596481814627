import React from 'react'
import { Link } from "react-router-dom";
import No_List from '../../../api/No_list.json';
import online_study from '../../../assets/images/e-learning/online-study.svg'
import EditResource from '../EditResource';

import {
  BookOpen,
  Edit,
  Trash2
} from 'react-feather'

export class MyResources extends React.Component {
  //#region constructor
  constructor(props) {
    super(props);
    // debugger
    this.state = {
      isModalShow: false,
      // isTabShow: false,
      // isFromReview: window.location.href.split('/')[window.location.href.split('/').length - 1]


      //this.props.isFromReview?this.props.isFromReview:true
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() { 
    
  }
  //#endregion




  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isModalShow: false,

    })
  }
  openCloseModal = () => {
    // this.setInitialState();
    this.setState({ isModalShow: !this.state.isModalShow }, () => {
    });


  };


  render() {
    return (
      <div className="row">
        {No_List.map((postDetail, index) => {
          return (
            <div className="col-md-4">
              <div className="card br-15">
                <div className="card-header pb-0">
                  <div className="d-flex">


                    <h5 className="title flex-grow-1 cursor-pointer-on">{postDetail.title}</h5>
                    <Edit type='button' data-bs-toggle="modal" onClick={this.openCloseModal} data-bs-target="#exampleModal2" className="header-icon-svg" />
                    <Trash2 type='button' data-bs-toggle="modal" data-original-title="test" data-bs-target="#deleteWarning" className="header-icon-svg" />
                  </div>
                  <div class="modal fade" id="deleteWarning" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog show" role="document">
                      <div class="modal-content br-15">
                        <div class="modal-body">
                          <div class="warning-message" >
                            <Trash2 />
                            <h6>Are you sure you want to delete?</h6>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button class="btn" type="button" data-bs-dismiss="modal">Cancel</button>
                          <button class="d-button" data-bs-dismiss="modal">
                            <div class="trash">
                              <div class="top">
                                <div class="paper"></div>
                              </div>
                              <div class="box"></div>
                              <div class="check">
                                <svg viewBox="0 0 8 6">
                                  <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                                </svg>
                              </div>
                            </div>
                            <span>Delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.isModalShow ? <EditResource onClose={this.openCloseModal} /> : null}
                </div>
                <div className="card-body cursor-pointer-on">
                  <Link to="/ImgVideoGallary" className="d-flex" >
                    <div className="me-auto c-date">{postDetail.date}</div>
                    <div className="type">Book</div>
                    <div className="type-icon"><BookOpen /></div>
                  </Link>
                  <div className="footer-blk">Published by</div>
                  <div className="footer-blk-detail">{postDetail.detail}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

    )
  }
}

export default MyResources



