import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import No_List from '../../../api/No_list.json'
import online_study from '../../../assets/images/e-learning/online-study.svg'
// import { Tabs, Tab } from 'react-bootstrap';

import MyResources from './MyResources.js'
import { BookOpen, Edit, Trash2 } from 'react-feather'
class E_LearningDetails extends React.Component {
  //#region constructor
  constructor(props) {
    super(props)
    // debugger
    this.state = {
      // isTodoModelShow: false,
      isTabShow: false,
      // isFromReview: window.location.href.split('/')[window.location.href.split('/').length - 1]

      //this.props.isFromReview?this.props.isFromReview:true
    }
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = 'Edsys | E-Learning Details'
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      // isTodoModelShow: false,
      isTabShow: false,
    })
  }
  // openCloseTodoModel = () => {
  //     // this.setInitialState();
  //     this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {
  //     });

  // };
  openTab = () => {
    // this.setInitialState();
    this.setState({ isTabShow: true }, () => {})
  }
  //#endregion

  //#region Render

  render() {
    return (
      <div className="page-bodys">
        {/* <!-- Container-fluid starts--> */}
        <div
          className="card"
          style={{
            // float: 'left',
            // marginTop: '-110px',
            borderRadius: '15px',
          }}
        >
          <Link to="/E_Learning" className="col-md-4">
            <h6
              style={{
                padding: '10px',
                marginTop: '5px',
              }}
            >
              Back
            </h6>
          </Link>
        </div>
        <div className="row e-learning-detail view-review view-assignment">
          <div className="col-12">
            <div className="card br-15">
              <div className="card-body header-card">
                <div className="student-header">
                  <div className="details-banner">
                    <h4>PHYSICS (Part 01)</h4>
                    <div className="row">
                      <div className="col-5">
                        <select className="js-example-basic-single form-select">
                          <option value="AL">All</option>
                          <option value="WY">Option 1</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="student-image">
                  <div className="student-avatar">
                    <img src={online_study} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  All Resources<div className="media"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={this.openTab}
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  My Resources
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="card br-15 e-learning-item">
              <div className="card-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      {No_List.map((postDetail, index) => {
                        return (
                          <Link to="/ImgVideoGallary" className="col-md-4">
                            <div className="card br-15 cursor-pointer-on">
                              <div className="card-header br-15 pb-0">
                                <div className="d-flex">
                                  <h5 className="title flex-grow-1">
                                    {postDetail.title}
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body cursor-pointer-on">
                                <div className="d-flex">
                                  <div className="me-auto c-date">
                                    {postDetail.date}
                                  </div>
                                  <div className="type">Book</div>
                                  <div className="type-icon">
                                    <BookOpen />
                                  </div>
                                </div>
                                <div className="footer-blk">Published by</div>
                                <div className="footer-blk-detail">
                                  {postDetail.detail}
                                </div>
                              </div>
                            </div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {this.state.isTabShow ? (
                      <MyResources onClose={this.openTab} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    )
  }
}

export default E_LearningDetails
