import React, { useEffect, useState } from "react";
import Period from "./Period";
import LectureInput from "./LectureInput";
import Weekend from "./Weekend";
import { Button } from "react-bootstrap";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { toast } from "react-toastify";

const TimeTableGridInput = (props) => {
  const { data, classroom } = props; // Destructure classList from props
  const { Periods, Lectures } = data;
  const { pageAfter } = props;
  console.log(props, "propsssss");
  const [timetableData, setTimetableData] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const handleTeacherChange = (
    day,
    periodIndex,
    periodId,
    teacherId,
    teacherName,
  ) => {
    setTimetableData((prevData) => {
      const updatedDay = [...prevData[day]];
      if (!updatedDay[periodIndex]) {
        updatedDay[periodIndex] = { period_id: periodId };
      }
      updatedDay[periodIndex].teacher_id = teacherName;
      // updatedDay[periodIndex].teacher_name = teacherId;
      return { ...prevData, [day]: updatedDay };
    });
  };

  const handleSubjectChange = (
    day,
    periodIndex,
    periodId,
    subjectId,
    subjectName,
  ) => {
    setTimetableData((prevData) => {
      const updatedDay = [...prevData[day]];
      if (!updatedDay[periodIndex]) {
        updatedDay[periodIndex] = { period_id: periodId };
      }
      updatedDay[periodIndex].subject_id = subjectName;
      // updatedDay[periodIndex].subject_name = subjectId;
      return { ...prevData, [day]: updatedDay };
    });
  };

  const CreateTimeTable = () => {
    const newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      division_id: pageAfter?.grade_id,
      shift_id: pageAfter?.shift_id,
      academic_year_id: pageAfter?.academic_year,
      user_id: Params.teacher_id,
      day: [timetableData],
    });
    simplePostCall(ApiConfig.TIMETABLE_CREATE, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const weekDaysColor = {
    MondayColor: [{ bgColor: "monday" }],
    TuesdayColor: [{ bgColor: "tuesday" }],
    WednesdayColor: [{ bgColor: "wednesday" }],
    ThursdayColor: [{ bgColor: "thursday" }],
    FridayColor: [{ bgColor: "friday" }],
  };

  const {
    MondayColor,
    TuesdayColor,
    WednesdayColor,
    ThursdayColor,
    FridayColor,
  } = weekDaysColor;

  const weekDays = [
    { Id: 0, Name: "Periods" },
    { Id: 1, Name: "Monday" },
    { Id: 2, Name: "Tuesday" },
    { Id: 3, Name: "Wednesday" },
    { Id: 4, Name: "Thursday" },
    { Id: 5, Name: "Friday" },
    { Id: 6, Name: "Saturday" },
    { Id: 7, Name: "Sunday" },
  ];

  return (
    <div className="tt-box">
      <div className="tt-row">
        {weekDays.map((day, index) => (
          <div
            className={
              "tt-col" + (day.Id !== 0 ? " " + day.Name.toLowerCase() : "")
            }
            key={index}
          >
            <div className="tt-title">
              <div className="tt-title-inner">
                <div className="title">{day.Name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {Periods.map((period, i) => (
        <div className="tt-row" key={i}>
          <Period data={period} />
          <LectureInput
            pageAfter={pageAfter}
            Lectures={Lectures}
            data={Lectures.monday[i]}
            periodId={period.id}
            Color={MondayColor}
            Classdiv={classroom}
            onTeacherChange={(teacherId, teacherName) =>
              handleTeacherChange(
                "monday",
                i,
                period.id,
                teacherId,
                teacherName,
              )
            }
            onSubjectChange={(subjectId, subjectName) =>
              handleSubjectChange(
                "monday",
                i,
                period.id,
                subjectId,
                subjectName,
              )
            }
          />
          <LectureInput
            pageAfter={pageAfter}
            Lectures={Lectures}
            data={Lectures.tuesday[i]}
            periodId={period.id}
            Color={TuesdayColor}
            Classdiv={classroom}
            onTeacherChange={(teacherId, teacherName) =>
              handleTeacherChange(
                "tuesday",
                i,
                period.id,
                teacherId,
                teacherName,
              )
            }
            onSubjectChange={(subjectId, subjectName) =>
              handleSubjectChange(
                "tuesday",
                i,
                period.id,
                subjectId,
                subjectName,
              )
            }
          />
          <LectureInput
            pageAfter={pageAfter}
            Lectures={Lectures}
            data={Lectures.wednesday[i]}
            periodId={period.id}
            Color={WednesdayColor}
            Classdiv={classroom}
            onTeacherChange={(teacherId, teacherName) =>
              handleTeacherChange(
                "wednesday",
                i,
                period.id,
                teacherId,
                teacherName,
              )
            }
            onSubjectChange={(subjectId, subjectName) =>
              handleSubjectChange(
                "wednesday",
                i,
                period.id,
                subjectId,
                subjectName,
              )
            }
          />
          <LectureInput
            pageAfter={pageAfter}
            Lectures={Lectures}
            data={Lectures.thursday[i]}
            periodId={period.id}
            Color={ThursdayColor}
            Classdiv={classroom}
            onTeacherChange={(teacherId, teacherName) =>
              handleTeacherChange(
                "thursday",
                i,
                period.id,
                teacherId,
                teacherName,
              )
            }
            onSubjectChange={(subjectId, subjectName) =>
              handleSubjectChange(
                "thursday",
                i,
                period.id,
                subjectId,
                subjectName,
              )
            }
          />
          <LectureInput
            pageAfter={pageAfter}
            Lectures={Lectures}
            data={Lectures.friday[i]}
            periodId={period.id}
            Color={FridayColor}
            Classdiv={classroom}
            onTeacherChange={(teacherId, teacherName) =>
              handleTeacherChange(
                "friday",
                i,
                period.id,
                teacherId,
                teacherName,
              )
            }
            onSubjectChange={(subjectId, subjectName) =>
              handleSubjectChange(
                "friday",
                i,
                period.id,
                subjectId,
                subjectName,
              )
            }
          />

          <Weekend />
          <Weekend />
        </div>
      ))}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={CreateTimeTable}>Generate Timetable</Button>
      </div>
    </div>
  );
};

export default TimeTableGridInput;
