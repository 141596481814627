import React, { useState, useEffect } from "react";
import { ChevronLeft } from "react-feather";
import { Link, useParams } from "react-router-dom";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

const QuestionList1 = (props) => {
  return (
    <>
      <div className="qa-header">
        <div className="d-flex justify-content-between">
          <div className="question-type">
            {props.questionData.question_type}
          </div>
          <div className="question-number">
            Question <span>{props.index + 1}</span>/
            <span className="t-ques">{props.Totaldata}</span>
          </div>
        </div>
        <div className="h-line"></div>
      </div>
      <div className="multi-choice question">
        <p>
          <span>Q.</span>
          {props.questionData.question_title}.
        </p>
        <div className="row answer">
          {props.questionData.option &&
            props.questionData.option.map((item, index) => {
              let currectAns = props.questionData.correct_answer[0];
              let your_answer = props.questionData.your_answer[0];
              return (
                <>
                  <div className="col-md-6" key={"item" + index}>
                    <div
                      className={`d-flex answer-list  ${
                        currectAns && currectAns?.answer_id === item.option_id
                          ? "correct-ans"
                          : your_answer?.answer_id !== currectAns?.answer_id &&
                            your_answer?.answer_id === item.option_id
                          ? "wrong-ans ans-selected"
                          : ""
                      }`}
                      // className=" d-flex answer-list wrong-ans ans-selected"

                      title="Wrong Answer"
                    >
                      <div className="radio-btn"></div>
                      <div className="ans-title">{item.option_list}</div>
                      {/* <div className="ms-auto">
                      <img src={b1} />
                    </div> */}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      <div></div>
    </>
  );
};

function ScoreDetails() {
  const { t } = useTranslation();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let api_key = Params.api_key;
  let customer_id = JSON.stringify(Params.customer_id);
  const [state, setState] = useState(1);
  const { studentUser, setStudentUser, setQusation, question, AssessmentId } =
    useContext(AppContext);
  const [ListTotal, setListTotal] = useState("");
  console.log(ListTotal);

  useEffect(() => {
    document.title = "Edsys | Score Details";
  }, []);

  const Question = [0];
  useEffect(() => {
    getAssignments();
  }, [id]);

  function getAssignments() {
    setLoading(true);

    simplePostCall(
      ApiConfig.RANK_LIS_FOR_ASSESSMENT,
      JSON.stringify({
        api_key: api_key,
        customer_id: customer_id,
        assessment_id: AssessmentId,
        student_id: id,
      }),
    )
      .then((data) => {
        if (data.result) {
          setLoading(false);

          setStudentUser(data.data);
          setQusation(data.data);

          // setState({ ...data, filteredData: data.assignment_students })
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        // <div className="page-body mark-sheet">
        <div className="row view-assignment">
          <div className="col-12">
            <Link to="/RankList">
              <h2
                onclick="location.href='rank-list.html';"
                className="back-btn"
              >
                <ChevronLeft className="pt-2" />
                <span>Back</span>
              </h2>
            </Link>
          </div>
          <div className="col-12">
            {studentUser.map((studData) => (
              <div className="card br-15">
                <div className="card-body header-card">
                  <div className="student-header">
                    <div className="details-banner">
                      <h3>{studData.assessment_title}</h3>
                      <h5 className="subject">{studData.assessment_subject}</h5>
                      <div className="sub-date">
                        {studData.assessment_created_date}
                      </div>
                    </div>
                  </div>
                  <div className="student-image">
                    {/* <div className="student-avatar">
                        <img src={b1} />
                        <div className="class">6th D</div>
                      </div> */}
                    <div style={{ marginTop: "60px" }} className="student-name">
                      {studData.assessment_created_by}
                    </div>
                  </div>
                  <div className="duration d-flex justify-content-between">
                    <div className="time m-2">
                      <i data-feather="clock"></i>
                      {studData.assessment_duration} Hrs
                    </div>
                    <div className="total-mark">
                      {t("Total Marks")} <span>50</span>
                    </div>
                    <div className="scored-marks">
                      {t("Scored Marks")} <span>{studData.totalScore}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-12">
            <div className="card br-15">
              <div className="card-body">
                <form
                  className="form-wizard"
                  id="regForm"
                  action="#"
                  method="POST"
                >
                  <div>
                    {question &&
                      question.length &&
                      question.map((questionDetalis, index) => {
                        let Totaldata = questionDetalis.questions.length
                          ? questionDetalis.questions.length
                          : 0;

                        return (
                          questionDetalis.questions &&
                          questionDetalis.questions.map((item, index) => {
                            return (
                              <>
                                {state == 1 ? (
                                  <QuestionList1
                                    questionData={item}
                                    Totaldata={Totaldata}
                                    index={index}
                                  />
                                ) : null}
                              </>
                            );
                          })
                        );
                      })}
                  </div>

                  {/* <!-- Circles which indicates the steps of the form:--> */}
                  {/* <div className="text-center">
                    <span className="step"></span>
                    <span className="step"></span>
                    <span className="step"></span>
                    <span className="step"></span>
                    <span className="step"></span>
                    <span className="step"></span>
                  </div> */}
                  {/* <!-- Circles which indicates the steps of the form:--> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}
    </>
  );
}
export default ScoreDetails;
