import React, { useEffect, useState } from "react";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Select from "react-select";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function StudentMarkListEdit() {
  const [gradeList, setGradeList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [exams, setExams] = useState([]);
  const [maxMark, setMaxmark] = useState([]);
  const [students, setStudents] = useState([]);
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    console.log(location.state, "findingss");
    getSubjectMarklist(location?.state?.subject?.value);
    // GradeListApi();
    SubjectListApi(location.state?.grade?.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.academicYear,
      grade: location?.state?.grade,
      term: location?.state?.term,
      subject: location?.state?.subject,
    }));
  }, []);

  const handleGradeChange = (grade) => {
    if (tags.some((item) => item.value !== location.state.grade?.value)) {
      setTags((prevItems) => [...prevItems, grade]);
    }
  };

  const SubjectListApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classId,
      assessment_offline_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log("Subjects:", data.list);
          const newData = data.list?.map((item) => {
            return {
              label: item.subject_name,
              value: item.assessment_subject,
              ...item,
            };
          });
          setSubList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getSubjectMarklist = (subjectId) => {
    setStudents([]);
    if (!subjectId) {
      toast.error("Please Select Subject");
      return;
    }
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_offline_id: location.state?.offlineId,
      session: formData.academicYear?.value
        ? formData.academicYear?.value
        : location.state.academicYear?.value,
      term: "",
      subject_id: subjectId,
      grade_id: tags[0]?.value
        ? tags.map((item) => item.value)
        : location.state.grade?.value,
      assessment_id: location.state?.assesmentIds,
    });
    simplePostCall(ApiConfig.SUBJECT_WISE_MARKLIST, newRequestBody)
      .then((data) => {
        console.log(data.data, "working");
        const name_ids = Object.keys(data.data);
        let newData = [];
        let Internal_exams = [];
        const newGrades = data?.grade?.map((item) => {
          return {
            label: item.class,
            value: item.assessment_class_class_division_id,
            ...item,
          };
        });
        setTags(newGrades);
        setGradeList(newGrades);
        name_ids.map((id) => {
          let examObjects = {
            student_name: data.data[id].student_name,
            student_id: id,
            subject_name: data.data[id].subject_name,
            Internal_exam: data.data[id].Internal_exam,
            student_profile: data.data[id].student_profile,
            subject_feedback: data.data[id].subject_feedback,

            Total: data.data[id].Total[0],
          };
          const Internal_exam = data.data[id].Internal_exam;
          console.log(data.data[id].Internal_exam, "internalname");
          Internal_exam.map((exam) => {
            Internal_exams.push(exam.evaluation_type_code);
          });
          setMaxmark(Internal_exam);
          newData.push(examObjects);
          console.log("ExamObj", examObjects);
        });
        Internal_exams = [...new Set(Internal_exams)];
        console.log(newData);

        setExams(Internal_exams);
        setStudents(newData);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleInternalExamChange = (e, studentIndex, examIndex) => {
    const { value } = e.target;
    const updatedStudents = [...students];
    updatedStudents[studentIndex].Internal_exam[examIndex].mark =
      parseInt(value);
    setStudents(updatedStudents);
  };
  const handleFeedbackExamChange = (e, studentIndex, examIndex) => {
    const { value } = e.target;
    const updatedStudents = [...students];
    updatedStudents[studentIndex].subject_feedback = value;
    console.log(updatedStudents, "updadetedStudents");
    setStudents(updatedStudents);
  };
  const handleExternalExamChange = (e, studentIndex) => {
    const { value } = e.target;

    const updatedStudents = [...students];
    updatedStudents[studentIndex].Exam.mark = parseInt(value);
    setStudents(updatedStudents);
    console.log(updatedStudents, "value");
  };

  const handleSubmit = () => {
    let changedMarks = [];
    students.map((student) => {
      student.Internal_exam.map((internalExam) => {
        if (internalExam.assessment_id) {
          console.log(internalExam, "students");
          changedMarks.push({
            assessment_id: internalExam.assessment_id,
            student_id: student.student_id,
            result_id: internalExam.result_id,
            assessment_tittle: internalExam.evaluation_type_code,
            subject_feedback: student.subject_feedback,
            result_mark:
              parseInt(internalExam.mark) >
              parseInt(internalExam.assessment_max_mark)
                ? internalExam.assessment_max_mark
                : internalExam.mark,
          });
        }
      });
      if (student?.Exam?.assessment_id) {
        console.log(student, "ExamStudents");
        changedMarks.push({
          assessment_id: student.Exam.assessment_id,
          student_id: student.student_id,
          result_id: student.Exam.results_id,
          subject_feedback: student.subject_feedback,
          result_mark:
            parseInt(student.Exam.mark) >
            parseInt(student.Exam.assessment_max_mark)
              ? student.Exam.assessment_max_mark
              : student.Exam.mark,
        });
      }
    });
    let editRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      result: changedMarks,
      user_id: Params.teacher_id,
      status: "formative",
    });
    if (changedMarks.length > 0) {
      const EditApi = simplePostCall(
        ApiConfig.EDIT_SUBJECT_WISE_MARKLIST,
        editRequestBody,
      );
      EditApi.then((response) => {
        if (response[0].result) {
          toast.success(response[0]?.message);

          getSubjectMarklist(location.state.subject.value);
          nav("/StudentMarkList", {
            state: {
              offlineId: location.state?.offlineId,
              academicYear: formData?.academicYear,
              term: formData?.term,
              SemExam: location?.state?.SemExam,
              grade: formData.grade,
              subject: formData.subject,
              assesmentIds: location.state?.assesmentIds,
            },
          });
        } else {
          toast.error(response[0].message);
        }
      }).catch((error) => {
        console.error("Error:", error);
      });
    } else {
      toast.error("No changes to publish");
    }
  };
  const draftSubmit = () => {
    let changedMarks = [];
    students.map((student) => {
      student.Internal_exam.map((internalExam) => {
        if (internalExam.assessment_id) {
          console.log(internalExam, "students");
          changedMarks.push({
            assessment_id: internalExam.assessment_id,
            student_id: student.student_id,
            result_id: internalExam.result_id,
            assessment_tittle: internalExam.evaluation_type_code,
            subject_feedback: student.subject_feedback,
            result_mark:
              parseInt(internalExam.mark) >
              parseInt(internalExam.assessment_max_mark)
                ? internalExam.assessment_max_mark
                : internalExam.mark,
          });
        }
      });
      if (student?.Exam?.assessment_id) {
        console.log(student, "ExamStudents");
        changedMarks.push({
          assessment_id: student.Exam.assessment_id,
          student_id: student.student_id,
          result_id: student.Exam.results_id,
          subject_feedback: student.subject_feedback,
          result_mark:
            parseInt(student.Exam.mark) >
            parseInt(student.Exam.assessment_max_mark)
              ? student.Exam.assessment_max_mark
              : student.Exam.mark,
        });
      }
    });
    let editRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      status: "draft",
      result: changedMarks,
      user_id: Params.teacher_id,
    });
    if (changedMarks.length > 0) {
      const EditApi = simplePostCall(
        ApiConfig.EDIT_SUBJECT_WISE_MARKLIST,
        editRequestBody,
      );
      EditApi.then((response) => {
        if (response[0].result) {
          toast.success(response[0]?.message);
          // setStudents([]);
          getSubjectMarklist(location.state.subject.value);
          nav("/StudentMarkList", {
            state: {
              offlineId: location.state?.offlineId,
              academicYear: formData.academicYear,
              term: formData.term,
              SemExam: location?.state?.SemExam,
              grade: formData.grade,
              subject: formData.subject,
            },
          });
        } else {
          toast.error(response[0].message);
        }
      }).catch((error) => {
        console.error("Error:", error);
      });
    } else {
      toast.error("No changes to publish");
    }
  };
  const base_url = "https://app.edsys.in/";
  const removeTags = (index) => {
    setTags(tags.filter((el, i) => i !== index));
  };
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Edit Student Mark List</h5>
              </div>
            </div>

            <div className="container row">
              <div className="col">
                <p className="DropTxt">Academic year</p>
                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.academicYear?.label}
                </div>
              </div>
              {/* <div className="col">
                <p className="DropTxt">Term</p>

                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.term?.label}
                </div>
              </div> */}
              <div className="col">
                <p className="DropTxt">Exam</p>
                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {location?.state?.SemExam}
                </div>
              </div>
              <div className="col">
                <p className="DropTxt">Subject</p>
                <p
                  style={{
                    // marginTop: 10,
                    // marginLeft: 10,
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData.subject.label}
                </p>
              </div>

              {/* <div style={{ justifyContent: "flex-end", display: "flex" }}> */}
              <button
                className="view-mark-list-button col-md-3"
                onClick={() => getSubjectMarklist(formData.subject?.value)}
              >
                View Mark List
              </button>
              {/* </div> */}
              <div>
                <p className="DropTxt">Select Grade Divisions</p>
                <div className="tags-input-container">
                  {tags.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag.label}</span>
                      <span className="close" onClick={() => removeTags(index)}>
                        &times;
                      </span>
                    </div>
                  ))}

                  <Select
                    className="tags-input"
                    value={formData.grade}
                    onChange={handleGradeChange}
                    options={gradeList}
                  />
                </div>
              </div>
              <p className="p-10 "></p>

              {students.length > 0 && (
                <div className="col">
                  <div className="d-flex">
                    {/* <h5 className="flex-grow-1 ml-30">Mark List</h5> */}
                  </div>
                  <div className="table">
                    <table>
                      <thead className="thead">
                        <tr>
                          <th style={{ color: "#fff", textAlign: "center" }}>
                            SI. No
                          </th>
                          <th style={{ color: "#fff", textAlign: "start" }}>
                            Student Name
                          </th>

                          {maxMark.map((exam, index) => (
                            <React.Fragment key={index}>
                              <th
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                {exam.evaluation_type_code} Mark (
                                {exam.assessment_max_mark})
                              </th>
                            </React.Fragment>
                          ))}

                          {exams.length > 0 && (
                            <th style={{ color: "#fff", textAlign: "center" }}>
                              Total ({students[0]?.Total?.totalMaxMark})
                            </th>
                          )}

                          <th style={{ color: "#fff", textAlign: "center" }}>
                            Feedback
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {students.map((item, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>
                              <img
                                className="rounded-circle"
                                src={
                                  item.student_profile
                                    ? base_url + item.student_profile
                                    : dashboardAvatar
                                }
                                alt="image"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  marginRight: "10px",
                                }}
                                onError={(e) => {
                                  e.target.src = dashboardAvatar;
                                }}
                              />
                              {item.student_name}
                            </td>

                            {item.Internal_exam.map((exam, i) => (
                              <React.Fragment key={i}>
                                <td style={{ textAlign: "center" }}>
                                  <input
                                    className="border-offlines"
                                    value={exam.mark}
                                    type="number"
                                    min="0"
                                    max={exam.assessment_max_mark}
                                    onChange={(e) =>
                                      handleInternalExamChange(e, index, i)
                                    }
                                  />
                                </td>
                              </React.Fragment>
                            ))}

                            {exams.length > 0 && (
                              <td style={{ textAlign: "center" }}>
                                {item.Total.totalMark}
                              </td>
                            )}

                            <React.Fragment>
                              <td style={{ textAlign: "center" }}>
                                <input
                                  className="border-offline"
                                  //   placeholder="Type here"
                                  value={item?.subject_feedback}
                                  type="text"
                                  onChange={(e) =>
                                    handleFeedbackExamChange(e, index)
                                  }
                                />
                              </td>
                            </React.Fragment>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "110%",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "10px",
                      }}
                    >
                      {/* <button
                        className="view-mark-list-button col-md-3"
                        onClick={draftSubmit}
                      >
                        Draft
                      </button> */}
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="view-mark-list-button col-md-12"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default StudentMarkListEdit;
