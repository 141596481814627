import React, { useState, useEffect } from "react";
import ApiConfig from "../../../../api/ApiConfig";
import { simplePostCall } from "../../../../api/ApiServices";
import "../../../../assets/css/select2.css";
import Params from "../../../../config/Params";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

const QuestionModal = (props) => {
  console.log(props, "proops");
  const { t } = useTranslation();

  const [questionState, setQuestionState] = useState({
    isloading: false,
    questions: [],
    base_url: "",
  });

  const [assignmentState, setAssignmentState] = useState({
    assignment: props.assignmentDetails,
  });

  function getQuestionDetails() {
    setQuestionState({ ...questionState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      question_id: props.questionID,
    });
    simplePostCall(ApiConfig.VIEW_ASSIGNMENT_QUESTION, newRequestBody)
      .then((data) => {
        if (data.result) {
          setQuestionState({
            ...questionState,
            isloading: false,
            questions: data.details,
            base_url: data.base_url,
          });
        } else {
          setQuestionState({ ...questionState, isloading: false });
        }
      })
      .catch((error) => {
        setQuestionState({ ...questionState, isloading: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    getQuestionDetails();
  }, []);
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={{ display: "block" }}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {questionState.isloading ? (
        <SpinnerCmp loading={questionState.isloading} />
      ) : (
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Question")}
              </h5>
              <button
                className="btn-close"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
              </button>
            </div>
            <div className="modal-body datetime-picker modal-scroll">
              <div style={{ width: "100%" }}>
                <table style={{ width: "50%", float: "left" }}>
                  <tr>
                    <th>{t("Question Title")} : </th>
                    <td>{questionState.questions.question_title}</td>
                  </tr>
                  {console.log(questionState, "questionState")}
                  <tr>
                    <th>{t("Subject")} : </th>
                    <td>{props?.assignmentDetails?.assignment_subject}</td>
                  </tr>
                </table>
                <table style={{ width: "50%" }}>
                  <tr>
                    <th>{t("Grade")} : </th>
                    <td>{props?.assignmentDetails?.assignment_class}</td>
                  </tr>
                  <tr>
                    <th>{t("Lesson")} : </th>
                    <td>{questionState.questions.lesson_name}</td>
                  </tr>
                </table>
              </div>
              <hr />
              {console.log(
                ApiConfig.NODE_URL + questionState.questions.question_content,
                "question",
              )}
              <div>
                {questionState.questions.extenstion == "pdf" ||
                questionState.questions.extenstion == "doc" ||
                questionState.questions.extenstion == "docx" ? (
                  <iframe
                    src={
                      ApiConfig.NODE_URL +
                      questionState.questions.question_content
                    }
                    style={{ width: "100%", height: "500px" }}
                  />
                ) : null}
                {questionState.questions.extenstion == "txt" ? (
                  <p>{questionState.questions.question_content}</p>
                ) : null}
                {questionState.questions.extenstion == "png" ||
                questionState.questions.extenstion == "jpg" ||
                questionState.questions.extenstion == "jpeg" ? (
                  <img
                    src={
                      ApiConfig.NODE_URL +
                      questionState.questions.question_content
                    }
                    style={{ width: "100%" }}
                  />
                ) : null}
                {questionState.questions.extenstion == "mp3" ||
                questionState.questions.extenstion == "opus" ? (
                  <audio controls>
                    <source
                      src={
                        ApiConfig.NODE_URL +
                        questionState.questions.question_content
                      }
                      type="audio/mp3"
                    />
                  </audio>
                ) : null}
                {questionState.questions.extenstion == "mp4" ? (
                  <video width="100%" controls controlsList="nodownload">
                    <source
                      src={
                        ApiConfig.NODE_URL +
                        questionState.questions.question_content
                      }
                      type="video/mp4"
                    />
                    <source
                      src={
                        ApiConfig.NODE_URL +
                        questionState.questions.question_content
                      }
                      type="video/ogg"
                    />
                  </video>
                ) : null}
                {questionState.questions.extenstion == "ppt" ? (
                  <iframe
                    style={{ width: "100%", height: "800px" }}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                      ApiConfig.NODE_URL +
                      questionState.questions.question_content
                    }`}
                    title=""
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                ) : null}
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
              >
                {t("Cancel")}
              </button>
              {/* <button
                onClick="location.href='add-questions.html';"
                className="btn btn-primary"
                id="Bookmark"
                type="button"
                //onClick={""}
              >
                Add Questions
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default QuestionModal;
