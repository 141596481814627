import React, { useState, useEffect } from "react";
import EmailCardData from "../../../api/EmailCardData.json";

import EmailModal from "./Emailmodal"
import { Edit3, Trash2, PlusCircle } from "react-feather";

import dashboardAvatar from "../../../assets/images/dashboard/1.png";
import { HTML5_FMT } from "moment";
import { Link } from "react-router-dom";

const EmailNew = () => {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);




  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">


          <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Email</h5>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>Add Email</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? <EmailModal onClose={openCloseTodoModel} /> : null}
                {/* modal End */}
              </div>
            </div>
           
            <div className="card-body">
           

              <div className="row">
                {EmailCardData.map((postDetail, index) => (
                  <div className="col-md-6 mb-3" key={index}>
                     <Link to="/ViewEmailNew">
                    <div className={postDetail.card_bgcolor}>
                      <div className="card-header d-flex justify-content-between">
                        <img
                          className="img-90 rounded-circle"
                          src={dashboardAvatar}
                          alt="image"
                          style={{ height: "50px", width: "50x" }}
                        />
                        <div className="class-body">
                          {postDetail.Participants}
                        </div>
                      </div>

                      <div className="card-body">
                        {postDetail.assignment_title}
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <h5>{postDetail.date}</h5>

                        <div className="class">{postDetail.division_grade}</div>
                      </div>
                    </div>
                    </Link>
                  </div>
                ))}
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailNew;




// import React, { useEffect, useState } from "react";
// import add_btn from "../../../assets/images/add_btn.svg";
// import view_icon from "../../../assets/images/view_icon.svg";
// import part_1 from "../../../assets/images/part_1.svg";
// import part_2 from "../../../assets/images/part_2.svg";
// import part_3 from "../../../assets/images/part_3.svg";
// import part_4 from "../../../assets/images/part_4.svg";
// import Modal from "react-bootstrap/Modal";
// import Form from "react-bootstrap/Form";
// import { Link } from "react-router-dom";

// function EmailNew() {
//   const [modalShow, setModalShow] = useState(false);
//   const [modalShow1, setModalShow1] = useState(false);

//   const [validated, setValidated] = useState(false);
//   const handleSubmit = (event) => {
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     }

//     setValidated(true);
//   };

//   return (
//     <div className="emailnew announcementnew assignment-sec">
//       <div className="main-section">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="announcement-top">
//               <label>Email</label>
//               <button onClick={() => setModalShow(true)}>
//                 <img src={add_btn} alt="" />
//                 Create New Email
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="email-table-wrapper">
//         <table className="email-table">
//           <thead>
//             <tr>
//               <th>Sr. No</th>
//               <th>Class</th>
//               <th>Participant</th>
//               <th>Email Subject</th>
//               <th>Date & Time</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>1</td>
//               <td>5th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>2</td>
//               <td>6th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>3</td>
//               <td>7th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>4</td>
//               <td>8th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>5</td>
//               <td>9th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>6</td>
//               <td>10th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//             <tr>
//               <td>7</td>
//               <td>4th A</td>
//               <td>
//                 <div className="part-img-wrapper">
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </td>
//               <td>About Exam</td>
//               <td>17-02-2023, 14:22:25</td>
//               <td>
//                 <Link to="/ViewEmailNew">
//                   <img src={view_icon} alt="" />
//                 </Link>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>

//       {/* Compose New Email Modal Start */}
//       <Modal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Compose Email To Parents
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-form-wrapper">
//             <Form
//               noValidate
//               validated={validated}
//               onSubmit={handleSubmit}
//               id="myForm"
//             >
//               <div className="row">
//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Grade <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Grade</option>
//                       <option> 5th A</option>
//                       <option> 6th A</option>
//                       <option> 7th A</option>
//                       <option> 8th A</option>
//                       <option> 9th A</option>
//                       <option> 10th A</option>
//                     </Form.Select>
//                   </Form.Group>
//                   <Form.Control.Feedback type="invalid">
//                     Please choose grade
//                   </Form.Control.Feedback>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Student Name <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option defaultValue>Select Student Name</option>
//                       <option value="Text"> John Doe</option>
//                       <option value="Image"> Atif Siddiqui</option>
//                       <option value="Audio"> Ashmer Choudhari</option>
//                       <option value="Pdf"> Aameer Khan</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a student name
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Boarded or Not Boarded Student <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> All Students</option>
//                       <option> Today's Present Students</option>
//                       <option> Leave Students</option>
//                       <option> Transportation Using Students</option>
//                       <option> Transportation Not Using Students </option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a student name
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>
//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Subject <span>*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       type="text"
//                       placeholder="Type Subject here..."
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       Please type subject
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Message <span>*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       as="textarea"
//                       placeholder="Type Message here..."
//                       style={{ height: "100px" }}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       Message can not be blank
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>
//               </div>
//             </Form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow(false)} className="cx-btn-1">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="cx-btn-2"
//               form="myForm"
//               value="Update"
//             >
//               Publish
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//       {/* Compose New Email Modal End */}
//     </div>
//   );
// }

// export default EmailNew;
