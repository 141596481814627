import React, { useEffect, useContext } from "react";
import b1 from "../../../assets/images/user/b1.png";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import SpinnerCmp from "../../../components/SpinnerCmp";
import ApiConfig from "../../../api/ApiConfig";

function RankList() {
  const { Attended_list, loading } = useContext(AppContext);
  console.log("Attended_list", Attended_list);
  useEffect(() => {
    document.title = "Edsys | RankList";
  }, []);

  const sliced_Arr = Attended_list.slice(4);
  console.log("sliced_Arr", sliced_Arr);

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <div className="row rank-list">
          <div className="col-12">
            <div className="rank-list-banner banner-img">
              <div className="profile">
                {/* <div className="person second">
              <Link to="/ScoreDetails">
                <div className="num">2</div>
                <i className="icofont icofont-rounded-double-right"></i>
                <img
                  onclick="location.href='score-details.html';"
                  src={b1}
                  alt=""
                  className="photo"
                />
                <p className="link">Lucky Tom</p>
                <p className="points">
                  46<span>/50</span>
                </p>
              </Link>
            </div> */}
                {Attended_list && Attended_list.length > 0 ? (
                  Attended_list.map((ele, index) => (
                    <div
                      className={
                        ele.rank === 1
                          ? "person m-3 first"
                          : ele.rank === 2
                          ? " person m-3 second"
                          : ele.rank === 3
                          ? "person m-3 third"
                          : "person m-3"
                      }
                    >
                      <Link to={`/ScoreDetails/` + ele.student_id}>
                        <div className="num">{ele.rank}</div>
                        <i className="icofont icofont-queen-crown"></i>

                        <img
                          src={
                            ApiConfig.NODE_URL + ele.student_profilepicture
                              ? ApiConfig.NODE_URL + ele.student_profilepicture
                              : b1
                          }
                          alt=""
                          className="photo main"
                        />
                        <p className="link">{ele.student_name}</p>
                        <p className="points">
                          {ele.mark}
                          <span>/50</span>
                        </p>
                      </Link>
                    </div>
                  ))
                ) : (
                  <div
                    onclick="location.href='#';"
                    className="student-rank-list"
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        color: "white",
                      }}
                    >
                      <span>No records founds</span>
                    </div>
                  </div>
                )}
                {/* <div className="person third">
              <Link to="/ScoreDetails">
                <div className="num">3</div>
                <i className="icofont icofont-rounded-double-right"></i>
                <img
                  onclick="location.href='score-details.html';"
                  src={b3}
                  alt=""
                  className="photo"
                />
                <p className="link">Thomas Abra</p>
                <p className="points">
                  42<span>/50</span>
                </p>
              </Link>
            </div> */}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                {sliced_Arr &&
                  sliced_Arr.map((ele, index) => (
                    <div className="student-rank-list" key={ele}>
                      <Link to={`/ScoreDetails/` + ele.student_id}>
                        <div className="d-flex align-items-center">
                          <div className="ranks">{ele.rank}</div>
                          <div className="p-image">
                            <img
                              src={
                                ApiConfig.BASE_URL + ele.student_profilepicture
                                  ? ApiConfig.BASE_URL +
                                    ele.student_profilepicture
                                  : b1
                              }
                              alt="..."
                            />
                          </div>
                          <div className="student-name">{ele.student_name}</div>
                          <div className="ms-auto scores">
                            {ele.mark}
                            <span>/50</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default RankList;
