import React, { useState, useEffect } from "react";
import "../assets/css/main.css";
import Clock from "react-live-clock";
import clockonicon from "../assets/images/punch/clockonicon.svg";
import clockofficon from "../assets/images/punch/clockofficon.svg";
import Params from "../config/Params";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import SpinnerCmp from "../components/SpinnerCmp";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
function WelcomeCard(props) {
  console.log(props.region, "region");
  const region = props;

  const { t } = useTranslation();

  const [state, setState] = useState({
    attendanceDetails: {},
    punchInTime: "-- : -- : --",
    punchOutTime: "-- : -- : --",
  });
  const [status, setStatus] = useState(0);

  const [attendanceProcessingState, setAttendanceProcessingState] = useState({
    isProcessing: false,
  });
  const [currentLocationState, setCurrentLocationState] = useState({
    current_latitude: 0,
    current_longitude: 0,
  });
  const [intervalState, setIntervalState] = useState({
    interval: null,
  });

  var showdate = new Date();
  var displaytodaysdate =
    showdate.getDate() +
    "/" +
    (showdate.getMonth() + 1) +
    "/" +
    showdate.getFullYear();
  var dt = showdate.toDateString();

  function getEmployeeAttendanceDetails() {
    setAttendanceProcessingState({ isProcessing: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      date: "",
    });
    simplePostCall(ApiConfig.EMPLOYEE_ATTENDANCE_DETAILS_API, newRequestBody)
      .then((data) => {
        setAttendanceProcessingState({ isProcessing: false });
        setState({
          attendanceDetails: data,
          punchInTime: "-- : -- : --",
          punchOutTime: "-- : -- : --",
        });
        if (data.result == true) {
          if (data.details.length == 1) {
            var totalpunchedtime = data.details[0].totalpunchedtime;
            var totalpunchedtime_array = totalpunchedtime.split(":"); // split it at the colons
            // minutes are worth 60 seconds. Hours are worth 60 minutes.
            var seconds =
              +totalpunchedtime_array[0] * 60 * 60 +
              +totalpunchedtime_array[1] * 60 +
              +totalpunchedtime_array[2];

            timer(seconds, true);
            setState({
              attendanceDetails: data,
              punchInTime: data.details[0].check_in_out,
              punchOutTime: "-- : -- : --",
            });
          } else if (data.details.length > 1) {
            timer(seconds, false);
            var totalpunchedtime = data.details[1].totalpunchedtime;
            var totalpunchedtime_array = totalpunchedtime.split(":"); // split it at the colons

            document.getElementById("hours_timer").innerHTML =
              totalpunchedtime_array[0] + ":";
            document.getElementById("minutes_timer").innerHTML =
              totalpunchedtime_array[1] + ":";
            document.getElementById("seconds_timer").innerHTML =
              totalpunchedtime_array[2] + "";

            setState({
              attendanceDetails: data,
              punchInTime: data.details[0].check_in_out,
              punchOutTime: data.details[1].check_in_out,
            });
            setStatus(data.details[1].status);
          } else {
            document.getElementById("hours_timer").innerHTML = "00:";
            document.getElementById("minutes_timer").innerHTML = "00:";
            document.getElementById("seconds_timer").innerHTML = "00";
          }
        }
      })
      .catch((error) => {
        console.log("api response", error);
        setAttendanceProcessingState({ isProcessing: false });
      });
  }

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;
      setCurrentLocationState({
        current_latitude: lat,
        current_longitude: lon,
      });
    });
  }

  function doPunchInOut() {
    setAttendanceProcessingState({ isProcessing: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      date: moment().format("YYYY-MM-DD") /* 2019-12-17 */,
      time: moment().format("HH:mm:ss") /* 17:19:24 */,
      latitude: currentLocationState.current_latitude,
      longitude: currentLocationState.current_longitude,
      device_id: "",
      device_type: "web",
      flag: state.attendanceDetails.result == false ? 1 : 2,
    });

    simplePostCall(ApiConfig.EMPLOYEE_MARK_ATTENDANCE_API, newRequestBody)
      .then((data) => {
        setAttendanceProcessingState({ isProcessing: false });
        if (data.result == true) {
          getEmployeeAttendanceDetails();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
        setAttendanceProcessingState({ isProcessing: false });
      });
  }

  function timer(totalSeconds = 0, runInterval = false) {
    var hoursLabel = document.getElementById("hours_timer");
    var minutesLabel = document.getElementById("minutes_timer");
    var secondsLabel = document.getElementById("seconds_timer");
    //var totalSeconds = 0;

    if (runInterval == true) {
      var interval = setInterval(setTime, 1000);
      setIntervalState({ interval: interval });
    } else {
      clearInterval(intervalState.interval);
      hoursLabel.innerHTML = "00";
      minutesLabel.innerHTML = "00";
      secondsLabel.innerHTML = "00";
    }

    function setTime() {
      ++totalSeconds;
      secondsLabel.innerHTML = pad(totalSeconds % 60);

      var minutes = parseInt(totalSeconds / 60);
      var minutes_disp = parseInt((totalSeconds / 60) % 60);
      minutesLabel.innerHTML = pad(minutes_disp) + ":";

      var hours = parseInt(minutes / 60);
      hoursLabel.innerHTML = pad(hours) + ":";
    }

    function pad(val) {
      var valString = val + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    }
  }

  useEffect(() => {
    getEmployeeAttendanceDetails();
    getCurrentLocation();
    return () => {};
  }, []);

  const handlePunchInOut = () => {
    if (state.attendanceDetails.details.length <= 1) {
      doPunchInOut();
    }
  };

  return (
    <div className="card profile-greeting">
      <SpinnerCmp
        loading={
          attendanceProcessingState.isProcessing ||
          localStorage.getItem("USER_NAME") === null
        }
      />
      <div
        className="card-body text-center edsys-check-in p-t-3"
        style={{
          display:
            attendanceProcessingState.isProcessing ||
            localStorage.getItem("USER_NAME") === null
              ? "none"
              : "",
        }}
      >
        {/* <h3 className="font-light">
          {t("Welcome Back")}, {localStorage.getItem("USER_NAME")}!!{" "}
        </h3> */}
        {/* <p>
          {t(
            "Welcome to the Edsys! we are glad that you are visited this dashboard",
          )}
          .
        </p> */}
        <div className="clock">
          <h1>{dt}</h1>
          <Clock
            format={"HH : mm : ss"}
            ticking={true}
            timezone={props.region}
          />
        </div>
        <div className="punch-button">
          <div className="total-hrs">{t("TOTAL HOURS")}</div>
          <div className="start-time">
            <span id="hours_timer">00:</span>
            <span id="minutes_timer">00:</span>
            <span id="seconds_timer">00</span>
          </div>
          <button
            className={
              state.attendanceDetails.result == false
                ? "punch-btn"
                : status === 2
                ? "punched-out-btn"
                : "punch-out-btn"
            }
            style={{
              backgroundColor: status === 2 ? "#ccc" : "",
              color: status === 2 ? "#000" : "",
              border: "none",
              "&:hover": {
                background: "transparent",
              },
            }}
            onClick={() => handlePunchInOut()}
          >
            {state.attendanceDetails.result == false
              ? `${t("Punch In")}`
              : status === 2
              ? `${t("Punched Out")}`
              : `${t("Punch Out")}`}
          </button>
        </div>
        <div className="row work-duration">
          <div className="col-md-4 punch-i" style={{ width: "50%" }}>
            <img className="p-in-img" src={clockonicon} />
            <div className="p-in">{t("Punch In")}</div>
            <div className="p-in-time">{state.punchInTime}</div>
          </div>
          <div
            className="col-md-4 ms-auto text-end punch-i"
            style={{ width: "50%" }}
          >
            <img className="p-out-img" src={clockofficon} />
            <div
              className="p-out"
              // style={{ display: "flex", justifyContent: "center" }}
            >
              {t("Punch Out")}
            </div>
            <div className="p-out-time">{state.punchOutTime}</div>
          </div>
        </div>
      </div>
      <div className="confetti">
        {/* <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div> */}
      </div>
    </div>
  );
}

export default WelcomeCard;
