import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import add_btn from "../../assets/images/add_btn.svg";
import video_thumb from "../../assets/images/video_thumb.svg";
import play_btn from "../../assets/images/play_btn.svg";
import views_icon from "../../assets/images/views_icon.svg";
import edit_icon from "../../assets/images/edit_icon.svg";
import inactive_edit_icon from "../../assets/images/inactive_edit_icon.svg";
import delete_icon from "../../assets/images/delete_icon.svg";
import inactive_delete_icon from "../../assets/images/inactive_delete_icon.svg";

function ScheduledPrograms() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div class="SchoolTvChannel announcementnew">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Scheduled Program- 10th D</label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 order-2">
                  <div className="video-list-section">
                    <p className="video-heading">Upcoming Videos</p>
                    <div className="video-item">
                      <div className="video-thumbnail">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </div>
                      <div className="video-details">
                        <div className="video-title">
                          <p className="main-title">Video Title</p>
                          <div className="views">
                            <img src={views_icon} alt="" />
                            <p className="view-count">125 </p>
                          </div>
                        </div>
                        <div className="video-time-stamp">
                          <div className="date-time-wrapper">
                            <p className="date">23/02/2023</p>
                            <p className="time">11:00 AM - 11:30 AM</p>
                          </div>
                          <div className="action-btn-wrapper">
                            <img src={inactive_edit_icon} alt="" />
                            <img src={inactive_delete_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="video-item">
                      <div className="video-thumbnail">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </div>
                      <div className="video-details">
                        <div className="video-title">
                          <p className="main-title">Video Title</p>
                          <div className="views">
                            <img src={views_icon} alt="" />
                            <p className="view-count">125 </p>
                          </div>
                        </div>
                        <div className="video-time-stamp">
                          <div className="date-time-wrapper">
                            <p className="date">23/02/2023</p>
                            <p className="time">11:00 AM - 11:30 AM</p>
                          </div>
                          <div className="action-btn-wrapper">
                            <img src={inactive_edit_icon} alt="" />
                            <img src={inactive_delete_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="video-item">
                      <div className="video-thumbnail">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </div>
                      <div className="video-details">
                        <div className="video-title">
                          <p className="main-title">Video Title</p>
                          <div className="views">
                            <img src={views_icon} alt="" />
                            <p className="view-count">125 </p>
                          </div>
                        </div>
                        <div className="video-time-stamp">
                          <div className="date-time-wrapper">
                            <p className="date">23/02/2023</p>
                            <p className="time">11:00 AM - 11:30 AM</p>
                          </div>
                          <div className="action-btn-wrapper">
                            <img src={inactive_edit_icon} alt="" />
                            <img src={inactive_delete_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="video-item">
                      <div className="video-thumbnail">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </div>
                      <div className="video-details">
                        <div className="video-title">
                          <p className="main-title">Video Title</p>
                          <div className="views">
                            <img src={views_icon} alt="" />
                            <p className="view-count">125 </p>
                          </div>
                        </div>
                        <div className="video-time-stamp">
                          <div className="date-time-wrapper">
                            <p className="date">23/02/2023</p>
                            <p className="time">11:00 AM - 11:30 AM</p>
                          </div>
                          <div className="action-btn-wrapper">
                            <img src={inactive_edit_icon} alt="" />
                            <img src={inactive_delete_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="video-item">
                      <div className="video-thumbnail">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </div>
                      <div className="video-details">
                        <div className="video-title">
                          <p className="main-title">Video Title</p>
                          <div className="views">
                            <img src={views_icon} alt="" />
                            <p className="view-count">125 </p>
                          </div>
                        </div>
                        <div className="video-time-stamp">
                          <div className="date-time-wrapper">
                            <p className="date">23/02/2023</p>
                            <p className="time">11:00 AM - 11:30 AM</p>
                          </div>
                          <div className="action-btn-wrapper">
                            <img src={inactive_edit_icon} alt="" />
                            <img src={inactive_delete_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 order-1">
                  <div className="video-detail-section">
                    <div className="meeting-header">
                      <div className="meeting-title">Teachers Meeting</div>
                      <div className="time-remaining">
                        <p>Time Remaining</p>
                        <p>
                          <span> </span> 2:00:00
                        </p>
                      </div>
                    </div>
                    <div className="video-wrapper">
                      <iframe
                        width="100%"
                        height="370"
                        src="https://www.youtube.com/embed/yWMKYID5fr8"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="video-description">
                      <p className="vd-title">Video Title</p>
                      <p className="vd-desc">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation.Ut enim ad minim veniam, quis nostrud
                        exercitation. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduledPrograms;
