import React, { useState } from 'react';
import cross from "../../../assets/images/cross-circle.svg"

const Weekend = props => {
    return (
        <div class="tt-col tt-content weekend">
            <div class="tt-desc tt-desc-inner">
                <img src={cross} />
                <div class="title">Weekend</div>
            </div>
        </div>
    )
};
export default Weekend;