import React, { useEffect, useState } from "react";
import {
    Video,
    FileText,
    Image,
    File,
    Headphones,
    Trash2,
    Edit3,
    Eye,
    PlusCircle,
} from "react-feather";
import { useLocation, Link } from "react-router-dom";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next"; 

function ViewEmail() {
  const { t } = useTranslation();

    const [emailDataState, setEmailDataState] = useState(
        {
            isloading: false,
            email_data: useLocation().state,
        }
    );
    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    useEffect(() => {
        document.title = "Edsys | Email ";
    }, []);
    return (
        <>
            {/* Container-fluid starts */}
            <div className="row announcement assignment-sec">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex">
                                <h5 className="flex-grow-1"> {t("View Email")}</h5>
                                <Link to="/Email" className="btn btn-primary">
                                   {t('Back')}
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {emailDataState.isloading ? (
                                    <SpinnerCmp loading={emailDataState.isloading} />
                                ) : (
                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="card br-15">
                                                <div className="card-body">
                                                    <div className="details-banner">
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Range Type")}</th>
                                                                <td className="communication_table_th_td">{emailDataState.email_data.email_parent_range}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Range Value")}</th>
                                                                <td className="communication_table_th_td">{emailDataState.email_data.email_parent_range_value}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Students")}</th>
                                                                <td className="communication_table_th_td">{emailDataState.email_data.email_parent_student}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Subject")}</th>
                                                                <td className="communication_table_th_td">{emailDataState.email_data.email_parent_subject}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Message")}</th>
                                                                <td className="communication_table_th_td">
                                                                    <div dangerouslySetInnerHTML={{ __html: emailDataState.email_data.email_parent_message }}></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Date")}</th>
                                                                <td className="communication_table_th_td">
                                                                    <div dangerouslySetInnerHTML={{ __html: emailDataState.email_data.email_parent_datetime }}></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container-fluid Ends */}
        </>
    );
}

export default ViewEmail;
