import React from "react";
import Skeleton from "react-loading-skeleton";

export const StudentsViewskeleton = () => {
  return (
    <div>
      {" "}
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <Skeleton width={200} height={30} />
              {/* <div className="createButt">
            <div className="CreateB">Update</div>
          </div> */}
            </div>
          </div>
          {/* {viewdata?.map((item) => ( */}
          <div className="tablesecc">
            <Skeleton width={200} height={30} />
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th scope="col-md-6">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col-md-6" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>

                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={20} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={20} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <Skeleton width={100} />
                  </th>
                  <td scope="col" className="textcol">
                    <Skeleton width={100} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="tablesec">
              <h4 className="studDet">
                <Skeleton width={100} />
              </h4>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tablesec">
              <h4 className="studDet">
                <Skeleton width={100} />
              </h4>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tablesec">
              <h4 className="studDet">
                <Skeleton width={100} />
              </h4>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="col">
                      <Skeleton width={100} />
                    </th>
                    <td scope="col" className="textcol">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};
