import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../src/assets/css/main.css';
import '../src/components/ExternalScripts';
import { AppState } from '../src/context/AppState'
import "../src/languageChange/i18n";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";

ReactDOM.render(
  <>
    <Suspense fallback={(<div>Loading</div>)}>
  <AppState>
    <App />
    </AppState>
    </Suspense>
  </>
  ,
  document.getElementById('root')
);


