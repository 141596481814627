import React, { useState, useEffect } from "react";
// import AssignmentCardData from "../../../api/assignment_card_data.json";
import subjecticon from "../../../assets/images/assignment/subtopicicon.svg";

import "../../../assets/css/select2.css";
import "../../../assets/css/style.css";
import { toast } from "react-toastify";
import subtopicicon from "../../../assets/images/assignment/subtopicicon.svg";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

// import {Modal} from "react-bootstrap"
import AssignmentModal from "./AssignmentModal";
import { Edit3, Trash2, PlusCircle, XSquare } from "react-feather";
import { postWithAuthCall, simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import SpinnerCmp from "../../../components/SpinnerCmp";
import ReactSelect from "react-select";
import filter from "../../../assets/images/filter.svg";
import ListSkeleton from "../../../components/skeletons/ListSkeleton";
import { useMemo } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Modal from "react-bootstrap/Modal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import add_btn from "../../../assets/images/add_btn.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";

const Assignment = () => {
  //----------------------
  const [openMode, setOpenMode] = useState(false);

  const [assignmentId, setAssignmentId] = useState("");

  const [show1, setShow1] = useState(false);

  const [validated, setValidated] = useState(false);
  const [time, setTime] = useState(null);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  //----------------

  const [isTodoModelShow, setisTodoModelShow] = useState(false);
  const [isTagModelShow, setisTagModelShow] = useState(false);
  const [Assignment, setAssignment] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [class_State, setClass_State] = useState("");
  const [subject_state, setSubject_state] = useState("");
  const [topic_state, setTopic_state] = useState("");
  const [TotalCourses, setTotalCourses] = useState(0);
  const [status, setStatus] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [result, setResult] = useState("");
  const [totalPages, setTotalPages] = useState("");

  // Handle tab change to update status

  console.log("clicked", status);

  useEffect(() => {
    document.title = "Edsys | Assignment ";
    setIsloading(true);

    getAssignment({ currentPage: page });
  }, [status]);
  const openCloseTodoModel = () => setisTodoModelShow(true);
  const openCloseTagModel = () => setisTagModelShow(false);
  const { t } = useTranslation();

  const getAssignment = ({ currentPage, text, subject, classroom }) => {
    const details = {};

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      search: text,
      subject: subject,
      class: classroom,
      page: currentPage,
      page_limit: 10,
      status:
        status == 0
          ? "pending"
          : status == 1
          ? "upcoming"
          : status == 2
          ? "history"
          : "",
    });
    simplePostCall(ApiConfig.ASSIGNMENT_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          setResult(data.result);
          setTotalPages(data.total_pages);
          setIsloading(false);
          let total = data.total_data ? data.total_data : 0;
          setTotalCourses(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData?.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            setAssignment(data?.data);
            setIsloading(false);
          } else {
            setAssignment([...Assignment, ...LearningData]);
            setIsloading(false);
          }
        } else {
          setResult(data.result);
          setIsloading(false);
          setAssignment([]);
        }
        // setAssignment(data.data);
        // console.log(data.data,"vghhjbb");
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  // Function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }

  const dateString = "2024-10-03";
  const formattedDate = formatDate(dateString);
  console.log(formattedDate, "formattedDate");
  ///////.........Delete Api........///////

  const DeleteAssignment = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,

      assignment_id: assignmentId,
    });
    simplePostCall(ApiConfig.ASSIGNMENT_DELET_API, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          toast.success(data.message);
          getAssignment({ currentPage: page });
          console.log("message", data.message);
        } else {
          toast.error("something went wrong");
          getAssignment({ currentPage: page });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const onPageLoad = () => {
    if (totalPages > page) {
      setPage(page + 1);
      getAssignment({ currentPage: page + 1 });
    }
  };

  // ======================================= PAGINATION ENDS ========================

  const class_options = useMemo(
    () =>
      [...new Set(Assignment?.map((item) => item.assignment_class))].map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [Assignment],
  );
  const subject_options = useMemo(
    () =>
      [...new Set(Assignment?.map((item) => item.assignment_subject))].map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [Assignment],
  );
  const topic_options = useMemo(
    () =>
      [...new Set(Assignment?.map((item) => item.assignment_title))].map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [Assignment],
  );

  return (
    <div>
      <div class="Forum library">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <h5 className="flex-grow-1">{t("Assignment")}</h5>
                <Link
                  to="/create-assignment"
                  type="button"
                  className="btn btn-edsys-primary"
                >
                  <PlusCircle
                    className="me-2 "
                    style={{ marginBottom: "-5px" }}
                  />
                  <span className="align-top">Create New Assignment</span>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div class="card-body p-0">
                    <input
                      class="form-control"
                      value={class_State}
                      onChange={(e) => {
                        // Update the state with the selected option
                        setClass_State(e.target.value);
                        getAssignment({
                          currentPage: page,
                          classroom: e.target.value,
                        });
                      }}
                      // options={class_options}
                      placeholder="filter By Class..."
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="card-body p-0">
                    {/* <ReactSelect
                      class="js-example-basic-single form-select" */}
                    <input
                      class="form-control"
                      value={subject_state}
                      onChange={(e) => {
                        setSubject_state(e.target.value);
                        getAssignment({
                          currentPage: page,
                          subject: e.target.value,
                        });
                      }}
                      // options={subject_options}
                      placeholder="filter By Subject..."
                    />
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div class="card-body p-0">
                    <input
                      class="form-control"
                      value={topic_state}
                      // onChange={(e) => {
                      //   console.log(e.target.value, "zzz");
                      //   setTopic_state(e.target.value);
                      //   getAssignment(page, e.target.value);
                      // }}
                      onChange={(e) => {
                        console.log(e.target.value, "zzz");
                        setTopic_state(e.target.value);
                        getAssignment({
                          currentPage: page,
                          text: e.target.value,
                        });
                      }}
                      // options={topic_options}
                      placeholder="filter By Topic..."
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <div class="card-body p-0">
                    <div
                      onClick={() => [
                        setAssignment([]),
                        getAssignment({ currentPage: page }),
                      ]}
                    >
                      <Button style={{ width: "80px" }}>Clear</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="books-main-wrapper">
                <TabView
                  activeIndex={status}
                  onTabChange={(e) => setStatus(e.index)}
                >
                  <TabPanel header="Pending">
                    <div className="row assignment-sec">
                      <div className="col-sm-12">
                        {isloading ? (
                          <ListSkeleton loading={isloading} />
                        ) : (
                          <div className="card">
                            <div className="card-body">
                              <InfiniteScroll
                                dataLength={Assignment?.length}
                                next={onPageLoad}
                                hasMore={Assignment?.length !== TotalCourses}
                                style={{
                                  overflow: "hidden",
                                }}
                                loader={
                                  result == true ? (
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      Loading....
                                    </h4>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <b>No Assignment Found</b>
                                    </p>
                                  )
                                }
                                endMessage={
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <b>No More data Found</b>
                                  </p>
                                }
                              >
                                <div className="row">
                                  {Assignment && Assignment?.length > 0
                                    ? Assignment?.map((assessment, index) => {
                                        let classIndex =
                                          index < 6
                                            ? index + 1
                                            : Math.floor(Math.random() * 6) + 1;
                                        return (
                                          <div className="col-md-6 mt-4">
                                            <div
                                              className={
                                                "card sub-" +
                                                classIndex +
                                                " stack-card"
                                              }
                                            >
                                              <div className="card-header">
                                                <div className="d-flex">
                                                  <img
                                                    className="flex-grow-0"
                                                    src={subtopicicon}
                                                  />
                                                  <h6 className="flex-grow-1">
                                                    {
                                                      assessment.assignment_subject
                                                    }
                                                  </h6>
                                                  <div className="class">
                                                    {
                                                      assessment.assignment_class
                                                    }
                                                  </div>
                                                </div>
                                              </div>

                                              <Link
                                                className="name card-body"
                                                to={
                                                  "/AssignmentDetails/" +
                                                  assessment.assignment_id
                                                }
                                                // className="card-body"
                                              >
                                                <h5>
                                                  {assessment.assignment_title}
                                                </h5>
                                              </Link>

                                              <div className="card-footer">
                                                <div className="d-sm-flex">
                                                  <h5>
                                                    {formatDate(
                                                      assessment.assignment_created_date,
                                                    )}
                                                  </h5>
                                                  <div className="action-list">
                                                    <ul className="list-unstyled">
                                                      <li>
                                                        <div
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModal2"
                                                          className="setting-secondary"
                                                        >
                                                          <Link
                                                            to={
                                                              "/create-assignment/" +
                                                              assessment.assignment_id
                                                            }
                                                            type="button"
                                                            // className="btn btn-edsys-primary"
                                                          >
                                                            <i data-feather="edit-3">
                                                              <Edit3 />
                                                            </i>
                                                          </Link>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <a
                                                          data-bs-toggle="modal"
                                                          data-original-title="test"
                                                          data-bs-target="#deleteWarning"
                                                          className="setting-secondary"
                                                        >
                                                          <i data-feather="trash-2">
                                                            <Trash2
                                                              onClick={() => [
                                                                setOpenMode(
                                                                  true,
                                                                ),
                                                                setAssignmentId(
                                                                  assessment.assignment_id,
                                                                ),
                                                              ]}
                                                            />
                                                          </i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </InfiniteScroll>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel header="InProgress">
                    <div className="row assignment-sec">
                      <div className="col-sm-12">
                        {isloading ? (
                          <ListSkeleton loading={isloading} />
                        ) : (
                          <div className="card">
                            <div className="card-body">
                              <InfiniteScroll
                                dataLength={Assignment?.length}
                                next={onPageLoad}
                                hasMore={Assignment?.length !== TotalCourses}
                                style={{
                                  overflow: "hidden",
                                }}
                                loader={
                                  result == true ? (
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      Loading....
                                    </h4>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <b>No Assignment Found</b>
                                    </p>
                                  )
                                }
                                endMessage={
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <b>No More data Found</b>
                                  </p>
                                }
                              >
                                <div className="row">
                                  {Assignment && Assignment?.length > 0
                                    ? Assignment?.map((assessment, index) => {
                                        let classIndex =
                                          index < 6
                                            ? index + 1
                                            : Math.floor(Math.random() * 6) + 1;
                                        return (
                                          <div className="col-md-6 mt-4">
                                            <div
                                              className={
                                                "card sub-" +
                                                classIndex +
                                                " stack-card"
                                              }
                                            >
                                              <div className="card-header">
                                                <div className="d-flex">
                                                  <img
                                                    className="flex-grow-0"
                                                    src={subtopicicon}
                                                  />
                                                  <h6 className="flex-grow-1">
                                                    {
                                                      assessment.assignment_subject
                                                    }
                                                  </h6>
                                                  <div className="class">
                                                    {
                                                      assessment.assignment_class
                                                    }
                                                  </div>
                                                </div>
                                              </div>

                                              <Link
                                                className="name card-body"
                                                to={
                                                  "/AssignmentDetails/" +
                                                  assessment.assignment_id
                                                }
                                                // className="card-body"
                                              >
                                                <h5>
                                                  {assessment.assignment_title}
                                                </h5>
                                              </Link>

                                              <div className="card-footer">
                                                <div className="d-sm-flex">
                                                  <h5>
                                                    {formatDate(
                                                      assessment.assignment_created_date,
                                                    )}
                                                  </h5>
                                                  <div className="action-list">
                                                    <ul className="list-unstyled">
                                                      <li>
                                                        <div
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModal2"
                                                          className="setting-secondary"
                                                        >
                                                          <Link
                                                            to={
                                                              "/create-assignment/" +
                                                              assessment.assignment_id
                                                            }
                                                            type="button"
                                                            // className="btn btn-edsys-primary"
                                                          >
                                                            <i data-feather="edit-3">
                                                              <Edit3 />
                                                            </i>
                                                          </Link>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <a
                                                          data-bs-toggle="modal"
                                                          data-original-title="test"
                                                          data-bs-target="#deleteWarning"
                                                          className="setting-secondary"
                                                        >
                                                          <i data-feather="trash-2">
                                                            <Trash2
                                                              onClick={() => [
                                                                setOpenMode(
                                                                  true,
                                                                ),
                                                                setAssignmentId(
                                                                  assessment.assignment_id,
                                                                ),
                                                              ]}
                                                            />
                                                          </i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </InfiniteScroll>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="History">
                    <div className="row assignment-sec">
                      <div className="col-sm-12">
                        {isloading ? (
                          <ListSkeleton loading={isloading} />
                        ) : (
                          <div className="card">
                            <div className="card-body">
                              <InfiniteScroll
                                dataLength={Assignment?.length}
                                next={onPageLoad}
                                hasMore={Assignment?.length !== TotalCourses}
                                style={{
                                  overflow: "hidden",
                                }}
                                loader={
                                  result == true ? (
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      Loading....
                                    </h4>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <b>No Assignment Found</b>
                                    </p>
                                  )
                                }
                                endMessage={
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <b>No More data Found</b>
                                  </p>
                                }
                              >
                                <div className="row">
                                  {Assignment && Assignment?.length > 0
                                    ? Assignment?.map((assessment, index) => {
                                        let classIndex =
                                          index < 6
                                            ? index + 1
                                            : Math.floor(Math.random() * 6) + 1;
                                        return (
                                          <div className="col-md-6 mt-4">
                                            <div
                                              className={
                                                "card sub-" +
                                                classIndex +
                                                " stack-card"
                                              }
                                            >
                                              <div className="card-header">
                                                <div className="d-flex">
                                                  <img
                                                    className="flex-grow-0"
                                                    src={subtopicicon}
                                                  />
                                                  <h6 className="flex-grow-1">
                                                    {
                                                      assessment.assignment_subject
                                                    }
                                                  </h6>
                                                  <div className="class">
                                                    {
                                                      assessment.assignment_class
                                                    }
                                                  </div>
                                                </div>
                                              </div>

                                              <Link
                                                className="name card-body"
                                                to={
                                                  "/AssignmentDetails/" +
                                                  assessment.assignment_id
                                                }
                                                // className="card-body"
                                              >
                                                <h5>
                                                  {assessment.assignment_title}
                                                </h5>
                                              </Link>

                                              <div className="card-footer">
                                                <div className="d-sm-flex">
                                                  <h5>
                                                    {formatDate(
                                                      assessment.assignment_created_date,
                                                    )}
                                                  </h5>
                                                  <div className="action-list">
                                                    <ul className="list-unstyled">
                                                      <li>
                                                        <div
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModal2"
                                                          className="setting-secondary"
                                                        >
                                                          <Link
                                                            to={
                                                              "/create-assignment/" +
                                                              assessment.assignment_id
                                                            }
                                                            type="button"
                                                            // className="btn btn-edsys-primary"
                                                          >
                                                            <i data-feather="edit-3">
                                                              <Edit3 />
                                                            </i>
                                                          </Link>
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <a
                                                          data-bs-toggle="modal"
                                                          data-original-title="test"
                                                          data-bs-target="#deleteWarning"
                                                          className="setting-secondary"
                                                        >
                                                          <i data-feather="trash-2">
                                                            <Trash2
                                                              onClick={() => [
                                                                setOpenMode(
                                                                  true,
                                                                ),
                                                                setAssignmentId(
                                                                  assessment.assignment_id,
                                                                ),
                                                              ]}
                                                            />
                                                          </i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </InfiniteScroll>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        centered
        show={openMode}
        onHide={() => setOpenMode(false)}
        animation={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="borderModal"></div>
          <p>Are you sure you want to delete ?</p>

          {/* <div className="borderModalBelow"></div> */}
        </Modal.Body>
        <div className="borderModal"></div>
        <Modal.Footer>
          <div className="modalBtns">
            <button className="cancelBtn" onClick={() => setOpenMode(false)}>
              Cancel
            </button>
            <button
              className="YesBtn"
              onClick={() => [setOpenMode(false), DeleteAssignment()]}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Assignment;
