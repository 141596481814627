import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import Select from "react-select";
import "../../../assets/css/style.css";
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 1,
  }),
};
const LectureInput = (props) => {
  const { firstName } = useContext(AppContext);
  const data = props.data;
  const Color = props.Color;
  const ClassDivision = props.Classdiv;
  const periodId = props.periodId;
  const pageAfter = props.pageAfter;

  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [selectedSubjectName, setSelectedSubjectName] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [grader, setGrader] = useState("");
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [formData, setFormData] = useState({
    grade: "",
    subject: "",
  });

  const handleTeacherChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: event.label,
      subject: "",
    }));
    setGrader(event.value);
    props.onTeacherChange(periodId, event.value, event.label); // Call the parent handler
    setSelectedTeacherName(event.label);
    // }
  };

  const handleSubjectChange = (event) => {
    // const [subjectId, subjectName] = event.target.value.split(",");
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: event.label,
    }));
    setSelectedSubject(event.value);
    props.onSubjectChange(periodId, event.value, event.label); // Call the parent handler
    setSelectedSubjectName(event.label);
  };

  useEffect(() => {
    getTeacherList();
    getSubjectList();
  }, []);

  const getTeacherList = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      division_id: pageAfter?.grade_id,
    });
    simplePostCall(ApiConfig.TEACHER_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          var classlist = [];
          data.list.map((data, index) => {
            classlist.push({
              label: data.teacher_name,
              value: data.teacher_id,
              teacher_image: data.teacher_image,
            });
          });
          setClassList({ classlist: classlist });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getSubjectList = () => {
    simplePostCall(
      ApiConfig.SUBJECT_LIST_TIMETABLE,
      JSON.stringify({
        ...Params,
        user_id: Params.teacher_id,
        division_id: pageAfter?.grade_id,
      }),
    )
      .then((data) => {
        if (data.result) {
          var subjectlist = [];
          data.list.map((data, index) => {
            subjectlist.push({
              label: data.subject_name,
              value: data.subject_id,
              subject_icon: data.subject_icon,
              subject_code: data.subject_code,
              teacher_image: data.teacher_image,
            });
          });
          setSubjectList({ subjectlist: subjectlist });
          // setSubjectList(data.list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <div className={"tt-col tt-content " + (Color[0]?.bgColor || "")}>
      <div className="tt-desc tt-desc-inner">
        <div style={{ height: "30px" }} />
        <div className="title">
          {/* <select
            style={{
              borderWidth: 1,
              borderColor: "purple",
              borderRadius: 10,
              marginTop: 10,
              width: "70%",
            }}
            value={formData.grade}
            onChange={handleTeacherChange}
          >
            <option value="">
              {selectedTeacherName ? selectedTeacherName : data?.teacher_name}
            </option>
            {classList.map((cls) => (
              <option
                key={cls.teacher_id}
                value={`${cls.teacher_id},${cls.teacher_name}`}
              >
                {cls.teacher_name}
              </option>
            ))}
          </select> */}
          <Select
            className=""
            placeholder={
              selectedTeacherName ? selectedTeacherName : data?.teacher_name
            }
            value={formData.grade}
            onChange={handleTeacherChange}
            options={classList?.classlist}
            styles={{
              control: (provided) => ({
                ...provided,
                borderWidth: 1,
                borderColor: "purple",
                borderRadius: 10,
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body} // Append the menu to the body to ensure it overlays other elements
            isSearchable
          />
        </div>
        <div className="title"></div>
        <div className="duration">
          {/* <select
            style={{
              borderWidth: 1,
              borderColor: "purple",
              width: "100%",
              borderRadius: 5,
              marginTop: 10,
            }}
            value={formData.subject}
            onChange={handleSubjectChange}
          >
            <option value="">
              {selectedSubjectName ? selectedSubjectName : data?.subject_name}
            </option>
            {subjectList.map((cls) => (
              <option
                key={cls.subject_id}
                value={`${cls.subject_id},${cls.subject_name}`}
              >
                {cls.subject_name}
              </option>
            ))}
          </select> */}
          <Select
            className=""
            placeholder={
              selectedSubjectName ? selectedSubjectName : data?.subject_name
            }
            value={formData.subject}
            onChange={handleSubjectChange}
            options={subjectList?.subjectlist}
            styles={{
              control: (provided) => ({
                ...provided,
                borderWidth: 1,
                borderColor: "purple",
                borderRadius: 10,
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body} // Append the menu to the body to ensure it overlays other elements
            isSearchable
          />
        </div>
      </div>
    </div>
  );
};

export default LectureInput;
