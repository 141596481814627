import React, { useState, useEffect } from "react";
import AssignmentCardData from "../../api/assignment_card_data.json";
import subjecticon from "../../assets/images/assignment/subtopicicon.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import AssignmentModal from "../academics/Assignment/AssignmentModal";
import { Edit3, Trash2, PlusCircle, BookOpen } from "react-feather";
import { Modal, Button } from "react-bootstrap";
import subtopicicon from "../../assets/images/assignment/subtopicicon.svg";

import "../../assets/css/tabstyles.css";
import "../../assets/css/OfflineAssesment.css";
import Params from "../../config/Params";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
const OfflineAssesment = () => {
  const [isTodoModelShow, setTodoModelShow] = useState(false);
  const [isTagModelShow, setTagModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [gradeList, setGradeList] = useState([]);

  const [Assignment, setAssignment] = useState([]);
  const [openMode, setOpenMode] = useState(false);
  const [assignmentId, setAssignmentId] = useState("");

  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    console.log(location?.state?.assesmentId, "ysxzzzz");
    console.log(location?.state?.AcademicYr, "yriddd");
    console.log(location?.state?.TermId?.term_id, "termid");
    console.log(location?.state?.assesmentName, "Namee");

    document.title = "Edsysy | Assignment";
  }, []);

  const setInitialState = () => {
    setTodoModelShow(false);
    setTagModelShow(false);
  };

  const openCloseTodoModel = () => {
    setInitialState();
    setTodoModelShow(!isTodoModelShow);
  };

  const openCloseTagModel = () => {
    setInitialState();
    setTagModelShow(!isTagModelShow);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isloading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);
  const [status, setStatus] = useState(0);

  const [class_State, setClass_State] = useState([]);
  const [subject_state, setSubject_state] = useState([]);
  const [topic_state, setTopic_state] = useState([]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  const gradeOptions = ["6A", "6B", "6C", "6D", "6F"];

  // Create state to hold the selected grade
  const [selectedGrade, setSelectedGrade] = useState("");

  // Handle change in selected grade
  // const handleGradeChange = (event) => {
  //   setSelectedGrade(event.target.value);
  // };
  //..........Filter Api's...........//
  useEffect(async () => {
    OffAss();
    // AcademicYrListApi();
    GradeListApi();
    // TermListApi(location?.state?.AcademicYr?.value);

    console.log(location?.state, "stateeee");
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.AcademicYr,

      term: location?.state?.TermId,
    }));
  }, []);

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location?.state?.assesmentId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleGradeChange = (grade) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: grade,
      subject: null,
    }));
  };
  const handleYearChange = (year) => {
    console.log("Seleected Acad Year", year);
    // setAcademicYear(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: year,
      term: null,
    }));
    setTermList([]);
    TermListApi(year.value);
  };
  const handleTermChange = (term) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      term: term,
    }));
    console.log("Selected Term", term);
  };
  console.log(location?.state?.AcademicYr, "yearrrr");

  const [formData, setFormData] = useState({
    examName: "",
    academicYear: {
      label: location?.state?.AcademicYr?.label,
      value: location?.state?.AcademicYr?.value,
    },
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });

  const DeleteAssignment = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,

      assessment_id: deleteId,
    });
    simplePostCall(
      "https://app.edsys.in:3000/api/teacher/offlineassessment/delete",
      newRequestBody,
    )
      .then((data) => {
        if (data.result == true) {
          toast.success(data.message);
          OffAss(page);
          console.log("message", data.message);
        } else {
          toast.error("something went wrong");
          OffAss(page);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const OffAss = () => {
    const details = {};

    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      academic_year: formData.academicYear?.value,
      term: formData.term?.value,
      grade: formData.grade?.value,
      offline_assessment_id: location?.state?.assesmentId,
    });
    simplePostCall(ApiConfig.SUBJECTWISE_LISTING_MAIN, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.data, "hiiiii");
          console.log(data.message, "message");
          setAssignment(data.data);
          setIsloading(false);
        } else {
          setAssignment([]);

          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  console.log(Assignment, "AssignmentData");
  return (
    <>
      <div className="Forum library">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="col-md -6">Offline Assesment</h5>
                  {/* <Link to="/GradewiseList">
                    <a type="button" className="btn btn-edsys-primary btn1">
                      <BookOpen className="me-2" />
                      <span style={{ marginTop: "20px" }}>
                        Grade Wise MarkList
                      </span>
                    </a>
                  </Link> */}

                  {/* <Link to="/SubjectwiseList">
                    <a
                      type="button"
                      className="btn btn-edsys-primary btn1"
                      style={{ marginLeft: "10px" }}
                    >
                      <BookOpen className="me-2" />
                      <span style={{ marginTop: "20px" }}>
                        Subject Wise MarkList
                      </span>
                    </a>
                  </Link> */}
                </div>
              </div>

              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div className="offline_border"></div>
                  <div className="assessment_topdiv">
                    <div className="col-md-12 row  headoffline">
                      <div className="col-md-3 ">
                        <div className="">Academic Year</div>
                        {/* <Select
                          class="js-example-basic-single form-select"
                          name="academicYear"
                          value={formData.academicYear}
                          // onChange={handleYearChange}
                          // options={academicYearList}
                          isDisabled
                        /> */}
                        {/* <input
                          style={{
                            padding:8,
                            
                            // backgroundColor:"red",
                            borderColor: "#e6edef",
                            borderWidth: 0.5,
                            borderRadius: 5,
                          }}
                          name="academicYear"
                          value={formData.academicYear.label}
                        /> */}
                        <div
                          style={{
                            marginTop: 10,
                            marginLeft: 10,
                            fontFamily: "bold",
                            color: "black",
                            fontSize: 17,
                          }}
                        >
                          {formData.academicYear.label}
                        </div>
                      </div>
                      <div className="col-md-3 ">
                        <div className="">Term Selected</div>
                        <div
                          style={{
                            marginTop: 10,
                            marginLeft: 10,
                            fontFamily: "bold",
                            color: "black",
                            fontSize: 17,
                          }}
                        >
                          {formData.term.label}
                        </div>
                        {/* <Select
                          class="js-example-basic-single form-select"
                          name="terms"
                          value={formData.term}
                          options={termList}
                          onChange={handleTermChange}
                          isDisabled
                        /> */}
                      </div>
                      <div className="col-md-3 ">
                        <div className="">Select Grade</div>
                        <Select
                          class="js-example-basic-single form-select"
                          name="grade"
                          value={formData.grade}
                          onChange={handleGradeChange}
                          options={gradeList}
                        />
                      </div>
                      <button
                        className="view-mark-list-button col-md-3"
                        style={{}}
                        onClick={() => OffAss()}
                      >
                        View List
                      </button>
                    </div>
                  </div>

                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <div className="card-body">
                            {console.log(Assignment, "ASSIIGNNN")}
                            <InfiniteScroll
                              dataLength={Assignment?.length}
                              // next={onPageLoad}
                              hasMore={Assignment?.length !== TotalCourses}
                              style={{
                                overflow: "hidden",
                              }}
                              loader={null}
                              // endMessage={
                              //   <p style={{ textAlign: "center", marginTop: "20px" }}>
                              //     <b>No More data Found</b>
                              //   </p>
                              // }
                            >
                              <div className="row">
                                {/* <div className="col-md-4">
                                      <div class="card-body p-0">
                                        <ReactSelect
                                          class="js-example-basic-single form-select"
                                          value={class_State}
                                          onChange={(selectedOption) => {
                                            // Update the state with the selected option
                                            setClass_State(selectedOption);
                                          }}
                                          // options={class_options}
                                          placeholder="filter By Class..."
                                        />
                                      </div>
                                    </div> */}
                                {/* <div className="col-md-4">
                                      <div class="card-body p-0">
                                        <ReactSelect
                                          class="js-example-basic-single form-select"
                                          value={subject_state}
                                          onChange={(e) =>
                                            e && setSubject_state(e)
                                          }
                                          // options={subject_options}
                                          placeholder="filter By Subject..."
                                        />
                                      </div>
                                    </div> */}
                                {/* <div className="col-md-4 d-flex">
                                      <div class="card-body p-0">
                                        <ReactSelect
                                          class="js-example-basic-single form-select"
                                          value={topic_state}
                                          onChange={(e) =>
                                            e && setTopic_state(e)
                                          }
                                          // options={topic_options}
                                          placeholder="filter By Topic..."
                                        />
                                      </div>
                                      <div className="col text-end">
                                        <div class="card-body p-0"></div>
                                      </div>
                                    </div> */}
                                {Assignment && Assignment?.length > 0 ? (
                                  Assignment?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4" style={{}}>
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />
                                              <h6 className="flex-grow-1">
                                                {/* {`${
                                                        assessment.assessment_offline_academic_year.split(
                                                          "-",
                                                        )[0]
                                                      }-${assessment.assessment_offline_academic_year
                                                        .split("-")[1]
                                                        .slice(-2)}`} */}
                                                {
                                                  assessment.assessment_subject_name
                                                }
                                              </h6>
                                              <div
                                                // className="d-sm-flex"
                                                style={{
                                                  borderRadius: "10%",
                                                  marginRight: "5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "#772569",
                                                  }}
                                                >
                                                  {assessment.assessment_status ===
                                                  "ready for apporval"
                                                    ? "Ready To Approve"
                                                    : assessment.assessment_status ===
                                                      "draft"
                                                    ? "draft"
                                                    : assessment.assessment_status ===
                                                      "published"
                                                    ? "published"
                                                    : ""}
                                                </p>
                                              </div>
                                              <div className="class">
                                                {assessment.assessment_class}
                                              </div>
                                            </div>
                                          </div>

                                          {/* <Link
                                            className="name card-body"
                                            to={`/ViewOfflineAssesment/${assessment.assessment_offline_id}`}
                                            style={{ color: "black" }}
                                            // className="card-body"
                                          > */}
                                          <h5
                                            className="name card-body"
                                            style={{ color: "black" }}
                                            onClick={() => {
                                              console.log(
                                                location?.state?.assesmentName,
                                                "nycc",
                                              );
                                              navigation(`/SubjectwiseList`, {
                                                state: {
                                                  offlineId:
                                                    assessment.assessment_offline_id,
                                                  SemExam:
                                                    location?.state
                                                      ?.assesmentName,
                                                  academicYear: {
                                                    label:
                                                      assessment.assessment_offline_academic_year,
                                                    value:
                                                      assessment.academic_year_id,
                                                  },
                                                  grade: {
                                                    label:
                                                      assessment.assessment_class,
                                                    value: assessment.grade_id,
                                                  },
                                                  term: {
                                                    label:
                                                      assessment.assessment_offline_term,
                                                    value: assessment.terms_id,
                                                  },
                                                  subject: {
                                                    label:
                                                      assessment.assessment_subject_name,
                                                    value:
                                                      assessment.assessment_subject_id,
                                                  },
                                                  startDate:
                                                    assessment.assessment_offline_start_date,
                                                },
                                              });
                                            }}
                                          >
                                            {console.log()}
                                            {assessment.assessment_title}
                                          </h5>
                                          {/* </Link> */}

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {assessment.assessment_start_date
                                                  .split("-")
                                                  .reverse()
                                                  .join("-")}
                                              </h5>

                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div>
                                                      <Link
                                                        // to={
                                                        //   "/create-assignment/" +
                                                        //   assessment.assignment_id
                                                        // }
                                                        type="button"
                                                        // className="btn btn-edsys-primary"
                                                      >
                                                        {/* <i data-feather="edit-3">
                                                                <Edit3 />
                                                              </i> */}
                                                      </Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2
                                                          onClick={() => [
                                                            setOpenMode(true),
                                                            setDeleteId(
                                                              assessment.assessment_id,
                                                            ),
                                                          ]}
                                                        />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  // Message to display when Assignment array is empty
                                  <div className="text-center mt-4">
                                    <h4>No Assessment Found</h4>
                                  </div>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          centered
          show={openMode}
          onHide={() => setOpenMode(false)}
          animation={true}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="borderModal"></div>
            <p>Are you sure you want to delete ?</p>

            {/* <div className="borderModalBelow"></div> */}
          </Modal.Body>
          <div className="borderModal"></div>
          <Modal.Footer>
            <div className="modalBtns">
              <button className="cancelBtn" onClick={() => setOpenMode(false)}>
                Cancel
              </button>
              <button
                className="YesBtn"
                onClick={() => [setOpenMode(false), DeleteAssignment()]}
              >
                Delete
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OfflineAssesment;
