import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSearchPlus } from "react-icons/fa";
import { BiExit } from "react-icons/bi";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import StudentDetskeleton from "./studentDetskeleton";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { simplePostCall } from "../../api/ApiServices";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { Book, BookOpen } from "react-feather";
import { MdReport } from "react-icons/md";

export const StudentListNew = () => {
  const [studentListdet, setStudentListdet] = useState([]);
  const [loading, setLoading] = useState(true);
  const [className, setClassName] = useState([]);
  const [changeClass, setChangeClass] = useState("");
  const [studentsearch, setStudentsearch] = useState("");
  const [admno, setAdmno] = useState("");

  function classList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data, "class list");
        if (data.result) {
          var Class = [];
          if (data.list?.length > 0) {
            data.list.map((clas, index) => {
              Class.push({
                label: clas.class_name,
                value: clas.class_id,
              });
            });
          }
          setClassName({ ...className, Class: Class });
          console.log(className, "classlistne");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const onChangeClass = (e) => {
    console.log(e.classlistval, "classlisy");
    setChangeClass(e.value);
  };
  const studentList = () => {
    try {
      axios(ApiConfig.STUDENT_LIST_IN_STUDENTMANAG, {
        method: "POST",

        data: {
          user_id: Params.teacher_id,
          student_name: studentsearch,
          admission_no: admno,
          class_id: changeClass,
          division_id: "",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
        headers: {
          Accept: "application/json",
        },
      }).then((data) => {
        if (data.data.result) {
          setLoading(false);
          console.log(data.data, "ARRAY");
          setStudentListdet(data.data.data);
          console.log(studentListdet, "detailed array");
        } else {
          setLoading(false);
          console.log("NO DATA in view");
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Planned TripListApi Error: ", err);
      throw err;
    }
  };
  const arr = [
    {
      div: "A",
    },
    {
      div: "B",
    },
    {
      div: "C",
    },
  ];
  const options = arr.map((item) => ({ value: item.div, label: item.div }));
  useEffect(() => {
    studentList();
    classList();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [changeClass, studentsearch, admno]);
  const navi = useNavigate();
  const base_url = "https://app.edsys.in/";

  const handleClear = () => {
    setStudentsearch("");
    setAdmno("");
    setChangeClass("");
  };

  return (
    <div className="StudentList">
      {loading ? (
        <StudentDetskeleton />
      ) : (
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="labelCreate">Students</label>
              </div>
            </div>
            <div className="tableCreate">
              <div class="table-responsive">
                <table class="table table-dark table-borderless newTable ">
                  <thead className="tableHeadd">
                    <th>#</th>
                    <th>Image</th>
                    <th>Student Name</th>
                    <th>Admission Number</th>
                    <th>Grade</th>
                    <th>Division</th>

                    <th></th>
                    <th></th>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>

                      <td></td>
                      <td>
                        <div>
                          <input
                            type="text"
                            className="tetxinpp text-inp1"
                            value={studentsearch}
                            onChange={(e) => {
                              setStudentsearch(e.target.value);
                              console.log(studentsearch);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="text"
                            className="tetxinpp text-inp1"
                            value={admno}
                            onChange={(e) => {
                              setAdmno(e.target.value);
                              console.log(setAdmno);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <Select
                            class="js-example-basic-single form-select "
                            aria-label="Default select example"
                            value={className.classlistval}
                            onChange={onChangeClass}
                            options={className.Class}
                          ></Select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Select
                            class="js-example-basic-single form-select "
                            aria-label="Default select example"
                            options={options}
                          ></Select>
                        </div>
                      </td>

                      <td></td>
                      <td>
                        <div className="buttdiv">
                          <button
                            type="button"
                            className="studListClr"
                            onClick={handleClear}
                          >
                            Clear
                          </button>
                        </div>
                      </td>
                    </tr>
                    {studentListdet?.map((item, index) => (
                      <tr>
                        <td className="textcol">{index + 1}</td>

                        <td>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "100px",
                            }}
                            src={base_url + item.student_profilepicture}
                            onError={(e) => {
                              e.target.src = dashboardAvatar;
                            }}
                          />
                        </td>
                        <td className="textcol">{item.student_name}</td>
                        <td className="textcol">
                          {item.student_admission_num}
                        </td>
                        <td className="textcol">{item.class_name}</td>
                        <td className="textcol">{item.class_division_name}</td>

                        <td></td>
                        <td className="iconSec">
                          <div
                            data-tip="view"
                            className="searchInStuList"
                            onClick={() =>
                              navi("/Studentdetails", {
                                state: item.student_id,
                              })
                            }
                          >
                            <FaSearchPlus className="icons" />
                          </div>
                          <div
                            style={{ height: "31px", width: "31px" }}
                            data-tip="Report"
                            className="searchInStuList"
                            onClick={() =>
                              navi("/Studentdetails", {
                                state: item.student_id,
                              })
                            }
                          >
                            <BookOpen className="icons" />
                          </div>
                          <ReactTooltip effect="solid" />
                          <div className="editInStuList" data-tip="view leaves">
                            <BiExit className="icons" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
