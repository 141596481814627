import React, { useEffect } from "react";
import dashboardAvatar from "../../../assets/images/dashboard/1.png";

function ViewEmailNew() {
  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
  }, []);

  const smsDetailsStyle1 = {
    textAlign: "center",

    // justifycontent:"center"
  };

  const smsDetailsStyle = {
    color: "blue",
    fontSize: "15px",
    backgroundColor: "#b5daff",
    // width:"100%",
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="">Email Notification Details</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <img
          className="img-90 rounded-circle"
          src={dashboardAvatar}
          alt="image"
          style={{ height: "50px", width: "50px" }}
        />
        <div className="class-body ml-2">Participants</div>
      </div>
      <div style={smsDetailsStyle1}>
        <a style={smsDetailsStyle}>6TH A</a>
      </div>

      <div class="grid text-center"></div>

      <div className="mt-2 col-12">
        <div className="card">
          <div className="card-header">
            <h6 className="text-primary mt-2">Subject</h6>

            <div className="mt-2">test mail</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="details-banner">
                <div className="sub-date">28 Oct 2021, Thu</div>
              </div>
              <div className="details-banner">
                <div className="sub-date">10:42</div>
              </div>
            </div>
            <div className="mt-2">Message</div>

            <div className="mt-2">
              hii sir i am not able to join the metting
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEmailNew;


// import React, { useEffect, useState } from "react";
// import {
//   Video,
//   FileText,
//   Image,
//   File,
//   Headphones,
//   Trash2,
//   Edit3,
//   Eye,
//   PlusCircle,
//   Settings,
// } from "react-feather";
// import Modal from "react-bootstrap/Modal";
// import { Carousel, Card, Stack, Button } from "react-bootstrap";
// import { useLocation, Link } from "react-router-dom";
// import SpinnerCmp from "../../../components/SpinnerCmp";
// import { useTranslation } from "react-i18next";
// import email_img from "../../../assets/images/email_img.png";
// import clock_icon from "../../../assets/images/clock_icon.svg";
// import slide_img_1 from "../../../assets/images/slide_img_1.png";
// import slide_img_2 from "../../../assets/images/slide_img_2.png";
// import audio1 from "../../../assets/audio/audio1.mp3";
// import docs1 from "../../../assets/images/doc.png";
// import Form from "react-bootstrap/Form";
// import Accordion from "react-bootstrap/Accordion";
// import Save from "../../../assets/images/saveFile.svg";
// import start_recording_icon from "../../../assets/images/start_recording_icon.svg";
// import stop_recording_icon from "../../../assets/images/stop_recording_icon.svg";
// import recording_waves from "../../../assets/images/recording_waves.svg";
// import part_1 from "../../../assets/images/part_1.svg";
// import part_2 from "../../../assets/images/part_2.svg";
// import part_3 from "../../../assets/images/part_3.svg";
// import part_4 from "../../../assets/images/part_4.svg";

// function ViewEmailNew() {
//   const [modalShow1, setModalShow1] = useState(false);
//   const [modalShow2, setModalShow2] = useState(false);

//   const [validated, setValidated] = useState(false);

//   const handleSubmit = (event) => {
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     }

//     setValidated(true);
//   };

//   const [isVisible, setIsVisible] = useState(false);

//   const handleRecording = () => {
//     setIsVisible(!isVisible);
//   };

//   const [selected, setSelected] = useState();

//   const handleChange = (event) => {
//     setSelected(event.target.value);
//   };

//   return (
//     <>
//       {/* Container-fluid starts */}
//       <div className="emailnew viewannouncementnew announcementnew assignment-sec">
//         <div className="main-section">
//           <div className="row">
//             <div className="col-lg-6 col-md-6">
//               <div className="announcement-title-box">
//                 <div className="at-heading">Email</div>
//                 <div className="at-subheading">Subject </div>
//                 <div className="at-author">About Assignment Submission</div>
//                 <div className="dateandtime-wrapper">
//                   <div className="date-stamp">28 Oct 2021, Thu</div>
//                   <div className="time-stamp">
//                     <img src={clock_icon} alt="" />
//                     1:00:00 PM
//                   </div>
//                 </div>
//                 <div className="part-img-wrapper">
//                   <p className="pi-heading">Participant</p>
//                   <img src={part_1} alt="" />
//                   <img src={part_2} alt="" />
//                   <img src={part_3} alt="" />
//                   <img src={part_4} alt="" />
//                   <span className="part-count">+25</span>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6">
//               <div className="announcement-img-box">
//                 <img src={email_img} alt="" className="img-fluid" />
//               </div>
//             </div>
//             <div className="col-md-12">
//               <div className="announcement-description-wrapper">
//                 <div className="ad-heading">
//                   <p>Message</p>
//                 </div>
//                 <div className="ad-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et dolore magna
//                     aliqua. Ut enim ad minim veniam, quis nostrud
//                     exercitation.Ut enim ad minim veniam, quis nostrud
//                     exercitation. Lorem ipsum dolor sit amet, consectetur
//                     adipiscing elit, sed do eiusmod tempor incididunt ut labore
//                     et dolore magna aliqua. Ut enim ad minim veniam, quis
//                     nostrud exercitation.Ut enim ad minim veniam, quis nostrud
//                     exercitation. Lorem ipsum dolor sit amet, consectetur
//                     adipiscing elit, sed do eiusmod tempor incididunt ut labore
//                     et dolore magna aliqua. Ut enim ad minim veniam, quis
//                     nostrud exercitation.Ut enim ad minim veniam, quis nostrud
//                     exercitation.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Container-fluid Ends */}

//       {/* Edit Announcement Modal Start */}
//       <Modal
//         show={modalShow1}
//         onHide={() => setModalShow1(false)}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Edit Announcement
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-form-wrapper">
//             <Form
//               noValidate
//               validated={validated}
//               onSubmit={handleSubmit}
//               id="myForm"
//             >
//               <div className="row">
//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Announcement Type</option>
//                       <option> Announcement</option>
//                       <option> Message</option>
//                       <option> Emergency</option>
//                       <option> Alerts</option>
//                       <option> Information</option>
//                     </Form.Select>
//                   </Form.Group>
//                   <Form.Control.Feedback type="invalid">
//                     Please choose a announcement type
//                   </Form.Control.Feedback>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select
//                       required
//                       value={selected}
//                       onChange={handleChange}
//                     >
//                       <option defaultValue>
//                         Select Announcement Content Type
//                       </option>
//                       <option value="Text"> Plain Text</option>
//                       <option value="Image"> Image</option>
//                       <option value="Audio"> Audio</option>
//                       <option value="Pdf"> PDF</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a content type
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Student Name <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Student Name</option>
//                       <option> select1</option>
//                       <option> select2</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a student name
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content <span>*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       as="textarea"
//                       placeholder="Type Message here..."
//                       style={{ height: "100px" }}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       Announcement content can not be blank
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>
//                 {selected === "Image" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Image</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Upload</button>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 {selected === "Audio" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Audio</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Download</button>
//                       </div>
//                     </div>
//                     <div className="record-audio-wrapper">
//                       <Accordion>
//                         <Accordion.Item eventKey="0">
//                           <Accordion.Header>Record Audio</Accordion.Header>
//                           <Accordion.Body>
//                             <p className="audio-title">Record & Upload Audio</p>
//                             <p className="audio-note">
//                               Click record image to start record and click the
//                               same after record ends for stop
//                             </p>
//                             <Form
//                               noValidate
//                               validated={validated}
//                               onSubmit={handleSubmit}
//                               id="myForm"
//                             >
//                               <div className="row">
//                                 <div className="col-md-12">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content <span>*</span>
//                                     </Form.Label>
//                                     <Form.Control
//                                       required
//                                       as="textarea"
//                                       placeholder="Type Message here..."
//                                       style={{ height: "38px" }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                       Announcement content can not be blank
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required>
//                                       <option> Select Announcement Type</option>
//                                       <option> Announcement</option>
//                                       <option> Message</option>
//                                       <option> Emergency</option>
//                                       <option> Alerts</option>
//                                       <option> Information</option>
//                                     </Form.Select>
//                                   </Form.Group>
//                                   <Form.Control.Feedback type="invalid">
//                                     Please choose a announcement type
//                                   </Form.Control.Feedback>
//                                 </div>

//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required value={selected}>
//                                       <option defaultValue>
//                                         Select Announcement Content Type
//                                       </option>
//                                       <option value="Text"> Plain Text</option>
//                                       <option value="Image"> Image</option>
//                                       <option value="Audio"> Audio</option>
//                                       <option value="Pdf"> PDF</option>
//                                     </Form.Select>
//                                     <Form.Control.Feedback type="invalid">
//                                       Please choose a content type
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-12">
//                                   <div className="recording-wrapper">
//                                     <div className="start-recording-section">
//                                       <p className="sr-title">Record Audio</p>
//                                       <img
//                                         src={start_recording_icon}
//                                         alt=""
//                                         onClick={handleRecording}
//                                       />
//                                     </div>
//                                     {isVisible ? (
//                                       <div className="starting">
//                                         <p className="start-title">
//                                           Stop Recording
//                                         </p>
//                                         <img
//                                           src={stop_recording_icon}
//                                           alt=""
//                                           onClick={handleRecording}
//                                         />
//                                         <p className="start-title">
//                                           Recording 001
//                                         </p>
//                                         <img src={recording_waves} alt="" />
//                                         <div className="starting-time-stamp">
//                                           2:28
//                                         </div>
//                                         <div className="recording-btn-wrapper">
//                                           <button className="download">
//                                             Download File
//                                           </button>
//                                           <button className="save">
//                                             Save Recording
//                                           </button>
//                                         </div>
//                                       </div>
//                                     ) : (
//                                       ""
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </Form>
//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow1(false)} className="cx-btn-1">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="cx-btn-2"
//               form="myForm"
//               value="Update"
//             >
//               Send
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//       {/* Edit Announcement Modal End */}

//       {/* Delete Announcement Modal Start */}

//       <Modal
//         show={modalShow2}
//         onHide={() => setModalShow2(false)}
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Delete Announcement
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="delete-text-wrapper">
//             Are you sure you want to delete ?
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="delete-btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
//               Cancel
//             </button>
//             <button type="submit" className="dlt-btn-2" value="delete">
//               Delete
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>

//       {/* Delete Announcement Modal End */}
//     </>
//   );
// }

// export default ViewEmailNew;
