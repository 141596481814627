import React, { useState, useEffect } from "react";
import { simplePostCall } from "../../../../api/ApiServices";
import ApiConfig from "../../../../api/ApiConfig";
import Params from "../../../../config/Params";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment";
import { childrenPropType } from "react-tabs/lib/helpers/propTypes";
import { AppContext } from "../../../../context/AppContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const initialState = {
  isloading: false,
  assignment_title: "",
  assignment_description: "",
  curriculum: "",
  class_division: "",
  class_division_id: "",
  subject: "",
  students: [],
  submission_date: "",
  lessons: [],
  topics: [],
  submission_mode: "",
  assignment_type: "",
  review_by_user_id: "",
  selectedCurriculum: null,
  selectedGrade: null,
  selectedSubject: null,
  selectedLessons: null,
  selectedTopics: null,
  selectedAssignmentType: null,
  selectedSubmissionMode: null,
  selectedReviewByUser: null,
  selectedStudents: null,
  assignment_title_error: "",
  assignment_description_error: "",
  curriculum_error: "",
  class_division_error: "",
  subject_error: "",
  assignment_type_error: "",
  submission_mode_error: "",
  submission_date_error: "",
  review_by_user_id_error: "",
  students_error: "",
};

export default function AssignmentStepOne(props) {
  const [firstt, setfirstt] = useState("");
  const { t } = useTranslation();
  const [mode, setMode] = useState("create");
  const [state, setState] = useState(initialState);
  const { assignmentDetailsState, setAssignmentDetailsState } =
    useContext(AppContext);
  console.log(props.classid, "propertyyyy", state.class_division_id);
  console.log(assignmentDetailsState, "initialState?.");
  useEffect(() => {
    if (props.assignmentDetails) {
      setMode("edit");
    } else {
      setMode("create");
    }
  }, [props.assignmentDetails]);

  useEffect(() => {
    if (mode === "edit") {
      setState({
        ...initialState,
        assignment_title: assignmentDetailsState?.assignment_title || "",
        assignment_description:
          assignmentDetailsState?.assignment_description || "",
        curriculum: assignmentDetailsState?.curriculum || "",
        class_division: assignmentDetailsState?.class_division || "",
        subject: assignmentDetailsState?.subject || "",
        students: assignmentDetailsState?.students || [],
        submission_date: assignmentDetailsState?.submission_date || "",
        lessons: props.assignmentDetails ? props.assignmentDetails.lessons : [],
        topics: props.assignmentDetails ? props.assignmentDetails.topics : [],
        submission_mode: assignmentDetailsState?.submission_mode || "",
        assignment_type: props.assignmentDetails
          ? props.assignmentDetails.assignment_type
          : "",
        review_by_user_id: assignmentDetailsState?.review_by_user_id || "",
      });
    } else {
      setState(initialState);
    }
  }, [mode, assignmentDetailsState, props.assignmentDetails]);
  console.log(state, "STATEEEEE");
  const [curriculumState, setCurriculumState] = useState({
    isloading: false,
    curriculums: [],
  });
  const [gradeState, setGradeState] = useState({
    isloading: false,
    grades: [],
  });
  const [subjectState, setSubjectState] = useState({
    isloading: false,
    subjects: [],
  });
  const [lessonsState, setLessonsState] = useState({
    isloading: false,
    lessons: [],
  });
  const [topicsState, setTopicsState] = useState({
    isloading: false,
    topics: [],
  });
  const [assignmentTypeState, setAssignmentTypeState] = useState({
    assignment_type: [
      { value: "project", label: "Project Assignment" },
      { value: "oral", label: "Reading Assignment" },
      { value: "writing", label: "Writing Assignment" },
      { value: "visual", label: "Visual Assignment" },
    ],
  });
  const [submissionModeState, setSubmissionModeState] = useState({
    submission_mode: [
      { value: "1", label: "Online" },
      { value: "0", label: "Direct" },
    ],
  });
  const [reviewUserState, setReviewUserState] = useState({
    isloading: false,
    reviewUsers: [],
  });
  const [studentsState, setStudentsState] = useState({
    isloading: false,
    students: [],
  });

  function handleSaveAndNext() {
    try {
      if (
        state.assignment_title === "" ||
        state.assignment_description === "" ||
        state.curriculum === "" ||
        (state.class_division === "" && first === "") ||
        state.subject === "" ||
        state.assignment_type === "" ||
        state.submission_mode === "" ||
        state.submission_date === "" ||
        state.review_by_user_id === "" ||
        state.students?.length === 0
      ) {
        toast.error("Please fill all mandatory fields");

        setState((prevState) => ({
          ...prevState,
          assignment_title_error:
            state.assignment_title === "" ? "Enter Assignment Title" : "",
          assignment_description_error:
            state.assignment_description === ""
              ? "Enter Assignment Description"
              : "",
          curriculum_error: state.curriculum === "" ? "Select Curriculum" : "",
          class_division_error:
            state.class_division === "" && first === "" ? "Select Grade" : "",
          subject_error: state.subject === "" ? "Select Subject" : "",
          assignment_type_error:
            state.assignment_type === "" ? "Select Assignment Type" : "",
          submission_mode_error:
            state.submission_mode === "" ? "Select Submission Mode" : "",
          submission_date_error:
            state.submission_date === "" ? "Select Submission Date" : "",
          review_by_user_id_error:
            state.review_by_user_id === "" ? "Select One Teacher" : "",
          students_error:
            state.students?.length === 0 ? "Select at least one Student" : "",
        }));
      } else {
        // setState({
        // ...state,
        // class_division: selectedOption.value,
        // selectedGrade: selectedOption,
        // class_division_id: selectedOption.value,
        // });
        props.saveAssignmentInfo(state);
        props.handleNext();
      }
    } catch (error) {
      console.error("Error in handleSaveAndNext:", error);
    }
  }

  ////////////////StRT////////////////

  function getCurriculumList() {
    setCurriculumState({ ...curriculumState, isloading: true });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          var curriculums = [];
          if (data.curriculum_list?.length > 0) {
            data.curriculum_list.map((curriculum, index) => {
              curriculums.push({
                label: curriculum.curriculum_name,
                value: curriculum.curriculum_id,
              });
            });
          }
          setCurriculumState({
            ...curriculumState,
            isloading: false,
            curriculums: curriculums,
          });
        } else {
          setCurriculumState({ ...curriculumState, isloading: false });
        }
      })
      .catch((error) => {
        setCurriculumState({ ...curriculumState, isloading: false });
        console.log("api response", error);
      });
  }

  function onCurriculumChange(selectedOption) {
    setState({
      ...state,
      curriculum: selectedOption.value,
      selectedCurriculum: selectedOption,
    });
    getSubjects();
    // getLessons('', selectedOption.value, '');
  }

  function getGradeList() {
    setGradeState({ ...gradeState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list?.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name + " " + grade.class_division_name,
                value: grade.class_division_id,
              });
            });
          }
          setGradeState({ ...gradeState, isloading: false, grades: grades });
        } else {
          setGradeState({ ...gradeState, isloading: false });
        }
      })
      .catch((error) => {
        setGradeState({ ...gradeState, isloading: false });
        console.log("api response", error);
      });
  }

  function onGradeChange(selectedOption) {
    console.log("selectedOption", selectedOption.value);
    setState({
      ...state,
      class_division: selectedOption.value,
      selectedGrade: selectedOption,
      class_division_id: selectedOption.value,
    });

    getSubjects(selectedOption.value);
    getLessons("", "", selectedOption.value);
    getStudents(selectedOption.value);
  }
  const [first, setfirst] = useState("");

  function getSubjects(selected_class_division) {
    setfirst(selected_class_division ? selected_class_division : props.classid);
    // setSubjectState({ ...subjectState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      class_id: selected_class_division
        ? selected_class_division
        : props.classid,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API, newRequestBody)
      .then((data) => {
        console.log(
          ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API,
          newRequestBody,
          "jhjhjhjhjhjh",
          data,
        );
        if (data.result) {
          var subjects = [];
          if (data.list?.length > 0) {
            data.list.map((subject, index) => {
              subjects.push({
                label: subject.subject_name,
                value: subject.subject_id,
              });
            });
          }

          setSubjectState({
            ...subjectState,
            isloading: false,
            subjects: subjects,
          });
        } else {
          setSubjectState({ ...subjectState, isloading: false });
        }
      })
      .catch((error) => {
        setSubjectState({ ...subjectState, isloading: false });
        console.log("api response", error);
      });
  }

  function onSubjectChange(selectedSubjectOption) {
    console.log(
      "selectedSubjectOption",
      selectedSubjectOption,
      "selectedSubjectOption",
      state,
    );
    setState({
      ...state,
      subject: selectedSubjectOption.value,
      selectedSubject: selectedSubjectOption,
    });

    getLessons(selectedSubjectOption.value, "", first, selectedSubjectOption);
    getReviewUsersList(
      state.class_division ? state.class_division : first,
      selectedSubjectOption.value,
    );
  }
  console.log(first, "jhjhjhjhjh");
  function getLessons(
    subject_id = "",
    curriculum_id = "",
    class_division = "",
    selectedSubjectOption = [],
  ) {
    setLessonsState({ ...lessonsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      // class_division_id: 36,
      class_division_id: state?.class_division,
      class_division_id: class_division
        ? class_division
        : state?.class_division,
      subject_id: subject_id ? subject_id : state.subject,
      curriculum_id: curriculum_id ? curriculum_id : state.curriculum,
    });
    console.log("lesson idsss", subject_id, "stateindise", state.subject);
    simplePostCall(ApiConfig.LESSONS_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var lessons = [];
          var all_topics = [];
          if (data.lessons_data?.length > 0) {
            data.lessons_data.map((lesson, index) => {
              lessons.push({
                label: lesson.lessons_name,
                value: lesson.lessons_id,
              });
              all_topics.push({
                lessons_id: lesson.lessons_id,
                lessons_topics: lesson.lessons_topics,
              });
            });
          }

          setLessonsState({
            ...lessonsState,
            isloading: false,
            lessons: lessons,
          });
          setState({
            ...state,
            selectedSubject: selectedSubjectOption,
            subject: subject_id,
            allTopics: all_topics,
          });
        } else {
          setLessonsState({ ...lessonsState, isloading: false });
        }
      })
      .catch((error) => {
        setLessonsState({ ...lessonsState, isloading: false });
        console.log("api response", error);
      });
  }

  function onLessonChange(selectedOption) {
    var selected_lessons = [];
    if (selectedOption?.length > 0) {
      selectedOption.map((lesson, index) => {
        selected_lessons.push(lesson.value);
      });
    }
    setState({
      ...state,
      lessons: selected_lessons,
      selectedLessons: selectedOption,
    });

    getTopics(selected_lessons);
  }

  function getTopics(selected_lessons) {
    var topics_list = [];
    if (state.allTopics?.length > 0) {
      state.allTopics.map((topics, index) => {
        if (selected_lessons.indexOf(topics.lessons_id) > -1) {
          if (topics.lessons_topics?.length > 0) {
            topics.lessons_topics.map((lessons_topic, index) => {
              topics_list.push({
                label: lessons_topic.topic_name,
                value: lessons_topic.topic_id,
              });
            });
          }
        }
      });
    }
    setTopicsState({ ...topicsState, topics: topics_list });
  }

  function onTopicChange(selectedOption) {
    var selected_topics = [];
    if (selectedOption?.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setState({
      ...state,
      topics: selected_topics,
      selectedTopics: selectedOption,
    });
  }

  //..End of function.. ///

  function onAssignmentTypeChange(selectedOption) {
    setState({
      ...state,
      assignment_type: selectedOption.value,
      selectedAssignmentType: selectedOption,
    });
  }

  function onSubmissionModeChange(selectedOption) {
    setState({
      ...state,
      submission_mode: selectedOption.value,
      selectedSubmissionMode: selectedOption,
    });
  }

  function getReviewUsersList(
    selected_class_division = "",
    selected_subject_id = "",
  ) {
    setReviewUserState({ ...reviewUserState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      class_division_id: selected_class_division
        ? selected_class_division
        : state.class_division_id,
      subject_id: selected_subject_id
        ? selected_subject_id
        : state.selectedSubject,
      // subject_id: selected_subject_id,
    });
    console.log(newRequestBody, "neewrequestboadyy");
    simplePostCall(ApiConfig.ASSIGNMENT_REVIEW_USER_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var review_users = [];
          var selected_review_by_user = [];
          var review_by_user_id = "";
          // if (data.reviewers_list.length > 0) {
          data.reviewers_list.map((reviewer, index) => {
            review_users.push({
              label: reviewer.review_user_name,
              value: reviewer.review_user_id,
            });
            if (Params.teacher_id == reviewer.review_user_id) {
              selected_review_by_user = [
                {
                  label: reviewer.review_user_name,
                  value: reviewer.review_user_id,
                },
              ];
              review_by_user_id = reviewer.review_user_id;
            }
          });
          // }
          setReviewUserState({
            ...reviewUserState,
            isloading: false,
            reviewUsers: review_users,
          });
          // setState({
          // ...state,
          // review_by_user_id: review_by_user_id,
          // selectedReviewByUser: selected_review_by_user,
          // })
        } else {
          setReviewUserState({ ...reviewUserState, isloading: false });
        }
      })
      .catch((error) => {
        setReviewUserState({ ...reviewUserState, isloading: false });
        console.log("api response", error);
      });
  }

  function onReviewUserChange(selectedOption) {
    setState({
      ...state,
      review_by_user_id: selectedOption.value,
      selectedReviewByUser: selectedOption,
    });
  }

  function getStudents(selected_class_division) {
    setStudentsState({ ...studentsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      section_ids: [selected_class_division],
    });

    simplePostCall(
      ApiConfig.STUDENTS_LIST_BY_SELECTED_CLASSES_API,
      newRequestBody,
    )
      .then((data) => {
        if (data.result) {
          var students = [];
          if (data.list?.length > 0) {
            data.list.map((student, index) => {
              students.push({
                label: student.student_name,
                value: student.student_id,
              });
            });
          }
          setStudentsState({
            ...studentsState,
            isloading: false,
            students: students,
          });
        } else {
          setStudentsState({ ...studentsState, isloading: false });
        }
      })
      .catch((error) => {
        setStudentsState({ ...studentsState, isloading: false });
        console.log("api response", error);
      });
  }

  function onStudentChange(selectedOption) {
    var selected_students = [];
    if (selectedOption?.length > 0) {
      selectedOption.map((student, index) => {
        selected_students.push(student.value);
      });
    }
    setState({
      ...state,
      students: selected_students,
      selectedStudents: selectedOption,
      class_division: first,

      class_division_id: first,
    });
  }
  console.log(state.assignment_class_division_id, "aaaaaa", firstt);
  useEffect(() => {
    getCurriculumList();
    getAssignments();
    getGradeList();
    getSubjects();
    if (state.class_division) {
      getSubjects(state.class_division);
    }
    if (state.curriculum && state.class_division) {
      getLessons("", state.curriculum, state.class_division);
    }
    if (state.class_division) {
      getStudents(state.class_division);
    }
  }, []);

  function getAssignments() {
    console.log("working");
    const selectedTopic = [];
    const lessons = [];
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assignment_id: props.id,
    });

    simplePostCall(ApiConfig.ASSIGNMENT_DETAILS_API, newRequestBody)
      .then((data) => {
        console.log("data", data);
        let assignment_data = data;
        assignment_data?.lesson.forEach((lesson) => {
          lesson.topics.forEach((topic) => {
            selectedTopic.push({
              label: topic.topic_name,
              value: topic.topic_id,
            });
          });
          lessons.push({
            label: lesson.lesson_name,
            value: lesson.lesson_id,
          });
        });
        console.log("selectedTopic: ", selectedTopic);
        console.log("lessons: ", lessons);
        setfirstt(data?.assignment_details?.assignment_class_division_id);
        setState({
          assignment_assigned_by:
            assignment_data?.assignment_details?.assignment_assigned_by,
          assignment_class:
            assignment_data?.assignment_details?.assignment_class,
          assignment_class_division_id:
            assignment_data?.assignment_details?.assignment_class_division_id,
          assignment_created_date:
            assignment_data?.assignment_details?.assignment_created_date,
          assignment_curriculum:
            assignment_data?.assignment_details?.assignment_curriculum,
          assignment_curriculum_name:
            assignment_data?.assignment_details?.assignment_curriculum_name,
          assignment_description:
            assignment_data?.assignment_details?.assignment_description,
          assignment_status:
            assignment_data?.assignment_details?.assignment_status,
          assignment_subject:
            assignment_data?.assignment_details?.assignment_subject,
          assignment_subject_id:
            assignment_data?.assignment_details?.assignment_subject_id,
          assignment_submission_date:
            assignment_data?.assignment_details?.assignment_submission_date,
          assignment_submission_mode:
            assignment_data?.assignment_details?.assignment_submission_mode,
          assignment_title:
            assignment_data?.assignment_details?.assignment_title,
          assignment_type: assignment_data?.assignment_details?.assignment_type,
          lesson: lessons,
          topic: selectedTopic,
        });
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const defaultLessons = [
    { value: "math", label: "Math" },
    { value: "science", label: "Science" },
    { value: "history", label: "History" },
  ];
  console.log(
    state?.lesson,
    "state?.lessonstate?.lesson",
    props.assignmentDetails,
  );
  return (
    <>
      {curriculumState.isloading || gradeState.isloading ? (
        <SpinnerCmp
          loading={curriculumState.isloading || gradeState.isloading}
        />
      ) : (
        <>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {assignmentDetailsState.assignment_title === ""
                  ? "Create Assignment"
                  : "Edit Assignment"}
              </h5>
            </div>

            <div class="modal-body datetime-picker">
              <form
                class="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Assignment Title")}</label>
                    <input
                      class="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                      name="title"
                      value={state.assignment_title}
                      onChange={(e) =>
                        setState({ ...state, assignment_title: e.target.value })
                      }
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.assignment_title_error ? "" : "none",
                      }}
                    >
                      {state.assignment_title_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="sub-task">{t("Assignment Description")}</label>
                    <textarea
                      class="form-control"
                      required=""
                      name="description"
                      value={state.assignment_description}
                      onChange={(e) =>
                        setState({
                          ...state,
                          assignment_description: e.target.value,
                        })
                      }
                      autocomplete="off"
                    ></textarea>
                    <small
                      style={{
                        color: "red",
                        display: state.assignment_description_error
                          ? ""
                          : "none",
                      }}
                    >
                      {state.assignment_description_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Curriculum")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      // value={state.selectedCurriculum}
                      onChange={onCurriculumChange}
                      options={curriculumState.curriculums}
                      placeholder={state.curriculum}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.curriculum_error ? "" : "none",
                      }}
                    >
                      {state.curriculum_error}
                    </small>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">{t("Grade")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        // value={state.selectedGrade}
                        onChange={onGradeChange}
                        options={gradeState.grades}
                        placeholder={state.class_division}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.class_division_error ? "" : "none",
                        }}
                      >
                        {state.class_division_error}
                      </small>
                    </div>

                    <div class="form-group col">
                      <label for="task-title">{t("Subject")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        // value={state.selectedSubject}
                        // defaultValue={state.selectedSubject}
                        onChange={onSubjectChange}
                        options={subjectState.subjects}
                        placeholder={state.subject}
                      />
                      {console.log(
                        "subjectState.subjects",
                        subjectState.subjects,
                      )}
                      <small
                        style={{
                          color: "red",
                          display: state.subject_error ? "" : "none",
                        }}
                      >
                        {state.subject_error}
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Lesson")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={
                        state.selectedLessons
                          ? state.selectedLessons
                          : props.assignmentDetails.lessons
                      }
                      defaultValue={state?.lesson}
                      onChange={onLessonChange}
                      options={lessonsState.lessons}
                      isMulti={true}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.lessons_error ? "" : "none",
                      }}
                    >
                      {state.lessons_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Topic")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={
                        state.selectedTopics
                          ? state.selectedTopics
                          : props.assignmentDetails.topics
                      }
                      onChange={onTopicChange}
                      options={topicsState.topics}
                      isMulti={true}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.topics_error ? "" : "none",
                      }}
                    >
                      {state.topics_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Assignment Type")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedAssignmentType}
                      onChange={onAssignmentTypeChange}
                      options={assignmentTypeState.assignment_type}
                      placeholder={state.assignment_type}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.assignment_type_error ? "" : "none",
                      }}
                    >
                      {state.assignment_type_error}
                    </small>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="task-title">{t("Submission Mode")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedSubmissionMode}
                        onChange={onSubmissionModeChange}
                        options={submissionModeState.submission_mode}
                        placeholder={state.submission_mode}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.submission_mode_error ? "" : "none",
                        }}
                      >
                        {state.submission_mode_error}
                      </small>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="task-title">{t("Submission Date")}</label>
                      <input
                        class="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        name="submission_date"
                        min={moment().format("YYYY-MM-DD")}
                        value={state.submission_date}
                        onChange={(e) =>
                          setState({
                            ...state,
                            submission_date: e.target.value,
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.submission_date_error ? "" : "none",
                        }}
                      >
                        {state.submission_date_error}
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Reviewed By")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedReviewByUser}
                      onChange={onReviewUserChange}
                      options={reviewUserState.reviewUsers}
                      placeholder={state.review_by_user_id}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.review_by_user_id_error ? "" : "none",
                      }}
                    >
                      {state.review_by_user_id_error}
                    </small>
                  </div>
                  {console.log(
                    state.selectedReviewByUser,
                    "state.selectedReviewByUser",
                    studentsState.students,
                  )}
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Students")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      // value={state.selectedReviewByUser}
                      onChange={onStudentChange}
                      options={studentsState.students}
                      placeholder={state.students}
                      isMulti={true}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.students_error ? "" : "none",
                      }}
                    >
                      {state.students_error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              {/*<button
 class="btn btn-secondary"
 id="Bookmark"
 type="submit"
 onClick={""}
 >
 Save
 </button> */}
              <button
                class="btn btn-primary"
                type="button"
                onClick={() => handleSaveAndNext()}
              >
                {assignmentDetailsState.assignment_title === ""
                  ? `${t("Create Assignment")}`
                  : "Update Assignment"}
              </button>
            </div>
          </div>
          <br />
        </>
      )}
    </>
  );
}
