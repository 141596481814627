import React, { useEffect, useState } from "react";
import userPic from "../../assets/images/dashboard/1.png";
import { Link } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import ReactCountryFlag from "react-country-flag";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";
import { SiOpenstreetmap } from "react-icons/si";

function UserProfile() {
  const { t } = useTranslation();
  const [passwordState, setPasswordState] = useState({
    isloading: false,
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [profileDataState, setProfileDataState] = useState({
    isloading: true,
    profileData: [],
    base_url: "",
  });

  function getUserProfileData() {
    setProfileDataState({ ...profileDataState, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.USER_PROFILE_DATA_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setProfileDataState({
            ...profileDataState,
            isloading: false,
            profileData: data.profile_data,
            base_url: data.base_url,
          });
        } else {
          setProfileDataState({ ...profileDataState, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setProfileDataState({ ...profileDataState, isloading: false });
        console.log("api response", error);
      });
  }

  function changePassword() {
    if (!validatePasswordInputs()) {
      setPasswordState({ ...passwordState, isloading: true });
      const newRequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        old_password: passwordState.old_password,
        new_password: passwordState.new_password,
      });

      simplePostCall(ApiConfig.CHANGE_PASSWORD, newRequestBody)
        .then((data) => {
          if (data.result) {
            //setPasswordState({ ...passwordState, isloading: false });
            toast.success(data.message);
            setTimeout(function () {
              window.location.replace("/UserProfile");
            }, 1000);
          } else {
            setPasswordState({ ...passwordState, isloading: false });
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setPasswordState({ ...passwordState, isloading: false });
          console.log("api response", error);
        });
    }
  }

  function validatePasswordInputs() {
    var isError = false;
    if (
      passwordState.old_password == "" ||
      passwordState.new_password == "" ||
      passwordState.confirm_password == ""
    ) {
      var err_msg = "";
      if (passwordState.old_password == "") {
        err_msg = "Please enter Old Password";
        isError = true;
      } else if (passwordState.new_password == "") {
        err_msg = "Please enter New Password";
        isError = true;
      } else if (passwordState.confirm_password == "") {
        err_msg = "Please enter Confirm Password";
        isError = true;
      }
      toast.error(err_msg);
    } else {
      if (passwordState.new_password != passwordState.confirm_password) {
        toast.error("New Password and Confirm Password are not matching");
        isError = true;
      }
    }
    return isError;
  }
  //format date--------------------------------
  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }
  const dateString = "2024-10-03";
  const formattedDate = formatDate(dateString);
  console.log(formattedDate, "formattedDate");
  useEffect(() => {
    document.title = "Edsys | User Profile ";
    getUserProfileData();
  }, []);
  return (
    <>
      {profileDataState.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="row user-profile">
            <div className="col-sm-12">
              <div className="my-profile-header">
                <div className="row align-items-center">
                  <div className="col-auto profile-image">
                    <img
                      src={
                        profileDataState.profileData.profile_pic
                          ? profileDataState.base_url +
                            profileDataState.profileData.profile_pic
                          : userPic
                      }
                    />
                  </div>
                  <div className="col ml-md-n2 profile-user-info">
                    {/*<h4 className="first-name mb-0">Mary</h4>*/}
                    <h4 className="sur-name mb-0">
                      {profileDataState.profileData.name}
                    </h4>
                    <div className="user-Location">
                      <i className="fas fa-map-marker-alt"></i>
                      {profileDataState.profileData.user_city}
                    </div>
                    {/*<div className="about-text">Lorem ipsum dolor sit amet.</div>*/}
                  </div>
                </div>
              </div>
              <div className="my-profile-menu d-flex">
                <ul
                  className="nav nav-pills flex-grow-1"
                  id="pills-icontab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-iconhome-tab"
                      data-bs-toggle="pill"
                      href="#pills-iconhome"
                      role="tab"
                      aria-controls="pills-iconhome"
                      aria-selected="true"
                    >
                      <i className="icofont icofont-user-male"></i>
                      {t("Personal Details")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-iconcontact-tab"
                      data-bs-toggle="pill"
                      href="#pills-iconcontact"
                      role="tab"
                      aria-controls="pills-iconcontact"
                      aria-selected="false"
                    >
                      <i className="icofont icofont-settings"></i>
                      {t("Account Settings")}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-mapping-tab"
                      data-bs-toggle="pill"
                      href="#pills-mapping"
                      role="tab"
                      aria-controls="pills-mapping"
                      aria-selected="false"
                    >
                      <SiOpenstreetmap className=" icofont-settings" />
                      {t("Mapping")}
                    </a>
                  </li> */}
                </ul>
                <Link to="/EditProfile" className="edit-profile">
                  {t("Edit Profile")}
                </Link>
              </div>
              <div className="tab-content" id="pills-icontabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-iconhome"
                  role="tabpanel"
                  aria-labelledby="pills-iconhome-tab"
                >
                  <div className="card mt-3">
                    <div className="card-header pb-0">
                      <h5 className="m-b-0">
                        <i className="icofont icofont-user-male mr-3"></i>{" "}
                        {t("Personal Details")}
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive info-table">
                        <table className="table text-nowrap">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <h6>{t("Contact Information")}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Email")}:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData.email}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Email Status")}:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData
                                  .is_email_verified == "yes" ? (
                                  <span className="badge badge-success">
                                    {t("Verified")}
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    {t("Verified")}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Phone")}:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData.user_mobile}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <h6>{t("General Information")}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Address")}:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData.user_address}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Nationality")}:</td>
                              {/* <td className="font-medium text-dark-medium"> <ReactCountryFlag countryCode={profileDataState.profileData.user_nationality} title={profileDataState.profileData.user_nationality} /> {profileDataState.profileData.user_nationality}</td> */}
                              <td className="font-medium text-dark-medium">
                                {" "}
                                <ReactCountryFlag
                                  title={
                                    profileDataState.profileData
                                      .user_nationality
                                  }
                                />{" "}
                                {profileDataState.profileData.user_nationality}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Joining Date")}:</td>
                              <td className="font-medium text-dark-medium">
                                {formatDate(
                                  profileDataState.profileData
                                    .user_joining_date,
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Date of Birth")}:</td>
                              <td className="font-medium text-dark-medium">
                                {formatDate(
                                  profileDataState.profileData.user_dob,
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Gender")}:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData.gender}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Internal No")}.:</td>
                              <td className="font-medium text-dark-medium">
                                {profileDataState.profileData.staff_id}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {passwordState.isloading ? (
                  <SpinnerCmp />
                ) : (
                  <div
                    className="tab-pane fade"
                    id="pills-iconcontact"
                    role="tabpanel"
                    aria-labelledby="pills-iconcontact-tab"
                  >
                    <div className="card mt-2">
                      <div className="card-header pb-0">
                        <h5 className="m-b-0">
                          <i className="icofont icofont-settings"></i>{" "}
                          {t("Account Settings")}
                        </h5>
                      </div>
                      <div className="mt-2 contact-form card-body">
                        <div className="update-pw">{t("Change Password")}</div>
                        <div className="theme-form">
                          <div className="form-icon">
                            <i className="icofont icofont-ui-lock"></i>
                          </div>
                          <div className="mb-3">
                            <label for="exampleInputName1">
                              {t("Old Password")}
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName1"
                              type="password"
                              value={passwordState.old_password}
                              onChange={(e) => {
                                setPasswordState({
                                  ...passwordState,
                                  old_password: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label for="exampleInputName2">
                              {t("New Password")}
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName2"
                              type="password"
                              value={passwordState.new_password}
                              onChange={(e) => {
                                setPasswordState({
                                  ...passwordState,
                                  new_password: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <label for="exampleInputName3">
                              {t("Confirm Password")}
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName3"
                              type="password"
                              value={passwordState.confirm_password}
                              onChange={(e) => {
                                setPasswordState({
                                  ...passwordState,
                                  confirm_password: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="text-sm-end">
                            <button
                              className="btn btn-primary"
                              onClick={changePassword}
                            >
                              {t("Save Changes")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="tab-pane fade"
                  id="pills-mapping"
                  role="tabpanel"
                  aria-labelledby="pills-mapping-tab"
                >
                  <div className="cardmt">
                    <div className="UserText">Schools </div>
                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Open this select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="userButt">
                      <button type="submit" className="promButt">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  );
}
export default UserProfile;
