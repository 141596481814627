import React, { useEffect, useState } from 'react'
import shape from '../../assets/images/authentication/shape.svg'
import edsysLogo from '../../assets/images/logo/edsysLogo.svg'
import shape1 from '../../assets/images/authentication/shape-1.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"; 
function LoginIn() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false)
  const [values, setValues] = useState({
    showPassword: false,
  })
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  useEffect(() => {
    document.title = 'Edsys | Locked '
  }, [])
  return (
    <section>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="login-card">
              <img className="shape-top" src={shape} />
              <img className="login-logo" src={edsysLogo} />
              <img className="shape-center" src={shape1} />
              <div className="login-main">
                <form className="theme-form login-form">
                  <h4>Mary Alexander</h4>
                  <div className="form-group">
                    <label className="col-form-label">
                      {t('Enter your Password')}
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icon-email"></i>
                      </span>
                      <input
                        type={values.showPassword ? 'text' : 'password'}
                        className="form-control"
                        // type="password"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                      />
                      <div className="show-hide">
                        <span
                          onClick={handleClickShowPassword}
                          className="show"
                        >
                          {' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label className="text-muted" for="checkbox1">
                       {t('Remember password')}
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <Link to="/">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        {t('Unlock')}
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default LoginIn
