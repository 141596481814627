import React, { useEffect, useState } from "react";
import { FaAnchor, FaSearchPlus } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EvaluationSkelton from "./EvaluationSkelton";
import ReactTooltip from "react-tooltip";

export const EvaluationType = () => {
  const [storeData, setStoreData] = useState([]);
  const [dataStore, setdataStore] = useState("");
  const [evalDefault, setEvalDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setfilter] = useState("");
  useEffect(() => {
    EvalutionList();
  }, [filter]);
  const EvalutionList = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      // evaluation_type_name: filter,
    });
    simplePostCall(ApiConfig.EVALUTION_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "Evalution Data");
        if (data.result) {
          setStoreData(data?.data);
          // console.log(data.data[0], "ev data...");
          setIsLoading(false);
          // console.log(filter, "filter");
        } else {
          setIsLoading(false);
          toast.error(data.message);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  const EvalutionSetAsDefault = (type_id) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      evalution_id: type_id,
      // evaluation_type_name: filter,
    });
    simplePostCall(ApiConfig.EVALUATION_SET_AS_DEFAULT, newRequestBody)
      .then((data) => {
        console.log(data, "Evalution Data");
        if (data.result) {
          toast.success(data.message);
          setEvalDefault(true);
          EvalutionList();
        } else {
          setIsLoading(false);
          setEvalDefault(false);
          toast.error(data.message);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  function deleteData() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      evaluation_type_id: dataStore,
    });
    simplePostCall(ApiConfig.DELETE_EVALUTION, newRequestBody)
      .then((data) => {
        if (data.result) {
          // setState({ ...state, subject: data.list[0].id });
          toast.success(data.message);
          EvalutionList();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const navic = useNavigate();
  return (
    <>
      {isLoading ? (
        <EvaluationSkelton />
      ) : (
        <div>
          <div className="evaluation">
            <div className="evalHead">
              <h5 className="labelCreate">Evaluation Type</h5>
              <div className="createBut">
                <div className="CreateB" onClick={() => navic("/AddEvalution")}>
                  Create Evaluation
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <div className="table">
                <table class=" table-dark table-borderless newTable ">
                  <thead className="tableHea">
                    <tr>
                      <th>Sl no</th>
                      <th>Evaluation Name</th>
                      <th>Evaluation Code</th>
                      <th className="textcol" style={{ textAlign: "center" }}>
                        Status
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* <thead className="">
                  <tr>
                    <th></th>
                    <th>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        style={{ width: "66%" }}
                        onChange={(e) => setfilter(e.target.value)}
                      />
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead> */}
                  {storeData?.map((item, index) => (
                    <tbody>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.evaluation_type_name}</td>
                        <td>{item.evaluation_type_code}</td>
                        <td className="textcol" style={{ textAlign: "center" }}>
                          {item.evaluation_type_status}
                        </td>
                        <td className="iconSec">
                          <div
                            data-tip="Edit"
                            data-place="top"
                            className="searchIcc"
                            onClick={() =>
                              navic("/ViewEvaluation", {
                                state: item.evaluation_type_id,
                              })
                            }
                          >
                            <FaEdit
                              className="icons"
                              data-tip="Edit"
                              data-place="top"
                            />
                          </div>
                          <ReactTooltip effect="solid" />
                          {item.evaluation_type_status === "default" ? (
                            <div />
                          ) : (
                            <div className="searchi">
                              <div
                                data-tip="Set as Default"
                                data-place="top"
                                className="searchIcc"
                                onClick={() =>
                                  EvalutionSetAsDefault(item.evaluation_type_id)
                                }
                              >
                                <FaAnchor
                                  className="icons"
                                  data-tip="Set as Default"
                                  data-place="top"
                                />
                              </div>

                              <div
                                data-tip="Delete"
                                data-place="top"
                                className="editBuu"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() =>
                                  setdataStore(item.evaluation_type_id)
                                }
                              >
                                <RiDeleteBin6Line className="icons" />
                              </div>
                            </div>
                          )}
                          <ReactTooltip effect="solid" />

                          <div
                            class="modal fade"
                            id="staticBackdrop"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1
                                    class="modal-title fs-5"
                                    id="staticBackdropLabel"
                                  ></h1>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  Are you sure you want to delete this item?
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    onClick={() => deleteData()}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
