import React from "react";

export default function AssignmentDetailsSkeleton() {
  return (
    <div>
      <div class="loading-skeleton">
        <div className="card assignment_card_skeleton border-0"></div>
        <div className="card mt-4 border-0"></div>
      </div>
    </div>
  );
}
