import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import eye from "../assets/images/eye.png";

function DashboardAssignments() {
  const [state, setState] = useState({
    assignment_data: [],
  });

  const location = useLocation();

  function setData() {
    const { assignment_data } = location.state;
    setState({ assignment_data: assignment_data });
  }

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <h5>Assignments</h5>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title </th>
                  <th scope="col">Grade</th>
                  <th scope="col">Pending</th>
                  <th scope="col">Submitted</th>
                  <th scope="col">Rejected</th>
                  <th scope="col">Reviewed</th>
                  <th scope="col">Total</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {state.assignment_data && state.assignment_data.length > 0 ? (
                  state.assignment_data.map((assignment, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{assignment.assignment_title}</td>
                        <td>{assignment.assignment_class}</td>
                        <td>{assignment.pending}</td>
                        <td>{assignment.submitted}</td>
                        <td>{assignment.rejected}</td>
                        <td>{assignment.reviewed}</td>
                        <td>{assignment.total}</td>
                        <td>
                          <Link
                            to={`/AssignmentDetails/${assignment.assignment_id}`}
                            className="col-md-4"
                          >
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={eye}
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th scope="row">No records found</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAssignments;
