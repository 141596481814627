import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import eye from "../assets/images/eye.png";

function AssignmentsTable(props) {
  const { t } = useTranslation();

  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <h5>{t("Assignments")}</h5>
          </div>
          <div className="table-responsive" style={{ paddingBottom: "10px" }}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("Title")} </th>
                  <th scope="col">{t("Grade")}</th>
                  <th scope="col">{t("Pending")}</th>
                  <th scope="col">{t("Submitted")}</th>
                  <th scope="col">{t("Rejected")}</th>
                  <th scope="col">{t("Reviewed")}</th>
                  <th scope="col">{t("Total")}</th>
                  <th scope="col">{t("View")}</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(props.assignment_data, "assingmnets")} */}
                {props.assignment_data && props.assignment_data.length > 0 ? (
                  props.assignment_data.slice(0, 5).map((assignment, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{assignment.assignment_title}</td>
                        <td>{assignment.assignment_class}</td>
                        <td>{assignment.pending}</td>
                        <td>{assignment.submitted}</td>
                        <td>{assignment.rejected}</td>
                        <td>{assignment.reviewed}</td>
                        <td>{assignment.total}</td>
                        <td>
                          <Link
                            to={`/AssignmentDetails/${assignment.assignment_id}`}
                            className="col-md-4"
                          >
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={eye}
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th scope="row">No records found</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex,",
              justifyContent: "flex-end",
              marginTop: "10px",
              width: "98%",
            }}
          >
            <Link
              to={"/dashboard/assignments"}
              state={{ assignment_data: props.assignment_data }}
              type="button"
              className="btn btn-edsys-primary"
              style={{
                display:
                  props.assignment_count && props.assignment_count > 5
                    ? ""
                    : "none",
                float: "right",
                width: "160px",
                marginTop: "1%",
              }}
            >
              <span>{t("See More")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignmentsTable;
