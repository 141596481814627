import React, { useEffect, useState, ChangeEvent } from "react";
import add_btn from "../../../assets/images/add_btn.svg";
import view_icon from "../../../assets/images/view_icon.svg";
import part_1 from "../../../assets/images/part_1.svg";
import part_2 from "../../../assets/images/part_2.svg";
import part_3 from "../../../assets/images/part_3.svg";
import part_4 from "../../../assets/images/part_4.svg";
import chat_avatar_1 from "../../../assets/images/chat_avatar_1.svg";
import chat_avatar_2 from "../../../assets/images/chat_avatar_2.svg";
import chat_avatar_3 from "../../../assets/images/chat_avatar_3.svg";
import chat_avatar_4 from "../../../assets/images/chat_avatar_4.svg";
import chat_avatar_5 from "../../../assets/images/chat_avatar_5.svg";
import search_icon from "../../../assets/images/search_icon.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import edit_icon from "../../../assets/images/edit_icon.svg";
import dropdown_icons from "../../../assets/images/dropdown_icons.svg";
import img_icon from "../../../assets/images/img_icon.svg";
import video_icon from "../../../assets/images/video_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import audio_icon from "../../../assets/images/audio_icon.svg";
import send_btn_icon from "../../../assets/images/send_btn_icon.svg";
import { PlusCircle } from "react-feather";
import Chatmodal from "./Chatmodal";

function Chat() {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);

  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };
  return (
    <div className="chat announcementnew assignment-sec">
      <div className="main-section">
        <div className="row">
        <div className="col-md-12">
            <div className=" ">
              <div className="d-flex">
                <h3 className="flex-grow-1 text-dark">Chat</h3>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>ContractList</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? (
                  <Chatmodal onClose={openCloseTodoModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="chat-wrapper">
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="chat-list-section">
                      <div className="search-wrapper">
                        <Form.Control type="text" placeholder="Search" />
                        <img src={search_icon} alt="" />
                      </div>
                      <p className="chat-heading">Messages</p>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="0">
                            <div className="row g-0">
                              <div className="col-md-2 col-sm-1 col-1">
                                <div className="chat-item-img-box">
                                  <img src={chat_avatar_1} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-10 col-sm-11 col-11">
                                <div className="row g-0">
                                  <div className="col-md-10 col-sm-10 col-10">
                                    <div className="chat-item-text-box">
                                      <p className="ci-main-text">Jasmin Doe</p>
                                      <p className="ci-sub-text">Subject</p>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-2 col-2">
                                    <div className="chat-item-count-box">
                                      11/1
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="1">
                            <div className="row g-0">
                              <div className="col-md-2 col-sm-1 col-1">
                                <div className="chat-item-img-box">
                                  <img src={chat_avatar_2} alt="" />
                                  <span className="status-red"></span>
                                </div>
                              </div>
                             <div className="col-md-10 col-sm-11 col-11">
                                <div className="row g-0">
                                  <div className="col-md-10 col-sm-10 col-10">
                                    <div className="chat-item-text-box">
                                      <p className="ci-main-text">Julia Damn</p>
                                      <p className="ci-sub-text">Subject</p>
                                    </div>
                                  </div>
                                   <div className="col-md-2 col-sm-2 col-2">
                                    <div className="chat-item-count-box">
                                      11/1
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="2">
                            <div className="row g-0">
                              <div className="col-md-2 col-sm-1 col-1">
                                <div className="chat-item-img-box">
                                  <img src={chat_avatar_3} alt="" />
                                  <span className="status-yellow"></span>
                                </div>
                              </div>
                             <div className="col-md-10 col-sm-11 col-11">
                                <div className="row g-0">
                                  <div className="col-md-10 col-sm-10 col-10">
                                    <div className="chat-item-text-box">
                                      <p className="ci-main-text">
                                        John Collins
                                      </p>
                                      <p className="ci-sub-text">Subject</p>
                                    </div>
                                  </div>
                                   <div className="col-md-2 col-sm-2 col-2">
                                    <div className="chat-item-count-box">
                                      11/1
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="3">
                            <div className="row g-0">
                              <div className="col-md-2 col-sm-1 col-1">
                                <div className="chat-item-img-box">
                                  <img src={chat_avatar_4} alt="" />
                                  <span className="status-yellow"></span>
                                </div>
                              </div>
                             <div className="col-md-10 col-sm-11 col-11">
                                <div className="row g-0">
                                  <div className="col-md-10 col-sm-10 col-10">
                                    <div className="chat-item-text-box">
                                      <p className="ci-main-text">Siera Dash</p>
                                      <p className="ci-sub-text">Subject</p>
                                    </div>
                                  </div>
                                   <div className="col-md-2 col-sm-2 col-2">
                                    <div className="chat-item-count-box">
                                      11/1
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="4">
                            <div className="row g-0">
                              <div className="col-md-2 col-sm-1 col-1">
                                <div className="chat-item-img-box">
                                  <img src={chat_avatar_5} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                             <div className="col-md-10 col-sm-11 col-11">
                                <div className="row g-0">
                                  <div className="col-md-10 col-sm-10 col-10">
                                    <div className="chat-item-text-box">
                                      <p className="ci-main-text">Sammer Doe</p>
                                      <p className="ci-sub-text">Subject</p>
                                    </div>
                                  </div>
                                   <div className="col-md-2 col-sm-2 col-2">
                                    <div className="chat-item-count-box">
                                      11/1
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="chat-detail-section">
                      <Tab.Content>
                        <Tab.Pane eventKey="0">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_1} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Jasmine Doe</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="1">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_2} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Julia Damn</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_3} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">John Collins</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_4} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Siera Dash</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_5} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Sammer Doe</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
