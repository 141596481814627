import React from "react";

export default function ChannelListSkeleton() {
  return (
    // <div className="card border-0">
      <div class="container loading-skeleton">
        <div class="row gy-3 mt-3">
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
          <div class="col-md-3">
            <div class="card channel_card bg-light"></div>
          </div>
        </div>
      </div>
    // </div>
  );
}
