import React, { useEffect, useState } from 'react'
import SpinnerCmp from '../../components/SpinnerCmp'
import UserPic from '../../assets/images/dashboard/1.png'
function StudentAttendance() {
  const [state, setState] = useState({
    isloading: false,
    notificationSettings: [],
    settings_sms_gateway_status: 0,
    settings_whatsapp_status: 0,
  })
  return (
    <>
      {state.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="row notif-setting">
            <h4>Classroom Attendance</h4>
            <div className="col-md-12">
              <div className="card br-15">
                <div className="card-body row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive">
                      <table className="table table-styling">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">images</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Admission Number</th>
                            <th scope="col">
                              {' '}
                              <div className="switch-md icon-state">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    //   name={
                                    //     notification_setting.field +
                                    //     '_sms_' +
                                    //     index
                                    //   }
                                    //   id={
                                    //     notification_setting.field +
                                    //     '_sms_' +
                                    //     index
                                    //   }
                                    //   checked={
                                    //     notification_setting.sms ? true : false
                                    //   }
                                    //   onClick={() =>
                                    //     onInputChange(
                                    //       !notification_setting.sms,
                                    //       notification_setting.field,
                                    //       'sms',
                                    //     )
                                    //   }
                                  />
                                  <span className="switch-state"></span>
                                </label>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>
                              <div className="switch-md icon-state">
                                {/* <label className="switch ">
                                  <input
                                    type="checkbox"
                                    // name={
                                    //   notification_setting.field +
                                    //   '_notification_' +
                                    //   index
                                    // }
                                    // id={
                                    //   notification_setting.field +
                                    //   '_notification_' +
                                    //   index
                                    // }
                                    // checked={
                                    //   notification_setting.push_notification
                                    //     ? true
                                    //     : false
                                    // }
                                    // onClick={() =>
                                    //   onInputChange(
                                    //     !notification_setting.push_notification,
                                    //     notification_setting.field,
                                    //     'notification',
                                    //   )
                                    // }
                                  />
                                  <span className="switch-state"></span>
                                </label> */}
                                <img
                                  src={UserPic}
                                  alt=""
                                  className=""
                                  style={{
                                    height: '25%',
                                    width: '25%',
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="switch-md icon-state">ABCD</div>
                            </td>

                            <td>12121212</td>
                            <div className="switch-md icon-state">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  //   name={
                                  //     notification_setting.field +
                                  //     '_sms_' +
                                  //     index
                                  //   }
                                  //   id={
                                  //     notification_setting.field +
                                  //     '_sms_' +
                                  //     index
                                  //   }
                                  //   checked={
                                  //     notification_setting.sms ? true : false
                                  //   }
                                  //   onClick={() =>
                                  //     onInputChange(
                                  //       !notification_setting.sms,
                                  //       notification_setting.field,
                                  //       'sms',
                                  //     )
                                  //   }
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
                          </tr>
                          {/* )
                              },
                            )
                          ) : (
                            <tr>
                              <td colSpan={3}>No data found</td>
                            </tr>
                          )} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  )
}
export default StudentAttendance
