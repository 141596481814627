import React from 'react';
import { ChevronLeft } from "react-feather";
import Video_User from '../assets/images/user/video-user.png'
import Video_Friend from '../assets/images/user/video-friend.jpg'

function Footer() {
  return (
    <>
      {/* footer start */}
      <footer className="footer" id='the_footer'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 footer-copyright">
              <p className="mb-0">Copyright 2021-22 © edsys All rights reserved.</p>
            </div>
            <div className="col-md-6">
              <p className="pull-right mb-0 ftr-contact">
                {/* <a href="#">Contact Us</a> */}
                {/* <a href="#">Terms & Conditions</a> */}
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* footer start end */}

      {/* video conferance modal */}
      <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg video-conference">
          <div className="modal-content">
            <div className="video-container">
              <img className="user-video-bg" src={Video_User} />
              <div className="top-left">
                <a data-bs-dismiss="modal" aria-label="Close"><ChevronLeft /></a>
                <span>6th A - Physics</span>
                <div className="duration">00:31</div>
              </div>
              <div className="top-right">
                <img src={Video_Friend} />
              </div>
              <div className="centered">
                <div className="call-btn" data-bs-dismiss="modal" aria-label="Close"><i data-feather="phone"></i></div>
                <div className="d-flex control-bar">
                  <div className="message"><i data-feather="message-square"></i></div>
                  <div className="mic"><i data-feather="mic"></i></div>
                  <div className="call-area"></div>
                  <div className="video-btn"><i data-feather="video"></i></div>
                  <div className="video-option"><i data-feather="more-vertical"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
