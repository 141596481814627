import React from "react";
import Skeleton from "react-loading-skeleton";

export default function ViewEvalutionSkelton() {
  return (
    <div className="main-sectionn ">
      <div className="col-md-12">
        <div className="announcementCreatee">
          <label>
            <Skeleton width={200} height={30} />
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12 row">
          <div className="form-group col-md-12">
            <label for="task-title">
              <Skeleton width={200} height={30} />
            </label>

            <Skeleton width={700} height={30} />
          </div>
          <div className="form-group col-md-12">
            <label for="task-title">
              <Skeleton width={200} height={30} />
            </label>
            <Skeleton width={700} height={30} />
          </div>
        </div>
        <div className="create-but-1">
          <div>
            <Skeleton width={200} height={30} />
          </div>

          <div>
            <Skeleton width={200} height={30} />
          </div>
        </div>
      </div>
    </div>
  );
}
