import React, { useEffect, useMemo, useState } from "react";
//import AssessmentModal from "./AssesmentModal";

import { Link } from "react-router-dom";
import { Edit3, PlusCircle, Search, Trash2 } from "react-feather";

//import assignment_card_data from "../../../api/assignment_card_data.json";
//import AssessmentDetails from "../Assesment/AssessmentDetails";
import AssesmentModal from "./AssesmentModal";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import subtopicicon from "../../../assets/images/assignment/subtopicicon.svg";
import SpinnerCmp from "../../../components/SpinnerCmp";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactSelect from "react-select";
import filter from "../../../assets/images/filter.svg";
import { useTranslation } from "react-i18next";
import ListSkeleton from "../../../components/skeletons/ListSkeleton";
import moment from "moment";
import { TabPanel, TabView } from "primereact/tabview";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

function Assessmentnew() {
  const [isTodoModelShow, setisTodoModelShow] = useState(false);
  const [isTagModelShow, setisTagModelShow] = useState(false);
  const [assessments, setassessments] = useState([]);
  const [assessmentId, setAssessmentId] = useState("");
  const [TotalCourses, setTotalCourses] = useState(0);
  const [isloading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState("");
  const [openMode, setOpenMode] = useState(false);
  const [class_State, setClass_State] = useState([]);
  const [subject_state, setSubject_state] = useState([]);
  const [topic_state, setTopic_state] = useState([]);
  const [status, setStatus] = useState(0);
  const [totalPages, setTotalPages] = useState("");

  useEffect(() => {
    document.title = "Edsys | Assessment ";
    setIsloading(true);
    getAssessments({ currentPage: page });
  }, [status]);
  const openCloseTodoModel = () => setisTodoModelShow(true);
  const openCloseTagModel = () => setisTagModelShow(false);
  const { t } = useTranslation();

  // ======================================= Assessment List API ======================
  const getAssessments = ({ currentPage, classroom, subject, topic }) => {
    const details = {};
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      page: currentPage,
      page_limit: 20,
      class: classroom,
      search: subject ? subject : topic,
      status:
        status == 0
          ? "pending"
          : status == 1
          ? "upcoming"
          : status == 2
          ? "history"
          : status == 3
          ? "all"
          : "",
    });
    console.log(
      newRequestBody,
      ApiConfig.ASSESSMENT_LIST_API,
      "ASSESSMENTLIST",
    );
    simplePostCall(ApiConfig.ASSESSMENT_LIST_API, newRequestBody)
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          setResult(data.result);
          setTotalPages(data.total_pages);
          // setassessments(data.data);
          let total = data.total_data ? data.total_data : 0;
          setTotalCourses(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData?.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            setassessments(data.data);
            setIsloading(false);
          } else {
            setassessments([...assessments, ...LearningData]);
            setIsloading(false);
          }
        } else {
          setResult(data.result);
          setIsloading(false);
          setassessments([]);
          // console.log('no records found');
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  // ======================================= Assessment Delete Api ======================
  const assessmentDelete = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assessment_id: assessmentId,
    });
    simplePostCall(ApiConfig.ASSESSMENT_DELET_API, newRequestBody)
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          // console.log('data', data);
          toast.success(data.message);
          getAssessments({ currentPage: page });
        } else {
          toast.error(data.message);
          getAssessments({ currentPage: page });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  //format date--------------------------------
  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }
  const dateString = "2024-10-03";
  const formattedDate = formatDate(dateString);
  console.log(formattedDate, "formattedDate");
  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const onPageLoad = () => {
    console.log(totalPages, page, "currentpage");
    if (totalPages > page) {
      setPage(page + 1);
      getAssessments({ currentPage: page + 1 });
    }
  };

  // ======================================= PAGINATION ENDS ========================
  const class_options = useMemo(
    () =>
      [...new Set(assessments?.map((item) => item.assessment_class))]?.map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [assessments],
  );
  const subject_options = useMemo(
    () =>
      [
        ...new Set(assessments?.map((item) => item.assessment_subject_name)),
      ]?.map((type) => {
        return { label: type, value: type };
      }),
    [assessments],
  );
  const topic_options = useMemo(
    () =>
      [...new Set(assessments?.map((item) => item.assessment_title))]?.map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [assessments],
  );
  const handler = (e) => {
    setStatus(e);
  };
  // console.log('clicked', status);
  return (
    <div class="Forum library">
      <div className="main-section">
        <div className="row assignment-sec">
          <div className="col-md-12">
            <div className="announcement-top">
              <h5 className="flex-grow-1">{t("Assessment")}</h5>
              <Link
                to="/create-assessment"
                type="button"
                className="btn btn-edsys-primary"
              >
                <PlusCircle className="me-2" />
                <span>{t("Create New Assessment")}</span>
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div class="card-body p-0">
                  <input
                    class="form-control"
                    value={class_State}
                    onChange={(e) => {
                      setClass_State(e.target.value);
                      getAssessments({
                        currentPage: page,
                        classroom: e.target.value,
                      });
                    }}
                    // options={class_options}
                    placeholder="filter By Class..."
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="card-body p-0">
                  <input
                    class="form-control"
                    value={subject_state}
                    onChange={(e) => {
                      setSubject_state(e.target.value);
                      getAssessments({
                        currentPage: page,
                        subject: e.target.value,
                      });
                    }}
                    // options={subject_options}
                    placeholder="filter By Subject..."
                  />
                </div>
              </div>
              <div className="col-md-3 ">
                <div class="card-body p-0">
                  <input
                    class="form-control"
                    value={topic_state}
                    onChange={(e) => {
                      setTopic_state(e.target.value);
                      setassessments([]);
                      getAssessments({
                        currentPage: page,
                        topic: e.target.value,
                      });
                    }}
                    // options={topic_options}
                    placeholder="filter By Topic..."
                  />
                </div>
              </div>
              <div className="col-md-1">
                <div class="card-body p-0">
                  <div
                    onClick={() => [
                      setassessments([]),
                      getAssessments({ currentPage: page }),
                    ]}
                  >
                    <Button style={{ width: "80px" }}>Clear</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="books-main-wrapper">
              <TabView
                activeIndex={status}
                onTabChange={(e) => handler(e.index)}
              >
                <TabPanel header="Pending">
                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <InfiniteScroll
                            dataLength={assessments?.length}
                            next={onPageLoad}
                            hasMore={assessments?.length !== TotalCourses}
                            style={{
                              overflow: "hidden",
                            }}
                            loader={
                              result == true ? (
                                <h4
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  Loading....
                                </h4>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <b>No Assessment Found</b>
                                </p>
                              )
                            }
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <b>No More data Found</b>
                              </p>
                            }
                          >
                            <div className="row">
                              {assessments && assessments?.length > 0
                                ? assessments?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4">
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />

                                              <h6 className="flex-grow-1">
                                                {
                                                  assessment.assessment_subject_name
                                                }
                                              </h6>
                                              <div className="class">
                                                {assessment.assessment_class}
                                              </div>
                                            </div>
                                          </div>

                                          <Link
                                            className="name card-body"
                                            to={
                                              "/AssessmentDetails/" +
                                              assessment.assessment_id
                                            }
                                            // className="card-body"
                                          >
                                            <h5>
                                              {assessment.assessment_title}
                                            </h5>
                                          </Link>

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {formatDate(
                                                  assessment.assessment_created_date,
                                                )}
                                              </h5>
                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#exampleModal2"
                                                      className="setting-secondary"
                                                    >
                                                      <Link
                                                        to={
                                                          "/create-assessment/" +
                                                          assessment.assessment_id
                                                        }
                                                      >
                                                        <i data-feather="edit-3">
                                                          <Edit3 />
                                                        </i>
                                                      </Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                      onClick={() => [
                                                        setOpenMode(true),
                                                        setStatus(0),
                                                        setAssessmentId(
                                                          assessment.assessment_id,
                                                        ),
                                                      ]}
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2 />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel header="Upcoming">
                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <InfiniteScroll
                            dataLength={assessments?.length}
                            next={onPageLoad}
                            hasMore={assessments?.length !== TotalCourses}
                            style={{
                              overflow: "hidden",
                            }}
                            loader={
                              result == true ? (
                                <h4
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  Loading....
                                </h4>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <b>No Assessment Found</b>
                                </p>
                              )
                            }
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <b>No More data Found</b>
                              </p>
                            }
                          >
                            <div className="row">
                              {assessments && assessments?.length > 0
                                ? assessments?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4">
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />
                                              <h6 className="flex-grow-1">
                                                {
                                                  assessment.assessment_subject_name
                                                }
                                              </h6>
                                              <div className="class">
                                                {assessment.assessment_class}
                                              </div>
                                            </div>
                                          </div>

                                          <Link
                                            className="name card-body"
                                            to={
                                              "/AssessmentDetails/" +
                                              assessment.assessment_id
                                            }
                                            // className="card-body"
                                          >
                                            <h5>
                                              {assessment.assessment_title}
                                            </h5>
                                          </Link>

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {formatDate(
                                                  assessment.assessment_created_date,
                                                )}
                                              </h5>
                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#exampleModal2"
                                                      className="setting-secondary"
                                                    >
                                                      <Link
                                                        to={
                                                          "/create-assessment/" +
                                                          assessment.assessment_id
                                                        }
                                                      >
                                                        <i data-feather="edit-3">
                                                          <Edit3 />
                                                        </i>
                                                      </Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                      onClick={() => [
                                                        setOpenMode(true),
                                                        setStatus(1),
                                                        setAssessmentId(
                                                          assessment.assessment_id,
                                                        ),
                                                      ]}
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2 />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="History">
                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <InfiniteScroll
                            dataLength={assessments?.length}
                            next={onPageLoad}
                            hasMore={assessments?.length !== TotalCourses}
                            style={{
                              overflow: "hidden",
                            }}
                            loader={
                              result == true ? (
                                <h4
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  Loading....
                                </h4>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <b>No Assessment Found</b>
                                </p>
                              )
                            }
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <b>No More data Found</b>
                              </p>
                            }
                          >
                            <div className="row">
                              {assessments && assessments?.length > 0
                                ? assessments?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4">
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />
                                              <h6 className="flex-grow-1">
                                                {
                                                  assessment.assessment_subject_name
                                                }
                                              </h6>
                                              <div className="class">
                                                {assessment.assessment_class}
                                              </div>
                                            </div>
                                          </div>

                                          <Link
                                            className="name card-body"
                                            to={
                                              "/AssessmentDetails/" +
                                              assessment.assessment_id
                                            }
                                            // className="card-body"
                                          >
                                            <h5>
                                              {assessment.assessment_title}
                                            </h5>
                                          </Link>

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {formatDate(
                                                  assessment.assessment_created_date,
                                                )}
                                              </h5>
                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#exampleModal2"
                                                      className="setting-secondary"
                                                    >
                                                      <Link
                                                        to={
                                                          "/create-assessment/" +
                                                          assessment.assessment_id
                                                        }
                                                      >
                                                        <i data-feather="edit-3">
                                                          <Edit3 />
                                                        </i>
                                                      </Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                      onClick={() => [
                                                        setOpenMode(true),
                                                        setStatus(2),
                                                        setAssessmentId(
                                                          assessment.assessment_id,
                                                        ),
                                                      ]}
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2 />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Any Time">
                  <div className="row assignment-sec">
                    <div className="col-sm-12">
                      {isloading ? (
                        <ListSkeleton loading={isloading} />
                      ) : (
                        <div className="card">
                          <InfiniteScroll
                            dataLength={assessments?.length}
                            next={onPageLoad}
                            hasMore={assessments?.length !== TotalCourses}
                            style={{
                              overflow: "hidden",
                            }}
                            loader={
                              result == true ? (
                                <h4
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  Loading....
                                </h4>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <b>No Assessment Found</b>
                                </p>
                              )
                            }
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <b>No More data Found</b>
                              </p>
                            }
                          >
                            <div className="row">
                              {assessments && assessments?.length > 0
                                ? assessments?.map((assessment, index) => {
                                    let classIndex =
                                      index < 6
                                        ? index + 1
                                        : Math.floor(Math.random() * 6) + 1;
                                    return (
                                      <div className="col-md-6 mt-4">
                                        <div
                                          className={
                                            "card sub-" +
                                            classIndex +
                                            " stack-card"
                                          }
                                        >
                                          <div className="card-header">
                                            <div className="d-flex">
                                              <img
                                                className="flex-grow-0"
                                                src={subtopicicon}
                                              />
                                              <h6 className="flex-grow-1">
                                                {
                                                  assessment.assessment_subject_name
                                                }
                                              </h6>
                                              <div className="class">
                                                {assessment.assessment_class}
                                              </div>
                                            </div>
                                          </div>

                                          <Link
                                            className="name card-body"
                                            to={
                                              "/AssessmentDetails/" +
                                              assessment.assessment_id
                                            }
                                            // className="card-body"
                                          >
                                            <h5>
                                              {assessment.assessment_title}
                                            </h5>
                                          </Link>

                                          <div className="card-footer">
                                            <div className="d-sm-flex">
                                              <h5>
                                                {formatDate(
                                                  assessment.assessment_created_date,
                                                )}
                                              </h5>
                                              <div className="action-list">
                                                <ul className="list-unstyled">
                                                  <li>
                                                    <div
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#exampleModal2"
                                                      className="setting-secondary"
                                                    >
                                                      <Link
                                                        to={
                                                          "/create-assessment/" +
                                                          assessment.assessment_id
                                                        }
                                                      >
                                                        <i data-feather="edit-3">
                                                          <Edit3 />
                                                        </i>
                                                      </Link>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-bs-toggle="modal"
                                                      data-original-title="test"
                                                      data-bs-target="#deleteWarning"
                                                      className="setting-secondary"
                                                      onClick={() => [
                                                        setOpenMode(true),
                                                        setStatus(3),
                                                        setAssessmentId(
                                                          assessment.assessment_id,
                                                        ),
                                                      ]}
                                                    >
                                                      <i data-feather="trash-2">
                                                        <Trash2 />
                                                      </i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        centered
        show={openMode}
        onHide={() => setOpenMode(false)}
        animation={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="borderModal"></div>
          <p>Are you sure you want to delete ?</p>

          {/* <div className="borderModalBelow"></div> */}
        </Modal.Body>
        <div className="borderModal"></div>
        <Modal.Footer>
          <div className="modalBtns">
            <button className="cancelBtn" onClick={() => setOpenMode(false)}>
              Cancel
            </button>
            <button
              className="YesBtn"
              onClick={() => [setOpenMode(false), assessmentDelete()]}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>

    //Old version code
    // <div className="row assignment-sec">
    //   <div className="col-sm-12">
    //     {isloading ? (
    //       <ListSkeleton loading={isloading} />
    //     ) : (
    //       <div className="card">
    //         <div className="card-header pb-0">
    //           <div className="d-flex">
    //             <h5 className="flex-grow-1">{t('Assessment')}</h5>
    //             <Link
    //               to="/create-assessment"
    //               type="button"
    //               className="btn btn-edsys-primary"
    //             >
    //               <PlusCircle className="me-2" />
    //               <span>{t('Create New Assessment')}</span>
    //             </Link>
    //           </div>
    //         </div>
    //         <div className="card-body">
    //           <InfiniteScroll
    //             dataLength={assessments?.length}
    //             next={onPageLoad}
    //             hasMore={assessments?.length !== TotalCourses}
    //             style={{
    //               overflow: 'hidden',
    //             }}
    //             loader={
    //               <h4 style={{ textAlign: 'center', marginTop: '20px' }}>
    //                 Loading....
    //               </h4>
    //             }
    //             // endMessage={
    //             //   <p style={{ textAlign: "center", marginTop: "20px" }}>
    //             //     <b>No More data Found</b>
    //             //   </p>
    //             // }
    //           >
    //             <div className="row">
    //               <div className="col-md-4">
    //                 <div class="card-body p-0">
    //                   <ReactSelect
    //                     class="js-example-basic-single form-select"
    //                     value={class_State}
    //                     onChange={(e) => e && setClass_State(e)}
    //                     options={class_options}
    //                     placeholder="filter By Class..."
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-4">
    //                 <div class="card-body p-0">
    //                   <ReactSelect
    //                     class="js-example-basic-single form-select"
    //                     value={subject_state}
    //                     onChange={(e) => e && setSubject_state(e)}
    //                     options={subject_options}
    //                     placeholder="filter By Subject..."
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-4 d-flex">
    //                 <div class="card-body p-0">
    //                   <ReactSelect
    //                     class="js-example-basic-single form-select"
    //                     value={topic_state}
    //                     onChange={(e) => e && setTopic_state(e)}
    //                     options={topic_options}
    //                     placeholder="filter By Topic..."
    //                   />
    //                 </div>
    //                 <div className="col text-end">
    //                   <div class="card-body p-0">
    //                     <img src={filter} alt="" />
    //                   </div>
    //                 </div>
    //               </div>
    //               {assessments && assessments?.length > 0
    //                 ? assessments?.map((assessment, index) => {
    //                     let classIndex =
    //                       index < 6
    //                         ? index + 1
    //                         : Math.floor(Math.random() * 6) + 1;
    //                     return (
    //                       <div className="col-md-6 mt-4">
    //                         <div
    //                           className={
    //                             'card sub-' + classIndex + ' stack-card'
    //                           }
    //                         >
    //                           <div className="card-header">
    //                             <div className="d-flex">
    //                               <img
    //                                 className="flex-grow-0"
    //                                 src={subtopicicon}
    //                               />
    //                               <h6 className="flex-grow-1">
    //                                 {assessment.assessment_subject}
    //                               </h6>
    //                               <div className="class">
    //                                 {assessment.assessment_class}
    //                               </div>
    //                             </div>
    //                           </div>

    //                           <Link
    //                             className="name card-body"
    //                             to={
    //                               '/AssessmentDetails/' +
    //                               assessment.assessment_id
    //                             }
    //                             // className="card-body"
    //                           >
    //                             <h5>{assessment.assessment_title}</h5>
    //                           </Link>

    //                           <div className="card-footer">
    //                             <div className="d-sm-flex">
    //                               <h5>
    //                                 {moment(
    //                                   assessment.assessment_created_date
    //                                 ).format('DD/MM/YYYY')}
    //                               </h5>
    //                               <div className="action-list">
    //                                 <ul className="list-unstyled">
    //                                   {/* <li>
    //                                     <div
    //                                       data-bs-toggle="modal"
    //                                       data-bs-target="#exampleModal2"
    //                                       className="setting-secondary"
    //                                     >
    //                                       <Link
    //                                         to={
    //                                           "/create-assessment/" +
    //                                           assessment.assessment_id
    //                                         }
    //                                       >
    //                                         <i data-feather="edit-3">
    //                                           <Edit3 />
    //                                         </i>
    //                                       </Link>
    //                                     </div>
    //                                   </li> */}
    //                                   <li>
    //                                     <a
    //                                       data-bs-toggle="modal"
    //                                       data-original-title="test"
    //                                       data-bs-target="#deleteWarning"
    //                                       className="setting-secondary"
    //                                     >
    //                                       <i data-feather="trash-2">
    //                                         <Trash2 />
    //                                       </i>
    //                                     </a>
    //                                   </li>
    //                                 </ul>
    //                               </div>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     );
    //                   })
    //                 : null}
    //             </div>
    //           </InfiniteScroll>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
}

export default Assessmentnew;
