import React, { useState, useEffect } from "react";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import { useTranslation } from "react-i18next";
import TablesSkeleton from "../../components/skeletons/TablesSkeleton";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import filter from "../../assets/images/filter.svg";
function StudentList() {
  const { t } = useTranslation();

  const [isloading, setIsloading] = useState(true);
  const [state, setState] = useState({
    selectClassId: 0,
    selectSectionId: 0,
  });
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  function getClasses() {
    simplePostCall(
      ApiConfig.CLASSES_LIST_API,
      JSON.stringify({ ...Params, user_id: Params.teacher_id })
    )
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          console.log(data);
          let class_id = data.list[0].class_id;
          setClassList(data.list);
          //   setState({ classId: class_id, sectionId: 0 });
          getSections(class_id);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("api response", error);
      });
  }
  function getSections(classId) {
    simplePostCall(
      ApiConfig.SECTION_LIST_API,
      JSON.stringify({
        ...Params,
        user_id: Params.teacher_id,
        class_id: classId,
      })
    )
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          let section_id = data.list[0].class_division_id;
          setState({
            selectClassId: classId,
            selectSectionId: section_id,
          });
          setSectionList(data.list);
          getStudents(classId, section_id);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("api response", error);
      });
    // getStudents(classId);
  }

  const getStudents = (classId, section_id) => {
    setIsloading(true);
    const newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classId,
      section_id: section_id,
    };
    simplePostCall(ApiConfig.STUDENTS_API, JSON.stringify(newRequestBody))
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          setStudentList(data.list);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("api response", error);
      });
  };

  const onSelectionChange = (e) => {
    const { name, value } = e.target;
    setIsloading(true);
    let preState = { ...state, [name]: value };
    setState(preState);

    if (name === "selectClassId") {
      setSectionList([]);
      setStudentList([]);
      getSections(value);
    } else {
      getStudents(state.selectClassId, value);
    }
  };

  useEffect(() => {
    getClasses();
  }, []);
  useEffect(() => {}, [state]);
  useEffect(() => {}, [
    state.selectClassId,
    state.selectSectionId,
    classList,
    sectionList,
    studentList,
  ]);

  const category = [
    { value: "Planned", label: "Planned" },
    { value: "Unplanned", label: "Unplanned" },
  ];
  const status = [
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  return (
    <>
      {/* <!-- Container-fluid starts--> */}

      <div className="row approve-leave">
        <div className="col-md-12">
          <div className="card br-15">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">{t("Students")}</h5>
              </div>
            </div>
            <div className="card-body">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row row">
                  <div className="form-group col-md-6">
                    <label htmlFor="task-title">{t("Select Class")}</label>
                    <select
                      //   className="js-example-basic-single form-select"
                      className="form-select"
                      onChange={onSelectionChange}
                      name="selectClassId"
                    >
                      {classList.length > 0
                        ? classList.map((obj, index) => {
                            return (
                              <option value={obj.class_id}>
                                {obj.class_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Select Section")}</label>
                    <select
                      //   className="js-example-basic-single form-select"
                      className="form-select"
                      name="selectSectionId"
                      onChange={onSelectionChange}
                    >
                      {sectionList.length > 0
                        ? sectionList.map((obj, index) => {
                            return (
                              <option value={obj.class_division_id}>
                                {obj.class_division_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
              </form>
              {isloading ? (
                <TablesSkeleton loading={isloading} />
              ) : (
                <>
                  <div className="col-12">
                    <div
                      className="card br-15 mt-4"
                      style={{ maxHeight: "80vh", overflowY: "auto" }}
                    >
                      <div className="card-body p-0">
                        <Table responsive="sm">
                          <thead className="position-sticky top-0 start-50 bg-light br-15">
                            <tr>
                              <th>{t("S No")}</th>
                              <th>{t("Profile Pic")}</th>

                              <th>{t("Student Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Mobile Number")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              className="position-sticky bg-light"
                              style={{ top: "35px" }}
                            >
                              <td>
                                <img src={filter} alt="" />
                              </td>

                              <td>
                                <Select
                                  class="js-example-basic-single form-select"
                                  // value={state.selectedCurriculum}
                                  // onChange={onCurriculumChange}
                                  options={category}
                                />
                              </td>
                              <td>
                                <Select
                                  class="js-example-basic-single form-select"
                                  // value={state.selectedCurriculum}
                                  // onChange={onCurriculumChange}
                                  options={status}
                                />
                              </td>
                              <td>
                                <Select
                                  class="js-example-basic-single form-select"
                                  // value={state.selectedCurriculum}
                                  // onChange={onCurriculumChange}
                                  options={status}
                                />
                              </td>
                              <td>
                                <Select
                                  class="js-example-basic-single form-select"
                                  // value={state.selectedCurriculum}
                                  // onChange={onCurriculumChange}
                                  options={status}
                                />
                              </td>
                            </tr>

                            {studentList && studentList.length > 0 ? (
                              studentList.map((student, index) => (
                                <tr
                                  // key={"leave" + index}
                                  className="student-rank-list"
                                >
                                  <td>{index + 1}</td>

                                  <td>
                                    <img
                                      src={student.student_profilepicture}
                                      alt={student.student_name}
                                    />
                                  </td>
                                  <td>{student.student_name}</td>
                                  <td>{student.student_email}</td>
                                  <td>{student.student_mobile}</td>
                                </tr>
                              ))
                            ) : (
                              <div
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="student-rank-list"
                              >
                                <div className="d-flex align-items-center">
                                  <p>{t("No records found")}..!</p>
                                </div>
                              </div>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
}

export default StudentList;
