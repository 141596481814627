import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";

export const CreateGradeList = () => {
  const [gradename, setGradename] = useState("");
  const [gradeSlabs, setGradeSlabs] = useState([
    { slabname: "", slabcode: "", starperc: 0, endperc: 0 },
  ]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const addGradeSlab = () => {
    const lastSlab = gradeSlabs[gradeSlabs.length - 1];
    setGradeSlabs([
      ...gradeSlabs,
      {
        slabname: "",
        slabcode: "",
        starperc: lastSlab.endperc,
        endperc: lastSlab.endperc,
      },
    ]);
  };

  const removeGradeSlab = (index) => {
    const newSlabs = gradeSlabs.filter((_, slabIndex) => slabIndex !== index);
    setGradeSlabs(newSlabs);
  };

  const handleSlabChange = (index, field, value) => {
    const newSlabs = [...gradeSlabs];
    newSlabs[index][field] = value;
    setGradeSlabs(newSlabs);
  };

  const handleMouseMove = (index, event) => {
    if (event.buttons !== 1) return;
    updateProgress(index, event);
  };

  const handleClick = (index, event) => {
    updateProgress(index, event);
  };

  const updateProgress = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const width = rect.width;
    const newProgress = Math.min(100, Math.max(0, (offsetX / width) * 100));
    const newSlabs = [...gradeSlabs];
    newSlabs[index].endperc = newProgress;
    setGradeSlabs(newSlabs);

    // Update the start percentage of the next slab
    if (index < gradeSlabs.length - 1) {
      newSlabs[index + 1].starperc = newProgress;
    }
  };

  const handleInputChange = (index, event) => {
    const newValue = Math.min(100, Math.max(0, Number(event.target.value)));
    const newSlabs = [...gradeSlabs];
    newSlabs[index].endperc = newValue;
    setGradeSlabs(newSlabs);

    // Update the start percentage of the next slab
    if (index < gradeSlabs.length - 1) {
      newSlabs[index + 1].starperc = newValue;
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (!gradename) {
      newErrors.gradename = "Grade name is required";
      isValid = false;
    }

    gradeSlabs.forEach((slab, index) => {
      if (!slab.slabname) {
        newErrors[`slabname_${index}`] = "Slab name is required";
        isValid = false;
      }
      if (!slab.slabcode) {
        newErrors[`slabcode_${index}`] = "Slab code is required";
        isValid = false;
      }
      if (slab.endperc <= slab.starperc) {
        newErrors[`slabperc_${index}`] =
          "End percentage must be greater than start percentage";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const CreateGrade = () => {
    if (!validate()) {
      toast.error("Please fix the validation errors.");
      return;
    }

    const gradeArray = gradeSlabs.map((slab) => ({
      grade_slab_name: slab.slabname,
      start_percentage: slab.starperc,
      end_percentage: slab.endperc,
      grade_slab_code: slab.slabcode,
    }));

    const RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_name: gradename,
      grade: gradeArray,
    });

    console.log("Request Body:", RequestBody); // Add this line to debug the request body

    simplePostCall(ApiConfig.GRADEE_LIST_ADD, RequestBody)
      .then((data) => {
        if (data) {
          console.log("API Response:", data); // Add this line to debug the API response
          toast.success("Created successfully");
          navigate("/maingradelist");
        } else {
          console.log("Error while creating");
        }
      })
      .catch((err) => {
        console.log(err, "API request failed");
      });
  };

  return (
    <div>
      <div className="evaluation">
        <div className="evalHead">
          <h5 className="labelCreate">Create Grading</h5>
        </div>
        <div className="row">
          <div className="col-md-12 totalGradecol">
            <div className="row">
              <div className="col-md-6">
                <h6>Grading Name</h6>
                <textarea
                  className="gradeTextarea"
                  placeholder="Enter the Grade Name"
                  value={gradename}
                  onChange={(e) => setGradename(e.target.value)}
                />
                {errors.gradename && (
                  <div className="erro">{errors.gradename}</div>
                )}
              </div>
            </div>
            {gradeSlabs.map((slab, index) => (
              <div key={index} className="slab-container">
                <div className="row">
                  <div className="closebutt">
                    <h5 className="gradetext">
                      Add Grading List {index === 0 ? null : index}
                    </h5>
                    <div className="removeSlabButton">
                      {index === 0 ? null : (
                        <button
                          className="gradeClose"
                          onClick={() => removeGradeSlab(index)}
                        >
                          <IoCloseSharp />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6>Grading Slab Name</h6>
                    <textarea
                      className="gradeTextarea"
                      placeholder="Enter the Grade slab Name"
                      value={slab.slabname}
                      onChange={(e) =>
                        handleSlabChange(index, "slabname", e.target.value)
                      }
                    />
                    {errors[`slabname_${index}`] && (
                      <div className="erro">{errors[`slabname_${index}`]}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <h6>Grading Slab Code</h6>
                    <textarea
                      className="gradeTextarea"
                      placeholder="Enter the Grade"
                      value={slab.slabcode}
                      onChange={(e) =>
                        handleSlabChange(index, "slabcode", e.target.value)
                      }
                    />
                    {errors[`slabcode_${index}`] && (
                      <div className="erro">{errors[`slabcode_${index}`]}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Grading Percentage</h6>
                    <div>
                      <div
                        className="progress"
                        onMouseMove={(e) => handleMouseMove(index, e)}
                        onClick={(e) => handleClick(index, e)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${slab.endperc}%` }}
                          aria-valuenow={slab.endperc}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {Math.round(slab.endperc)}%
                        </div>
                      </div>
                      <input
                        type="number"
                        value={Math.round(slab.endperc)}
                        onChange={(e) => handleInputChange(index, e)}
                        min="0"
                        max="100"
                        style={{
                          marginTop: "10px",
                          padding: "5px",
                          width: "60px",
                          display: "flex",
                          textAlign: "center",
                          border: "1px solid #dacece",
                          color: "#76787d",
                        }}
                      />
                      {errors[`slabperc_${index}`] && (
                        <div className="erro">
                          {errors[`slabperc_${index}`]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="subButt">
              <div className="addSlabButton">
                <button className="addslab" onClick={addGradeSlab}>
                  Add Another Slab
                </button>
              </div>
              <button className="btn btn-primary crbt" onClick={CreateGrade}>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGradeList;
