import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaSearchPlus } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import { Filter } from "react-feather";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import LoadingModal from "./Loadingmodal";
import SyllabusTable from "./syllabusTableSkel";
import ReactTooltip from "react-tooltip";

export const SyllabusCreate = () => {
  const [curricu, setCurricu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const Cirriculam = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      page_limit: "100",
    });
    simplePostCall(ApiConfig.CURRICULUM_LIST_API, RequestBody)
      .then((data) => {
        console.log(data, "data lists");
        if (data.result) {
          let curriculam = [];
          if (data?.curriculum_list.length > 0) {
            setLoading(false);
            data.curriculum_list.map((ciriculum, index) => {
              console.log(ciriculum, "ciriculum");
              curriculam.push({
                lab: ciriculum.curriculum_name,
                valu: ciriculum.curriculum_id,
                language: ciriculum.curriculum_language,
              });
            });
          }
          setCurricu({ ...curricu, curriculam: curriculam });
          console.log(curricu, "datalatest");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("api res fail", err);
      });
  };

  useEffect(() => {
    Cirriculam();
  }, []);
  const navi = useNavigate();
  return (
    <div className="SyllabusCreate">
      {loading ? (
        <SyllabusTable />
      ) : (
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="labelCreate">Syllabus</label>
                <Link to={"/CreateSyllabus"}>
                  <div className="createButt">
                    <div className="CreateB">Create Syllabus</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="tableCreate">
              <table class="table table-dark table-borderless newTable ">
                <thead className="tableHead">
                  <th>#</th>
                  <th>Syllabus Name</th>
                  <th></th>
                </thead>
                {console.log(curricu.curriculam, "sdfbdbn")}
                {curricu.curriculam?.map((item, index) => (
                  <tbody>
                    {index === 0 ? (
                      <td></td>
                    ) : (
                      <td className="textcol">{index}</td>
                    )}
                    {index === 0 ? (
                      <td>
                        <div>
                          <input
                            type="text"
                            className="tetxinp"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </td>
                    ) : (
                      <td className="textcol">{item.lab}</td>
                    )}
                    {index === 0 ? (
                      <td></td>
                    ) : (
                      <td className="iconSec">
                        <ReactTooltip effect="solid" />
                        <div
                          data-tip="view"
                          className="searchIc"
                          onClick={() =>
                            navi("/ViewSyllabus", {
                              state: item.valu,
                            })
                          }
                        >
                          <FaSearchPlus className="icons" />
                        </div>

                        <div
                          data-tip="edit"
                          className="editBu"
                          onClick={() =>
                            navi("/UpdateSyllabus", {
                              state: item.valu,
                            })
                          }
                        >
                          <FaEdit className="icons" />
                        </div>
                      </td>
                    )}
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
