import React, { useState, useEffect } from "react";
import AssignmentCardData from "../../api/assignment_card_data.json";

import { Link, useNavigate } from "react-router-dom";

import { Edit3, Trash2, PlusCircle, BookOpen } from "react-feather";
import { Modal, Button } from "react-bootstrap";

import Academic from "../../assets/images/Academic.png";

import "../../assets/css/tabstyles.css";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import { TabPanel, TabView } from "primereact/tabview";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactSelect from "react-select";
import ApiConfig from "../../api/ApiConfig";
import BarChart from "./GradeListView";
import Chartrrr from "./GradeListView";
const OfflineAssesmentHome = () => {
  const [isTodoModelShow, setTodoModelShow] = useState(false);
  const [isTagModelShow, setTagModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [pages, setPages] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);
  const [status, setStatus] = useState(0);

  const [class_State, setClass_State] = useState([]);
  const [subject_state, setSubject_state] = useState([]);
  const [topic_state, setTopic_state] = useState([]);
  const navigation = useNavigate();
  console.log("clicked", status);
  useEffect(() => {
    document.title = "Edsysy | Assignment";
    OfflineAssessmentList(page);
  }, [status]);

  const setInitialState = () => {
    setTodoModelShow(false);
    setTagModelShow(false);
  };

  const openCloseTodoModel = () => {
    setInitialState();
    setTodoModelShow(!isTodoModelShow);
  };

  const openCloseTagModel = () => {
    setInitialState();
    setTagModelShow(!isTagModelShow);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [Assignment, setAssignment] = useState([]);
  const [openMode, setOpenMode] = useState(false);
  const [assList, setAssList] = useState([]);
  const [assignmentId, setAssignmentId] = useState([]);
  console.log(assignmentId, "assignmentIdassignmentId");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  //................Offline AssessmentList...................//

  const OfflineAssessmentList = (currentPage) => {
    const details = {};

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      search_prameter: "",
      page: currentPage,
      page_limit: "",
      status: status == 0 ? "scheduled" : status == 1 ? "history" : "",
    });
    simplePostCall(ApiConfig.OFFLINEASSESSMENT_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data, "hiiiii");
          setPages(data.total_pages);
          setIsloading(false);
          let total = data.total_count ? data.total_count : 0;
          setTotalCourses(total);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData?.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            setAssignment(data.data);

            console.log(data.data[0].assessment_offline_id, "datazzzz");
            setIsloading(false);
          } else {
            setAssignment([...Assignment, ...LearningData]);
            setIsloading(false);
          }
        } else {
          setIsloading(false);
          setAssignment([]);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  console.log(assignmentId, "assigid");
  //................Delete Assignment...................//

  const DeleteAssignment = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,

      offline_assessment_id: assignmentId,
    });
    simplePostCall(ApiConfig.OFFLINEASSESSMENT_DELETE, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          toast.success(data.message);
          OfflineAssessmentList(page);
          console.log("message", data.message);
        } else {
          toast.error(data.message);
          OfflineAssessmentList(page);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const onPageLoad = () => {
    console.log(page, "onPageLoad", pages);
    if (page > pages) {
      setPage(page + 1);
      OfflineAssessmentList(page + 1);
    }
  };

  return (
    <>
      <div className="Forum library">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="col-md -6">Offline Assesments</h5>
                  <div style={{ marginRight: 10 }}></div>
                  <div className="off_butt">
                    <Link to={"/EvaluationType"}>
                      <a type="button" className="btn btn-edsys-primary btn1">
                        <PlusCircle className="me-2" />
                        <span style={{ marginTop: "20px" }}>
                          Evaluation Type
                        </span>
                      </a>
                    </Link>
                    <Link to={"/maingradelist"}>
                      <a type="button" className="btn btn-edsys-primary btn1">
                        <PlusCircle className="me-2" />
                        <span style={{ marginTop: "20px" }}>Grading List</span>
                      </a>
                    </Link>
                    <Link to={"/CreateOfflineAssessment"}>
                      <a type="button" className="btn btn-edsys-primary btn1">
                        <PlusCircle className="me-2" />
                        <span style={{ marginTop: "20px" }}>
                          Create New Offline Assessment
                        </span>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <TabView
                    activeIndex={status}
                    onTabChange={(e) => {
                      setStatus(e.index);
                      setAssignment([]);
                      setPage(1);
                    }}
                  >
                    <TabPanel header="Scheduled">
                      <div className="row assignment-sec">
                        <div className="col-sm-12">
                          {isloading ? (
                            <ListSkeleton loading={isloading} />
                          ) : (
                            <div className="card">
                              <div className="card-body">
                                <InfiniteScroll
                                  dataLength={Assignment?.length}
                                  next={onPageLoad}
                                  hasMore={
                                    Assignment?.length !== TotalCourses &&
                                    page <= pages
                                  } // Check if there are more pages to load
                                  style={{
                                    overflow: "hidden",
                                  }}
                                  loader={
                                    Assignment?.length !== TotalCourses &&
                                    page < pages ? ( // Show loader only if there are more pages to load
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          marginTop: "20px",
                                        }}
                                      >
                                        Loading....
                                      </h4>
                                    ) : null
                                  }
                                >
                                  <div className="row">
                                    {Assignment && Assignment?.length > 0
                                      ? Assignment?.map((assessment, index) => {
                                          let classIndex =
                                            index < 6
                                              ? index + 1
                                              : Math.floor(Math.random() * 6) +
                                                1;
                                          return (
                                            <div className="col-md-6 mt-4">
                                              <div
                                                className={
                                                  "card sub-" +
                                                  classIndex +
                                                  " stack-card"
                                                }
                                              >
                                                <div className="card-header">
                                                  <div className="d-flex">
                                                    <img
                                                      className="flex-grow-0"
                                                      src={Academic}
                                                    />

                                                    <h6 className="flex-grow-1">
                                                      {
                                                        assessment.assessment_offline_academic_year
                                                      }
                                                    </h6>
                                                    <div className="action-list">
                                                      <ul className="d-sm-flex">
                                                        <li>
                                                          <div
                                                            // className="d-sm-flex"
                                                            style={{
                                                              borderRadius:
                                                                "10%",
                                                              marginRight:
                                                                "5px",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                color:
                                                                  "#772569",
                                                              }}
                                                            >
                                                              {assessment.assessment_offline_status ===
                                                              "ready for apporval"
                                                                ? "Ready To Approve"
                                                                : assessment.assessment_offline_status ===
                                                                  "draft"
                                                                ? "draft"
                                                                : assessment.assessment_offline_status ===
                                                                  "published"
                                                                ? "published"
                                                                : ""}
                                                            </p>
                                                          </div>
                                                        </li>
                                                        <li>
                                                          <div
                                                            style={{
                                                              padding: 5,
                                                              backgroundColor:
                                                                "white",
                                                              borderRadius: 20,
                                                              marginRight: 5,
                                                              marginTop: -5,
                                                            }}
                                                          >
                                                            <i
                                                              data-feather="edit-3"
                                                              onClick={() =>
                                                                navigation(
                                                                  "/EditOfflineAssessment",
                                                                  {
                                                                    state: {
                                                                      offlineId:
                                                                        assessment.assessment_offline_id,
                                                                      name: assessment.assessment_offline_name,
                                                                      academicYear:
                                                                        {
                                                                          label:
                                                                            assessment.assessment_offline_academic_year,
                                                                          value:
                                                                            assessment.assessment_offline_academic_year_id,
                                                                        },
                                                                      gradingName:
                                                                        {
                                                                          label:
                                                                            assessment.assessment_grading_name,
                                                                          value:
                                                                            assessment.assessment_grading_id,
                                                                        },

                                                                      startDate:
                                                                        assessment.assessment_offline_start_date,
                                                                      endDate:
                                                                        assessment.assessment_offline_end_date,
                                                                      term: {
                                                                        label:
                                                                          assessment.assessment_offline_term,
                                                                        value:
                                                                          assessment.terms_id,
                                                                      },
                                                                      status:
                                                                        assessment.assessment_offline_status,
                                                                    },
                                                                  },
                                                                )
                                                              }
                                                            >
                                                              <Edit3
                                                                style={{
                                                                  marginBottom:
                                                                    -5,
                                                                  height: 20,
                                                                  width: 20,
                                                                }}
                                                              />
                                                            </i>
                                                          </div>
                                                        </li>

                                                        <li>
                                                          <a
                                                            // data-bs-toggle="modal"
                                                            // data-original-title="test"
                                                            // data-bs-target="#deleteWarning"
                                                            // className="setting-secondary"
                                                            style={{
                                                              padding: 5,
                                                              backgroundColor:
                                                                "white",
                                                              borderRadius: 20,
                                                            }}
                                                          >
                                                            <i data-feather="trash-2">
                                                              <Trash2
                                                                style={{
                                                                  marginBottom:
                                                                    -5,
                                                                  height: 20,
                                                                  width: 20,
                                                                }}
                                                                onClick={() => [
                                                                  setOpenMode(
                                                                    true,
                                                                  ),
                                                                  setAssignmentId(
                                                                    assessment.assessment_offline_id,
                                                                  ),
                                                                ]}
                                                              />
                                                            </i>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  onClick={() => {
                                                    navigation(
                                                      "/OfflineAssesmentChoose",
                                                      {
                                                        state: {
                                                          assesmentId:
                                                            assessment.assessment_offline_id,
                                                          assesmentName:
                                                            assessment.assessment_offline_name,
                                                          AcademicYr: {
                                                            label:
                                                              assessment.assessment_offline_academic_year,
                                                            value:
                                                              assessment.assessment_offline_academic_year_id,
                                                          },
                                                          TermId: {
                                                            label:
                                                              assessment.assessment_offline_term,
                                                            value:
                                                              assessment.terms_id,
                                                          },
                                                        },
                                                      },
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    className="name card-body d-flex"
                                                    style={{
                                                      color: "black",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                    // className="card-body"
                                                  >
                                                    <h5>
                                                      {
                                                        assessment.assessment_offline_name
                                                      }
                                                    </h5>
                                                    <div className="class">
                                                      {
                                                        assessment.assessment_offline_term
                                                      }
                                                    </div>
                                                  </div>

                                                  <div className="card-footer">
                                                    <div className="d-sm-flex">
                                                      <h5>
                                                        {assessment.assessment_offline_start_date
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}
                                                      </h5>
                                                      <h5
                                                        style={{
                                                          marginLeft: "35%",
                                                        }}
                                                      >
                                                        {assessment.assessment_offline_end_date
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}
                                                      </h5>
                                                      <div className="action-list">
                                                        <ul className="list-unstyled">
                                                          <li>
                                                            <div></div>
                                                          </li>
                                                          <li></li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </InfiniteScroll>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel header="History">
                      <div className="row assignment-sec">
                        <div className="col-sm-12">
                          {isloading ? (
                            <ListSkeleton loading={isloading} />
                          ) : (
                            <div className="card">
                              <div className="card-body">
                                {console.log(Assignment, "ASSIIGNNN")}
                                <InfiniteScroll
                                  dataLength={Assignment?.length}
                                  next={onPageLoad}
                                  hasMore={
                                    Assignment?.length !== TotalCourses &&
                                    page <= pages
                                  } // Check if there are more pages to load
                                  style={{
                                    overflow: "hidden",
                                  }}
                                  loader={
                                    Assignment?.length !== TotalCourses &&
                                    page < pages ? ( // Show loader only if there are more pages to load
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          marginTop: "20px",
                                        }}
                                      >
                                        Loading....
                                      </h4>
                                    ) : null
                                  }
                                >
                                  <div className="row">
                                    {Assignment && Assignment?.length > 0
                                      ? Assignment?.map((assessment, index) => {
                                          let classIndex =
                                            index < 6
                                              ? index + 1
                                              : Math.floor(Math.random() * 6) +
                                                1;
                                          return (
                                            <div className="col-md-6 mt-4">
                                              <div
                                                className={
                                                  "card sub-" +
                                                  classIndex +
                                                  " stack-card"
                                                }
                                              >
                                                <div className="card-header">
                                                  <div className="d-flex">
                                                    <img
                                                      className="flex-grow-0"
                                                      src={Academic}
                                                    />
                                                    <h6 className="flex-grow-1">
                                                      {
                                                        assessment.assessment_offline_academic_year
                                                      }
                                                    </h6>
                                                    <div className="action-list ">
                                                      <ul className="list-unstyled d-flex">
                                                        <li>
                                                          <a
                                                            style={{
                                                              padding: 5,
                                                              backgroundColor:
                                                                "white",
                                                              borderRadius: 20,
                                                            }}
                                                          >
                                                            <i data-feather="trash-2">
                                                              <Trash2
                                                                style={{
                                                                  marginBottom:
                                                                    -5,
                                                                  height: 20,
                                                                  width: 20,
                                                                }}
                                                                onClick={() => [
                                                                  setOpenMode(
                                                                    true,
                                                                  ),
                                                                  setAssignmentId(
                                                                    assessment.assessment_offline_id,
                                                                  ),
                                                                ]}
                                                              />
                                                            </i>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    navigation(
                                                      "/OfflineAssesmentChoose",
                                                      {
                                                        state: {
                                                          assesmentId:
                                                            assessment.assessment_offline_id,
                                                          assesmentName:
                                                            assessment.assessment_offline_name,
                                                          AcademicYr: {
                                                            label:
                                                              assessment.assessment_offline_academic_year,
                                                            value:
                                                              assessment.assessment_offline_academic_year_id,
                                                          },
                                                          TermId: {
                                                            label:
                                                              assessment.assessment_offline_term,
                                                            value:
                                                              assessment.terms_id,
                                                          },
                                                        },
                                                      },
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    className="name card-body d-flex"
                                                    onClick={() => {
                                                      console.log(
                                                        "yksss",
                                                        assessment.assessment_offline_id,
                                                      );
                                                      navigation(
                                                        "/OfflineAssesmentChoose",
                                                        {
                                                          state: {
                                                            assesmentId:
                                                              assessment.assessment_offline_id,
                                                            assesmentName:
                                                              assessment.assessment_offline_name,
                                                            AcademicYr: {
                                                              label:
                                                                assessment.assessment_offline_academic_year,
                                                              value:
                                                                assessment.assessment_offline_academic_year_id,
                                                            },
                                                            TermId: {
                                                              label:
                                                                assessment.assessment_offline_term,
                                                              value:
                                                                assessment.terms_id,
                                                            },
                                                          },
                                                        },
                                                      );
                                                    }}
                                                    style={{
                                                      color: "black",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                    // className="card-body"
                                                  >
                                                    {console.log(
                                                      "assessment_offline_id",
                                                      assessment.assessment_offline_id,
                                                    )}
                                                    <h5>
                                                      {
                                                        assessment.assessment_offline_name
                                                      }
                                                    </h5>
                                                    <div className="class">
                                                      {
                                                        assessment.assessment_offline_term
                                                      }
                                                    </div>
                                                  </div>

                                                  <div className="card-footer">
                                                    <div className="d-sm-flex">
                                                      <h5>
                                                        {assessment.assessment_offline_start_date
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}
                                                      </h5>

                                                      <h5
                                                        style={{
                                                          marginLeft: "35%",
                                                        }}
                                                      >
                                                        {assessment.assessment_offline_end_date
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}
                                                      </h5>
                                                      <div className="action-list">
                                                        <ul className="list-unstyled">
                                                          <li>
                                                            <div
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#exampleModal2"
                                                            // className="setting-secondary"
                                                            >
                                                              <Link
                                                                to={
                                                                  "/create-assignment/" +
                                                                  assessment.assignment_id
                                                                }
                                                                type="button"
                                                                // className="btn btn-edsys-primary"
                                                              >
                                                                {/* <i data-feather="edit-3">
                                                                <Edit3 />
                                                              </i> */}
                                                              </Link>
                                                            </div>
                                                          </li>
                                                          <li>
                                                            {/* <a
                                                            data-bs-toggle="modal"
                                                            data-original-title="test"
                                                            data-bs-target="#deleteWarning"
                                                            className="setting-secondary"
                                                          >
                                                            <i data-feather="trash-2">
                                                              <Trash2
                                                                onClick={() => [
                                                                  setOpenMode(
                                                                    true
                                                                  ),
                                                                  // setAssignmentId(
                                                                  //   assessment.assignment_id
                                                                  // ),
                                                                ]}
                                                              />
                                                            </i>
                                                          </a> */}
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </InfiniteScroll>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          centered
          show={openMode}
          onHide={() => setOpenMode(false)}
          animation={true}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="borderModal"></div>
            <p>Are you sure you want to delete ?</p>

            {/* <div className="borderModalBelow"></div> */}
          </Modal.Body>
          <div className="borderModal"></div>
          <Modal.Footer>
            <div className="modalBtns">
              <button className="cancelBtn" onClick={() => setOpenMode(false)}>
                Cancel
              </button>
              <button
                className="YesBtn"
                onClick={() => [setOpenMode(false), DeleteAssignment()]}
              >
                Delete
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OfflineAssesmentHome;
