import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Jutsu } from 'react-jutsu';
import Params from '../config/Params';
import { simplePostCall } from '../api/ApiServices';
import ApiConfig from '../api/ApiConfig';
import SpinnerCmp from '../components/SpinnerCmp';
import { toast } from "react-toastify";

const Conference = () => {
    /*const [room, setRoom] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')*/

    const [call, setCall] = useState(false);
    const [state, setState] = useState({ isProcessing: true });
    const [jitsiState, setJitsiState] = useState({
        roomName: '',
        displayName: '',
        //password: 'dattebayo',
        subject: '',
        parentNode: 'jitsi-container',
        class_division_id: 0,
    });

    const location = useLocation();

    console.log("location", location);

    document.getElementById("main_nav").classList.add("close_icon");
    document.getElementById("page_container").style.marginLeft = "0px";
    document.getElementById("page_container").style.padding = "0px";
    document.getElementById("the_footer").style.marginLeft = "0px";

    /* load jitsi api on page load starts */
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://" + location.state.jitsi_domain + "/external_api.js";
    document.head.appendChild(script);
    /* load jitsi api on page load ends */

    function getConferenceData() {
        setState({ isProcessing: true });
        const location_state = location.state;
        var class_division_id = location_state.class_division_id;

        let newRequestBody = JSON.stringify({
            customer_id: Params.customer_id,
            api_key: Params.api_key,
            teacher_id: Params.teacher_id,
            class_division_id: class_division_id
        });

        simplePostCall(ApiConfig.CONFERENCE_API, newRequestBody)
            .then((data) => {
                if (data.result) {
                    initiateConference(data, class_division_id);
                }
                setState({ isProcessing: false });
            })
            .catch((error) => {
                setState({ isProcessing: false });
                console.log("api response", error);
            });
    }

    function initiateConference(conferenceData, class_division_id) {

        setJitsiState({
            roomName: conferenceData.roomName,
            displayName: conferenceData.user.user_name,
            //password: 'dattebayo',
            subject: conferenceData.subject,
            parentNode: 'jitsi-container',
            class_division_id: class_division_id,
        });

        setCall(true);
    }

    function endMeeting(class_division_id) {
        setState({ isProcessing: true });
        setCall(false);

        let newRequestBody = JSON.stringify({
            customer_id: Params.customer_id,
            api_key: Params.api_key,
            teacher_id: Params.teacher_id,
            class_division_id: class_division_id
        });

        simplePostCall(ApiConfig.END_CONFERENCE_API, newRequestBody)
            .then((data) => {
                if (data.result) {
                    toast.success(data.message, {
                        autoClose: 2000
                    });
                    setTimeout(() => {
                        window.location.replace("/");
                    }, 2000);
                }
                setState({ isProcessing: true });
            })
            .catch((error) => {
                setState({ isProcessing: false });
                console.log("api response", error);
            });
    }

    useEffect(() => {
        getConferenceData();

    }, []);

    return call ? (
        <>

            <Jutsu
                subject={jitsiState.subject}
                roomName={jitsiState.roomName}
                displayName={jitsiState.displayName}
                //password={password}
                onMeetingEnd={() => endMeeting(jitsiState.class_division_id)}
                loadingComponent={<p>loading ...</p>}
                errorComponent={<p>Oops, something went wrong</p>}
                containerStyles={{ width: "100%", height: "calc(100vh - 76px)" }}
                enableWelcomePage={false}
            />
        </>
    ) : (
        <>
            <SpinnerCmp loading={state.isProcessing} />
        </>
    )
}

export default Conference;