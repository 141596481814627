import React, { useEffect } from 'react';
import reactDom from 'react-dom';
import { Link } from "react-router-dom";


import {
    Play, Video
} from "react-feather";
import ChannelList_data from '../../../api/ChannelList_data.json'


function Channel_Video() {
    useEffect(() => {
        document.title = "Edsys | Channel Video "
    }, [])
    return (
        <>
            {/* <!-- Container-fluid starts--> */}
            <div class="row channel-video-content">
                <div class="col-md-12">
                    <div class="card br-15">
                        <div class="card-body">
                            <video loop controls poster="https://s3-us-west-2.amazonaws.com/s.cdpn.io/29229/Big%20Buck%20Bunny%20poster.jpg" src="https://player.vimeo.com/external/194837908.sd.mp4?s=c350076905b78c67f74d7ee39fdb4fef01d12420&profile_id=164"></video>
                            <div class="about-video">
                                <h4>Video title</h4>
                                <p class="video-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                            <div class="upcoming-block">
                                <h5>Upcoming Videos</h5>
                                <div class="row channel-list">
                                    {ChannelList_data.map((postDetail, index) => {
                                        return (
                                            <div class="col-md-3">
                                                <div class="card br-15">
                                                    <div class="card-body">
                                                        <div class="channel-video">
                                                            <img src={postDetail.Image_set} />
                                                            <Link to='/Channel_Video' className="nav-link me">
                                                                <Play class="play-button" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer">
                                                        <div class="channel-class">{postDetail.channel}</div>
                                                        <div class="video-title">{postDetail.title}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </>
    )
}

export default Channel_Video

