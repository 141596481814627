// import Header from '../src/components/Header'
import React, { Suspense, useEffect, useState } from "react";
import Layout from "../src/screens/Layout";
import Assignment from "./screens/academics/Assignment";
import LoginTwo from "./screens/login/Login";
import { BrowserRouter, Route, Switch, Routes } from "react-router-dom";
import authContext from "./screens/login/GetUserStatus";

const Login = React.lazy(() => import("./screens/login/Login"));

function App() {
  let ActiveComponent;
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("is_logged_in")),
  );
  if (authenticated) {
    ActiveComponent = (
      <div className="App page-wrapper compact-wrapper" id="pageWrapper">
        <Layout />
      </div>
    );
  } else {
    ActiveComponent = (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* {routes} */}
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }

  return (
    <authContext.Provider value={{ authenticated, setAuthenticated }}>
      {ActiveComponent}
    </authContext.Provider>
  );
}

export default App;

/*

// import Header from '../src/components/Header'
import Layout from '../src/screens/Layout'
import Assignment from './screens/academics/Assignment';
import LoginTwo from './screens/login/Login';


function App() {
  return (
    <div className="App page-wrapper compact-wrapper" id="pageWrapper">
     

      <Layout />
     
      
    </div>
  );
}

export default App;


*/
