import React, { useEffect, useState } from "react";
import {
  Video,
  FileText,
  Image,
  File,
  Headphones,
  Trash2,
  Edit3,
  Eye,
  PlusCircle,
  Mail,
} from "react-feather";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import SpinnerCmp from "../../../components/SpinnerCmp";
import Params from "../../../config/Params";
import "../../../assets/css/select2.css";
import ConfirmationModel from "../../../components/ConfirmationModel";
import { Link, useNavigate } from "react-router-dom";
import AddEditEmail from "./AddEditEmail";
import { useTranslation } from "react-i18next";
import filter from "../../../assets/images/filter.svg";
import ReactSelect from "react-select";
import CommunicationSkeleton from "../../../components/skeletons/CommunicationSkeleton";

function Email() {
  const [emailList, setEmailList] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [addEditModelData, setAddEditModelData] = useState({
    mode: "",
    editData: {},
    isModel: false,
  });
  const [deleteId, setDeleteId] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);

  const [isloading, setIsloading] = useState(true);

  const postAPICall = (uri, dataObj, actionFor) => {
    simplePostCall(uri, JSON.stringify(dataObj))
      .then((data) => {
        setIsloading(false);
        if (data.result) {
          if (actionFor === "email") {
            setEmailList(data.list);
            setIsNextPage(data.next_page);
          }
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("api response", error);
      });
  };
  function getEmailList() {
    postAPICall(
      ApiConfig.EMAIL_LIST_API,
      {
        ...Params,
        employee_id: Params.teacher_id,
        page: pageIndex,
      },
      "email"
    );
  }
  const onActionHandle = (action, obj) => {
    switch (action.toLowerCase()) {
      case "edit":
        setAddEditModelData({ mode: "edit", editData: obj, isModel: true });
        break;

      default:
        break;
    }
  };
  const onHandleAdd = (isAdded) => {
    setAddEditModelData({
      mode: "add",
      editData: {},
      isModel: !addEditModelData.isModel,
    });
    if (isAdded) getEmailList();
  };

  useEffect(() => {
    document.title = "Edsys | Email ";
    getEmailList();
  }, []);
  useEffect(() => {
    getEmailList();
  }, [pageIndex]);
  const classOptions = [
    { value: "6th A", label: "6th A" },
    { value: "10th A", label: "10th A" },
    { value: "12th A", label: "12th A" },
  ];
  const namesOption = [
    { value: "Hamdan", label: "Hamdan" },
    { value: "Shivam", label: "Shiva" },
    { value: "Sarin", label: "Sarin" },
  ];
  return (
    <>
      {/* Container-fluid starts */}
      <div class="row announcement assignment-sec">
        <div className="col-sm-12">
          {isloading ? (
            <CommunicationSkeleton loading={isloading} />
          ) : (
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">{t("Email")}</h5>
                  <a
                    type="button"
                    data-bs-toggle="modal"
                    onClick={onHandleAdd}
                    data-bs-target="#exampleModal"
                    className="btn btn-edsys-primary"
                  >
                    <PlusCircle className="me-2" />
                    <span>{t("Add Email")}</span>
                  </a>
                  {addEditModelData.isModel ? (
                    <AddEditEmail
                      data={addEditModelData}
                      onClose={onHandleAdd}
                    />
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div class="card-body p-0">
                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        // onChange={onInputChangeHandle}
                        name="employee_leave_from_date"
                        // value={state.employee_leave_from_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="card-body p-0">
                      <ReactSelect
                        class="js-example-basic-single form-select"
                        // value={state.selectedCurriculum}
                        // onChange={onCurriculumChange}
                        options={namesOption}
                        placeholder="filter By Subject..."
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex">
                    <div class="card-body p-0">
                      <ReactSelect
                        class="js-example-basic-single form-select"
                        // value={state.selectedCurriculum}
                        // onChange={onCurriculumChange}
                        options={classOptions}
                        placeholder="filter By Topic..."
                      />
                    </div>
                    <div className="col text-end">
                      <div class="card-body p-0">
                        <img src={filter} alt="" />
                      </div>
                    </div>
                  </div>
                  {emailList.map((email, index) => {
                    return (
                      <div className="col-md-4 mt-4" key={index}>
                        <div class="card stack-card br-15">
                          <div class="card-body">
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0">
                                <div class="ico-n">
                                  {/* <img src={announcement.icon_set} /> */}
                                  <Mail />
                                  {/* <Image /> */}
                                </div>
                              </div>
                              <div
                                // onClick={() => {
                                //   navigate("/ViewAnnouncement", {
                                //     state: email,
                                //   });
                                // }}
                                className="flex-grow-1 ms-3"
                              >
                                <h5>{email.email_parent_subject}</h5>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      email.email_parent_message.length > 50
                                        ? email.email_parent_message.slice(
                                            0,
                                            49
                                          ) + "..."
                                        : email.email_parent_message,
                                  }}
                                />
                              </div>
                            </div>

                            <div className=" card-footer">
                              <div className=" date-footer d-sm-flex">
                                {email.email_parent_datetime}

                                <div className="action-list">
                                  <ul className="list-unstyled">
                                    <li>
                                      <div
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal2"
                                        className="setting-secondary"
                                      >
                                        <Link to="/view-email" state={email}>
                                          <Eye height={16} />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setPageIndex(pageIndex - 1);
                    // getAnnouncements(pageIndex+1);
                  }}
                  disabled={pageIndex === 1}
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (isNextPage) {
                      setPageIndex(pageIndex + 1);
                      // getAnnouncements(pageIndex+1);
                    }
                  }}
                  disabled={!isNextPage}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Container-fluid Ends */}
    </>
  );
}

export default Email;
