import React, { useEffect, useState } from "react";
import "../assets/css/main.css";
import WelcomeCard from "../components/WelcomeCard";
import CourseCountCard from "../components/CourseCountCard";
import AssignmentsTable from "../components/AssignmentsTable";
import AssessmentsTable from "../components/AssessmentsTable.js";
import LessonsSlider from "../components/LessonsSlider";
import ApiConfig from "../api/ApiConfig";
import { simplePostCall } from "../api/ApiServices";
import Params from "../config/Params";
import DashboardSkeleton from "../components/skeletons/DashboardSkeleton";

function Dashboard() {
  const [state, setState] = useState({
    isProcessing: false,
  });
  const [assignmentState, setAssignmentState] = useState();
  const [assessmentState, setAssessmentState] = useState();
  const [timetableState, setTimetableState] = useState();
  const [initialSlideState, setInitialSlideState] = useState();
  const [countBlockState, setCountBlockState] = useState();
  const [region, setRegion] = useState("");
  const [assessmentAssignmentCountsState, setAssessmentAssignmentCountsState] =
    useState({ assignment_count: 0, assessment_count: 0 });
  const [jitsiDomainState, setJitsiDomainState] = useState();

  function getDashboardData() {
    setState({ isProcessing: true });
    console.log(ApiConfig.DASHBOARD_API, JSON.stringify(Params), "dashboard");
    simplePostCall(ApiConfig.DASHBOARD_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          console.log(data.region, "blockelements");
          setRegion(data.region);
          setAssignmentState(data.assignment_list);
          setAssessmentState(data.assessment_list);
          setTimetableState(data.timetable_details);
          setCountBlockState(data.blocks_elements);
          setAssessmentAssignmentCountsState({
            assignment_count: data.assignment_count,
            assessment_count: data.assessment_count,
          });
          setJitsiDomainState(data.jitsi_domain);
          setInitialSlideState(data.initial_slide);
        }
        setState({ isProcessing: false });
      })
      .catch((error) => {
        setState({ isProcessing: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    document.title = "Edsys";
    getDashboardData();
  }, []);
  return (
    <>
      {state.isProcessing && <DashboardSkeleton />}
      <div style={{ display: state.isProcessing ? "none" : "" }}>
        {/* Container-fluid starts  */}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8">
              <div className="row my-status">
                <CourseCountCard counts_data={countBlockState} />
              </div>
            </div>
            <div className="col-md-4">
              <WelcomeCard region={region} />
            </div>
            {/* <div className="col-md-14">
              <Dummy2 />
            </div> */}
          </div>
        </div>
        <LessonsSlider
          timetable_data={timetableState}
          initial_slide={initialSlideState}
          jitsi_domain={jitsiDomainState}
        />

        <AssignmentsTable
          assignment_data={assignmentState}
          assignment_count={assessmentAssignmentCountsState.assignment_count}
        />

        <AssessmentsTable
          assessment_data={assessmentState}
          assessment_count={assessmentAssignmentCountsState.assessment_count}
        />
        {/* Container-fluid Ends */}
      </div>
    </>
  );
}

export default Dashboard;
