import React, { useEffect, useState } from "react";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Select from "react-select";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function EditSubjectwiseList() {
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [exams, setExams] = useState([]);
  const [maxMark, setMaxmark] = useState([]);
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });
  const location = useLocation();
  const nav = useNavigate();
  useEffect(async () => {
    console.log(location.state);
    getSubjectMarklist(location.state.subject.value);
    GradeListApi();
    SubjectListApi(location.state.grade.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.academicYear,
      grade: location?.state?.grade,
      term: location?.state?.term,
      subject: location?.state?.subject,
    }));
  }, []);

  // const AcademicYrListApi = () => {
  //   let newRequestBody = JSON.stringify({
  //     api_key: Params.api_key,
  //     customer_id: Params.customer_id,
  //   });
  //   simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
  //     .then((data) => {
  //       if (data.result == true) {
  //         // console.log(data.message, "Message academicyrlist");
  //         const newData = data.data?.map((item) => {
  //           return {
  //             label: item.academic_year_name,
  //             value: item.academic_year_id,
  //             ...item,
  //           };
  //         });
  //         setAcademicYearList(newData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("api response", error);
  //     });
  // };

  // const handleYearChange = (year) => {
  //   console.log("Seleected Acad Year", year);
  //   if (year.value !== formData.academicYear.value) {
  //     // setAcademicYear(value);
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       academicYear: year,
  //       term: null,
  //     }));
  //     setTermList([]);
  //     // TermListApi(year.value);
  //   }
  // };

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleGradeChange = (grade) => {
    if (grade.value !== formData.grade.value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        grade: grade,
        subject: null,
      }));
      setSubList([]);
      SubjectListApi(grade.value);
    }
  };

  // const TermListApi = (academic_year) => {
  //   let newRequestBody = JSON.stringify({
  //     api_key: Params.api_key,
  //     customer_id: Params.customer_id,
  //     terms_academic_year: academic_year,
  //   });
  //   simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
  //     .then((data) => {
  //       if (data.result === true) {
  //         console.log("Term Data", data.data);
  //         const newData = data.data?.map((item) => {
  //           return { label: item.terms_name, value: item.terms_id, ...item };
  //         });
  //         setTermList(newData);
  //       } else {
  //         console.error("API returned false result:", data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching term list:", error);
  //     });
  // };

  // const handleTermChange = (term) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     term: term,
  //   }));
  //   console.log("Selected Term", term);
  // };

  const SubjectListApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: [classId],
      assessment_offline_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log("Subjects:", data.list);
          const newData = data.list?.map((item) => {
            return {
              label: item.subject_name,
              value: item.assessment_subject,
              ...item,
            };
          });
          setSubList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleSubjectChange = (subject) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: subject,
    }));
    console.log("Selected Subject", subject);
  };

  const getSubjectMarklist = (subjectId) => {
    setStudents([]);
    if (!subjectId) {
      toast.error("Please Select Subject");
      return;
    }
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_offline_id: location.state?.offlineId,
      term: formData.term?.value
        ? formData.term.value
        : location.state.term.value,
      session: formData.academicYear?.value
        ? formData.academicYear?.value
        : location.state.academicYear.value,
      subject_id: subjectId,
      grade_id: formData.grade?.value
        ? formData.grade?.value
        : location.state.grade.value,
    });
    simplePostCall(ApiConfig.SUBJECT_WISE_MARKLIST, newRequestBody)
      .then((data) => {
        console.log(data.data, "working");
        const name_ids = Object.keys(data.data); //storing student_ids
        let newData = [];
        let Internal_exams = [];

        name_ids.map((id) => {
          let examObjects = {
            student_name: data.data[id].student_name,
            student_id: id,
            subject_name: data.data[id].subject_name,
            Internal_exam: data.data[id].Internal_exam,
            student_profile: data.data[id].student_profile,
            subject_feedback: data.data[id].subject_feedback,
            // Exam: data.data[id].Exam[0],
            Total: data.data[id].Total[0],
          };
          const Internal_exam = data.data[id].Internal_exam;
          console.log(data.data[id].Internal_exam, "internalname");
          Internal_exam.map((exam) => {
            Internal_exams.push(exam.evaluation_type_code);
          });
          setMaxmark(Internal_exam);
          newData.push(examObjects);
          console.log("ExamObj", examObjects);
        });
        Internal_exams = [...new Set(Internal_exams)];
        console.log(newData);

        setExams(Internal_exams);
        setStudents(newData);
        // console.log(newData, "newData");
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleInternalExamChange = (e, studentIndex, examIndex) => {
    const { value } = e.target;
    const updatedStudents = [...students];
    updatedStudents[studentIndex].Internal_exam[examIndex].mark =
      parseInt(value);
    setStudents(updatedStudents);
  };
  const handleFeedbackExamChange = (e, studentIndex, examIndex) => {
    const { value } = e.target;
    const updatedStudents = [...students];
    updatedStudents[studentIndex].subject_feedback = value;
    console.log(updatedStudents, "updadetedStudents");
    setStudents(updatedStudents);
  };
  const handleExternalExamChange = (e, studentIndex) => {
    const { value } = e.target;

    const updatedStudents = [...students];
    updatedStudents[studentIndex].Exam.mark = parseInt(value);
    setStudents(updatedStudents);
    console.log(updatedStudents, "value");
  };

  const handleSubmit = () => {
    let changedMarks = [];
    students.map((student) => {
      student.Internal_exam.map((internalExam) => {
        if (internalExam.assessment_id) {
          console.log(internalExam, "students");
          changedMarks.push({
            assessment_id: internalExam.assessment_id,
            student_id: student.student_id,
            result_id: internalExam.result_id,
            assessment_tittle: internalExam.evaluation_type_code,
            subject_feedback: student.subject_feedback,
            result_mark:
              parseInt(internalExam.mark) >
              parseInt(internalExam.assessment_max_mark)
                ? internalExam.assessment_max_mark
                : internalExam.mark,
          });
        }
      });
      if (student?.Exam?.assessment_id) {
        console.log(student, "ExamStudents");
        changedMarks.push({
          assessment_id: student.Exam.assessment_id,
          student_id: student.student_id,
          result_id: student.Exam.results_id,
          subject_feedback: student.subject_feedback,
          result_mark:
            parseInt(student.Exam.mark) >
            parseInt(student.Exam.assessment_max_mark)
              ? student.Exam.assessment_max_mark
              : student.Exam.mark,
        });
      }
    });
    let editRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      result: changedMarks,
      user_id: Params.teacher_id,
      status: "",
    });
    if (changedMarks.length > 0) {
      const EditApi = simplePostCall(
        ApiConfig.EDIT_SUBJECT_WISE_MARKLIST,
        editRequestBody,
      );
      EditApi.then((response) => {
        if (response[0].result) {
          toast.success(response[0]?.message);
          // setStudents([]);
          getSubjectMarklist(location.state.subject.value);
          nav("/SubjectwiseList", {
            state: {
              offlineId: location.state?.offlineId,
              academicYear: formData.academicYear,
              term: formData.term,
              SemExam: location?.state?.SemExam,
              grade: formData.grade,
              subject: formData.subject,
            },
          });
        } else {
          toast.error(response[0].message);
        }
      }).catch((error) => {
        console.error("Error:", error);
      });
    } else {
      toast.error("No changes to publish");
    }
  };
  const draftSubmit = () => {
    let changedMarks = [];
    students.map((student) => {
      student.Internal_exam.map((internalExam) => {
        if (internalExam.assessment_id) {
          console.log(internalExam, "students");
          changedMarks.push({
            assessment_id: internalExam.assessment_id,
            student_id: student.student_id,
            result_id: internalExam.result_id,
            assessment_tittle: internalExam.evaluation_type_code,
            subject_feedback: student.subject_feedback,
            result_mark:
              parseInt(internalExam.mark) >
              parseInt(internalExam.assessment_max_mark)
                ? internalExam.assessment_max_mark
                : internalExam.mark,
          });
        }
      });
      if (student?.Exam?.assessment_id) {
        console.log(student, "ExamStudents");
        changedMarks.push({
          assessment_id: student.Exam.assessment_id,
          student_id: student.student_id,
          result_id: student.Exam.results_id,
          subject_feedback: student.subject_feedback,
          result_mark:
            parseInt(student.Exam.mark) >
            parseInt(student.Exam.assessment_max_mark)
              ? student.Exam.assessment_max_mark
              : student.Exam.mark,
        });
      }
    });
    let editRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      status: "draft",
      result: changedMarks,
      user_id: Params.teacher_id,
    });
    if (changedMarks.length > 0) {
      const EditApi = simplePostCall(
        ApiConfig.EDIT_SUBJECT_WISE_MARKLIST,
        editRequestBody,
      );
      EditApi.then((response) => {
        if (response[0].result) {
          toast.success(response[0]?.message);
          // setStudents([]);
          getSubjectMarklist(location.state.subject.value);
          nav("/SubjectwiseList", {
            state: {
              offlineId: location.state?.offlineId,
              academicYear: formData.academicYear,
              term: formData.term,
              SemExam: location?.state?.SemExam,
              grade: formData.grade,
              subject: formData.subject,
            },
          });
        } else {
          toast.error(response[0].message);
        }
      }).catch((error) => {
        console.error("Error:", error);
      });
    } else {
      toast.error("No changes to publish");
    }
  };
  const base_url = "https://app.edsys.in/";
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Edit Subject wise List</h5>
              </div>
            </div>
            {/* <div className="offmarkcad col-md-12 row "> */}
            <div className="container row">
              {/* <SubjectCusDrop /> */}

              <div className="col">
                <p className="DropTxt">Academic year</p>
                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.academicYear?.label}
                </div>
              </div>
              <div className="col">
                <p className="DropTxt">Term</p>

                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.term?.label}
                </div>
              </div>
              <div className="col">
                <p className="DropTxt">Exam</p>
                <div
                  style={{
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {location?.state?.SemExam}
                </div>
              </div>

              <div className="col">
                <p className="DropTxt">Select Grade Division</p>
                <Select
                  class="js-example-basic-single form-select"
                  name="grade"
                  value={formData.grade}
                  onChange={handleGradeChange}
                  options={gradeList}
                />
              </div>

              <div className="col">
                <p className="DropTxt">Select Subject</p>
                <Select
                  class="js-example-basic-single form-select"
                  name="subject"
                  value={formData.subject}
                  onChange={handleSubjectChange} // Update the onChange handler
                  options={subList}
                />
              </div>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <button
                  className="view-mark-list-button col-md-3"
                  onClick={() => getSubjectMarklist(formData.subject?.value)}
                >
                  View Mark List
                </button>
              </div>

              <p className="p-10 "></p>

              {students.length > 0 && (
                <div className="col">
                  <div className="d-flex">
                    <h5 className="flex-grow-1 ml-30">Mark List</h5>
                  </div>
                  <div className="table">
                    <table>
                      <thead className="thead">
                        <tr>
                          <th style={{ color: "#fff", textAlign: "center" }}>
                            SI. No
                          </th>
                          <th style={{ color: "#fff", textAlign: "center" }}>
                            Student Name
                          </th>
                          {/* <th style={{ color: "#fff", textAlign: "center" }}>
                          Rubric
                        </th> */}
                          {/* <th style={{ color: "#fff", textAlign: "center" }}>
                            Subject
                          </th> */}
                          {maxMark.map((exam, index) => (
                            <React.Fragment key={index}>
                              <th
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                {exam.evaluation_type_code} Mark (
                                {exam.assessment_max_mark})
                              </th>
                              {/* <th style={{ color: "#fff", textAlign: "center" }}>
                              Max Mark
                            </th> */}
                            </React.Fragment>
                          ))}
                          {/* <th style={{ color: "#fff", textAlign: "center" }}>
                          Exam Mark ({students[0]?.Exam?.assessment_max_mark})
                        </th> */}
                          {/* <th style={{ color: "#fff", textAlign: "center" }}>
                        Max Mark
                      </th> */}
                          {exams.length > 0 && (
                            <th style={{ color: "#fff", textAlign: "center" }}>
                              Total ({students[0]?.Total?.totalMaxMark})
                            </th>
                          )}
                          {/* <th style={{ color: "#fff", textAlign: "center" }}>
                          Max Mark
                        </th> */}
                          <th style={{ color: "#fff", textAlign: "center" }}>
                            Feedback
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {students.map((item, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>
                              <img
                                className="rounded-circle"
                                src={
                                  item.student_profile
                                    ? base_url + item.student_profile
                                    : dashboardAvatar
                                }
                                alt="image"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  marginRight: "10px",
                                }}
                                onError={(e) => {
                                  e.target.src = dashboardAvatar;
                                }}
                              />
                              {item.student_name}
                            </td>
                            {/* <td style={{ textAlign: "center" }}>
                            {item.subject_name}
                          </td> */}
                            {/* <td style={{ textAlign: "center" }}>{item?.rubic}</td> */}

                            {item.Internal_exam.map((exam, i) => (
                              <React.Fragment key={i}>
                                <td style={{ textAlign: "center" }}>
                                  <input
                                    // disabled={exam.result_id ? false : true}
                                    style={{ border: 0 }}
                                    value={exam.mark}
                                    type="number"
                                    min="0"
                                    max={exam.assessment_max_mark}
                                    onChange={(e) =>
                                      handleInternalExamChange(e, index, i)
                                    }
                                  />
                                </td>
                                {/* <td style={{ textAlign: "center" }}>
                                {exam.assessment_max_mark}
                              </td> */}
                              </React.Fragment>
                            ))}

                            {/* <td style={{ textAlign: "center" }}>
                            <input
                              // disabled={item.Exam.results_id ? false : true}
                              value={item.Exam.mark}
                              type="number"
                              min="0"
                              max={item.Exam.assessment_max_mark}
                              onChange={(e) =>
                                handleExternalExamChange(e, index)
                              }
                            />
                          </td> */}
                            {/* <td style={{ textAlign: "center" }}>
                          {item.Exam.assessment_max_mark}
                        </td> */}
                            {exams.length > 0 && (
                              <td style={{ textAlign: "center" }}>
                                {item.Total.totalMark}
                              </td>
                            )}

                            <React.Fragment>
                              <td style={{ textAlign: "center" }}>
                                <input
                                  style={{ border: 0 }}
                                  // disabled={exam.result_id ? false : true}
                                  value={item?.subject_feedback}
                                  type="text"
                                  onChange={(e) =>
                                    handleFeedbackExamChange(e, index)
                                  }
                                />
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {exam.assessment_max_mark}
                              </td> */}
                            </React.Fragment>

                            {/* <td style={{ textAlign: "center" }}>
                            {item.Total.totalMaxMark}
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "10px",
                      }}
                    >
                      <button
                        className="view-mark-list-button col-md-3"
                        onClick={draftSubmit}
                      >
                        Draft
                      </button>
                    </div>
                    <div
                      style={{
                        // width: "20%",
                        // display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="view-mark-list-button col-md-12"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* <button className="view-mark-list-button1 ">Submit</button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default EditSubjectwiseList;
