import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Play, ExternalLink } from "react-feather";
import ChannelList_data from "../../../api/ChannelList_data.json";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next"; 
import ChannelListSkeleton from "../../../components/skeletons/ChannelListSkeleton";

function ChannelList() {
  const { t } = useTranslation();

  const [channelListState, setChannelListState] = useState({
    isloading: false,
    channelList: [],
    key: "",
    channel_ip: ""
  });

  const channelClassStyle = {
    paddingTop: "5%",
    paddingBottom: "5%",
    fontSize: "24px"
  }

  function getChannelList() {
    setChannelListState({ ...channelListState, isloading: true });

    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id
    });
    simplePostCall(ApiConfig.CHANNEL_LIST_API, requestBody)
      .then((data) => {
        if (data.result) {
          setChannelListState({
            ...channelListState,
            isloading: false,
            channelList: data.class_details,
            key: data.key,
            channel_ip: data.channel_ip
          });
        } else {
          setChannelListState({ ...channelListState, isloading: false });
        }
      })
      .catch((error) => {
        setChannelListState({ ...channelListState, isloading: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    document.title = "Edsys | Channel List ";
    getChannelList();
  }, []);
  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <h4>{t('Channel List')}</h4>
      {channelListState.isloading ? <ChannelListSkeleton /> :
        <div class="row channel-list mt-4">

          {channelListState.channelList.length > 0 ? channelListState.channelList.map((channel, index) => {
            return (
              <div class="col-md-3" key={index}>
                <div class="card br-15">
                  <div class="card-body">
                    <div class="channel-video">
                      {/*<img src={channel.Image_set} />
                    <Link to="/Channel_Video" className="nav-link me">
                      <Play class="play-button" />
                    </Link>*/}
                      <a target="_blank" href={"http://" + channelListState.channel_ip + "/#/dashboard?schoolId=" + Params.customer_id + "&teacherId=" + Params.teacher_id + "&divisionId=" + channel.class_division_id + "&key=" + channelListState.key}>
                        {t('Schedule Channel')} <ExternalLink style={{ width: "15px", height: "15px" }} />
                      </a><br /><br />
                      <a target="_blank" href={"http://" + channelListState.channel_ip + "/#/school/ref?id=" + Params.customer_id + "&userId=" + Params.user_id + "&divisionId=" + channel.class_division_id + "&key=" + channelListState.key}>
                        {t('View Channel')} <ExternalLink style={{ width: "15px", height: "15px" }} />
                      </a>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="channel-class" style={channelClassStyle}>{channel.class_name + " - " + channel.class_division_name}</div>
                    <div class="video-title">{/*channel.class_name + " " + channel.class_division_name*/}</div>
                  </div>
                </div>
              </div>
            );
          }) : <div>
            <p>{t("View Channel")}</p>
          </div>}
        </div>
      }
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
}

export default ChannelList;
