import React, { useState, useEffect } from "react";
import calender_3 from "../../assets/images/svg/calendar_3.svg";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import moment from "moment";
import SpinnerCmp from "../../components/SpinnerCmp";
import AvailedLeaves from "./AvailedLeaves";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyResources_new from "../E_Learnin_new/MyResources_new";
import MyLeaves from "../Leaves/My_Leaves";
import { useTranslation } from "react-i18next";

function EmpLeaves() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isTabShow, SetisTabShow] = useState(false);

  const Showmodal = () => {
    SetisTabShow(true);
  };
  const [state, setState] = useState({
    isloading: false,
    employee_leave_from_date: "",
    employee_leave_to_date: "",
    employee_leave_no_of_days: "",
    employee_leave_reason: "",
    employee_leave_type: "full-day",
    employee_leave_category_id: "",
    reporting_person: "",
  });
  const [leaveDetailsState, setLeaveDetailsState] = useState({
    isloading: true,
    leave_categories: [],
    leave_count_details: [],
    leave_types: [],
    reporting_to: [],
  });
  const [leaveOptionTypeIdState, setLeaveOptionTypeIdState] = useState([
    "option-one",
    "option-two",
    "option-three",
  ]);

  const onInputChangeHandle = (e) => {
    const { name, value } = e.target;
    let days = 0;
    let isdateUpdated = false;
    if (name === "employee_leave_to_date" && state.employee_leave_from_date) {
      isdateUpdated = true;
      days = getDaysDiff(state.employee_leave_from_date, value);
    } else if (
      name === "employee_leave_from_date" &&
      state.employee_leave_to_date
    ) {
      isdateUpdated = true;
      days = getDaysDiff(value, state.employee_leave_to_date);
    }
    if (days < 0) {
      toast.error("From Date should not be greater then To Date");
      return false;
    }
    if (isdateUpdated) {
      setState({
        ...state,
        [name]: value,
        employee_leave_no_of_days: days === 0 ? 1 : days,
      });
    } else setState({ ...state, [name]: value });
  };
  const getDaysDiff = (fromDate, toDate) => {
    var startDate = moment(fromDate, "YYYY-MM-DD");
    var endDate = moment(toDate, "YYYY-MM-DD");
    return endDate.diff(startDate, "days");
  };

  function getAppyLeaveDetails() {
    setLeaveDetailsState({ ...leaveDetailsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      employee_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.EMPLOYEE_APPLY_LEAVE_DETAILS, newRequestBody)
      .then((data) => {
        if (data.result) {
          setLeaveDetailsState({
            ...leaveDetailsState,
            isloading: false,
            leave_categories: data.leave_categories,
            leave_count_details: data.leave_count_details,
            leave_types: data.leave_types,
            reporting_to: data.reporting_to,
          });
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLeaveDetailsState({ ...leaveDetailsState, isloading: false });
        console.log("api response", error);
      });
  }

  const onApplyLeave = () => {
    if (onValidateFiled()) {
      setState({ ...state, isloading: true });

      let newRequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: JSON.stringify(Params.customer_id),
        employee_id: JSON.stringify(Params.teacher_id),
        employee_leave_from_date: state.employee_leave_from_date,
        employee_leave_to_date: state.employee_leave_to_date,
        employee_leave_no_of_days: JSON.stringify(
          state.employee_leave_no_of_days,
        ),
        employee_leave_reason: state.employee_leave_reason,
        employee_leave_type: state.employee_leave_type,
        employee_leave_category_id: JSON.parse(
          state.employee_leave_category_id,
        ),
        reporting_person: JSON.parse(state.reporting_person),
      });
      simplePostCall(ApiConfig.EMPLOYEE_APPLY_LEAVE_API, newRequestBody)
        .then((data) => {
          if (data.result) {
            setState({
              isloading: false,
              employee_leave_from_date: "",
              employee_leave_to_date: "",
              employee_leave_no_of_days: "",
              employee_leave_reason: "",
              employee_leave_type: "full-day",
              employee_leave_category_id: "",
              reporting_person: "",
            });
            toast.success(data.message);
          } else {
            setState({ ...state, isloading: false });
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setState({ ...state, isloading: false });
          console.log("api response", error);
        });
    }
  };

  const onValidateFiled = () => {
    let isValid = true;
    let message = "";
    if (!state.employee_leave_category_id) {
      isValid = false;
      message = "Select Leave Category";
    } else if (!state.employee_leave_from_date) {
      isValid = false;
      message = "Select From Date";
    } else if (!state.employee_leave_to_date) {
      isValid = false;
      message = "Select To Date";
    } else if (!state.employee_leave_type) {
      isValid = false;
      message = "Select Leave Type";
    } else if (!state.reporting_person) {
      isValid = false;
      message = "Select Reporting Person";
    } else if (!state.employee_leave_reason) {
      isValid = false;
      message = "Enter Leave Reason";
    }
    if (!isValid) toast.error(message);
    return isValid;
  };

  useEffect(() => {
    document.title = "Edsys | Apply Leave";
    getAppyLeaveDetails();
  }, []);
  console.log("leaveDetailsState", leaveDetailsState);
  return (
    <>
      {leaveDetailsState.isloading || state.isloading ? (
        <SpinnerCmp />
      ) : (
        <div>
          {/* <!-- Container-fluid starts--> */}
          <div className="row apply-leave">
            <div className="col-12">
              <div className="card br-15">
                <div className="card-body header-card">
                  <div className="col-12">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          {t("Apply Leaves")}
                          <div className="media"></div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={Showmodal}
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          {t("My Leaves")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="student-header">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="details-banner ">
                          {leaveDetailsState.leave_count_details ? (
                            <div>
                              <h3>{t("Leave Status")}</h3>
                              <div className="row">
                                <div class="px-2 py-3">
                                  <div class="row gx-4">
                                    <div class="col">
                                      <div
                                        className="leave-status d-flex"
                                        onClick={() => {
                                          navigate("/AvailedLeaves");
                                        }}
                                      >
                                        <div className="flex-grow-1">
                                          <div className="d-flex justify-content-around">
                                            <div className="leaves-count ">
                                              {
                                                leaveDetailsState
                                                  .leave_count_details
                                                  .total_leaves
                                              }
                                            </div>
                                            <div className="leaves-count ">
                                              {
                                                leaveDetailsState
                                                  .leave_count_details
                                                  .leaves_balance
                                              }
                                            </div>
                                            <div className="leaves-count">
                                              {
                                                leaveDetailsState
                                                  .leave_count_details
                                                  .total_leaves_taken
                                              }
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-around">
                                            <div className="title">
                                              Availed Leaves
                                            </div>
                                            <div className="title">
                                              Leaves Balance
                                            </div>
                                            <div className="title">
                                              Leaves Taken
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <img src={calender_3} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div class="col">
                                      <div className="leave-status d-flex ">
                                        <div className="flex-grow-1">
                                          <div className="leaves-count">
                                            {
                                              leaveDetailsState
                                                .leave_count_details
                                                .leaves_balance
                                            }
                                          </div>
                                          <div className="title">
                                            Leaves Balance
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <img src={calender_3} />
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>

                                {/* <div className="col-md-6">
                                  <div className="chart-area">
                                    <div className="card--chart">
                                      <div
                                        className="js-donut-chart-container donut-chart-container"
                                        style={{
                                          height: "280px",
                                          width: "280px",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          ) : null}

                          <h3>{t("Apply for Leave")}</h3>
                          <form
                            className="form-bookmark needs-validation"
                            id="bookmark-form"
                            novalidate=""
                          >
                            <div className="form-row">
                              <div className="col-md-12 leve-form">
                                <div className="radio-group">
                                  {leaveDetailsState.leave_types ? (
                                    <>
                                      {leaveDetailsState.leave_types.map(
                                        (leave_type, index) => {
                                          return (
                                            <>
                                              <input
                                                type="radio"
                                                id={
                                                  leaveOptionTypeIdState[index]
                                                }
                                                name="employee_leave_type"
                                                value={leave_type.id}
                                                onChange={(e) => {
                                                  setState({
                                                    ...state,
                                                    employee_leave_type:
                                                      e.target.value,
                                                  });
                                                }}
                                                checked={
                                                  state.employee_leave_type ===
                                                  leave_type.id
                                                }
                                              />
                                              <label
                                                htmlFor={
                                                  leaveOptionTypeIdState[index]
                                                }
                                              >
                                                {leave_type.value}
                                              </label>
                                            </>
                                          );
                                        },
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label for="task-title">{t("From")}</label>
                                  <input
                                    className="datepicker-here form-control digits"
                                    type="date"
                                    data-language="en"
                                    placeholder="MM-DD-YYYY"
                                    data-position="top left"
                                    onChange={onInputChangeHandle}
                                    name="employee_leave_from_date"
                                    value={state.employee_leave_from_date}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label for="task-title">{t("To")}</label>
                                  <input
                                    className="datepicker-here form-control digits"
                                    type="date"
                                    data-language="en"
                                    placeholder="MM-DD-YYYY"
                                    data-position="top left"
                                    onChange={onInputChangeHandle}
                                    name="employee_leave_to_date"
                                    value={state.employee_leave_to_date}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="task-title">
                                  {t("Leave Category")}
                                </label>
                                <select
                                  className="js-example-basic-single form-select"
                                  onChange={onInputChangeHandle}
                                  name="employee_leave_category_id"
                                  value={state.employee_leave_category_id}
                                >
                                  <option>Select Leave Category</option>
                                  {leaveDetailsState.leave_categories.map(
                                    (leave_category, index) => {
                                      return (
                                        <option
                                          value={
                                            leave_category.leave_category_id
                                          }
                                        >
                                          {leave_category.leave_category_name}
                                        </option>
                                      );
                                    },
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="task-title">
                                  {t("Reporting Person")}
                                </label>
                                <select
                                  className="js-example-basic-single form-select"
                                  onChange={onInputChangeHandle}
                                  name="reporting_person"
                                  value={state.reporting_person}
                                >
                                  <option>Select Reporting Person</option>
                                  {leaveDetailsState.reporting_to.map(
                                    (reporting, index) => {
                                      return (
                                        <option value={reporting.user_id}>
                                          {reporting.user_name}
                                        </option>
                                      );
                                    },
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-md-12">
                                <label for="sub-task">
                                  {t("Enter Reason for applying leave")}
                                </label>
                                <textarea
                                  className="form-control"
                                  required=""
                                  autocomplete="off"
                                  onChange={onInputChangeHandle}
                                  name="employee_leave_reason"
                                  value={state.employee_leave_reason}
                                ></textarea>
                              </div>
                            </div>
                          </form>
                          <div className="card-footer">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={onApplyLeave}
                            >
                              {t("Apply Leave")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        {isTabShow ? <MyLeaves onClose={Showmodal} /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </div>
      )}
    </>
  );
}

export default EmpLeaves;
