import React, { useEffect, useState } from "react";
import "../../assets/css/GradewiseList1.css";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Value } from "sass";
import { toast } from "react-toastify";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const AlertModal = ({ message, onClose }) => {
  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-contents alert-modal">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <p>{message}</p>
        <div style={{ textAlign: "right" }}>
          <button onClick={onClose} className="btn btn-primary">
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const EditGradewiseList = () => {
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [errors, setErrors] = useState({});
  const [listMsg, setListMsg] = useState("");
  const [gradeview, setGradeview] = useState([]);
  const [gradeStudents, setGradeStudents] = useState([]);
  const [gradeMarkList, setGradeMarkList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [extracted, setExtracted] = useState([]);

  const location = useLocation();
  // const history = useHistory();
  // console.log(location?.state, "offlinwww");
  //...............Grade View Api.................//

  useEffect(() => {
    // AcademicYrListApi();
    AcademicYrListApi();

    GradeListApi();
    TermListApi(location?.state?.AcademicYear?.value);
    // console.log(location?.state, "STATEEEEDDD");
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.AcademicYear,
      grade: location.state.Grade,
      term: location?.state?.Term,
      offlineId: location?.state?.OfflineId,
    }));
    GradeViewApi(location.state?.Grade?.value);
  }, []);

  const GradeViewApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      assessment_offline_id: location.state?.OfflineId,
      class_division_id: classId,
    });
    simplePostCall(ApiConfig.GRADE_VIEW, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          const dataList = data.data;
          const students = [];
          const exams = [];
          dataList?.map((assessment) => {
            // console.log(assessment, "assessmentitem");
            let newSubject = {
              assessment_subject_id: assessment.assessment_subject_id,
              assessment_subject_name: assessment.assessment_subject_name,
              assessment_max_mark: assessment.assessment_max_mark,
              assessment_title: assessment.assessment_title,
              internals: [],
            };
            assessment.assessment_student?.map((student) => {
              const existingStudent = students?.find(
                (item) => item.student_id === student.student_id,
              );
              newSubject.subject_max_mark = student.assessment_max_mark;
              student.internal_exam?.map((iExam) => {
                const isExists = newSubject.internals.some(
                  (item) =>
                    item.evaluation_type_code === iExam.evaluation_type_code,
                );
                if (!isExists) {
                  newSubject.internals.push({
                    evaluation_type_code: iExam.evaluation_type_code,
                    max_mark: iExam.assessment_max_mark,
                  });
                }
              });
              //checking student already in students array and adding subject to student else adding student and subject
              if (existingStudent?.student_id === student.student_id) {
                // console.log(assessment, "assesment");
                existingStudent.exams = [
                  ...existingStudent.exams,
                  {
                    assessment_subject_id: assessment.assessment_subject_id,
                    assessment_subject_name: assessment.assessment_subject_name,
                    result_id: student.result_id,
                    student_id: student.student_id,
                    assessment_id: student.assessment_id,
                    student_mark: student.student_mark,
                    internal_exam: student.internal_exam,
                    student_profile: student.student_profile,
                  },
                ];
              } else {
                let newStudent = {};
                newStudent.student_id = student.student_id;
                newStudent.student_Name = student.student_Name;
                newStudent.student_profile = student.student_profile;
                newStudent.feedback = student.feedback;
                newStudent.exams = [
                  {
                    assessment_subject_id: assessment.assessment_subject_id,
                    assessment_subject_name: assessment.assessment_subject_name,
                    result_id: student.result_id,
                    student_id: student.student_id,
                    assessment_id: student.assessment_id,
                    student_mark: student.student_mark,
                    internal_exam: student.internal_exam,
                  },
                ];
                students.push(newStudent);
              }
            });
            exams.push(newSubject);
          });
          // console.log("Students Array \n", students, "\nExams\n", exams);
          setExamList(exams);
          setGradeMarkList(students);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  //...............Term List Api.................//

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          // console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  //...............Subject List Api.................//

  const SubjectListApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          // console.log("Subjects:", data.list);
          const newData = data.list?.map((item) => {
            return { label: item.subject_name, value: item.id, ...item };
          });
          setSubList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  //...............Grade List Api.................//

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location.state?.OfflineId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message GradeList");
          // console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  //...............Academic Yr List Api.................//

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          TermListApi(newData[0].value);
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  //...........Handle Functions.............//

  const handleYearChange = (year) => {
    // console.log("Seleected Acad Year", year);
    // setAcademicYear(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: year,
      term: null,
    }));
    setTermList([]);
    TermListApi(year.value);
  };

  const handleGradeChange = (grade) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: grade,
      subject: null,
    }));
  };

  const handleTermChange = (term) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      term: term,
    }));
    // console.log("Selected Term", term);
  };

  const [alertMessage, setAlertMessage] = useState(null);

  const showAlert = (message) => {
    setAlertMessage(message);
  };

  const closeAlert = () => {
    setAlertMessage(null);
  };

  // console.log(gradeMarkList, "marklisttttt");
  // console.log(examList, "examListtttt");
  // Function to handle input change
  // Function to handle input change
  const [dataofstud, setDataofstud] = useState([]);

  const handleMarkChange = (
    event,
    studentId,
    subjectId,
    assessmentId,
    type,
    evalCode = null,
  ) => {
    const { value } = event.target;
    // console.log(value, "Valuee", type);
    setGradeMarkList((prevGradeMarkList) => {
      const updatedGradeMarkList = prevGradeMarkList.map((student) => {
        if (student.student_id === studentId) {
          const updatedExams = student.exams.map((exam) => {
            if (exam.assessment_subject_id === subjectId) {
              if (type === "internal") {
                const updatedInternalExams = exam.internal_exam.map(
                  (internalExam) => {
                    if (internalExam.assessment_id === assessmentId) {
                      return {
                        ...internalExam,
                        student_result: value,
                      };
                    }
                    return internalExam;
                  },
                );

                return {
                  ...exam,
                  internal_exam: updatedInternalExams,
                };
              } else if (type === "main") {
                // console.log(exam.assessment_id, assessmentId, "equally");
                if (exam.assessment_id === assessmentId) {
                  return {
                    ...exam,
                    student_mark: value,
                  };
                }
              }
            }
            return exam;
          });

          return {
            ...student,
            exams: updatedExams,
          };
        }
        return student;
      });
      // console.log(updatedGradeMarkList, "updatedGradeMarkList");
      setGradeMarkList(updatedGradeMarkList);
      // console.log(gradeMarkList, "grademarkkk");
      return updatedGradeMarkList;
    });
  };
  const handleFeedBackChange = (event, studentId, type, evalCode = null) => {
    const { value } = event.target;
    setGradeMarkList((prevGradeMarkList) => {
      const updatedGradeMarkList = prevGradeMarkList.map((student) => {
        if (student.student_id === studentId) {
          if (type === "main") {
            return {
              ...student,
              feedback: value,
            };
          }
        }
        return { ...student };
      });
      setGradeMarkList(updatedGradeMarkList);
      console.log(updatedGradeMarkList, "updatedGradeMarkList");
      return updatedGradeMarkList;
    });
  };
  //...............Edit Grade Wise Api.................//
  const nav = useNavigate();

  const handleEditSubmit = () => {
    // console.log(gradeMarkList, "12333444gradeMarkList");
    // Extract the necessary data from updatedGradeMarkList
    const result = gradeMarkList.reduce((acc, student) => {
      student.exams?.forEach((exam) => {
        console.log(student, "studentssss");
        // console.log(acc, "exam789456");
        // For main exam
        acc.push({
          student_id: student.student_id,
          assessment_id: exam.assessment_id,
          result_mark: exam.student_mark,
          subject_feedback: student.feedback,
        });

        // For internal exams
        exam.internal_exam?.forEach((internalExam) => {
          acc.push({
            student_id: student.student_id,
            assessment_id: internalExam.assessment_id,
            result_mark: internalExam.student_result,
          });
          // console.log(internalExam, "internalExams");
        });
      });
      return acc;
    }, []);
    // console.log(result, "requestbody");
    // Prepare the payload for the API call
    const payload = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      status: "",
      user_id: Params.teacher_id,
      result: result,
    };

    // Make the API call to update the marks
    fetch(ApiConfig.OFFLINE_ASSESSMENT_GRADEWISE_EDIT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data[0].result === true) {
          toast.success(data[0].message);
          nav(
            "/GradewiseList",

            {
              state: {
                academicYear: location?.state?.AcademicYear,
                grade: location.state.Grade,
                term: location?.state?.Term,
                offlineId: location?.state?.OfflineId,
              },
            },
            { replace: true },
          );
        } else {
          toast.error(data[0].message);
        }
        // console.log("Edit API response:", data);
      })
      .catch((error) => {
        console.error("Error editing marks:", error);
        toast.error("An error occurred while editing marks.");
      });
  };
  const handleDraftSubmit = () => {
    // console.log(gradeMarkList, "12333444gradeMarkList");
    // Extract the necessary data from updatedGradeMarkList
    const result = gradeMarkList.reduce((acc, student) => {
      student.exams?.forEach((exam) => {
        // console.log(student, "studentssss");
        // console.log(acc, "exam789456");
        // For main exam
        acc.push({
          student_id: student.student_id,
          assessment_id: exam.assessment_id,
          result_mark: exam.student_mark,
          subject_feedback: student.feedback,
        });

        // For internal exams
        exam.internal_exam?.forEach((internalExam) => {
          acc.push({
            student_id: student.student_id,
            assessment_id: internalExam.assessment_id,
            result_mark: internalExam.student_result,
          });
          // console.log(internalExam, "internalExams");
        });
      });
      return acc;
    }, []);
    // console.log(result, "requestbody");
    // Prepare the payload for the API call
    const payload = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      status: "draft",
      user_id: Params.teacher_id,
      result: result,
    };

    // Make the API call to update the marks
    fetch(ApiConfig.OFFLINE_ASSESSMENT_GRADEWISE_EDIT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data[0].result === true) {
          toast.success(data[0].message);
          nav(
            "/GradewiseList",

            {
              state: {
                academicYear: location?.state?.AcademicYear,
                grade: location.state.Grade,
                term: location?.state?.Term,
                offlineId: location?.state?.OfflineId,
              },
            },
            { replace: true },
          );
        } else {
          toast.error(data[0].message);
        }
        // console.log("Edit API response:", data);
      })
      .catch((error) => {
        console.error("Error editing marks:", error);
        toast.error("An error occurred while editing marks.");
      });
  };
  const base_url = "https://app.edsys.in/";
  return (
    <div className="card p-4">
      <h1>Edit Grade Wise List</h1>

      <div className="container row">
        {/* Academic Year Select */}
        <div className="col">
          <p className="DropTxt">Academic year</p>
          {/* <Select
            class="js-example-basic-single form-select"
            name="academicYear"
            value={formData.academicYear}
            onChange={handleYearChange}
            options={academicYearList}
          /> */}
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
              fontFamily: "bold",
              color: "grey",
              fontSize: 17,
            }}
          >
            {location?.state?.AcademicYear?.label}
          </div>
        </div>

        {/* Term Select */}
        <div className="col">
          <p className="DropTxt">Term</p>
          {/* <Select
            class="js-example-basic-single form-select"
            name="terms"
            value={formData.term}
            options={termList}
            onChange={handleTermChange}
          /> */}

          <div
            style={{
              marginTop: 10,
              // marginLeft: 10,
              fontFamily: "bold",
              color: "grey",
              fontSize: 17,
            }}
          >
            {location?.state?.Term?.label}
          </div>
        </div>

        {/* Grade Division Select */}
        <div className="col">
          <p className="DropTxt">Select Class Divison</p>
          <Select
            class="js-example-basic-single form-select"
            name="grade"
            value={formData.grade}
            onChange={handleGradeChange}
            options={gradeList}
          />
        </div>

        {/* Submit Button */}
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <button
            className="view-mark-list-button col-md-3"
            onClick={() => GradeViewApi(formData.grade?.value)}
          >
            View Mark List
          </button>
        </div>
      </div>

      {/* Display Table */}

      {/* {gradeview?.length > 0 && (
        <div className="table-responsive">
          <table className="custom-table">
            <thead className="thead">
              <tr>
                <th>Sl. No</th>
                <th>Student Name</th>
              
                {gradeview?.map((subject, index) => (
                  <th key={index}>
                    {subject.assessment_subject_name} (
                    {subject.assessment_max_mark})
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {console.log(gradeview, "gradeview")}
              {gradeStudents?.length > 0 ? (
                gradeStudents?.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{student.student_Name}</td>
                   
                    {gradeview?.map((subject, idx) => {
                      const assessmentStudent =
                        subject.assessment_student?.find(
                          (studentData) =>
                            studentData.student_id === student.student_id &&
                            studentData.class_name === subject.assessment_class,
                        );

                      return (
                        <td key={idx}>
                          <input
                            type="number"
                            placeholder={
                              assessmentStudent
                                ? assessmentStudent.student_mark
                                : ""
                            }
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                student.student_id,
                                subject.assessment_subject_name,
                                assessmentStudent?.result_id,
                              )
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={gradeview?.length + 2}>N/A</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )} */}

      {/* New Table */}

      {gradeMarkList?.length > 0 ? (
        <div className="table-responsive">
          <table className="custom-table">
            <thead className="thead">
              <tr>
                <th rowspan="2">Sl. No</th>
                <th rowspan="2">Student Name</th>
                {examList?.map((sub) => (
                  <th colspan={sub.internals?.length + 1}>
                    {sub.assessment_subject_name} ({sub.assessment_title})
                    {/* ({sub.assessment_max_mark}) */}
                  </th>
                ))}
                <th rowSpan="2">Feedback</th>
              </tr>
              <tr>
                {examList?.map((sub) =>
                  sub.internals?.length > 0 ? (
                    <React.Fragment>
                      {sub.internals.map((exam) => (
                        <th>
                          {exam.evaluation_type_code} ({exam.max_mark})
                        </th>
                      ))}
                      <th>Mark ({sub.subject_max_mark})</th>
                    </React.Fragment>
                  ) : (
                    <th>Mark ({sub.subject_max_mark})</th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {gradeMarkList?.map((student, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "start" }}>
                    <img
                      className="rounded-circle"
                      src={
                        student.student_profile
                          ? base_url + student.student_profile
                          : dashboardAvatar
                      }
                      alt="image"
                      style={{
                        height: "30px",
                        width: "30px", // Corrected typo: should be "px" instead of "x"
                        marginRight: "10px",
                      }}
                      onError={(e) => {
                        e.target.src = dashboardAvatar;
                      }}
                    />
                    {student?.student_Name}
                    {/* {console.log(student.exams, "insidestudent")} */}
                  </td>
                  {student?.exams?.map((exam) =>
                    exam.internal_exam?.length > 0 ? (
                      <React.Fragment key={exam.assessment_subject_id}>
                        {exam.internal_exam.map((internalExam) => (
                          <td key={internalExam.assessment_id}>
                            <input
                              type="number"
                              className="no-border"
                              value={internalExam.student_result || ""}
                              onChange={(event) =>
                                handleMarkChange(
                                  event,
                                  student.student_id,
                                  exam.assessment_subject_id,
                                  internalExam.assessment_id,
                                  "internal",
                                  internalExam.evaluation_type_code,
                                )
                              }
                            />
                          </td>
                        ))}
                        <td>{exam.student_mark}</td>
                      </React.Fragment>
                    ) : (
                      <td key={exam.assessment_id}>
                        {/* {console.log(exam.assessment_id, "assessment_id")} */}
                        <input
                          key={exam.assessment_id}
                          type="number"
                          className="no-border"
                          value={exam.student_mark || ""}
                          onChange={(event) => [
                            // console.log(event, "clicking"),
                            handleMarkChange(
                              event,
                              student.student_id,
                              exam.assessment_subject_id,
                              exam.assessment_id,
                              "main",
                            ),
                          ]}
                        />
                      </td>
                    ),
                  )}
                  <td key={index} style={{ textAlign: "center" }}>
                    <input
                      key={index}
                      placeholder={student?.feedback}
                      type="text"
                      className="no-border"
                      // value={student?.feedback || ""}
                      onChange={(e) =>
                        handleFeedBackChange(e, student.student_id, "main")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Marklist Found
        </div>
      )}

      {alertMessage && (
        <AlertModal message={alertMessage} onClose={closeAlert} />
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <button
            className="view-mark-list-button col-md-3"
            onClick={handleDraftSubmit}
          >
            Draft
          </button>
        </div>
        <div
          className="d-flex justify-content-flex-end"
          style={{ justifyContent: "flex-end", marginTop: "-20px" }}
        >
          <button
            className="view-mark-list-button"
            onClick={() => handleEditSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditGradewiseList;
