import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BookOpen, Edit, Trash2 } from "react-feather";
import moment from "moment";
import Params from "../../config/Params";
import { postWithAuthCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ResourceSkeleton from "../../components/skeletons/ResourceSkeleton";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const MyResources_new = (props) => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const selectedType = props.state.selectedType;
  console.log(props, "prosps");
  const [isModalShow, SetisModalShow] = useState(false);
  const [DeleteID, setDeleteID] = useState("");
  const [MyResourcesList, SetMyResourcesList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);

  const Showmodal = () => {
    SetisModalShow(true);
  };

  useEffect(() => {
    setIsloading(true);
    getMyResources(page);
  }, [selectedType]);

  const onActionHandleCallback = (e) => {
    const newRequestBody = {
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      api_key: Params.api_key,
      resources_id: DeleteID,
    };
    postWithAuthCall(
      ApiConfig.MY_RESOURCES_DELET,
      JSON.stringify(newRequestBody),
    )
      .then((data) => {
        toast.success(data.message);
        getMyResources();
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  function getMyResources(currentPage) {
    // setIsloading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      subject: props?.data?.subject_id,
      class: props?.data?.class_id,
      resources_type: selectedType ? selectedType : "",
      page: currentPage,
      page_limit: 20,
    };
    simplePostCall(ApiConfig.MY_RESOURCES_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          setIsloading(false);
          let total = data.total_count ? data.total_count : 0;
          setTotalCourses(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            SetMyResourcesList(data.data);
            setIsloading(false);
          } else {
            SetMyResourcesList([...MyResourcesList, ...LearningData]);
            setIsloading(false);
          }
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const onPageLoad = () => {
    setPage(page + 1);
    getMyResources(page + 1);
  };
  // Function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }
  return (
    <>
      <div className="page-bodys">
        {isloading ? (
          <ResourceSkeleton loading={isloading} />
        ) : (
          <InfiniteScroll
            style={{
              overflow: "hidden",
            }}
            dataLength={MyResourcesList.length}
            next={onPageLoad}
            hasMore={MyResourcesList.length !== TotalCourses}
            loader={
              <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                Loading....
              </h4>
            }
          >
            <div className="row">
              {MyResourcesList.map((postDetail, index) => {
                // console.log(postDetail);
                return (
                  <div className="col-md-4" key={index}>
                    <div className="card br-15">
                      <div className="card-header pb-0">
                        <div className="d-flex">
                          <h5 className="title flex-grow-1 cursor-pointer-on">
                            {" "}
                            {postDetail.resources_name}
                          </h5>
                          <Edit
                            type="button"
                            // data-bs-toggle="modal"
                            // onClick={Showmodal}
                            onClick={() => {
                              nav("/EditResource", { state: postDetail });
                            }}
                            // onClick={() =>
                            //   resourceDetails(postDetail.resources_id)
                            // }
                            // data-bs-target="#editModal"
                            className="header-icon-svg"
                          />
                          <Trash2
                            onClick={() => {
                              setDeleteID(postDetail.resources_id);
                            }}
                            type="button"
                            data-bs-toggle="modal"
                            data-original-title="test"
                            data-bs-target="#deleteWarning"
                            className="header-icon-svg"
                          />
                        </div>
                        <div
                          class="modal fade"
                          id="deleteWarning"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog show" role="document">
                            <div class="modal-content br-15">
                              <div class="modal-body">
                                <div class="warning-message">
                                  <Trash2 />
                                  <h6>
                                    {t("Are you sure you want to delete")}?
                                  </h6>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  class="btn"
                                  type="button"
                                  data-bs-dismiss="modal"
                                >
                                  {t("Cancel")}
                                </button>
                                <button
                                  onClick={(e) => onActionHandleCallback(e)}
                                  class="d-button"
                                  data-bs-dismiss="modal"
                                >
                                  <span>{t("Delete")}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {this.state.isModalShow ? <EditResource onClose={this.openCloseModal} /> : null} */}
                      </div>
                      <div className="card-body cursor-pointer-on">
                        <Link
                          to={`/ImgVideoGallaryNew/${postDetail.resources_id}`}
                          className="d-flex"
                        >
                          <div className="me-auto c-date">
                            {formatDate(postDetail.resources_created_date)}
                          </div>
                          <div className="type">
                            {postDetail.resource_category_name}
                          </div>
                          <div className="type-icon">
                            <BookOpen />
                          </div>
                        </Link>
                        <div className="footer-blk">Published by</div>
                        <div className="footer-blk-detail">
                          {" "}
                          {postDetail.published_by}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default MyResources_new;
