import React, { useEffect, useState } from "react";
import { Camera } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall, multipartPostCall } from "../../api/ApiServices";
import UserPic from "../../assets/images/dashboard/1.png";
import Params from "../../config/Params";
import Select from "react-select";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

function EditProfile() {
  const { t } = useTranslation();

  const [state, setState] = useState({
    isloading: false,
    first_name: "",
    last_name: "",
    user_dob: "",
    gender_code: "",
    email: "",
    user_mobile: "",
    user_address: "",
    user_city: "",
    nationality: "",
    base_url: "",
    profile_pic: "",
    profile_pic_file: "",
    profile_pic_file_name: "",
    profile_pic_file_url: "",
    selectedNationality: [],
    staff_id: "",
    joining_date: "",
  });

  /* country list starts */
  const { getCodes, getName } = require("country-list");
  const countryCodeList = getCodes();
  let countryList = [];
  for (var i = 0; i < countryCodeList.length; i++) {
    var countryCode = countryCodeList[i];
    var countryName = getName(countryCode);

    countryList.push({ value: countryCode, label: countryName });
  }
  /* country list ends */

  function getUserProfileData() {
    setState({ ...state, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.USER_PROFILE_DATA_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setState({
            ...state,
            first_name: data.profile_data.first_name,
            last_name: data.profile_data.last_name,
            user_dob: data.profile_data.user_dob,
            gender_code: data.profile_data.gender_code,
            email: data.profile_data.email,
            user_mobile: data.profile_data.user_mobile,
            user_address: data.profile_data.user_address,
            user_city: data.profile_data.user_city,
            nationality: data.profile_data.user_nationality,
            profile_pic: data.profile_data.profile_pic,
            staff_id: data.profile_data.staff_id,
            joining_date: data.profile_data.user_joining_date,
            nationality: data.profile_data.user_nationality,
            base_url: data.base_url,
            selectedNationality: {
              value: data.profile_data.user_nationality,
              label: data.profile_data.user_nationality
                ? getName(data.profile_data.user_nationality)
                : "",
            },
          });
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setState({
        ...state,
        profile_pic_file: file,
        profile_pic_file_name: file.name,
        profile_pic_file_url: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  function onNationalityChange(selectedOption) {
    setState({
      ...state,
      selectedNationality: selectedOption,
      nationality: selectedOption.value,
    });
  }

  function updateProfile() {
    if (!validateInputs()) {
      setState({ ...state, isloading: true });

      var formData = new FormData();
      formData.append("customer_id", Params.customer_id);
      formData.append("api_key", Params.api_key);
      formData.append("id", Params.teacher_id);
      formData.append("teacher_name", state.first_name);
      formData.append("user_email", state.email);
      formData.append("teacher_last_name", state.last_name);
      formData.append("user_dob", state.user_dob);
      formData.append("mobile", state.user_mobile);
      formData.append("user_gender", state.gender_code);
      formData.append("user_nationality", state.nationality);
      formData.append("user_address", state.user_address);
      formData.append("user_city", state.user_city);
      formData.append("user_internal_no", state.staff_id);
      formData.append("user_joining_date", state.joining_date);
      formData.append("user_profile_pic", state.profile_pic_file);
      formData.append("designation", "teacher");
      console.log(
        formData,
        ApiConfig.UPDATE_PROFILE,
        "ApiConfig.UPDATE_PROFILE",
      );
      multipartPostCall(ApiConfig.UPDATE_PROFILE, formData)
        .then((data) => {
          if (data.result) {
            toast.success(data.message);
            setTimeout(function () {
              window.location.replace("/UserProfile");
            }, 1000);
          } else {
            setState({ ...state, isloading: false });
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setState({ ...state, isloading: false });
          console.log("api response", error);
        });
    }
  }

  function validateInputs() {
    if (
      state.first_name == "" ||
      state.last_name == "" ||
      state.user_dob == "" ||
      state.gender_code == "" ||
      state.email == "" ||
      state.user_mobile == "" ||
      state.user_address == "" ||
      state.user_city == "" ||
      state.nationality == "" ||
      state.staff_id == "" ||
      state.joining_date == ""
    ) {
      var err_msg = "";
      var isError = false;
      const num = /[0-9]/;
      if (state.first_name == "") {
        err_msg = "Please enter First Name";
        isError = true;
      } else if (state.last_name == "") {
        err_msg = "Please enter Last Name";
        isError = true;
      } else if (state.user_dob == "") {
        err_msg = "Please enter Date of Birth";
        isError = true;
      } else if (state.gender_code == "") {
        err_msg = "Please Select Gender";
        isError = true;
      } else if (state.email == "") {
        err_msg = "Please enter Email";
        isError = true;
      } else if (state.user_mobile.length >= 10) {
        err_msg = "Please enter Phone Number ";
        isError = true;
      }
      // if (!num.test(state.user_mobile.length >= 10)) {
      //   //alert("Password should be a mixture of at least one uppercase, lowercase, numeric & special character.");
      //   err_msg = 'Please enter Phone Number '
      //   isError = true
      // }
      else if (state.user_address == "") {
        err_msg = "Please enter Address";
        isError = true;
      } else if (state.user_city == "") {
        err_msg = "Please enter City";
        isError = true;
      } else if (state.nationality == "") {
        err_msg = "Please select Nationality";
        isError = true;
      } else if (state.staff_id == "") {
        err_msg = "Please enter Staff ID";
        isError = true;
      } else if (state.joining_date == "") {
        err_msg = "Please enter Joining Date";
        isError = true;
      }
      toast.error(err_msg);
    }
    return isError;
  }

  useEffect(() => {
    document.title = "Edsys | Edit Profile ";
    getUserProfileData();
  }, []);
  return (
    <>
      {state.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="row user-profile">
            <div className="col-sm-12">
              <div className="edit-pro my-profile-header">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="profile-pic-wrapper">
                      <div className="pic-holder">
                        {/* <!-- uploaded pic shown here --> */}
                        <img
                          id="profilePic"
                          className="pic"
                          src={
                            state.profile_pic_file_url
                              ? state.profile_pic_file_url
                              : state.profile_pic
                              ? state.base_url + state.profile_pic
                              : UserPic
                          }
                        />

                        <label
                          for="newProfilePhoto"
                          className="upload-file-block"
                        >
                          <div className="text-center">
                            <div className="mb-2">
                              <Camera className="fa fa-camera fa-2x" />
                            </div>
                            <div className="text-uppercase">
                              {t("Update")} <br /> Profile Photo
                            </div>
                          </div>
                        </label>
                        <input
                          className="uploadProfileInput"
                          onChange={(e) => handleImageChange(e)}
                          type="file"
                          name="profile_pic"
                          id="newProfilePhoto"
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col ml-md-n2 profile-user-info">
                    {/*<h4 className="first-name mb-0">Mary</h4>*/}
                    <h4 className="sur-name mb-0">
                      {state.first_name + " " + state.last_name}
                    </h4>
                    <div className="user-Location">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {state.user_city}
                    </div>
                    {/*<div className="about-text">Lorem ipsum dolor sit amet.</div>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <form className="card mt-3">
                <div className="card-header pb-0">
                  <h4 className="card-title mb-0">{t("Edit Profile")}</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#"
                      data-bs-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#"
                      data-bs-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{t("First Name")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                          value={state.first_name}
                          onChange={(e) => {
                            setState({ ...state, first_name: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{t("Last Name")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                          value={state.last_name}
                          onChange={(e) => {
                            setState({ ...state, last_name: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Date of Birth")}</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        value={state.user_dob}
                        onChange={(e) => {
                          setState({ ...state, user_dob: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Gender")}</label>
                      <select
                        className="js-example-basic-single form-control"
                        onChange={(e) => {
                          setState({ ...state, gender_code: e.target.value });
                        }}
                        value={state.gender_code}
                      >
                        <option
                          value="m"
                          selected={state.gender_code == "m" ? true : false}
                        >
                          Male
                        </option>
                        <option
                          value="f"
                          selected={state.gender_code == "f" ? true : false}
                        >
                          Female
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{t("Email")}</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          value={state.email}
                          onChange={(e) => {
                            setState({ ...state, email: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          {t("Contact Number")}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={state.user_mobile}
                          onChange={(e) => {
                            setState({ ...state, user_mobile: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <div className="mb-3">
                        <label className="form-label">{t("Address")}</label>
                        <textarea
                          className="form-control"
                          type="text"
                          placeholder="Address"
                          value={state.user_address}
                          onChange={(e) => {
                            setState({
                              ...state,
                              user_address: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{t("City")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="City"
                          value={state.user_city}
                          onChange={(e) => {
                            setState({ ...state, user_city: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Nationality")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedNationality}
                        onChange={onNationalityChange}
                        options={countryList}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{t("Staff ID")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Staff ID"
                          value={state.staff_id}
                          onChange={(e) => {
                            setState({ ...state, staff_id: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          {t("Joining Date")}
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          placeholder="Joining Date"
                          value={state.joining_date}
                          onChange={(e) => {
                            setState({
                              ...state,
                              joining_date: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/*<div className="form-group col-md-12">
                  <div>
                    <label className="form-label">Short Bio</label>
                    <textarea className="form-control" rows="3" placeholder="Enter About your description"></textarea>
                  </div>
                </div>*/}
                  </div>
                </div>
                <div className="card-footer text-end">
                  <a className="btn btn-primary" onClick={updateProfile}>
                    {t("Update Profile")}
                  </a>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  );
}
export default EditProfile;
