import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import SpinnerCmp from '../../components/SpinnerCmp';
import Params from '../../config/Params';
import { simplePostCall } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import Select from 'react-select';
import moment from "moment";

const StudentCreateLeave = props => {
    const [state, setState] = useState({
        isloading: false,
        selectedGrade: [],
        selectedStudent: [],
        selectedLeaveType: [],
        selectedLeaveMode: [],
        student_id: "",
        leave_from_date: "",
        leave_to_date: "",
        leave_type: "",
        leave_mode: "",
        reason: "",
    });
    const [gradeState, setGradeState] = useState({
        isloading: false,
        grades: []
    });
    const [studentState, setStudentState] = useState({
        isloading: false,
        students: []
    });
    const [leaveTypeState, setLeaveType] = useState(
        [
            { value: "full-day", label: 'Full Day' },
            { value: "first-half", label: 'First half/Pickup' },
            { value: "second-half", label: 'Second half/Drop' }
        ]
    );
    const [leaveModeState, setLeaveModeState] = useState(
        [
            { value: 'schooling-transportation', label: 'Schooling & Transportation' },
            { value: 'transportation-only', label: 'Transportation-only' }
        ]
    );

    function getClasses() {
        setGradeState({ ...gradeState, isloading: true });

        let requestBody = JSON.stringify({
            customer_id: Params.customer_id,
            api_key: Params.api_key,
            user_id: Params.teacher_id
        });

        simplePostCall(ApiConfig.CLASSES_LIST_API, requestBody)
            .then((data) => {
                if (data.result) {
                    var grades = [];
                    if (data.list.length > 0) {
                        data.list.map((grade, index) => {
                            grades.push({ label: grade.class_name + " " + grade.class_division_name, value: grade.class_division_id });

                        });
                    }
                    setGradeState({ ...gradeState, isloading: false, grades: grades });
                } else {
                    setGradeState({ ...gradeState, isloading: false });
                    toast.success(data.message);
                }
            })
            .catch((error) => {
                setGradeState({ ...gradeState, isloading: false });
                console.log("api response", error);
            });
    }

    function onGradeChange(selectedOptions) {
        var class_division_ids = [];
        if (selectedOptions.length > 0) {
            selectedOptions.map((selected_option, index) => {
                class_division_ids.push(selected_option.value);

            });
        }
        setState({ ...state, selectedGrade: selectedOptions });
        getStudentData(class_division_ids);
    }

    function getStudentData(class_division_ids) {
        if (class_division_ids.length > 0) {
            setStudentState({ ...studentState, isloading: true });

            let requestBody = JSON.stringify({
                customer_id: Params.customer_id,
                api_key: Params.api_key,
                user_id: Params.teacher_id,
                section_ids: class_division_ids
            });

            simplePostCall(ApiConfig.STUDENTS_LIST_BY_SELECTED_CLASSES_API, requestBody)
                .then((data) => {
                    if (data.result) {
                        var students = [];
                        if (data.list.length > 0) {
                            data.list.map((student, index) => {
                                students.push({ label: student.student_name + " (" + student.student_admission_num + ")", value: student.student_id });

                            });
                        }
                        setStudentState({ ...studentState, isloading: false, students: students });
                    } else {
                        setStudentState({ ...studentState, isloading: false });
                        toast.error(data.message);
                    }
                })
                .catch((error) => {
                    setStudentState({ ...studentState, isloading: false });
                    console.log("api response", error);
                });
        } else {
            setStudentState({ ...studentState, isloading: false, students: [] });
            setState({ ...state, student_id: "" });
        }
    }

    function onStudentChange(selectedOption) {
        setState({ ...state, selectedStudent: selectedOption, student_id: selectedOption.value });
    }

    function onLeaveTypeChange(selectedOption) {
        setState({ ...state, selectedLeaveType: selectedOption, leave_type: selectedOption.value });
    }

    function onLeaveModeChange(selectedOption) {
        setState({ ...state, selectedLeaveMode: selectedOption, leave_mode: selectedOption.value });
    }

    function createLeave() {
        if (!validateInput()) {
            setState({ ...state, isloading: true });
            let requestBody = JSON.stringify({
                customer_id: Params.customer_id,
                api_key: Params.api_key,
                teacher_id: Params.teacher_id,
                student_id: state.student_id,
                leave_from_date: state.leave_from_date,
                leave_to_date: state.leave_to_date,
                leave_type: state.leave_type,
                leave_mode: state.leave_mode,
                reason: state.reason
            });
            simplePostCall(ApiConfig.CREATE_STUDENT_LEAVE, requestBody)
                .then((data) => {
                    if (data.result) {
                        setTimeout(function () {
                            window.location.replace("/StudentLeaves");
                        }, 1000);
                        toast.success(data.message);
                    } else {
                        setState({ ...state, isloading: false });
                        toast.error(data.message);
                    }
                })
                .catch((error) => {
                    setState({ ...state, isloading: false });
                    console.log("api response", error);
                });
        }
    }

    function validateInput() {
        var isError = false;
        if (state.student_id == "" || state.leave_from_date == "" || state.leave_to_date == "" || state.leave_type == "" || state.leave_mode == "" || state.reason == "") {
            var err_msg = "";
            if (state.student_id == "") {
                err_msg = "Select a student";
            } else if (state.leave_from_date == "") {
                err_msg = "Select leave from date";
            } else if (state.leave_to_date == "") {
                err_msg = "Select leave to date";
            } else if (state.leave_type == "") {
                err_msg = "Select leave type";
            } else if (state.leave_mode == "") {
                err_msg = "Select leave mode";
            } else if (state.reason == "") {
                err_msg = "Enter reason";
            }
            toast.error(err_msg);
            isError = true;
        }

        return isError;
    }

    useEffect(() => {
        getClasses();
    }, []);
    return (
        <>
            {gradeState.isloading ? <SpinnerCmp /> :
                <>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Create Leave - Student
                            </h5>
                        </div>

                        <div class="modal-body datetime-picker">
                            <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="" >
                                <div class="form-row">
                                    <div className='row'>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Grade</label>
                                            <Select
                                                class="js-example-basic-single form-select"
                                                onChange={onGradeChange}
                                                options={gradeState.grades}
                                                isMulti={true}
                                                value={state.selectedGrade}
                                            />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Student</label>
                                            <Select
                                                class="js-example-basic-single form-select"
                                                onChange={onStudentChange}
                                                options={studentState.students}
                                                value={state.selectedStudent}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Leave From Date</label>
                                            <input
                                                class="datepicker-here form-control digits"
                                                type="date"
                                                data-language="en"
                                                placeholder="MM-DD-YYYY"
                                                data-position="top left"
                                                name="submission_date"
                                                //min={moment().format("YYYY-MM-DD")}
                                                value={state.leave_from_date}
                                                onChange={(e) => {
                                                    setState({ ...state, leave_from_date: e.target.value })
                                                }}
                                            />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Leave To Date</label>
                                            <input
                                                class="datepicker-here form-control digits"
                                                type="date"
                                                disabled={state.leave_from_date == "" ? true : false}
                                                data-language="en"
                                                placeholder="MM-DD-YYYY"
                                                data-position="top left"
                                                name="submission_date"
                                                min={state.leave_from_date}
                                                value={state.leave_to_date}
                                                onChange={(e) => {
                                                    setState({ ...state, leave_to_date: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Leave Type</label>
                                            <Select
                                                class="js-example-basic-single form-select"
                                                onChange={onLeaveTypeChange}
                                                options={leaveTypeState}
                                                value={state.selectedLeaveType}
                                            />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="task-title">Leave Mode</label>
                                            <Select
                                                class="js-example-basic-single form-select"
                                                value={state.selectedLeaveMode}
                                                onChange={onLeaveModeChange}
                                                options={leaveModeState}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="sub-task">Reason</label>
                                        <textarea
                                            class="form-control"
                                            required=""
                                            name="description"
                                            value={state.reason}
                                            onChange={(e) => {
                                                setState({ ...state, reason: e.target.value });
                                            }}
                                            autocomplete="off"
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                type="button"
                                onClick={createLeave}
                            >
                                Create Leave
                            </button>
                        </div>
                    </div>
                    <br />
                </>
            }
        </>
    )
}
export default StudentCreateLeave;