import b4 from '../../../../assets/images/user/b4.png';
import icon1 from '../../../../assets/images/assignment/review-icon-1.svg';
import { Clock, UploadCloud } from 'react-feather';

import React, { useState, useEffect } from 'react';
import ApiConfig from '../../../../api/ApiConfig';
import Params from '../../../../config/Params';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Tabs1 = ({
  props,
  rating,
  creativity,
  neatness,
  overrating,
  marks,
  outOfMarks,
}) => {
  const [happy, setHappy] = useState(false);
  const [sad, setSad] = useState(false);
  const [good, setGood] = useState(false);
  const [ok, setOk] = useState(false);
  const [angry, setAngry] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [sliderValueone, setSliderValueone] = useState(0);

  const { idy, student_id } = useParams();
  console.log(idy, student_id, 'paramssssy');

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
    neatness(Math.round(calculatePercentage()));
  };
  const calculatePercentage = () => {
    return (sliderValue / 10) * 1;
  };
  const handleSliderCompleteChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValueone(value);
    rating(Math.round(calculateCompletePercentage()));
  };

  const calculateCompletePercentage = () => {
    return (sliderValueone * 100) / 100;
  };

  const handleRatingChange = (event) => {
    const selectedRating = parseInt(event.target.value);
    overrating(selectedRating);
  };
  const handleMarks = (event) => {
    marks(event.target.value);
  };
  const handleMarksOutOf = (event) => {
    outOfMarks(event.target.value);
  };
  return (
    <>
      <div>
        <div className="row rating-card">
          <div className="col-md-6">
            <div className="card br-15">
              <div className="card-body">
                <div className="review-card">
                  <img className="title-icon" src={icon1} />
                  <div className="review-title">Completeness</div>
                  <div className="slidecontainer">
                    <input
                      type="range"
                      className="form-range"
                      id="customRange1"
                      value={sliderValueone}
                      onChange={handleSliderCompleteChange}
                    />
                    <p>{Math.round(calculateCompletePercentage())}%</p>
                  </div>
                  {/* <div className="rating-container rating-blk digits">
                    <select
                      id="u-rating-1to10"
                      name="rating"
                      autocomplete="off"
                      // value={}
                      onChange={handleRatingChange}
                    >
                      {[...Array(10)].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card br-15">
              <div className="card-body">
                <div className="review-card">
                  <img className="title-icon" src={icon1} />
                  <div className="review-title">Creativity</div>
                  <div className="creativity-rating">
                    <ul className="feedback">
                      <li
                        className={`angry ${angry ? 'active' : null}`}
                        id="angry"
                        onClick={() => {
                          creativity('VeryLow');
                          setAngry(!angry);
                          setHappy(false);
                          setOk(false);
                          setSad(false);
                          setGood(false);
                        }}
                      >
                        <div>
                          <svg className="eye left">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="eye right">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="mouth">
                            <use xlinkHref="#mouth" />
                          </svg>
                        </div>
                      </li>
                      <li
                        className={`sad ${sad ? 'active' : null}`}
                        id="sad"
                        onClick={() => {
                          creativity('Low');
                          setSad(!sad);
                          setHappy(false);
                          setOk(false);
                          setAngry(false);
                          setGood(false);
                        }}
                      >
                        <div>
                          <svg className="eye left">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="eye right">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="mouth">
                            <use xlinkHref="#mouth" />
                          </svg>
                        </div>
                      </li>
                      <li
                        className={`ok ${ok ? 'active' : null}`}
                        id="ok"
                        onClick={() => {
                          creativity('Normal');
                          setSad(false);
                          setHappy(false);
                          setOk(!ok);
                          setAngry(false);
                          setGood(false);
                        }}
                      >
                        <div></div>
                      </li>
                      <li
                        className={`good ${good ? 'active' : null}`}
                        id="good"
                        onClick={() => {
                          creativity('High');
                          setSad(false);
                          setHappy(false);
                          setOk(false);
                          setAngry(false);
                          setGood(!good);
                        }}
                      >
                        <div>
                          <svg className="eye left">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="eye right">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="mouth">
                            <use xlinkHref="#mouth" />
                          </svg>
                        </div>
                      </li>
                      <li
                        className={`happy ${happy ? 'active' : null}`}
                        id="happy"
                        onClick={() => {
                          creativity('VeryHigh');
                          setHappy(!happy);
                          setSad(false);
                          setOk(false);
                          setAngry(false);
                          setGood(false);
                        }}
                      >
                        <div>
                          <svg className="eye left">
                            <use xlinkHref="#eye" />
                          </svg>
                          <svg className="eye right">
                            <use xlinkHref="#eye" />
                          </svg>
                        </div>
                      </li>
                    </ul>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: 'none' }}
                    >
                      <symbol
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 7 4"
                        id="eye"
                      >
                        <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                      </symbol>
                      <symbol
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 7"
                        id="mouth"
                      >
                        <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                      </symbol>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card br-15">
              <div className="card-body">
                <div className="review-card">
                  <img className="title-icon" src={icon1} />
                  <div className="review-title">Neatness</div>

                  <div className="slidecontainer">
                    <input
                      type="range"
                      className="form-range"
                      id="customRange1"
                      value={sliderValue}
                      onChange={handleSliderChange}
                    />
                    <p>{Math.round(calculatePercentage())}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card br-15">
              <div className="card-body">
                <div className="review-card">
                  <img className="title-icon" src={icon1} />
                  <div className="review-title">Overall Rating</div>
                  <div
                    style={{ marginTop: '8px' }}
                    className="rating-container"
                  >
                    <select
                      id="u-rating-1to10"
                      name="rating"
                      autocomplete="off"
                      onChange={handleRatingChange}
                    >
                      {[...Array(5)].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card br-15">
              <div className="card-body">
                <div className="review-card">
                  <img className="title-icon" src={icon1} alt="icon" />
                  <div className="review-title">Mark Scored</div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8px',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      style={{
                        height: 50,
                        width: 50,
                      }}
                      type="number"
                      className="square-input"
                      onChange={handleMarks}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px',
                      }}
                    >
                      <text>Out of </text>
                    </div>
                    <input
                      style={{ height: 50, width: 50 }}
                      type="number"
                      className="square-input"
                      onChange={handleMarksOutOf}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Tabs2 = ({ props, feedback }) => {
  const [textValue, setTextValue] = useState('');
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
    console.log(event.target.value, 'event.target.value');
    feedback(textValue);
  };
  return (
    <>
      {/* {Changes here,className='tab'} */}
      <div>
        <div className="row">
          <div className="col-12">
            <div className="mb-5 ass-comment">
              <label className="form-label" for="exampleFormControlTextarea4">
                Comments
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea4"
                rows="3"
                value={textValue}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="mb-5">
              <label>Upload Answer Feedback</label>
              <div
                className="dropzone"
                id="singleFileUpload"
                action="/upload.php"
              >
                <div className="dz-message needsclick">
                  <UploadCloud className="uploadCloud" />
                  <h6>Drop files here or click to upload.</h6>
                  <span className="note needsclick">
                    (This is just a demo dropzone. Selected files are{' '}
                    <strong>not</strong> actually uploaded.)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Tabs3 = ({ props, feeds }) => {
  const [feedback, setFeedback] = useState('');
  const handleRadioButtonChange = (event) => {
    const selectedLabel = event.target.nextSibling.textContent;
    setFeedback(selectedLabel);
    feeds(selectedLabel);
  };
  return (
    <>
      <div className="row final-ass-fback">
        <div className="col-md-12">
          <label className="form-label" htmlFor="feedbackRadioButton">
            Feedback
          </label>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio1"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio1">Excellent</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio2"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio2">Good</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio3"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio3">Very Good</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio4"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio4">Average</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio5"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio5">Poor</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inputGroup">
            <input
              id="radio6"
              name="radio"
              type="radio"
              onChange={handleRadioButtonChange}
            />
            <label htmlFor="radio6">Very Poor</label>
          </div>
        </div>
        <div>
          <p>Selected Feedback: {feedback}</p>
        </div>
      </div>
    </>
  );
};
function ReviewAssignment() {
  const Navigation = useNavigate();
  const { idy, student_id } = useParams();
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [files, setFiles] = useState([]);
  const [rating, setRating] = useState(1);
  const [creativity, setCreativity] = useState('');
  const [neat, setNeat] = useState('');
  const [overRating, setOverRating] = useState('');
  const [feedback, setFeedBack] = useState('');
  const [feeds, setFeeds] = useState('');
  const [marks, setMarks] = useState('');
  const [outOf, setOutof] = useState('');

  useEffect(() => {
    fetchAssignmentDetails();
    document.title = 'Edsys | ReviewAssignment ';
  }, []);

  const fetchAssignmentDetails = async () => {
    try {
      const response = await fetch(ApiConfig.ASSIGNMENT_SUBMITVIEW, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: Params.customer_id, // Assuming Params.customer_id is a constant
          api_key: Params.api_key, // Assuming Params.api_key is a constant
          assignment_id: idy,
          student_id: student_id, // You may want to change this if it should be dynamic
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched assignment details:', data);
        setAssignmentDetails(data.submitted_details);
        setFiles(data.submitted_details.submitted_files);
      } else {
        console.error('Failed to fetch assignment details');
      }
    } catch (error) {
      console.error('Error fetching assignment details:', error);
    }
  };

  const fetchAssignmentReview = async () => {
    try {
      let requestBody = JSON.stringify({
        customer_id: Params.customer_id, // Assuming Params.customer_id is a constant
        api_key: Params.api_key, // Assuming Params.api_key is a constant
        teacher_id: Params.teacher_id,
        student_id: student_id,
        submission_id: assignmentDetails.submission_id,
        completeness: rating,
        neatness: neat,
        mark: marks,
        rating: overRating,
        total_mark: outOf,
        grade: '3',
        creativity: creativity,
        comment: feedback,
        status: 'reviewed',
      });
      console.log(requestBody, 'requestbody in review');
      const response = await fetch(ApiConfig.ASSIGNMENT_REVIEW, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched assignment REVIEW', data.message);
        toast.success(data.message);
      } else {
        console.error('Failed to fetch assignment details');
      }
    } catch (error) {
      console.error('Error fetching assignment details:', error);
    }
  };
  const [state, setState] = useState(0);
  const myData = [1];
  return (
    <div className="page-body">
      {/* <!-- Container-fluid starts--> */}
      <div className="row view-assignment">
        <div className="col-12">
          <div className="card br-15">
            <div className="card-body header-card">
              <div className="student-header">
                <div className="details-banner">
                  <h3>{assignmentDetails?.review_details?.assignment}</h3>
                  <h5>
                    <Clock />
                    {assignmentDetails?.submission_time}
                  </h5>
                  <div className="sub-date">
                    {assignmentDetails?.submission_date}
                  </div>
                </div>
              </div>
              <div className="student-image">
                <div className="student-avatar">
                  <img
                    src={ApiConfig.NODE_URL + assignmentDetails?.profilepicture}
                  />
                  <div className="class">{assignmentDetails?.class}</div>
                </div>
                <div className="student-name">{assignmentDetails?.student}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card br-15">
            <div className="card-header br-15 pb-0">
              <h5>Review Section</h5>
            </div>
            <div className="card-body ass-form">
              <form
                className="form-wizard"
                id="regForm"
                action="#"
                method="POST"
              >
                <div>
                  {myData.map((item, index) => {
                    return (
                      <>
                        {state == 0 ? (
                          <Tabs1
                            tabsData={item}
                            rating={(e) => setRating(e)}
                            creativity={(e) => setCreativity(e)}
                            neatness={(e) => setNeat(e)}
                            overrating={(e) => setOverRating(e)}
                            marks={(e) => setMarks(e)}
                            outOfMarks={(e) => setOutof(e)}
                          />
                        ) : null}
                        {state == 1 ? (
                          <Tabs2
                            tabsData={item}
                            feedback={(e) => setFeedBack(e)}
                          />
                        ) : null}
                        {state == 2 ? (
                          <Tabs3 tabsData={item} feeds={(e) => setFeeds(e)} />
                        ) : null}
                      </>
                    );
                  })}
                </div>

                <div>
                  <div className="text-end btn-mb">
                    <button
                      className="btn btn-secondary"
                      style={{ marginRight: '5px' }}
                      id="prevBtn"
                      type="button"
                      disabled={state === 0 ? true : false}
                      onClick={() => {
                        setState(state - 1);
                      }}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-primary"
                      id="nextBtn"
                      type="button"
                      // disabled={state === 2 ? true : false}
                      onClick={() => {
                        setState(state + 1);
                        if (state === 2) {
                          fetchAssignmentReview();
                          Navigation(
                            `/ViewReviewedAssignment/${student_id}/${idy}`
                          );
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
                {/* <!-- Circles which indicates the steps of the form:--> */}
                <div className="text-center">
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                </div>
                {/* <!-- Circles which indicates the steps of the form:--> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default ReviewAssignment;
