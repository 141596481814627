import React, { useState, useEffect } from "react";
import { simplePostCall } from "../../../../api/ApiServices";
import ApiConfig from "../../../../api/ApiConfig";
import Params from "../../../../config/Params";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function AssessmentStepOne(props) {
  const { t } = useTranslation();
  const id = props.id;
  console.log("propsssss", props);
  const [state, setState] = useState({
    assessment_title: props.assessmentDetails.assessment_title
      ? props.assessmentDetails.assessment_title
      : "",
    assessment_description: props.assessmentDetails
      ? props.assessmentDetails.assessment_description
      : "",
    curriculum: props.assessmentDetails
      ? props.assessmentDetails.curriculum
      : "",
    curriculum_id: props.assessmentDetails
      ? props.assessmentDetails.curriculum_id
      : "",
    selectedCurriculum: props.assessmentDetails
      ? props.assessmentDetails.selectedCurriculum
      : [],
    class_division: props.assessmentDetails
      ? props.assessmentDetails.class_division
      : [],
    assessment_grade: props.assessmentDetails
      ? props.assessmentDetails.assessment_grade
      : [],
    subject: props.assessmentDetails ? props.assessmentDetails.subject : "",
    selectedSubject: props.assessmentDetails
      ? props.assessmentDetails.selectedSubject
      : [],
    lessons: props.assessmentDetails ? props.assessmentDetails.lessons : [],
    selectedLessons: props.assessmentDetails
      ? props.assessmentDetails.selectedLessons
      : [],
    topics: props.assessmentDetails ? props.assessmentDetails.topics : [],
    selectedTopics: props.assessmentDetails
      ? props.assessmentDetails.selectedTopics
      : [],
    assessment_type: props.assessmentDetails
      ? props.assessmentDetails.assessment_type
      : "",
    selectedAssessmentType: props.assessmentDetails
      ? props.assessmentDetails.selectedAssessmentType
      : [],
    assessment_duration: props.assessmentDetails
      ? props.assessmentDetails.assessment_duration
      : "",
    selectedAssessmentDuration: props.assessmentDetails
      ? props.assessmentDetails.selectedAssessmentDuration
      : [],
    assessment_improvement: props.assessmentDetails
      ? props.assessmentDetails.assessment_improvement
      : "",
    selectedAssessmentImprovement: props.assessmentDetails
      ? props.assessmentDetails.selectedAssessmentImprovement
      : [],
    negative_score: props.assessmentDetails
      ? props.assessmentDetails.negative_score
      : "no",
    assessment_start_date: props.assessmentDetails
      ? props.assessmentDetails.assessment_start_date
      : "",
    assessment_start_time: props.assessmentDetails
      ? props.assessmentDetails.assessment_start_time
      : "",
    assessment_end_date: props.assessmentDetails
      ? props.assessmentDetails.assessment_end_date
      : "",
    assessment_end_time: props.assessmentDetails
      ? props.assessmentDetails.assessment_end_time
      : "",
    assessment_title_error: "",
    assessment_description_error: "",
    curriculum_error: "",
    class_division_error: "",
    subject_error: "",
    assessment_type_error: "",
    assessment_duration_error: "",
    assessment_improvement_error: "",
    assessment_start_date_error: "",
    assessment_start_time_error: "",
    assessment_end_date_error: "",
    assessment_end_time_error: "",
  });
  const [assessmentDurationState, setAssessmentDurationState] = useState({
    duration_list: [],
  });
  const [curriculumState, setCurriculumState] = useState({
    isloading: false,
    curriculums: [],
  });
  const [gradeState, setGradeState] = useState({
    isloading: false,
    grades: [],
  });
  // console.log('gradeState', gradeState);
  const [subjectState, setSubjectState] = useState({
    isloading: false,
    subjects: [],
  });
  const [lessonsState, setLessonsState] = useState({
    isloading: false,
    lessons: [],
  });
  const [topicsState, setTopicsState] = useState({
    isloading: false,
    topics: [],
  });
  const [assessmentImprovementState, setAssessmentImprovementState] = useState({
    assessment_improvements: [
      // { value: '100', label: 'Unlimitted improvement' },
      { value: "0", label: "0 - No more improvement" },
      { value: "1", label: "1 - Improvement" },
      { value: "2", label: "2 - Improvement" },
      { value: "3", label: "3 - Improvement" },
    ],
  });

  function handleSaveAndNext() {
    console.log(state, "state in next page");
    if (
      state.assessment_title == "" ||
      state.assessment_description == "" ||
      state.curriculum == "" ||
      state.class_division?.length == 0 ||
      state.subject == "" ||
      state.assessment_type == "" ||
      state.assessment_duration == "" ||
      state.assessment_improvement == ""
    ) {
      var assessment_title_error =
        state.assessment_title == "" ? "Enter Assessment Title" : "";
      var assessment_description_error =
        state.assessment_description == ""
          ? "Enter Assessment Description"
          : "";
      var curriculum_error = state.curriculum == "" ? "Select Curriculum" : "";
      var class_division_error =
        state.class_division?.length == 0 ? "Select atleast one Grade" : "";
      var subject_error = state.subject == "" ? "Select Subject" : "";
      var assessment_type_error =
        state.assessment_type == "" ? "Select Assessment Type" : "";
      var assessment_duration_error =
        state.assessment_duration == "" ? "Select Assessment Duration" : "";
      var assessment_improvement_error =
        state.assessment_improvement == ""
          ? "Select Assessment Improvement"
          : "";

      setState({
        ...state,

        assessment_title_error: assessment_title_error,
        assessment_description_error: assessment_description_error,
        curriculum_error: curriculum_error,
        class_division_error: class_division_error,
        subject_error: subject_error,
        assessment_type_error: assessment_type_error,
        assessment_duration_error: assessment_duration_error,
        assessment_improvement_error: assessment_improvement_error,
      });
      console.log(state.assessment_type, "this is assessment_type");
      if (state.assessment_type == "No") {
        checkAssessmentStartAndEndTimings();
      }
    } else {
      if (state.assessment_type == "No") {
        checkAssessmentStartAndEndTimings();
      } else if (id == undefined) {
        props.saveAssessmentInfo(state);
      } else {
        props.publishAssessmentInfo(state);
      }
    }
  }

  function checkAssessmentStartAndEndTimings() {
    if (
      state.assessment_start_date == "" ||
      state.assessment_start_time == "" ||
      state.assessment_end_date == "" ||
      state.assessment_end_time == ""
    ) {
      var assessment_start_date_error =
        state.assessment_start_date == "" ? "Select Assessment Start Date" : "";
      var assessment_start_time_error =
        state.assessment_start_time == "" ? "Select Assessment Start Time" : "";
      var assessment_end_date_error =
        state.assessment_end_date == "" ? "Select Assessment End Date" : "";
      var assessment_end_time_error =
        state.assessment_end_time == "" ? "Select Assessment End Time" : "";

      setState({
        ...state,
        assessment_start_date_error: assessment_start_date_error,
        assessment_start_time_error: assessment_start_time_error,
        assessment_end_date_error: assessment_end_date_error,
        assessment_end_time_error: assessment_end_time_error,
      });
    } else if (id == undefined) {
      props.saveAssessmentInfo(state);
    } else {
      props.publishAssessmentInfo(state);
    }
  }

  function getAssessmentDurationList() {
    var assess_duration_list = [];
    for (var i = 2; i <= 36; i++) {
      assess_duration_list.push({ label: i * 5, value: i * 5 });
    }
    setAssessmentDurationState({
      ...assessmentDurationState,
      duration_list: assess_duration_list,
    });
  }

  function getCurriculumList() {
    setCurriculumState({ ...curriculumState, isloading: true });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          var curriculums = [];
          if (data.curriculum_list.length > 0) {
            data.curriculum_list.map((curriculum, index) => {
              curriculums.push({
                label: curriculum.curriculum_name,
                value: curriculum.curriculum_id,
              });
            });
          }
          setCurriculumState({
            ...curriculumState,
            isloading: false,
            curriculums: curriculums,
          });
        } else {
          setCurriculumState({ ...curriculumState, isloading: false });
        }
      })
      .catch((error) => {
        setCurriculumState({ ...curriculumState, isloading: false });
        console.log("api response", error);
      });
  }

  function onCurriculumChange(selectedOption) {
    setState({
      ...state,
      curriculum: selectedOption.value,
      selectedCurriculum: selectedOption,
    });
    getSubjects();
    //getLessons("", selectedOption.value, "");
  }

  function getGradeList() {
    setGradeState({ ...gradeState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name + " " + grade.class_division_name,
                value: grade.class_division_id,
              });
            });
          }
          setGradeState({ ...gradeState, isloading: false, grades: grades });
        } else {
          setGradeState({ ...gradeState, isloading: false });
        }
      })
      .catch((error) => {
        setGradeState({ ...gradeState, isloading: false });
        console.log("api response", error);
      });
  }

  function onGradeChange(selectedOption) {
    setState({
      ...state,
      class_division: selectedOption.value,
      assessment_grade: selectedOption,
    });
    getSubjects(selectedOption.value);
    getLessons("", "", selectedOption.value);
    // getStudents(selectedOption.value)
  }

  function getSubjects(selected_class_division) {
    setSubjectState({ ...subjectState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      //class_division_id: selected_class_division ? selected_class_division : state.class_division
      class_id: selected_class_division,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var subjects = [];
          if (data.list.length > 0) {
            data.list.map((subject, index) => {
              subjects.push({
                label: subject.subject_name,
                value: subject.subject_id,
                subject_code: subject.subject_code,
              });
            });
          }
          setSubjectState({
            ...subjectState,
            isloading: false,
            subjects: subjects,
          });
          // console.log('subjects', subjectState, subjects);
        } else {
          setSubjectState({ ...subjectState, isloading: false });
        }
      })
      .catch((error) => {
        setSubjectState({ ...subjectState, isloading: false });
        console.log("api response", error);
      });
  }

  function onSubjectChange(selectedOption) {
    // console.log('selectedOption', selectedOption);
    setState({
      ...state,
      subject: selectedOption.value,
      selectedSubject: selectedOption,
    });

    getLessons(selectedOption.value, "", "", selectedOption);
  }

  function getLessons(
    subject_id = "",
    curriculum_id = "",
    class_division = [],
    selectedSubjectOption = [],
  ) {
    setLessonsState({ ...lessonsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      class_division_id: class_division ? class_division : state.class_division,
      subject_id: subject_id ? subject_id : state.subject,
      curriculum_id: state.curriculum_id
        ? state.curriculum_id
        : state.curriculum,
    });
    console.log("newRequestBody1234", state.curriculum);
    simplePostCall(ApiConfig.LESSONS_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var lessons = [];
          var all_topics = [];
          if (data.lessons_data.length > 0) {
            data.lessons_data.map((lesson, index) => {
              lessons.push({
                label: lesson.lessons_name,
                value: lesson.lessons_id,
              });
              all_topics.push({
                lessons_id: lesson.lessons_id,
                lessons_topics: lesson.lessons_topics,
              });
            });
          }

          setLessonsState({
            ...lessonsState,
            isloading: false,
            lessons: lessons,
          });
          setState({
            ...state,
            selectedSubject: selectedSubjectOption,
            subject: subject_id,
            allTopics: all_topics,
          });
        } else {
          setLessonsState({ ...lessonsState, isloading: false, lessons: [] });
        }
      })
      .catch((error) => {
        setLessonsState({ ...lessonsState, isloading: false });
        console.log("api response", error);
      });
  }

  function onLessonChange(selectedOption) {
    var selected_lessons = [];
    if (selectedOption.length > 0) {
      selectedOption.map((lesson, index) => {
        selected_lessons.push(lesson.value);
      });
    }
    setState({
      ...state,
      lessons: selected_lessons,
      selectedLessons: selectedOption,
      topics: [],
      selectedTopics: [],
    });
    getTopics(selected_lessons);
  }

  function getTopics(selected_lessons) {
    var topics_list = [];
    // console.log('state given', state, 'selected_lessons', selected_lessons);
    if (state.allTopics?.length > 0) {
      state.allTopics.map((topics, index) => {
        if (selected_lessons.indexOf(topics.lessons_id) > -1) {
          if (topics.lessons_topics?.length > 0) {
            topics.lessons_topics.map((lessons_topic, index) => {
              topics_list.push({
                label: lessons_topic.topic_name,
                value: lessons_topic.topic_id,
              });
            });
          }
        }
      });
    }
    setTopicsState({ ...topicsState, topics: topics_list });
  }

  function onTopicChange(selectedOption) {
    var selected_topics = [];
    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setState({
      ...state,
      topics: selected_topics,
      selectedTopics: selectedOption,
    });
  }

  function onAssessmentTypeChange(selectedOption) {
    console.log("assessment type", selectedOption);
    setState({
      ...state,
      assessment_type: selectedOption.value,
      selectedAssessmentType: selectedOption,
    });
  }

  function onAssessmentDurationChange(selectedOption) {
    setState({
      ...state,
      assessment_duration: selectedOption.value,
      selectedAssessmentDuration: selectedOption,
    });
  }

  function onAssessmentImprovementChange(selectedOption) {
    setState({
      ...state,
      assessment_improvement: selectedOption.value,
      selectedAssessmentImprovement: selectedOption,
    });
  }

  useEffect(() => {
    console.log(props);
    getAssessmentsDetails();
    getAssessmentDurationList();
    getCurriculumList();
    getGradeList();
  }, []);
  const [stateforlesstop, setstateforlesstop] = useState([]);
  const [stateforlesstopics, setstateforlesstopics] = useState([]);
  function convertTimeToMinutes(timeFormat) {
    const [hours, minutes, seconds] = timeFormat.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return totalMinutes;
  }
  function getAssessmentsDetails() {
    const selectedTopic = [];
    const lessons = [];
    let convertedResponse;
    // setAssignmentDetailsState.isloading (true)
    simplePostCall(
      ApiConfig.ASSESSMENT_DETAILS_API,
      JSON.stringify({ ...Params, assessment_id: id }),
    )
      .then((data) => {
        let assessment_data = data.assessment_details;
        console.log(assessment_data, "assessment_data");
        setstateforlesstop(
          assessment_data?.lesson.map((item) => ({
            value: item.lesson_id,
            label: item.lesson_name,
          })),
        );
        var all_topics = [];
        assessment_data.lesson.forEach((lesson) => {
          lesson.topics.forEach((topic) => {
            all_topics.push({
              value: topic.assessment_topic_topic_id,
              label: topic.topic_name,
            });
          });
        });
        setstateforlesstopics(all_topics);
        // const topics = assessment_data.lesson.forEach((lesson) => {
        //   lesson.topics.forEach((topic) => {
        //     all_topics.push(topic);
        //     console.log(all_topics, "topicsss");
        //     setstateforlesstopics(
        //       all_topics.map((topiks) => ({
        //         value: topiks.assessment_topic_id,
        //         label: topiks.topic_name,
        //       })),
        //     );
        //   });
        // });
        // var all_topics = [];
        // if (data.length > 0) {
        //   assessment_data?.lesson.map((lesson, index) => {
        //     all_topics.push({
        //       lessons_id: lesson.lessons_id,
        //       lessons_topics: lesson.lessons_topics,
        //     });
        //   });
        // }
        console.log(all_topics, "all_topics");
        // setstateforlesstopics(
        //   assessment_data?.lesson.map((item) =>
        //     item.itemss.map(({ dorito }) => ({
        //       value: dorito.assessment_topic_id,
        //       label: dorito.topic_name,
        //     })),
        //   ),
        // );

        console.log("assessment_data", assessment_data);
        assessment_data?.lesson.forEach((lesson) => {
          lesson.topics.forEach((topic) => {
            selectedTopic.push({
              label: topic.topic_name,
              value: topic.assessment_topic_topic_id,
            });
          });
          lessons.push({
            label: lesson.lesson_name,
            value: lesson.lesson_id,
          });
        });
        //asessment type conversion
        if (assessment_data.assessment_type === "Any time") {
          convertedResponse = {
            label: assessment_data.assessment_type,
            value: "Yes",
          };
        } else if (assessment_data.assessment_type === "Specific time") {
          convertedResponse = {
            label: assessment_data.assessment_type,
            value: "no",
          };
        } else {
          convertedResponse = "Invalid response"; // Handle invalid responses
        }
        console.log("selectedTopic: ", selectedTopic);
        console.log("lessons: ", lessons);
        console.log("selcted type", convertedResponse);
        setState({
          curriculum: assessment_data.curriculum,
          assessment_title: assessment_data.assessment_title,
          assessment_description: assessment_data.assessment_description,
          assessment_grade: assessment_data.assessment_grade,
          subject: assessment_data.assessment_subject,
          subject_id: assessment_data.assessment_subject_id,
          assessment_type: assessment_data.assessment_type,
          assessment_duration: convertTimeToMinutes(
            assessment_data.assessment_duration,
          ),
          assessment_improvement: assessment_data.assessment_improvement,
          assessment_start_date: assessment_data.assessment_start_date,
          assessment_start_time: assessment_data.assessment_start_time,
          assessment_end_date: assessment_data.assessment_end_date,
          assessment_end_time: assessment_data.assessment_end_time,
          assessment_negative_marks: assessment_data.assessment_negative_marks,
          class_division_id: assessment_data.class_division_id,
          curriculum_id: assessment_data.curriculum_id,
          lesson: lessons,
          topic: selectedTopic,
          asessment_typs: convertedResponse,
        });
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  return (
    <>
      {curriculumState.isloading || gradeState.isloading ? (
        <SpinnerCmp
          loading={curriculumState.isloading || gradeState.isloading}
        />
      ) : (
        <>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {t(
                  state.assessment_title
                    ? "Update Assesment"
                    : "Create Assessment",
                )}
              </h5>
            </div>

            <div class="modal-body datetime-picker">
              <form
                class="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Assessment Title")}</label>
                    <input
                      class="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                      name="title"
                      value={state.assessment_title}
                      onChange={(e) => {
                        setState({
                          ...state,
                          assessment_title: e.target.value,
                        });
                      }}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.assessment_title_error ? "" : "none",
                      }}
                    >
                      {state.assessment_title_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="sub-task">{t("Assessment Description")}</label>
                    <textarea
                      class="form-control"
                      required=""
                      name="description"
                      value={state.assessment_description}
                      onChange={(e) => {
                        setState({
                          ...state,
                          assessment_description: e.target.value,
                        });
                      }}
                      autocomplete="off"
                    ></textarea>
                    <small
                      style={{
                        color: "red",
                        display: state.assessment_description_error
                          ? ""
                          : "none",
                      }}
                    >
                      {state.assessment_description_error}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Curriculum")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      // value={state.selectedCurriculum}
                      //  defaultValue={{ label: "aatif", value: 0 }}
                      onChange={onCurriculumChange}
                      options={curriculumState.curriculums}
                      placeholder={state.curriculum}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.curriculum_error ? "" : "none",
                      }}
                    >
                      {state.curriculum_error}
                    </small>
                  </div>
                  {/* <div class="row">
                    <div class="form-group col">
                      <label for="task-title">Grade</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedGrade}
                        onChange={onGradeChange}
                        options={gradeState.grades}
                        isClearable={true}
                        isMulti={true}
                      />
                      <small
                        style={{
                          color: 'red',
                          display: state.class_division_error ? '' : 'none',
                        }}
                      >
                        {state.class_division_error}
                      </small>
                    </div>

                    <div class="form-group col">
                      <label for="task-title">Subject</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedSubject}
                        onChange={onSubjectChange}
                        options={subjectState.subjects}
                      />
                      <small
                        style={{
                          color: 'red',
                          display: state.subject_error ? '' : 'none',
                        }}
                      >
                        {state.subject_error}
                      </small>
                    </div>
                  </div> */}

                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">{t("Grade")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.assessment_grade}
                        onChange={onGradeChange}
                        options={gradeState.grades}
                        placeholder={state.assessment_grade}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.class_division_error ? "" : "none",
                        }}
                      >
                        {state.class_division_error}
                      </small>
                    </div>

                    <div class="form-group col">
                      <label for="task-title">{t("Subject")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedSubject}
                        defaultValue={state.selectedSubject}
                        onChange={onSubjectChange}
                        options={subjectState.subjects}
                        placeholder={state.subject}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.subject_error ? "" : "none",
                        }}
                      >
                        {state.subject_error}
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Lesson")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      defaultValue={state?.lesson}
                      // value={state.selectedLessons}
                      value={
                        state.selectedLessons
                          ? state.selectedLessons
                          : stateforlesstop
                      }
                      // value={[
                      //   { value: "item.lesson_id", label: "item.lesson_name" },
                      // ]}
                      onChange={onLessonChange}
                      options={lessonsState.lessons}
                      isMulti={true}
                    />
                    <small style={{ color: "red", display: "none" }}>{}</small>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">{t("Topic")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      defaultValue={state?.topic}
                      // value={state.selectedTopics}
                      value={
                        state.selectedTopics
                          ? state.selectedTopics
                          : stateforlesstopics
                      }
                      onChange={onTopicChange}
                      options={topicsState.topics}
                      isMulti={true}
                    />
                    <small style={{ color: "red", display: "none" }}>{}</small>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">{t("Assessment Type")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        defaultValue={state?.asessment_typs}
                        value={state.selectedAssessmentType}
                        onChange={onAssessmentTypeChange}
                        options={[
                          { label: "Any Time", value: "Yes" },
                          { label: "Specific Time", value: "No" },
                        ]}
                        placeholder={state.assessment_type}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.assessment_type_error ? "" : "none",
                        }}
                      >
                        {state.assessment_type_error}
                      </small>
                    </div>

                    <div class="form-group col">
                      <label for="task-title">
                        {t("Assessment Duration (Minutes)")}
                      </label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedAssessmentDuration}
                        onChange={onAssessmentDurationChange}
                        options={assessmentDurationState.duration_list}
                        placeholder={state.assessment_duration}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.assessment_duration_error
                            ? ""
                            : "none",
                        }}
                      >
                        {state.assessment_duration_error}
                      </small>
                    </div>
                  </div>

                  {state.assessment_type == "No" ? (
                    <div id="assessment_time_div">
                      <div class="row">
                        <div class="form-group col">
                          <label for="task-title">
                            {t("Assessment")} {t("Start Date")}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            value={state.assessment_start_date}
                            onChange={(e) => {
                              setState({
                                ...state,
                                assessment_start_date: e.target.value,
                              });
                            }}
                            min={moment().format("YYYY-MM-DD")}
                          />
                          <small
                            style={{
                              color: "red",
                              display: state.assessment_start_date_error
                                ? ""
                                : "none",
                            }}
                          >
                            {state.assessment_start_date_error}
                          </small>
                        </div>

                        <div class="form-group col">
                          <label for="task-title">
                            {t("Assessment")} {t("Start Time")}
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            value={state.assessment_start_time}
                            onChange={(e) => {
                              setState({
                                ...state,
                                assessment_start_time: e.target.value,
                              });
                            }}
                          />
                          <small
                            style={{
                              color: "red",
                              display: state.assessment_start_time_error
                                ? ""
                                : "none",
                            }}
                          >
                            {state.assessment_start_time_error}
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col">
                          <label for="task-title">
                            {t("Assessment")} {t("End Date")}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            value={state.assessment_end_date}
                            onChange={(e) => {
                              setState({
                                ...state,
                                assessment_end_date: e.target.value,
                              });
                            }}
                            min={moment().format("YYYY-MM-DD")}
                          />
                          <small
                            style={{
                              color: "red",
                              display: state.assessment_end_date_error
                                ? ""
                                : "none",
                            }}
                          >
                            {state.assessment_end_date_error}
                          </small>
                        </div>

                        <div class="form-group col">
                          <label for="task-title">
                            {t("Assessment")} {t("End Time")}
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            value={state.assessment_end_time}
                            onChange={(e) => {
                              setState({
                                ...state,
                                assessment_end_time: e.target.value,
                              });
                            }}
                          />
                          <small
                            style={{
                              color: "red",
                              display: state.assessment_end_time_error
                                ? ""
                                : "none",
                            }}
                          >
                            {state.assessment_end_time_error}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">
                        {t("Assessment Improvement")}
                      </label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedAssessmentImprovement}
                        onChange={onAssessmentImprovementChange}
                        options={
                          assessmentImprovementState.assessment_improvements
                        }
                        placeholder={(() => {
                          switch (state.assessment_improvement) {
                            case 0:
                              return "No more improvement";
                            case 1:
                              return "1 - Improvement";
                            case 2:
                              return "2 - Improvement";
                            case 3:
                              return "3 - Improvement";
                            default:
                              return null;
                          }
                        })()}
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.assessment_improvement_error
                            ? ""
                            : "none",
                        }}
                      >
                        {state.assessment_improvement_error}
                      </small>
                    </div>

                    <div class="form-group col">
                      <label for="task-title">{t("Negative Score")}</label>
                      <div class="form-control">
                        <input
                          type={"radio"}
                          name="negative_score"
                          value="yes"
                          onChange={(e) => {
                            setState({
                              ...state,
                              negative_score: e.target.value,
                            });
                          }}
                          checked={
                            state.negative_score == "yes" ||
                            state.assessment_negative_marks == "yes"
                              ? true
                              : false
                          }
                        />{" "}
                        Yes &nbsp;&nbsp;&nbsp;
                        <input
                          type={"radio"}
                          name="negative_score"
                          value="no"
                          onChange={(e) => {
                            setState({
                              ...state,
                              negative_score: e.target.value,
                            });
                          }}
                          checked={
                            state.negative_score == "no" ||
                            state.assessment_negative_marks == "no"
                              ? true
                              : false
                          }
                        />{" "}
                        No
                      </div>
                      <small style={{ color: "red", display: "none" }}>
                        {}
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              {/*<button
                            class="btn btn-secondary"
                            id="Bookmark"
                            type="submit"
                            onClick={""}
                        >
                            Save
                                    </button> */}
              <button
                class="btn btn-primary"
                type="button"
                onClick={() => handleSaveAndNext()}
              >
                {t(
                  state.assessment_title
                    ? "Update Assesment"
                    : "Create Assessment",
                )}
              </button>
            </div>
          </div>
          <br />
        </>
      )}
    </>
  );
}
