import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import Avatar from "../../../assets/images/Avatar.png";

const LectureDetails = (props) => {
  console.log(props, "props");
  // debugger;
  var lecture = props.lecture;
  const Color = props.Color;
  const { firstName } = useContext(AppContext);

  const renderLectureDetails = () => {
    const base_url = "https://app.edsys.in:3000/";
    return (
      // lecture ? lecture && lecture.map((item, index) => {
      //     var cardColor = period.PeriodId
      //     if (cardColor > 6)
      //         cardColor = Math.floor(Math.random() * 6) + 1;
      //     return (
      <div className="col-md-12">
        <div className={"card sub-" + Color[0].bgColor + " stack-card"}>
          <div className="card-header">
            <div className="d-flex">
              <img
                className="flex-grow-0"
                src="assets/images/assignment/subtopicicon.svg"
              />
              <h6 className="flex-grow-1">{lecture.Subject}</h6>
              <div className="class">period {lecture.period}</div>
            </div>
          </div>
          <div className="card-footer">
            <div className="d-flex">
              {/* <img className="flex-grow-0" src={base_url + lecture.Image} /> */}
              <img
                className="flex-grow-0"
                src={lecture.Image ? base_url + lecture.Image : Avatar}
              />
              <div className="flex-grow-1 lec_list_name">
                {firstName === lecture.teacher_name
                  ? "My Period"
                  : lecture.teacher_name}
              </div>
              <div className="time">
                {lecture.StartTime} - {lecture.EndTime}
              </div>
            </div>
          </div>
        </div>
      </div>
      // )

      // }) : null
    );
  };
  return <>{renderLectureDetails()}</>;
};

export default LectureDetails;
