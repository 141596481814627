import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useNavigate } from "react-router-dom";
import { BookOpen, PlusCircle } from "react-feather";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment";

function SubjectwiseList() {
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [exams, setExams] = useState([]);
  const [maxMark, setMaxmark] = useState([]);
  const [students, setStudents] = useState([]);
  const [assessmentId, setAssessmentId] = useState("");
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });

  const nav = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    getSubjectMarklist(location.state.subject.value);
    // AcademicYrListApi();
    GradeListApi();
    // TermListApi(location.state.academicYear.value);
    SubjectListApi(location.state.grade.value);
    console.log(location.state, "nachhhhh");
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.academicYear,
      grade: location?.state?.grade,
      term: location?.state?.term,
      subject: location?.state?.subject,
    }));
  }, []);

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleYearChange = (year) => {
    console.log("Seleected Acad Year", year);
    if (year.value !== formData.academicYear.value) {
      // setAcademicYear(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        academicYear: year,
        term: null,
      }));
      setTermList([]);
      TermListApi(year.value);
    }
  };

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleGradeChange = (grade) => {
    if (grade.value !== formData.grade.value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        grade: grade,
        subject: null,
      }));
      setSubList([]);
      SubjectListApi(grade.value);
    }
  };

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  const handleTermChange = (term) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      term: term,
    }));
    console.log("Selected Term", term);
  };

  const SubjectListApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: [classId],
      assessment_offline_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log("Subjects:", data.list);
          const newData = data.list?.map((item) => {
            return {
              label: item.subject_name,
              value: item.assessment_subject,
              ...item,
            };
          });
          setSubList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleSubjectChange = (subject) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: subject,
    }));
    console.log("Selected Subject", subject);
  };

  const getSubjectMarklist = (subjectId) => {
    setStudents([]);
    if (!subjectId) {
      toast.error("Please Select Subject");
      return;
    }
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_offline_id: location.state?.offlineId,
      term: formData.term?.value
        ? formData.term.value
        : location.state.term.value,
      session: formData.academicYear?.value
        ? formData.academicYear?.value
        : location.state.academicYear.value,
      subject_id: subjectId,
      grade_id: formData.grade?.value
        ? formData.grade?.value
        : location.state.grade.value,
    });
    simplePostCall(ApiConfig.SUBJECT_WISE_MARKLIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          const name_ids = Object.keys(data.data);
          console.log(name_ids, "data in array");
          let newData = [];
          let Internal_exams = [];

          name_ids.map((id) => {
            let examObjects = {
              student_name: data.data[id].student_name,
              student_id: id,
              subject_name: data.data[id].subject_name,
              Internal_exam: data.data[id].Internal_exam,
              student_profile: data.data[id].student_profile,
              subject_feedback: data.data[id].subject_feedback,
              // Exam: data.data[id].Exam[0],
              Total: data.data[id].Total[0],
              rubric: data.data[id].Total[2],
            };
            const Internal_exam = data.data[id].Internal_exam;
            console.log(data.data[id].Internal_exam, "internalname");
            Internal_exam.map((exam) => {
              Internal_exams.push(exam.evaluation_type_code);
              setAssessmentId(exam.assessment_id);
              console.log(exam, "internal examss");
            });
            setMaxmark(Internal_exam);
            newData.push(examObjects);
            console.log("ExamObj", examObjects);
          });
          Internal_exams = [...new Set(Internal_exams)];
          console.log(newData);

          setExams(Internal_exams);
          setStudents(newData);
          console.log(newData, "newData");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const handlePublish = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      result_assessment_id: assessmentId,
    });
    simplePostCall(ApiConfig.PUBLISH_SUBJECTWISE, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  console.log(students, "studentzzz");
  const currentDate = moment().format("YYYY-MM-DD");
  const startDate = location?.state?.startDate;
  console.log(currentDate, "currentDate");
  console.log(startDate, "startDate");
  const shouldShowButton = moment(startDate).isSameOrBefore(currentDate);
  const base_url = "https://app.edsys.in/";
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1 "> View Subject wise List</h5>

                {/* <Link to="/GradewiseList"> */}
                <div className="link" style={{ marginRight: 10 }}>
                  <a
                    type="button"
                    className="btn btn-edsys-primary btn1"
                    onClick={() => {
                      nav("/GradewiseList", {
                        state: {
                          academicYear: formData.academicYear,
                          grade: formData.grade,
                          term: formData.term,
                          offlineId: location.state?.offlineId,
                          StartingDate: startDate,
                        },
                      });
                    }}
                  >
                    <div className="d-flex">
                      <BookOpen className="me-2" />
                      <span style={{}}>Grade Wise MarkList</span>
                    </div>
                  </a>
                </div>
                {/* </Link> */}

                {/* <Link to="/EditSubjectwiseList"> */}
                {/* {shouldShowButton && ( */}
                <a
                  type="button"
                  className="btn btn-edsys-primary "
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() =>
                    nav("/EditSubjectwiseList", {
                      state: {
                        offlineId: location.state?.offlineId,
                        academicYear: formData.academicYear,
                        term: formData.term,
                        SemExam: location?.state?.SemExam,
                        grade: formData.grade,
                        subject: formData.subject,
                      },
                    })
                  }
                >
                  <PlusCircle className="me-2" />
                  <span style={{}}>Edit Subject wise List</span>
                </a>
                {/* )} */}
                {/* </Link> */}
              </div>
            </div>

            {/* <div className="offmarkcad col-md-12 row "> */}
            {/* <div className="col-md-3"> */}
            <div className="container row">
              {/* <SubjectCusDrop /> */}
              {/* <div className=""> */}
              <div className="col">
                <p className="DropTxt">Academic year</p>
                {/* <Select
                      class="js-example-basic-single form-select"
                      name="academicYear"
                      value={formData.academicYear}
                      onChange={handleYearChange}
                      options={academicYearList}
                      isDisabled
                    /> */}
                <div
                  style={{
                    // marginTop: 10,
                    // marginLeft: 10,
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.academicYear?.label}
                </div>
              </div>
              <div className="col">
                <p className="DropTxt">Term</p>

                {/* <Select
                      class="js-example-basic-single form-select"
                      name="terms"
                      value={formData.term}
                      options={termList}
                      onChange={handleTermChange}
                    /> */}
                <div
                  style={{
                    // marginTop: 10,
                    // marginLeft: 10,
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {formData?.term?.label}
                </div>
              </div>
              <div className="col">
                <p className="DropTxt">Exam</p>

                {/* <Select
                      class="js-example-basic-single form-select"
                      name="terms"
                      value={formData.term}
                      options={termList}
                      onChange={handleTermChange}
                    /> */}
                <div
                  style={{
                    // marginTop: 10,
                    // marginLeft: 10,
                    fontFamily: "bold",
                    color: "grey",
                    fontSize: 17,
                  }}
                >
                  {location?.state?.SemExam}
                </div>
              </div>

              <div className="col">
                <p className="DropTxt">Select Grade Division</p>
                <Select
                  class="js-example-basic-single form-select"
                  name="grade"
                  value={formData.grade}
                  onChange={handleGradeChange}
                  options={gradeList}
                />
              </div>

              <div className="col">
                <p className="DropTxt">Select Subject</p>
                <Select
                  class="js-example-basic-single form-select"
                  name="subject"
                  value={formData.subject}
                  onChange={handleSubjectChange} // Update the onChange handler
                  options={subList}
                />
              </div>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <button
                  className="view-mark-list-button col-md-3"
                  onClick={() => getSubjectMarklist(formData.subject?.value)}
                >
                  View Mark List
                </button>
              </div>
              {/* </div> */}

              <p className="p-10 "></p>
            </div>
            {students.length > 0 ? (
              <div style={{ margin: "20px" }} className="col">
                <div className="table">
                  <table>
                    <thead className="thead">
                      <tr>
                        <th style={{ color: "#fff", textAlign: "center" }}>
                          SI. No
                        </th>
                        <th style={{ color: "#fff", textAlign: "center" }}>
                          Student Name
                        </th>

                        {/* <th style={{ color: "#fff", textAlign: "center" }}>
                            Subject
                          </th> */}

                        {maxMark.map((exam, index) => (
                          <React.Fragment key={index}>
                            <th style={{ color: "#fff", textAlign: "center" }}>
                              {exam.evaluation_type_code} Mark (
                              {exam.assessment_max_mark})
                            </th>
                            {/* <th style={{ color: "#fff", textAlign: "center" }}>
                              Max Mark
                            </th> */}
                          </React.Fragment>
                        ))}
                        {/* <th style={{ color: "#fff", textAlign: "center" }}>
                            Exam Mark ({students[0]?.Exam?.assessment_max_mark})
                          </th> */}
                        {/* <th style={{ color: "#fff", textAlign: "center" }}>
                          Max Mark
                        </th> */}
                        {exams.length > 0 && (
                          <th style={{ color: "#fff", textAlign: "center" }}>
                            Total ({students[0]?.Total?.totalMaxMark})
                          </th>
                        )}
                        {/* <th style={{ color: "#fff", textAlign: "center" }}>
                          Max Mark
                        </th> */}
                        <th style={{ color: "#fff", textAlign: "center" }}>
                          Rubric
                        </th>
                        <th style={{ color: "#fff", textAlign: "center" }}>
                          Feedback
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {students.map((item, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td>
                            <img
                              className="rounded-circle"
                              src={
                                item.student_profile
                                  ? base_url + item.student_profile
                                  : dashboardAvatar
                              }
                              alt="image"
                              style={{
                                height: "30px",
                                width: "30px",
                                marginRight: "10px",
                              }}
                              onError={(e) => {
                                e.target.src = dashboardAvatar;
                              }}
                            />
                            {item.student_name}
                          </td>
                          {/* <td style={{ textAlign: "center" }}>
                              {item.subject_name}
                            </td> */}

                          {item.Internal_exam.map((exam, i) => (
                            <React.Fragment key={i}>
                              <td style={{ textAlign: "center" }}>
                                {exam.mark}
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {exam.assessment_max_mark}
                              </td> */}
                            </React.Fragment>
                          ))}

                          {/* <td style={{ textAlign: "center" }}>
                              {item.Exam.mark}
                            </td> */}
                          {/* <td style={{ textAlign: "center" }}>
                            {item.Exam.assessment_max_mark}
                          </td> */}
                          {exams.length > 0 && (
                            <td style={{ textAlign: "center" }}>
                              {item.Total.totalMark}
                            </td>
                          )}
                          <td style={{ textAlign: "center" }}>
                            {item?.Total?.rubric}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item?.subject_feedback}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <button
                    className="view-mark-list-button col-md-4"
                    onClick={handlePublish}
                  >
                    Publish
                  </button>
                </div>
              </div>
            ) : (
              <h6
                className="col-md-9"
                style={{ color: "#000", textAlign: "center", marginTop: 20 }}
              >
                No Marklist
              </h6>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectwiseList;
