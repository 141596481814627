import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import '../../assets/css/select2.css'
import { simplePostCall } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import Params from '../../config/Params'
import SpinnerCmp from '../../components/SpinnerCmp'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"; 

const AddEditTodo = (props) => {
  const { t } = useTranslation();

  const displayStyle = {
    display: 'block',
  }
  const [state, setState] = useState({
    isLoading: false,
    title: '',
    note: '',
    reminder_date: '',
    reminder_time: '',
    reminder: false,
  })

  function onReminderChange() {
    setState({ ...state, reminder: !state.reminder })
  }
  function saveTodo() {
    if (state.title == '' || state.note == '' || state.reminder_date == '') {
      var err_msg = ''
      if (state.title == '') {
        err_msg = 'Enter Title'
      }
      if (state.note == '') {
        err_msg = err_msg == '' ? 'Enter Note' : err_msg + ', Note'
      }
      if (state.reminder_date == '') {
        err_msg =
          err_msg == '' ? 'Enter Reminder Date' : err_msg + ', Reminder Date'
      }
      toast.error(err_msg)
    } else {
      let newRequestBody = {
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        user_id: Params.teacher_id,
        title: state.title,
        note: state.note,
        remainder: state.reminder,
        remainder_date: state.reminder_date + ' ' + state.reminder_time,
        todo_id: props.todo ? props.todo.id : '',
      }

      var API_URL = props.todo.id ? ApiConfig.UPDATE_TODO : ApiConfig.ADD_TODO
      simplePostCall(API_URL, JSON.stringify(newRequestBody))
        .then((data) => {
          console.log(data)
          if (data.result) {
            toast.success(data.message)
            setTimeout(() => {
              window.location.replace('/todo')
            }, 1000)
          } else {
            setState({ ...state, isLoading: false })
            toast.error(data.message)
          }
        })
        .catch((error) => {
          setState({ ...state, isLoading: false })
          console.log('api response', error)
        })
    }
  }
  function saveTodoCancle() {
    window.location.replace('/todo')
  }

  function getTododata(todoData) {
    console.log('todoData', todoData)
    setState({
      ...state,
      title: todoData.title,
      note: todoData.note,
      reminder_date: todoData.remainder_date_only,
      reminder_time: todoData.remainder_time_only,
      reminder: todoData.remainder,
    })
  }

  function onInputChange() {}

  useEffect(() => {
    if (props.todo) {
      getTododata(props.todo)
    }
  }, [])

  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={displayStyle}
      id="dvAddEditTodo"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="dvAddEditTodoLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg"
        role="document"
        style={{ width: '50%' }}
      >
        {state.isLoading ? (
          <SpinnerCmp />
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t('Add Todo Note')}
              </h5>
              <button
                className="btn-close"
                type="button"
                onClick={props.onClose}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {' '}
              </button>
            </div>
            <div className="modal-body">
              <div
                className="form-bookmark needs-validation"
                id="bookmark-form"
              >
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="task-title">{t('Title')}</label>
                    <input
                      value={state.title}
                      onChange={(e) => {
                        setState({ ...state, title: e.target.value })
                      }}
                      className="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="sub-task">{t('Note')}</label>
                    <textarea
                      value={state.note}
                      onChange={(e) => {
                        setState({ ...state, note: e.target.value })
                      }}
                      className="form-control"
                      required=""
                      autoComplete="off"
                    >
                      {' '}
                    </textarea>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label htmlFor="task-title">{t('Reminder Date')}</label>
                      <input
                        value={state.reminder_date}
                        onChange={(e) => {
                          setState({ ...state, reminder_date: e.target.value })
                        }}
                        className="form-control"
                        id="task-title"
                        type="date"
                        required=""
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="task-title">{t('Reminder Time')}</label>
                      <input
                        value={state.reminder_time}
                        onChange={(e) => {
                          setState({ ...state, reminder_time: e.target.value })
                        }}
                        className="form-control"
                        id="task-title"
                        type="time"
                        required=""
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="d-block mb-0">
                      <input
                        onClick={() => onReminderChange()}
                        onChange={onInputChange}
                        className="checkbox_animated"
                        type="checkbox"
                        checked={state.reminder == true ? true : false}
                      />
                      {t('Reminder')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <Link to="/todo"> */}
              <button
                className="btn btn-primary pull-right"
                type="button"
                onClick={props.onClose}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t('Cancel')}
              </button>
              {/* </Link> */}
              <button
                className="btn btn-secondary pull-right"
                id="Bookmark"
                type="submit"
                onClick={saveTodo}
              >
                {props.todo.id ? `${t('Update')}` : `${t('Save')}`}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default AddEditTodo
