import React, { useState, useEffect } from 'react'
import ApiConfig from '../../../api/ApiConfig'
import { simplePostCall } from '../../../api/ApiServices'
import Params from '../../../config/Params'
import { toast } from 'react-toastify'
import SpinnerCmp from '../../../components/SpinnerCmp'
import { useTranslation } from "react-i18next"; 

const toastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const AddEditEmail = (props) => {
  const { t } = useTranslation();
  let editModelData =
    props.data && props.data.mode !== 'add' ? props.data.editData : {}
  const [state, setState] = useState({
    email_subject:
      Object.keys(editModelData).length > 0
        ? editModelData.email_parent_subject
        : '',
    email_message:
      Object.keys(editModelData).length > 0
        ? editModelData.email_parent_message
        : '',
    boarded_status: 'boarded',
    class_ids: ['0'],
    student_ids: ['0'],
    class_id: '',
    class_division_id: '',
  })
  const [classList, setClassList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [filebase64, setFilebase64] = useState('')
  const [loading, setLoading] = useState(false)
  const types = ['alert', 'announcement', 'emergency', 'info', 'message']
  const content_type = ['Text', 'Image', 'PDF', 'Audio']

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  const onInputChangeHandle = (e) => {
    const { name, value, files } = e.target
    if (name === 'class_id') loadSectionList(value)
    else if (name === 'class_division_id') getStudents(state.class_id, value)
    setState({ ...state, [name]: value })
    toast.dismiss()
  }
  const displayStyle = {
    display: 'block',
  }
  const displayFormStyle = {
    display: loading ? 'none' : 'block',
  }
  const ddlBoardedStatus = [
    {
      text: 'Already boarded',
      value: 'boarded',
    },
    {
      text: 'Not yet boarded',
      value: 'notboarded',
    },
  ]

  const onSubmitHandle = (e) => {
    var isvalid = true
    var message = 'please fill in all the mandatory fields..! '
    // debugger;
    // toast.loading("processing..", toastOptions);
    Object.keys(state).forEach((key) => {
      if (isvalid) {
        var fieldName
        if (key === 'email_subject' && !state[key]) {
          isvalid = false
          message = 'Enter Subject'
        } else if (key === 'email_message' && !state[key]) {
          isvalid = false
          message = 'Enter Message'
        } else if (key === 'boarded_status' && state[key].length === 0) {
          isvalid = false
          message = 'Select boarded status'
        } else if (key === 'class_ids' && !state.class_division_id) {
          isvalid = false
          message = 'Select Class/Divisions'
        } else if (key === 'student_ids' && state[key].length === 0) {
          isvalid = false
          message = 'Select student'
        }
      }
      if (!isvalid) {
        return
      }
    })
    if (isvalid) {
      setLoading(true)

      let obj = {
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        employee_id: Params.teacher_id,
        class_ids: [state.class_division_id ? state.class_division_id : ''],
        student_ids: window.elHelper.getSelectedOptions('selectStudents'),
        email_subject: state.email_subject,
        email_message: state.email_message,
        boarded_status: state.boarded_status,
      }
      simplePostCall(ApiConfig.EMAIL_ADD_API, JSON.stringify(obj))
        .then((data) => {
          if (data.result) {
            toast.success(data.message, toastOptions)
            props.onClose(true)
          } else {
            setLoading(false)
            toast.success(data.message, toastOptions)
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Something went wrong. please try again!', toastOptions)
          console.log('api response', error)
        })
    } else {
      toast.error(message, toastOptions)
    }
  }

  function loadClassList() {
    simplePostCall(
      ApiConfig.CLASSES_LIST_API,
      JSON.stringify({ ...Params, user_id: Params.teacher_id }),
    )
      .then((data) => {
        if (data.result) {
          setClassList(data.list)
          setState({ ...state, class_id: data.list[0].class_id })
          loadSectionList(data.list[0].class_id)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }
  function loadSectionList(classId) {
    simplePostCall(
      ApiConfig.SECTION_LIST_API,
      JSON.stringify({
        ...Params,
        user_id: Params.teacher_id,
        class_id: classId,
      }),
    )
      .then((data) => {
        if (data.result) {
          let section_id = data.list[0].class_division_id
          setSectionList(data.list)
          getStudents(classId, section_id)
          setStudentList([])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const getStudents = (classId, section_id) => {
    // setIsloading(true);
    const newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classId,
      section_id: section_id,
    }
    simplePostCall(ApiConfig.STUDENTS_API, JSON.stringify(newRequestBody))
      .then((data) => {
        // setIsloading(false)
        if (data.result) {
          setStudentList(data.list)
        }
      })
      .catch((error) => {
        // setIsloading(false)
        console.log('api response', error)
      })
  }

  useEffect(() => {
    loadClassList()
    window.elHelper.initSelect2()
  }, [])
  useEffect(() => {}, [sectionList])

  return (
    <div
      className="modal fade show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <SpinnerCmp loading={loading} />
        <div className="modal-content" style={displayFormStyle}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.data.mode === 'view'
                ? 'View'
                : props.data.mode === 'edit'
                ? `${t('Edit')}`
                : `${t('Create')}`}{" "}
              {t('Email')}
            </h5>
            <button
              className="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {' '}
            </button>
          </div>
          <div className="modal-body datetime-picker modal-scroll">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Subject")}</label>
                  <input
                    className="form-control"
                    id="task-title"
                    type="text"
                    readOnly={props.data.mode === 'view'}
                    required=""
                    autocomplete="off"
                    name="email_subject"
                    value={state.email_subject}
                    onChange={onInputChangeHandle}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label for="task-title">{t("Message")}</label>
                  <textarea
                    className="form-control"
                    id="task-title"
                    type="text"
                    readOnly={props.data.mode === 'view'}
                    required=""
                    autocomplete="off"
                    name="email_message"
                    value={state.email_message}
                    onChange={onInputChangeHandle}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Boarded Status")}</label>
                  <select
                    //   className="js-example-basic-single form-select"
                    className="form-select"
                    name="boarded_status"
                    onChange={onInputChangeHandle}
                    defaultValue={state.boarded_status}
                    disabled={props.data.mode !== 'add'}
                  >
                    {ddlBoardedStatus.length > 0
                      ? ddlBoardedStatus.map((status, index) => {
                          return (
                            <option key={index} value={status.value}>
                              {status.text}
                            </option>
                          )
                        })
                      : null}
                  </select>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label for="task-title">{t("Class")}</label>
                    <select
                      className="js-example-basic-single form-select"
                      name="class_id"
                      value={state.class_id}
                      onChange={onInputChangeHandle}
                    >
                      {classList && classList.length > 0
                        ? classList.map((obj, index) => (
                            <option key={index} value={obj.class_id}>
                              {obj.class_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Select Section")}</label>
                    <select
                      // id="selectDivisions"
                      // className="js-example-basic-single form-select"
                      className="form-select"
                      name="class_division_id"
                      value={state.class_division_id}
                      onChange={onInputChangeHandle}
                    >
                      <option value="">All</option>
                      {sectionList.length > 0
                        ? sectionList.map((obj, index) => {
                            return (
                              <option key={index} value={obj.class_division_id}>
                                {obj.class_division_name}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">{t("Students")}</label>
                  <select
                    id="selectStudents"
                    className="js-example-basic-multiple form-select"
                    name="student_ids"
                    value={state.student_ids}
                    onChange={(e) => {
                      alert('d')
                    }}
                    onSelect={(e) => {
                      alert('fdfd')
                    }}
                    multiple="multiple"
                  >
                    <option value="0" selected={true}>
                      All
                    </option>
                    {studentList.length > 0
                      ? studentList.map((obj, index) => {
                          return (
                            <option key={index} value={obj.student_id}>
                              {obj.student_name}
                            </option>
                          )
                        })
                      : null}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {props.data.mode !== 'view' ? (
              <button
                className="btn btn-secondary"
                id="Bookmark"
                type="submit"
                onClick={onSubmitHandle}
              >
                {t("Save")}
              </button>
            ) : null}
            <button
              className="btn btn-primary"
              type="button"
              onClick={props.onClose}
              data-bs-dismiss="modal"
            >
               {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddEditEmail
