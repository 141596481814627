import React from "react";
import "../../../assets/css/LoadingModal.css"; // Optional: For custom styling

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-modal-content">
        <div className="spinner"></div> {/* Optional: Add a spinner */}
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingModal;
