import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaAnchor, FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaSearchPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

export const MainGradeList = () => {
  const navic = useNavigate();
  const [gradelis, setGradelis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [evalDefault, setEvalDefault] = useState(false);

  //=============LIST===================//
  const fetchGradeList = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.GRADEWISE_LIST, RequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          const arry = data.list.map((item, index) => ({
            id: index + 1,
            name: item.assessment_grading_name,
            state_id: item.assessment_grading_id,
            assessment_grading_status: item.assessment_grading_status,
            assessment_grading_id: item.assessment_grading_id,
          }));
          setGradelis(arry);
          console.log(arry, "gradelist");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("api res fail", err);
        setLoading(false);
      });
  };
  const GradingSetAsDefault = (type_id) => {
    console.log(type_id, "type_id");

    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      grading_id: type_id,
    });
    simplePostCall(ApiConfig.GRADEING_SET_AS_DEFAULT, newRequestBody)
      .then((data) => {
        console.log(data, "Evalution Data");
        if (data.result) {
          toast.success(data.message);
          setEvalDefault(true);
          fetchGradeList();
        } else {
          setEvalDefault(false);
          toast.error(data.message);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  //===============DELETE==============//
  const DeleteGradeList = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: delet,
    });
    simplePostCall(ApiConfig.GRADEE_LIST_DELETE, RequestBody)
      .then((data) => {
        console.log("Response data:", data);
        if (data.result) {
          toast.success(data?.message);
          fetchGradeList();
        } else {
          toast.error(data?.message);
          console.log("false");
        }
      })
      .catch((error) => {
        console.error("Error in catch block:", error);
        toast.error("Delete Failed");
      });
  };

  useEffect(() => {
    fetchGradeList();
  }, []);
  const [delet, setDelet] = useState("");
  return (
    <div className="evaluationn">
      <div className="evalHead">
        <h5 className="labelCreate">Grading List</h5>
        <div className="createBut">
          <div className="CreateB" onClick={() => navic("/createGradelist")}>
            Create Grading List
          </div>
        </div>
      </div>
      <div className="table maingradeStyle">
        <table>
          <thead>
            <tr>
              <th className="textcol">Sl no</th>
              <th className="textcol">Grading Name</th>
              <th className="textcol" style={{ textAlign: "center" }}>
                Status
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                {[...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td className="textcol">
                      <Skeleton width={20} />
                    </td>
                    <td className="textcol">
                      <Skeleton width={150} />
                    </td>
                    <td className="textcol">
                      <Skeleton width={150} />
                    </td>
                    <td>
                      <div className="iconss">
                        <div className="">
                          <Skeleton circle width={20} height={20} />
                        </div>
                        <div className="">
                          <Skeleton circle width={20} height={20} />
                        </div>
                        <div className="">
                          <Skeleton circle width={20} height={20} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              gradelis.map((item) => (
                <tr key={item.id}>
                  <td className="textcol">{item.id}</td>
                  <td className="textcol">{item.name}</td>
                  <td className="textcol" style={{ textAlign: "center" }}>
                    {item.assessment_grading_status}
                  </td>
                  <td className="iconss">
                    <div
                      className="sear"
                      onClick={() =>
                        navic("/GradeList", { state: item.state_id })
                      }
                    >
                      <FaSearchPlus
                        data-tip="View"
                        data-place="top"
                        className="icons"
                      />
                    </div>
                    <div
                      className="editb"
                      onClick={() =>
                        navic("/GradelistEdit", { state: item.state_id })
                      }
                    >
                      <FaEdit
                        data-tip="Edit"
                        data-place="top"
                        className="icons"
                      />
                    </div>
                    <ReactTooltip effect="solid" />
                    {item.assessment_grading_status === "default" ? (
                      <div />
                    ) : (
                      <div className="searc">
                        <div
                          className="sear"
                          // className="icons"
                          data-tip="Set as Default"
                          data-place="top"
                          onClick={() =>
                            GradingSetAsDefault(item.assessment_grading_id)
                          }
                        >
                          <FaAnchor
                            className="icons"
                            data-tip="Set as Default"
                            data-place="top"
                            // className="icons"
                          />
                        </div>

                        <div data-tip="Delete" data-place="top">
                          <button
                            type="button"
                            class="del"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => setDelet(item.state_id)}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    )}
                    <ReactTooltip effect="solid" />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Delete
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">Are you sure want to delete</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => DeleteGradeList()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
