import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import No_List from "../../api/No_list.json";
import online_study from "../../assets/images/e-learning/online-study.svg";
import { BookOpen, Edit, Trash2 } from "react-feather";
import MyResources_new from "./MyResources_new";
import Pending_Resources from "./Pending_Resources";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import moment from "moment";
import SpinnerCmp from "../../components/SpinnerCmp";
import Select from "react-select";
import ResourceSkeleton from "../../components/skeletons/ResourceSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const E_Learning_Details = () => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    getCatagrytypeList();
    console.log("DDAATTAA", location.state);
    return () => {};
  }, []);

  const [isTabShow, SetisTabShow] = useState(false);
  const [isTabShowPending, SetisTabShowPending] = useState(false);
  const [LearningList, SetLearningList] = useState([]);
  const [nextPage, setNextPage] = useState(0);
  console.log("nextPage", nextPage);
  const [isloading, setIsloading] = useState(false);
  const [resource_type_id, setResource_type_id] = useState(null);
  const [TotalCourses, setTotalCourses] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [state, setState] = useState({
    class_division_id: "",
  });

  const [typeState, setTypeState] = useState({
    isloading: false,
    catagryType: [],
    catagrydata: [],
  });
  const [page, setPage] = useState(1);
  console.log("page", page);

  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
    setIsloading(true);
    getElearning(page);
    return () => {};
  }, [state.selectedGradeType]);
  const Showmodal = () => {
    SetisTabShow(true);
  };
  const ShowmodalPending = () => {
    SetisTabShowPending(true);
  };
  function getCatagrytypeList() {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      category_id: "",
    });
    setTypeState({ ...typeState, isloading: true });

    simplePostCall(ApiConfig.E_LEARNING_TYPE, newRequestBody)
      .then((data) => {
        if (data.result) {
          var type = [{ label: "All", value: "" }];
          var category = [];

          data.type.map((dataType, index) => {
            type.push({
              label: dataType.resource_type_name,
              value: dataType.resource_type_id,
            });
            setResource_type_id(dataType.resource_type_id);
          });
          // console.log("TTTYYYpPPP",type);
          data.category.map((dataType, index) => {
            category.push({
              label: dataType.resource_category_name,
              value: dataType.resource_category_id,
            });
          });
          setTypeState({
            ...typeState,
            isloading: false,
            catagryType: type,
            catagrydata: category,
          });
        } else {
          setTypeState({ ...typeState, isloading: false });
        }
      })
      .catch((error) => {
        setTypeState({ ...typeState, isloading: false });
        console.log("api response", error);
      });
  }

  function getElearning(currentPage) {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      subject: location?.state?.subject_id,
      class: location?.state?.class_id,
      resources_type:
        state.selectedGradeType && state.selectedGradeType.value
          ? state.selectedGradeType && state.selectedGradeType.value
          : "",
      page: currentPage,
      page_limit: 20,
    };
    simplePostCall(ApiConfig.ALL_RESOURCES_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          setIsloading(false);
          let total = data.total_count ? data.total_count : 0;
          setTotalCourses(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            SetLearningList(data.data);
            setNextPage(data.next_page);
            setTotalPages(data.total_pages);
            setIsloading(false);
          } else {
            SetLearningList([...LearningList, ...LearningData]);
            setIsloading(false);
          }
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function ontypeChange(selectedOption) {
    setPage(1);
    setState({
      ...state,
      selectedType: selectedOption.value,
      selectedGradeType: selectedOption,
    });
  }
  const onPageLoad = () => {
    setPage(page + 1);
    getElearning(page + 1);
  };
  // Function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }
  return (
    <>
      {/* {isloading ? (
        <SpinnerCmp />
      ) : ( */}
      <div className="page-bodys">
        {/* <!-- Container-fluid starts--> */}
        <div
          className="card"
          style={{
            // float: 'left',
            // marginTop: '-110px',
            borderRadius: "15px",
          }}
        >
          <Link to="/E_Learning" className="col-md-4">
            <h6
              style={{
                padding: "10px",
                marginTop: "5px",
              }}
            >
              {t("Back")}
            </h6>
          </Link>
        </div>
        <div className="row e-learning-detail view-review view-assignment">
          <div className="col-12">
            <div className="card br-15">
              <div className="card-body header-card">
                <div className="student-header">
                  <div className="details-banner">
                    {/* <h4>PHYSICS (Part 01)</h4> */}
                    <form
                      className="form-bookmark needs-validation"
                      id="bookmark-form"
                      novalidate=""
                    >
                      <div className="form-row row">
                        <div className="form-group col-md-4">
                          <label for="task-title">{t("Type")}</label>
                          <Select
                            class="js-example-basic-single form-select"
                            value={state.selectedGradeType}
                            onChange={ontypeChange}
                            options={typeState.catagryType}
                            maxMenuHeight={"auto"}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="student-image">
                  <div className="student-avatar">
                    <img src={online_study} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {t("All Resources")}

                  <div className="media"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={Showmodal}
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  {t("My Resources")}
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  onClick={ShowmodalPending}
                  className="nav-link"
                  id="pills-profile-tab-2"
                  data-bs-toggle="pill"
                  href="#pills-profile-2"
                  role="tab"
                  aria-controls="pills-profile-2"
                  aria-selected="false"
                >
                  {t("Pending Resources")}
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-12">
            <div className="card br-15 e-learning-item border-0">
              <div className="card-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {isloading ? (
                      <ResourceSkeleton loading={isloading} />
                    ) : (
                      <InfiniteScroll
                        style={{
                          overflow: "hidden",
                        }}
                        dataLength={LearningList.length}
                        next={onPageLoad}
                        hasMore={LearningList.length !== TotalCourses}
                        loader={
                          <p style={{ textAlign: "center", marginTop: "20px" }}>
                            Loading....
                          </p>
                        }
                        // endMessage={
                        //   <p
                        //     style={{
                        //       textAlign: "center",
                        //       marginTop: "20px",
                        //       marginBottom: "15px",
                        //     }}
                        //   >
                        //     <b>No More data Found</b>
                        //   </p>
                        // }
                      >
                        <div className="row">
                          {LearningList && LearningList.length > 0 ? (
                            LearningList.map((postDetail, index) => (
                              <Link
                                to={`/ImgVideoGallaryNew/${postDetail.resources_id}`}
                                className="col-md-4"
                              >
                                <div className="card br-15 cursor-pointer-on">
                                  <div className="card-header br-15 pb-0">
                                    <div className="d-flex">
                                      <h5 className="title flex-grow-1">
                                        {postDetail.resources_name}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="card-body cursor-pointer-on">
                                    <div className="d-flex">
                                      <div className="me-auto c-date">
                                        {formatDate(
                                          postDetail.resources_created_date,
                                        )}
                                      </div>
                                      <div className="type">
                                        {postDetail.resource_category_name}
                                      </div>
                                      <div className="type-icon">
                                        <BookOpen />
                                      </div>
                                    </div>
                                    <div className="footer-blk">
                                      Published by
                                    </div>
                                    <div className="footer-blk-detail">
                                      {postDetail.published_by}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ))
                          ) : (
                            <div className="d-flex align-items-center">
                              <span>{t("No records founds")}</span>
                            </div>
                          )}
                        </div>
                      </InfiniteScroll>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {isTabShow ? (
                      <MyResources_new
                        onClose={Showmodal}
                        // data={MyResourcesList}
                        // apiData={getMyResources}
                        state={state}
                        data={location.state}
                      />
                    ) : null}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-profile-2"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab-2"
                  >
                    {isTabShowPending ? (
                      <Pending_Resources
                        onClose={ShowmodalPending}
                        data={location.state}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
      {/* )} */}
    </>
  );
};

export default E_Learning_Details;
