import React, { useEffect, useState } from "react";
import "../../assets/css/myDirayCheck.css";
import watch_icon from "../../assets/images/watch_icon.svg";
import view_note_icon from "../../assets/images/view_note_icon.svg";
import schedule_icon_white from "../../assets/images/schedule_icon_white.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { simpleGetCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import axios from "axios";
import { FaPlus } from "react-icons/fa6";

import ListSkeleton from "../../components/skeletons/ListSkeleton";

function MyDiary() {
  const [selectedDateRadio, setSelectedDateRadio] = useState(null);
  const navic = useNavigate();
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState("");
  const [show1, setShow1] = useState(false);
  const [showLi1, setShowLi1] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [diaryListOne, setDiaryListOne] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const [dateStore, setdateStore] = useState("");
  const [Filter, setFilter] = useState("");
  const [Priority_Filter, setPriority_Filer] = useState("");
  const [high, sethigh] = useState(false);
  const [medium, setmedium] = useState(false);
  const [low, setlow] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [Isloading, setIsloading] = useState(true);

  useEffect(() => {
    diaryList(page + 1);
  }, [Filter, dateStore, Priority_Filter]);

  // <------------------------------------->
  const handleClose = () => {
    setShow(false);
    // setShowLi1(false); // Add this line to hide prio_div when modal closes
  };
  const handleShow = () => setShow(true);
  // <------->

  const handlePriorityChange = (e) => {
    if (selectedRadio !== "s-option1") {
      setShowLi1(e.target.checked);
    }
  };
  // ---------

  const checkClose = () => {
    setShow1(false);
  };

  const handleClose1 = () => {
    setShow1(false);
    diaryList(page + 1);
  };

  const handleShow1 = () => setShow1(true);

  // <--------->
  // futuredate disable code
  const handleDateChange = (date) => {
    setSelectedDay(date);
    calenderChange(date);
  };

  // <------------>

  function calenderChange(dateObj) {
    const { day, month, year } = dateObj;

    const monthIndex = month - 1;

    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth =
      monthIndex < 9 ? "0" + (monthIndex + 1) : monthIndex + 1;

    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    setdateStore(formattedDate);

    return formattedDate;
  }
  const allFunctions = () => {
    setFilter("read");
    diaryList(page);
    handleClose();
  };
  // <------modal radio---------->

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };
  // <------------------------------>

  const diaryList = (currentPage) => {
    try {
      axios(ApiConfig.DIARY_LIST, {
        method: "GET",
        params: {
          teacher_id: Params.teacher_id,
          page_no: currentPage,
          page_limit: 20,
          search: "",
          data_filter: dateStore,
          status_filter: Filter,
          priority_filter: Priority_Filter,
        },
        headers: {
          Accept: "application/json",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      })
        .then((data) => {
          if (data.data.result) {
            setDiaryListOne([]);
            let total = data?.data?.next_page ? data?.data?.next_page : false;
            // console.log(page === total, "total");
            setTotal(total);
            if (currentPage === 1) {
              const temp = [];
              Object.entries(data.data.diary_list).map(([key, value]) => {
                temp.push({
                  date: key,
                  data: value,
                });
              });
              const outputArray = [];
              temp.forEach((item) => {
                const index = outputArray.findIndex(
                  (obj) => obj.date == item.date,
                );
                if (index >= 0) {
                  outputArray[index].data.push(...item.data);
                } else {
                  outputArray.push({ date: item.date, data: item.data });
                }
              });
              setDiaryListOne([]);
              setDiaryListOne(outputArray);
            } else {
              const temp = [...diaryListOne];
              Object.entries(data.data.diary_list).map(([key, value]) => {
                temp.push({
                  date: key,
                  data: value,
                });
              });
              const outputArray = [];
              temp.forEach((item) => {
                const index = outputArray.findIndex(
                  (obj) => obj.date == item.date,
                );
                if (index >= 0) {
                  outputArray[index].data.push(...item.data);
                } else {
                  outputArray.push({ date: item.date, data: item.data });
                }
              });
              setDiaryListOne(outputArray);
            }
          } else {
            setDiaryListOne([]);
            setIsloading(false);
          }
        })
        .catch((err) => {
          console.log("cath error", err);
        });
    } catch (err) {
      console.log("Planned TripListApi Error: ", err);
      throw err;
    }
  };
  const onPageLoad = () => {
    setPage(page + 1);
    diaryList(page + 1);
  };
  const levels = () => {
    sethigh(true);
    setmedium(false);
    setlow(false);
  };
  const levels1 = () => {
    sethigh(false);
    setmedium(true);
    setlow(false);
  };
  const levels2 = () => {
    sethigh(false);
    setmedium(false);
    setlow(true);
  };
  const handleClear = () => {
    setPriority_Filer("");
    setdateStore("");
    setFilter("");
    setSelectedRadio(null);
    sethigh("");
    setmedium("");
    setlow("");
    diaryList(page);
  };
  // const onPageLoad = () => {
  //   console.log(totalPages, page, "currentpage");
  //   if (totalPages > page) {
  //     setPage(page + 1);
  //     diaryList({ currentPage: page + 1 });
  //   }
  // };

  return (
    <>
      <div class="MyDiary SchoolTvChannel announcementnew">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>My Diary</label>
                <div className="fill_plus_div">
                  <img src={filter_icon} alt="" onClick={handleShow} />
                  <div className="create" onClick={() => navic("/CreateDiary")}>
                    <FaPlus className="plusIcone" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <InfiniteScroll
                style={{
                  overflow: "hidden",
                }}
                dataLength={diaryListOne?.length}
                next={onPageLoad}
                hasMore={total !== false}
                // loader={
                //   <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                //     No Diary Found
                //   </h4>
                // }
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    <b> No Diary Found</b>
                  </p>
                }
              >
                {diaryListOne?.map((diary, index) => (
                  <div className="rjmnhjkn jkw" key={index}>
                    <div className="col-md-12">
                      <div className="diary-date">
                        <p>{diary.date}</p>
                      </div>
                    </div>

                    <div className="row">
                      {diary.data && diary.data.length > 0
                        ? diary.data.map((entry, index) => {
                            let classIndex =
                              index <= 5
                                ? index + 1
                                : Math.floor(Math.random() * 5) + 1;

                            const getClassNames = (index) => {
                              switch (index) {
                                case 1:
                                  return "color-1";
                                case 2:
                                  return "color-2";
                                case 3:
                                  return "color-3";
                                case 4:
                                  return "color-4";
                                case 5:
                                  return "color-5";

                                default:
                                  return "";
                              }
                            };

                            return (
                              <div
                                className="col-md-4 cursors"
                                key={entry.diary_id}
                              >
                                <div
                                  onClick={() =>
                                    navic("/NoteView", {
                                      state: entry.diary_id,
                                    })
                                  }
                                  className={
                                    "program-card " + getClassNames(classIndex)
                                  }
                                >
                                  <div className="noti-count">
                                    {entry.diary_read_comments}
                                  </div>
                                  <div className="pc-class">
                                    <div className="period">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        fill="#772569"
                                        className="bi bi-journal-bookmark"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8"
                                        />
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="pc-details">
                                    <div className="diary-title">
                                      <p
                                        style={{
                                          maxWidth: "200px",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {entry.diary_title}
                                      </p>
                                      <p className="timing">
                                        <img src={watch_icon} alt="" />{" "}
                                        {entry.diary_time}
                                      </p>
                                    </div>
                                    <div className="diary-sub-wrapper">
                                      <button className="subject">
                                        {entry?.diary_class[0]}
                                      </button>
                                      <button className="subject">
                                        {entry.diary_preiority}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Filter By</p>
              <p
                className="clears"
                onClick={() => {
                  handleClose();
                  handleClear();
                }}
              >
                Clear
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select-wrapper">
            <ul>
              <li>
                <input
                  type="radio"
                  id="d-option"
                  name="selector"
                  checked={selectedRadio === "d-option"}
                  onClick={() => {
                    handleClose();
                    handleShow1();
                    // allFunctions();
                    handleRadioChange("d-option");
                  }}
                />
                <label for="d-option">Date</label>

                <div class="check" onClick={() => allFunctions()}>
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input
                  type="radio"
                  id="s-option"
                  name="selector"
                  checked={selectedRadio === "s-option"}
                  onClick={() => {
                    allFunctions();
                    handleRadioChange("s-option");
                  }}
                />
                <label for="s-option">Unread</label>

                <div class="check" onClick={() => allFunctions}>
                  <div class="inside"></div>
                </div>
              </li>

              <li>
                <input
                  type="radio"
                  id="t-option"
                  name="selector"
                  checked={selectedRadio === "t-option"}
                  onClick={() => {
                    setFilter("unread");

                    handleClose();
                    handleRadioChange("t-option");
                  }}
                />
                <label for="t-option">Read</label>

                <div
                  class="check"
                  onClick={() => {
                    setFilter("unread");

                    handleClose();
                  }}
                >
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input
                  type="radio"
                  id="s-option1"
                  name="selector"
                  checked={selectedRadio === "s-option1"}
                  onChange={(e) => {
                    handleRadioChange("s-option1");
                    handlePriorityChange(e);
                  }}
                />
                <label for="s-option1">Priority</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              {showLi1 && (
                <div className="prio_div">
                  <input
                    type="radio"
                    id="s-option1"
                    name="selector"
                    onChange={handlePriorityChange}
                    style={{ visibility: "hidden" }}
                  />
                  <div class="check" style={{ visibility: "hidden" }}>
                    <div class="inside"></div>
                  </div>
                  {/* start */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="priorit_option High">
                      <label
                        onClick={() => {
                          setPriority_Filer("High");

                          handleClose();
                        }}
                        for="s-option4"
                        style={{ color: high ? "#732565" : null }}
                      >
                        High
                      </label>
                      {/* <-------? */}
                      <input
                        type="radio"
                        id="s-option4"
                        name="priority"
                        checked={selectedRadio === "s-option4"}
                        onClick={() => {
                          levels();
                          setPriority_Filer("High");

                          handleClose();
                          handleRadioChange("s-option4");
                        }}
                        onChange={handlePriorityChange}
                        className="priorit_Radio"
                      />
                    </div>

                    <div className="priorit_option Medium">
                      <label
                        for="s-option2"
                        onClick={() => {
                          setPriority_Filer("Medium");

                          handleClose();
                        }}
                        style={{ color: medium ? "#732565" : null }}
                      >
                        Medium
                      </label>
                      {/* <-------? */}
                      <input
                        type="radio"
                        id="s-option2"
                        name="selector"
                        checked={selectedRadio === "s-option2"}
                        onChange={handlePriorityChange}
                        onClick={() => {
                          levels1();
                          setPriority_Filer("Medium");

                          handleClose();

                          handleRadioChange("s-option2");
                        }}
                        className="priorit_Radio"
                      />
                    </div>
                    <div className="priorit_option Low">
                      <label
                        onClick={() => {
                          setPriority_Filer("Low");

                          // handleClose();
                        }}
                        for="s-option3"
                        style={{ color: low ? "#732565" : null }}
                      >
                        Low
                      </label>
                      {/* <-------? */}
                      <input
                        type="radio"
                        id="s-option3"
                        name="selector"
                        checked={selectedRadio === "s-option3"}
                        onClick={() => {
                          levels2();
                          setPriority_Filer("Low");

                          handleClose();
                          handleRadioChange("s-option3");
                        }}
                        onChange={handlePriorityChange}
                        className="priorit_Radio"
                      />
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* Filter Modal End */}

      {/* Date Selector Modal Start */}
      <Modal
        show={show1}
        onHide={handleClose1}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Body className="date-picker-body">
          <Calendar
            value={selectedDay}
            onChange={(e) => {
              calenderChange(e);
              setSelectedDay(e);
            }}
            shouldHighlightWeekends
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper">
            <button className="calendar-btn-1" onClick={handleClose1}>
              Cancel
            </button>
            <button className="calendar-btn-2" onClick={handleClose1}>
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Date Selector Modal End */}
    </>
  );
}

export default MyDiary;
