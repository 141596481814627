import React, { useState } from 'react';
//import AssessmentModal from "./AssesmentModal";

import { Link } from 'react-router-dom';
import { Edit3, PlusCircle, Trash2 } from 'react-feather';

//import assignment_card_data from "../../../api/assignment_card_data.json";
//import AssessmentDetails from "../Assesment/AssessmentDetails";
import AssesmentModal from './AssesmentModal';
import { simplePostCall } from '../../../api/ApiServices';
import ApiConfig from '../../../api/ApiConfig';
import Params from '../../../config/Params';
import subtopicicon from '../../../assets/images/assignment/subtopicicon.svg';
import SpinnerCmp from '../../../components/SpinnerCmp';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactSelect from 'react-select';
import filter from '../../../assets/images/filter.svg';

export class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTodoModelShow: false,
      isTagModelShow: false,
      assessments: [],
      isloading: true,
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = 'Edsys | Assessment';
    this.getAssessments();
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isTodoModelShow: false,
      isTagModelShow: false,
      assessments: [],
    });
  };
  openCloseTodoModel = () => {
    // this.setInitialState();
    this.setState(
      { ...this.state, isTodoModelShow: !this.state.isTodoModelShow },
      () => {}
    );
  };
  openCloseTagModel = () => {
    // this.setInitialState();
    this.setState({
      ...this.state,
      isTagModelShow: !this.state.isTagModelShow,
    });
  };
  //#endregion
  //Load Assignments start

  getAssessments = () => {
    simplePostCall(ApiConfig.ASSESSMENT_LIST_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          this.setState({
            ...this.state,
            isloading: false,
            assessments: data.data,
          });
        } else this.setState({ ...this.state, isloading: false });
      })
      .catch((error) => {
        this.setState({ ...this.state, isloading: false });
        console.log('api response', error);
      });
  };
  topicsOptions = [
    { value: 'Introduction', label: 'Introduction' },
    { value: 'Topic 1', label: 'Topic 1' },
    { value: 'Topic 2', label: 'Topic 2' },
    { value: 'Topic 3', label: 'Topic 3' },
    { value: 'Topic 4', label: 'Topic 4' },
  ];
  subjectOptions = [
    { value: 'English', label: 'English' },
    { value: 'Maths', label: 'Maths' },
    { value: 'Science', label: 'Science' },
    { value: 'Physics', label: 'Physics' },
    { value: 'Social Studies', label: 'Social Studies' },
  ];
  classOptions = [
    { value: '6th A', label: '6th A' },
    { value: '10th A', label: '10th A' },
    { value: '12th A', label: '12th A' },
  ];
  //end
  ///
  //// Filter api ///

  //#region Render

  render() {
    return (
      <>
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            {this.state.isloading ? (
              <SpinnerCmp loading={this.state.isloading} />
            ) : (
              <div className="card">
                <div className="card-header pb-0">
                  <div className="d-flex">
                    <h5 className="flex-grow-1">Assessment</h5>
                    <Link
                      to="/create-assessment"
                      type="button"
                      //data-bs-toggle="modal"
                      //onClick={this.openCloseTodoModel}
                      //data-bs-target="#exampleModal"
                      className="btn btn-edsys-primary"
                    >
                      
                      <PlusCircle className="me-2" />
                      <span>Create New Assessment</span>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          // value={state.selectedCurriculum}
                          // onChange={onCurriculumChange}
                          options={this.classOptions}
                          placeholder="filter By Class..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          // value={state.selectedCurriculum}
                          // onChange={onCurriculumChange}
                          options={this.subjectOptions}
                          placeholder="filter By Subject..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          // value={state.selectedCurriculum}
                          // onChange={onCurriculumChange}
                          options={this.topicsOptions}
                          placeholder="filter By Topic..."
                        />
                      </div>
                      <div className="col text-end">
                        <div class="card-body p-0">
                          <img src={filter} alt="" />
                        </div>
                      </div>
                    </div>
                    {this.state.assessments &&
                    this.state.assessments?.length > 0
                      ? this.state.assessments.map((assessment, index) => {
                          let classIndex =
                            index < 6
                              ? index + 1
                              : Math.floor(Math.random() * 6) + 1;
                          return (
                            <div className="col-md-6 mt-4">
                              <div
                                className={
                                  'card sub-' + classIndex + ' stack-card'
                                }
                              >
                                <div className="card-header">
                                  <div className="d-flex">
                                    <img
                                      className="flex-grow-0"
                                      src={subtopicicon}
                                    />
                                    <h6 className="flex-grow-1">
                                      {assessment.assessment_subject}
                                    </h6>
                                    <div className="class">
                                      {assessment.assessment_class}
                                    </div>
                                  </div>
                                </div>

                                <Link
                                  className="name card-body"
                                  to={
                                    '/AssessmentDetails/' +
                                    assessment.assessment_id
                                  }
                                  // className="card-body"
                                >
                                  <h5>{assessment.assessment_title}</h5>
                                </Link>

                                <div className="card-footer">
                                  <div className="d-sm-flex">
                                    <h5>
                                      {assessment.assessment_created_date}
                                    </h5>
                                    <div className="action-list">
                                      <ul className="list-unstyled">
                                        <li>
                                          <div
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal2"
                                            className="setting-secondary"
                                          >
                                            <Link
                                              to={
                                                '/create-assessment/' +
                                                assessment.assessment_id
                                              }
                                            >
                                              <i data-feather="edit-3">
                                                <Edit3 />
                                              </i>
                                            </Link>
                                          </div>
                                        </li>
                                        <li>
                                          <a
                                            data-bs-toggle="modal"
                                            data-original-title="test"
                                            data-bs-target="#deleteWarning"
                                            className="setting-secondary"
                                          >
                                            <i data-feather="trash-2">
                                              <Trash2 />
                                            </i>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- Container-fluid Ends--> */}
      </>
    );
  }
}

export default Assessment;
