import React, { useState, useEffect } from "react";

import SmsCardData from "../../../api/Sms_card_data.json";

import AnnouncementModel from "./AnnouncementModel";
import { Edit3, Trash2, PlusCircle } from "react-feather";
import dashboardAvatar from "../../../assets/images/dashboard/1.png";
// import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { HTML5_FMT } from "moment";
import { Link } from "react-router-dom";

const AnnouncementNew = ({ dark }) => {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);

  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">SMS</h5>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>Create New SMS</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? (
                  <AnnouncementModel onClose={openCloseTodoModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                {SmsCardData.map((postDetail, index) => (
                  <div className="col-md-6 mb-3" key={index}>
                    <Link to="/ViewAnnouncementNew">
                      <div
                        className={`${postDetail.card_bgcolor} ${
                          dark ? "dark-only" : ""
                        }`}
                      >
                        <div className="card-header d-flex justify-content-between">
                          <img
                            className="img-90 rounded-circle"
                            src={dashboardAvatar}
                            alt="image"
                            style={{ height: "50px", width: "50px" }}
                          />
                          <div className="class-body">
                            {postDetail.Participants}
                          </div>
                        </div>

                        <div className="card-body">
                          {postDetail.assignment_title}
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                          <h5>{postDetail.date}</h5>
                          <div className="class">
                            {postDetail.division_grade}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementNew;

// import React, { useEffect, useState } from "react";
// import {
//   Video,
//   FileText,
//   Image,
//   File,
//   Headphones,
//   Trash2,
//   Edit3,
//   Eye,
//   PlusCircle,
// } from "react-feather";
// import Button from "react-bootstrap/Button";
// import Accordion from "react-bootstrap/Accordion";
// import Save from "../../../assets/images/saveFile.svg";
// import Modal from "react-bootstrap/Modal";
// import Form from "react-bootstrap/Form";
// import "../../../assets/css/select2.css";
// import Dropdown from "react-bootstrap/Dropdown";
// import { Link, useNavigate } from "react-router-dom";
// import announcement_icon from "../../../assets/images/announcement_icon.svg";
// import message_icon from "../../../assets/images/message_icon.svg";
// import emergency_icon from "../../../assets/images/emergency_icon.svg";
// import alert_icon from "../../../assets/images/alert_icon.svg";
// import info_icon from "../../../assets/images/info_icon.svg";
// import dropdown_icons from "../../../assets/images/dropdown_icons.svg";
// import edit_icon from "../../../assets/images/edit_icon.svg";
// import delete_icon from "../../../assets/images/delete_icon.svg";
// import add_btn from "../../../assets/images/add_btn.svg";
// import start_recording_icon from "../../../assets/images/start_recording_icon.svg";
// import stop_recording_icon from "../../../assets/images/stop_recording_icon.svg";
// import recording_waves from "../../../assets/images/recording_waves.svg";

// function AnnouncementNew() {
//   const navigate = useNavigate();

//   const [modalShow, setModalShow] = useState(false);
//   const [modalShow1, setModalShow1] = useState(false);
//   const [modalShow2, setModalShow2] = useState(false);

//   const [validated, setValidated] = useState(false);

//   const handleSubmit = (event) => {
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     }

//     setValidated(true);
//   };

//   const [isVisible, setIsVisible] = useState(false);

//   const handleRecording = () => {
//     setIsVisible(!isVisible);
//   };

//   const [selected, setSelected] = useState();

//   const handleChange = (event) => {
//     setSelected(event.target.value);
//   };

//   return (
//     <>
//       {/* Container-fluid starts */}
//       <div class="announcementnew assignment-sec">
//         <div className="main-section">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="announcement-top">
//                 <label>Announcement</label>
//                 <button onClick={() => setModalShow(true)}>
//                   <img src={add_btn} alt="" />
//                   Create New Announcement
//                 </button>
//               </div>
//             </div>
//             <div className="col-md-4 cx-mb-20">
//               <div
//                 className="card sub-1 card-bg-1 stack-card"
//                 // onClick={() => navigate("/ViewAnnouncementNew")}
//               >
//                 <div class="card-header">
//                   <div class="d-flex">
//                     <img class="flex-grow-0" src={announcement_icon} />
//                     <h6 class="flex-grow-1">Announcement</h6>
//                     <Dropdown className="Common_dropDown">
//                       <Dropdown.Toggle
//                         className="dropdown-btn"
//                         id="dropdown-basic"
//                       >
//                         <img src={dropdown_icons} alt="" />
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => setModalShow1(true)}>
//                           <img src={edit_icon} alt="" />
//                           Edit
//                         </Dropdown.Item>
//                         <Dropdown.Item onClick={() => setModalShow2(true)}>
//                           <img src={delete_icon} alt="" />
//                           Delete
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>
//                 </div>
//                 <a class="card-body" href="#">
//                   <h5>Announcement Content</h5>
//                 </a>
//                 <div class="card-footer">
//                   <div class="d-sm-flex">
//                     <h5>28 Oct 2021, Thu</h5>
//                   </div>
//                 </div>
//                 <div className="card-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et
//                     <a href="/ViewAnnouncementNew">Read More...</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 cx-mb-20">
//               <div className="card sub-2 card-bg-2 stack-card">
//                 <div class="card-header">
//                   <div class="d-flex">
//                     <img class="flex-grow-0" src={message_icon} />
//                     <h6 class="flex-grow-1">Messages</h6>
//                     <Dropdown className="Common_dropDown">
//                       <Dropdown.Toggle
//                         className="dropdown-btn"
//                         id="dropdown-basic"
//                       >
//                         <img src={dropdown_icons} alt="" />
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => setModalShow1(true)}>
//                           <img src={edit_icon} alt="" />
//                           Edit
//                         </Dropdown.Item>
//                        <Dropdown.Item onClick={() => setModalShow2(true)}>
//                           <img src={delete_icon} alt="" />
//                           Delete
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>
//                 </div>
//                 <a class="card-body" href="#">
//                   <h5>Announcement Content</h5>
//                 </a>
//                 <div class="card-footer">
//                   <div class="d-sm-flex">
//                     <h5>28 Oct 2021, Thu</h5>
//                   </div>
//                 </div>
//                 <div className="card-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et
//                     <a href="/ViewAnnouncementNew">Read More...</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 cx-mb-20">
//               <div className="card sub-3 card-bg-3 stack-card">
//                 <div class="card-header">
//                   <div class="d-flex">
//                     <img class="flex-grow-0" src={emergency_icon} />
//                     <h6 class="flex-grow-1">Emergency</h6>
//                     <Dropdown className="Common_dropDown">
//                       <Dropdown.Toggle
//                         className="dropdown-btn"
//                         id="dropdown-basic"
//                       >
//                         <img src={dropdown_icons} alt="" />
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => setModalShow1(true)}>
//                           <img src={edit_icon} alt="" />
//                           Edit
//                         </Dropdown.Item>
//                        <Dropdown.Item onClick={() => setModalShow2(true)}>
//                           <img src={delete_icon} alt="" />
//                           Delete
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>
//                 </div>
//                 <a class="card-body" href="#">
//                   <h5>Announcement Content</h5>
//                 </a>
//                 <div class="card-footer">
//                   <div class="d-sm-flex">
//                     <h5>28 Oct 2021, Thu</h5>
//                   </div>
//                 </div>
//                 <div className="card-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et
//                     <a href="/ViewAnnouncementNew">Read More...</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 cx-mb-20">
//               <div className="card sub-4 card-bg-4 stack-card">
//                 <div class="card-header">
//                   <div class="d-flex">
//                     <img class="flex-grow-0" src={alert_icon} />
//                     <h6 class="flex-grow-1">Alerts</h6>
//                     <Dropdown className="Common_dropDown">
//                       <Dropdown.Toggle
//                         className="dropdown-btn"
//                         id="dropdown-basic"
//                       >
//                         <img src={dropdown_icons} alt="" />
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => setModalShow1(true)}>
//                           <img src={edit_icon} alt="" />
//                           Edit
//                         </Dropdown.Item>
//                        <Dropdown.Item onClick={() => setModalShow2(true)}>
//                           <img src={delete_icon} alt="" />
//                           Delete
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>
//                 </div>
//                 <a class="card-body" href="#">
//                   <h5>Announcement Content</h5>
//                 </a>
//                 <div class="card-footer">
//                   <div class="d-sm-flex">
//                     <h5>28 Oct 2021, Thu</h5>
//                   </div>
//                 </div>
//                 <div className="card-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et
//                     <a href="/ViewAnnouncementNew">Read More...</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 cx-mb-20">
//               <div className="card sub-5 card-bg-5 stack-card">
//                 <div class="card-header">
//                   <div class="d-flex">
//                     <img class="flex-grow-0" src={info_icon} />
//                     <h6 class="flex-grow-1">Information</h6>
//                     <Dropdown className="Common_dropDown">
//                       <Dropdown.Toggle
//                         className="dropdown-btn"
//                         id="dropdown-basic"
//                       >
//                         <img src={dropdown_icons} alt="" />
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => setModalShow1(true)}>
//                           <img src={edit_icon} alt="" />
//                           Edit
//                         </Dropdown.Item>
//                        <Dropdown.Item onClick={() => setModalShow2(true)}>
//                           <img src={delete_icon} alt="" />
//                           Delete
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>
//                 </div>
//                 <a class="card-body" href="#">
//                   <h5>Announcement Content</h5>
//                 </a>
//                 <div class="card-footer">
//                   <div class="d-sm-flex">
//                     <h5>28 Oct 2021, Thu</h5>
//                   </div>
//                 </div>
//                 <div className="card-text-box">
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et
//                     <a href="/ViewAnnouncementNew">Read More...</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//        {/* Container-fluid Ends */}

//       {/* Create New Announcement Modal Start */}
//       <Modal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Announcement Creation
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-form-wrapper">
//             <Form
//               noValidate
//               validated={validated}
//               onSubmit={handleSubmit}
//               id="myForm"
//             >
//               <div className="row">
//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Announcement Type</option>
//                       <option> Announcement</option>
//                       <option> Message</option>
//                       <option> Emergency</option>
//                       <option> Alerts</option>
//                       <option> Information</option>
//                     </Form.Select>
//                   </Form.Group>
//                   <Form.Control.Feedback type="invalid">
//                     Please choose a announcement type
//                   </Form.Control.Feedback>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select
//                       required
//                       value={selected}
//                       onChange={handleChange}
//                     >
//                       <option defaultValue>
//                         Select Announcement Content Type
//                       </option>
//                       <option value="Text"> Plain Text</option>
//                       <option value="Image"> Image</option>
//                       <option value="Audio"> Audio</option>
//                       <option value="Pdf"> PDF</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a content type
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Student Name <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Student Name</option>
//                       <option> select1</option>
//                       <option> select2</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a student name
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content <span>*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       as="textarea"
//                       placeholder="Type Message here..."
//                       style={{ height: "100px" }}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       Announcement content can not be blank
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>
//                 {selected === "Image" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Image</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Upload</button>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 {selected === "Audio" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Audio</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Download</button>
//                       </div>
//                     </div>
//                     <div className="record-audio-wrapper">
//                       <Accordion>
//                         <Accordion.Item eventKey="0">
//                           <Accordion.Header>Record Audio</Accordion.Header>
//                           <Accordion.Body>
//                             <p className="audio-title">Record & Upload Audio</p>
//                             <p className="audio-note">
//                               Click record image to start record and click the
//                               same after record ends for stop
//                             </p>
//                             <Form
//                               noValidate
//                               validated={validated}
//                               onSubmit={handleSubmit}
//                               id="myForm"
//                             >
//                               <div className="row">
//                                 <div className="col-md-12">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content <span>*</span>
//                                     </Form.Label>
//                                     <Form.Control
//                                       required
//                                       as="textarea"
//                                       placeholder="Type Message here..."
//                                       style={{ height: "38px" }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                       Announcement content can not be blank
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required>
//                                       <option> Select Announcement Type</option>
//                                       <option> Announcement</option>
//                                       <option> Message</option>
//                                       <option> Emergency</option>
//                                       <option> Alerts</option>
//                                       <option> Information</option>
//                                     </Form.Select>
//                                   </Form.Group>
//                                   <Form.Control.Feedback type="invalid">
//                                     Please choose a announcement type
//                                   </Form.Control.Feedback>
//                                 </div>

//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required value={selected}>
//                                       <option defaultValue>
//                                         Select Announcement Content Type
//                                       </option>
//                                       <option value="Text"> Plain Text</option>
//                                       <option value="Image"> Image</option>
//                                       <option value="Audio"> Audio</option>
//                                       <option value="Pdf"> PDF</option>
//                                     </Form.Select>
//                                     <Form.Control.Feedback type="invalid">
//                                       Please choose a content type
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-12">
//                                   <div className="recording-wrapper">
//                                     <div className="start-recording-section">
//                                       <p className="sr-title">Record Audio</p>
//                                       <img
//                                         src={start_recording_icon}
//                                         alt=""
//                                         onClick={handleRecording}
//                                       />
//                                     </div>
//                                     {isVisible ? (
//                                       <div className="starting">
//                                         <p className="start-title">
//                                           Stop Recording
//                                         </p>
//                                         <img
//                                           src={stop_recording_icon}
//                                           alt=""
//                                           onClick={handleRecording}
//                                         />
//                                         <p className="start-title">
//                                           Recording 001
//                                         </p>
//                                         <img src={recording_waves} alt="" />
//                                         <div className="starting-time-stamp">
//                                           2:28
//                                         </div>
//                                         <div className="recording-btn-wrapper">
//                                           <button className="download">
//                                             Download File
//                                           </button>
//                                           <button className="save">
//                                             Save Recording
//                                           </button>
//                                         </div>
//                                       </div>
//                                     ) : (
//                                       ""
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </Form>
//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow(false)} className="cx-btn-1">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="cx-btn-2"
//               form="myForm"
//               value="Update"
//             >
//               Publish
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//       {/* Create New Announcement Modal End */}

//       {/* Edit Announcement Modal Start */}
//       <Modal
//         show={modalShow1}
//         onHide={() => setModalShow1(false)}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Edit Announcement
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-form-wrapper">
//             <Form
//               noValidate
//               validated={validated}
//               onSubmit={handleSubmit}
//               id="myForm"
//             >
//               <div className="row">
//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Announcement Type</option>
//                       <option> Announcement</option>
//                       <option> Message</option>
//                       <option> Emergency</option>
//                       <option> Alerts</option>
//                       <option> Information</option>
//                     </Form.Select>
//                   </Form.Group>
//                   <Form.Control.Feedback type="invalid">
//                     Please choose a announcement type
//                   </Form.Control.Feedback>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content Type <span>*</span>
//                     </Form.Label>
//                     <Form.Select
//                       required
//                       value={selected}
//                       onChange={handleChange}
//                     >
//                       <option defaultValue>
//                         Select Announcement Content Type
//                       </option>
//                       <option value="Text"> Plain Text</option>
//                       <option value="Image"> Image</option>
//                       <option value="Audio"> Audio</option>
//                       <option value="Pdf"> PDF</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a content type
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-6">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Student Name <span>*</span>
//                     </Form.Label>
//                     <Form.Select required>
//                       <option> Select Student Name</option>
//                       <option> select1</option>
//                       <option> select2</option>
//                     </Form.Select>
//                     <Form.Control.Feedback type="invalid">
//                       Please choose a student name
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>

//                 <div className="col-md-12">
//                   <Form.Group className="mb-3" controlId="validationCustom01">
//                     <Form.Label>
//                       Announcement Content <span>*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       as="textarea"
//                       placeholder="Type Message here..."
//                       style={{ height: "100px" }}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       Announcement content can not be blank
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </div>
//                 {selected === "Image" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Image</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Upload</button>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 {selected === "Audio" && (
//                   <div className="col-md-12">
//                     <div className="image-input-wrapper">
//                       <p className="input-title-text">Audio</p>
//                       <label className="BorderedLabel" htmlFor="filePicker">
//                         <input type="file" className="d-none" id="filePicker" />
//                         <div className="insiderContent">
//                           <img src={Save} className="saver" alt="" />
//                           <p className="detailTxt">Drag & Drop Your File</p>
//                           <p className="detailTxt">Or</p>

//                           <label htmlFor="buttonFile" className="browseBtn">
//                             Browse File{" "}
//                             <input
//                               type="file"
//                               className="d-none"
//                               id="buttonFile"
//                             />
//                           </label>
//                         </div>
//                       </label>
//                       <div className="btn-wrapper">
//                         <button className="upload-btn">Download</button>
//                       </div>
//                     </div>
//                     <div className="record-audio-wrapper">
//                       <Accordion>
//                         <Accordion.Item eventKey="0">
//                           <Accordion.Header>Record Audio</Accordion.Header>
//                           <Accordion.Body>
//                             <p className="audio-title">Record & Upload Audio</p>
//                             <p className="audio-note">
//                               Click record image to start record and click the
//                               same after record ends for stop
//                             </p>
//                             <Form
//                               noValidate
//                               validated={validated}
//                               onSubmit={handleSubmit}
//                               id="myForm"
//                             >
//                               <div className="row">
//                                 <div className="col-md-12">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content <span>*</span>
//                                     </Form.Label>
//                                     <Form.Control
//                                       required
//                                       as="textarea"
//                                       placeholder="Type Message here..."
//                                       style={{ height: "38px" }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                       Announcement content can not be blank
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required>
//                                       <option> Select Announcement Type</option>
//                                       <option> Announcement</option>
//                                       <option> Message</option>
//                                       <option> Emergency</option>
//                                       <option> Alerts</option>
//                                       <option> Information</option>
//                                     </Form.Select>
//                                   </Form.Group>
//                                   <Form.Control.Feedback type="invalid">
//                                     Please choose a announcement type
//                                   </Form.Control.Feedback>
//                                 </div>

//                                 <div className="col-md-6">
//                                   <Form.Group
//                                     className="mb-3"
//                                     controlId="validationCustom01"
//                                   >
//                                     <Form.Label>
//                                       Announcement Content Type <span>*</span>
//                                     </Form.Label>
//                                     <Form.Select required value={selected}>
//                                       <option defaultValue>
//                                         Select Announcement Content Type
//                                       </option>
//                                       <option value="Text"> Plain Text</option>
//                                       <option value="Image"> Image</option>
//                                       <option value="Audio"> Audio</option>
//                                       <option value="Pdf"> PDF</option>
//                                     </Form.Select>
//                                     <Form.Control.Feedback type="invalid">
//                                       Please choose a content type
//                                     </Form.Control.Feedback>
//                                   </Form.Group>
//                                 </div>
//                                 <div className="col-md-12">
//                                   <div className="recording-wrapper">
//                                     <div className="start-recording-section">
//                                       <p className="sr-title">Record Audio</p>
//                                       <img
//                                         src={start_recording_icon}
//                                         alt=""
//                                         onClick={handleRecording}
//                                       />
//                                     </div>
//                                     {isVisible ? (
//                                       <div className="starting">
//                                         <p className="start-title">
//                                           Stop Recording
//                                         </p>
//                                         <img
//                                           src={stop_recording_icon}
//                                           alt=""
//                                           onClick={handleRecording}
//                                         />
//                                         <p className="start-title">
//                                           Recording 001
//                                         </p>
//                                         <img src={recording_waves} alt="" />
//                                         <div className="starting-time-stamp">
//                                           2:28
//                                         </div>
//                                         <div className="recording-btn-wrapper">
//                                           <button className="download">
//                                             Download File
//                                           </button>
//                                           <button className="save">
//                                             Save Recording
//                                           </button>
//                                         </div>
//                                       </div>
//                                     ) : (
//                                       ""
//                                     )}
//                                   </div>
//                                 </div>
//                               </div>
//                             </Form>
//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </Form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow1(false)} className="cx-btn-1">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="cx-btn-2"
//               form="myForm"
//               value="Update"
//             >
//               Update
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//       {/* Edit Announcement Modal End */}

//       {/* Delete Announcement Modal Start */}
//       <Modal
//         show={modalShow2}
//         onHide={() => setModalShow2(false)}
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter border-bottom">
//             Delete Announcement
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="delete-text-wrapper">
//         Are you sure you want to delete ?
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div className="delete-btn-wrapper border-top w-100">
//             <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="dlt-btn-2"
//               value="delete"
//             >
//               Delete
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//       {/* Delete Announcement Modal End */}

//     </>
//   );
// }

// export default AnnouncementNew;
