import React, { useState, useEffect } from "react";
import ApiConfig from "../../../../../api/ApiConfig";
import { simplePostCall } from "../../../../../api/ApiServices";
import "../../../../../assets/css/select2.css";
import Params from "../../../../../config/Params";
import SpinnerCmp from "../../../../../components/SpinnerCmp";

const MCQSingleAnswer = (props) => {
  const [questionState, setQuestionState] = useState({
    isloading: false,
    questions: [],
    options: [],
    answers: [],
    base_url: "",
  });

  const [assessmentState, setAssessmentState] = useState({
    assessment: props.assessmentDetails,
  });

  function getQuestionDetails() {
    setQuestionState({ ...questionState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      question_id: props.questionID,
    });
    console.log(
      ApiConfig.VIEW_ASSESSMENT_QUESTION,
      newRequestBody,
      ".questions.assessment_questions_title",
    );
    simplePostCall(ApiConfig.VIEW_ASSESSMENT_QUESTION, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log(data, ".questions.assessment_questions_title");
          setQuestionState({
            ...questionState,
            isloading: false,
            questions: data.data,
            options: data.options,
            answers: data.answers,
            base_url: data.base_url,
          });
        } else {
          setQuestionState({ ...questionState, isloading: false });
        }
      })
      .catch((error) => {
        setQuestionState({ ...questionState, isloading: false });
        console.log("api response", error);
      });
  }

  function htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  useEffect(() => {
    getQuestionDetails();
  }, []);
  console.log(
    "questionState",
    questionState.questions.assessment_question_answer_clarification,
  );
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={{ display: "block" }}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {questionState.isloading ? (
        <SpinnerCmp loading={questionState.isloading} />
      ) : (
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Question
              </h5>
              <button
                className="btn-close"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
              </button>
            </div>
            <div className="modal-body datetime-picker modal-scroll">
              <div style={{ width: "100%" }}>
                <table style={{ width: "50%", float: "left" }}>
                  <tr>
                    <th>Question Title : </th>
                    <td>{questionState.questions.assessmentQuestionsTitle}</td>
                  </tr>
                </table>
                <table style={{ width: "50%" }}>
                  <tr>
                    <th style={{ width: "25%" }}>Question Type : </th>
                    <td>
                      {
                        questionState.questions
                          .assessmentQuestionsTypeDisplayName
                      }
                    </td>
                  </tr>
                </table>
              </div>
              <hr />
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(
                      questionState.questions.assessmentQuestionsInstructions,
                    ),
                  }}
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(
                      questionState.questions.assessmentQuestionsQuestion,
                    ),
                  }}
                ></div>
                {questionState.options.length > 0 ? (
                  <>
                    <ul>
                      {questionState.options.map((option, index) => {
                        return (
                          <li key={index}>
                            <div>
                              {
                                questionState.answers
                                  .assessment_question_option_id
                              }
                              <input
                                type="radio"
                                name="mcqradio"
                                id="r_"
                                style={{
                                  float: "left",
                                  marginTop: "4px",
                                  cursor: "not-allowed",
                                }}
                                checked={
                                  option.assessment_question_option_id ==
                                  questionState.answers[0]
                                    .assessment_question_option_id
                                    ? true
                                    : false
                                }
                                disabled={
                                  option.assessment_question_option_id ==
                                  questionState.answers[0]
                                    .assessment_question_option_id
                                    ? false
                                    : true
                                }
                              />
                              <div
                                style={{ marginLeft: "3%" }}
                                dangerouslySetInnerHTML={{
                                  __html: htmlDecode(
                                    option.assessment_question_option_list,
                                  ),
                                }}
                              ></div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : null}

                <label>Answer Clarification</label>

                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      questionState.questions
                        .assessmentQuestionAnswerClarification,
                  }}
                ></div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MCQSingleAnswer;
