//const BASE_URL = "https://edusuite.trackschoolbus.com/";
//const BASE_URL_LOGIN = 'https://app.klickitapp.com/';
//const BASE_URL ='https://app.klickitapp.com/';
//const BASE_URL ='https://app.edsys.in/';
export const BASE_URL = "https://stage.edsys.in/";
export const E_BASE_URL = "https://stage.edsys.in:3014/";
export const NODE_URL = "https://app.edsys.in:3000/";
export const NEW_BASE_URL = "http://edusuite.trackschoolbus.com/api/teacher/";
// export const BASE_URL = 'http://localhost:3000'

//const PATH = "api/teacherapp/";
const PATH = "api/teacher/";
const EDIRECTORY = "api/edirectory/";

const ApiConfig = {
  BASE_URL: BASE_URL,
  NODE_URL: NODE_URL, //43 api's pending //current 19 api's pending
  EMPLOYEE_ATTENDANCE_DETAILS_API: BASE_URL + PATH + "markattendance/details",
  EMPLOYEE_MARK_ATTENDANCE_API: BASE_URL + PATH + "markattendance",
  DASHBOARD_API: NODE_URL + PATH + "dashboard",
  ASSIGNMENT_LIST_API: NODE_URL + PATH + "assignment/list",
  ASSIGNMENT_DETAILS_API: NODE_URL + PATH + "assignment/details",
  UPDATE_ASSIGNMENT_API: NODE_URL + PATH + "assignment/update",
  ASSIGNMENT_DELET_API: NODE_URL + PATH + "assignment/delete",
  ASSIGNMENT_SUBMITVIEW: NODE_URL + PATH + "assignment/submittedview",
  ASSIGNMENT_REVIEW: NODE_URL + PATH + "assignment/review",

  ASSESSMENT_DELET_API: NODE_URL + PATH + "assessment/delete",
  ASSIGNMENT_CREATE_API: NODE_URL + PATH + "assignment/create",
  ASSESSMENT_LIST_API: NODE_URL + PATH + "assessment/list",
  ASSESSMENT_DETAILS_API: NODE_URL + PATH + "assessment/details",
  CLASSES_LIST_API: NODE_URL + PATH + "classes/list",
  SUBJECT_LIST_API: NODE_URL + PATH + "subject/list",
  SECTION_LIST_API: NODE_URL + PATH + "section/list",
  LESSONS_LIST_API_NEW: NODE_URL + PATH + "curriculum/lessons",
  LESSONS_LIST_API_NEW_ELARNING:
    "https://stage.edsys.in:3014/api/teacher/curriculum/getlessons",
  ATTENDANCE_API: BASE_URL + PATH + "attendance",
  EMPLOYEE_LEAVE_CATEGORY_LIST_API:
    BASE_URL + PATH + "employeeleave/list/category_list",
  EMPLOYEE_APPLY_LEAVE_API: NODE_URL + PATH + "employeeleave/apply",
  CONFERENCE_API: BASE_URL + PATH + "conference",
  SYLLABUS: BASE_URL + PATH + "syllabus",
  LESSONS_DETAIL_API: NODE_URL + "api/edirectory/syllabus/list",
  END_CONFERENCE_API: BASE_URL + PATH + "conference/sessionend",
  MY_LEAVE_LIST_API: NODE_URL + PATH + "employeeleave/list",
  STUDENTS_API: BASE_URL + PATH + "student",
  ASSESSMENT_CREATE_API: NODE_URL + PATH + "assessment/create",
  ASSESSMENT_UPDATE_API: NODE_URL + PATH + "assessment/update",
  ANNOUNCEMENT_API: BASE_URL + PATH + "announcement",
  ANNOUNCEMENT_ADD_API: BASE_URL + PATH + "announcement/add",
  ANNOUNCEMENT_EDIT_API: BASE_URL + PATH + "announcement/edit",
  ANNOUNCEMENT_DELETE_API: BASE_URL + PATH + "announcement/delete",
  EMAIL_LIST_API: BASE_URL + PATH + "emailparent",
  EMAIL_ADD_API: BASE_URL + PATH + "emailparent/add",
  EMPLOYEE_LEAVE_APPROVE_API: BASE_URL + PATH + "employeeleave/approve",
  EMPLOYEE_LEAVE_CANCEL_API: BASE_URL + PATH + "employeeleave/cancel",
  CURRICULUM_LIST_API: NODE_URL + PATH + "curriculum/masterlist",
  SUBJECT_LIST_BY_SELECTED_GRADE_API: NODE_URL + PATH + "subject/list",
  LESSONS_LIST_API: NODE_URL + PATH + "curriculum/getlessons",
  ASSIGNMENT_REVIEW_USER_LIST_API:
    BASE_URL + PATH + "assignment/review/reviewuserlist",
  STUDENTS_LIST_BY_SELECTED_CLASSES_API:
    NODE_URL + PATH + "classes/studentsbyclasses",
  AVAILABLE_QUESTIONS_FOR_ASSIGNMENT_API:
    NODE_URL + PATH + "assignment/question/availablequestions",
  VIEW_ASSIGNMENT_QUESTION: NODE_URL + PATH + "assignment/question/view",
  ADD_QUESTION_TO_ASSIGNMENT:
    NODE_URL + PATH + "assignment/question/addquestion",
  DATA_FOR_CREATING_ASSIGNMENT_QUESTION_API:
    BASE_URL + PATH + "assignment/details/dataforcreatequestion",
  /// Flow //
  CREATE_ASSIGNMENT_QUESTION_API:
    BASE_URL + PATH + "assignment/question/create",
  /// New Aassment
  CREATE_ASSESSMENT_QUESTION_API:
    NODE_URL + PATH + "assessment/question/create",
  PUBLISH_ASSIGNMENT_API: NODE_URL + PATH + "assignment/publish",
  PUBLISH_ASSESSNMENT_API: NODE_URL + PATH + "assessment/publish",
  AVAILABLE_QUESTIONS_FOR_ASSESSMENT_API:
    NODE_URL + PATH + "assessment/available_questions",
  ADD_QUESTION_TO_ASSESSMENT: NODE_URL + PATH + "assessment/addquestion",
  RANK_LIS_FOR_ASSESSMENT: BASE_URL + PATH + "assessment/result/",
  VIEW_ASSESSMENT_QUESTION: NODE_URL + PATH + "assessment/quesstion/view",
  PARENT_SMS_LIST: BASE_URL + PATH + "smsparent",
  ADD_PARENT_SMS: BASE_URL + PATH + "smsparent/add",
  PARENT_PUSH_NOTIFICATION_LIST: BASE_URL + PATH + "notificationparent",
  ADD_PARENT_PUSH_NOTIFICATION: BASE_URL + PATH + "notificationparent/add",
  TODO_LIST_API: BASE_URL + PATH + "todo/list",
  ADD_TODO: BASE_URL + PATH + "todo/add",
  UPDATE_TODO: BASE_URL + PATH + "todo/update",
  DELETE_TODO: BASE_URL + PATH + "todo/delete",
  CHANNEL_LIST_API: BASE_URL + PATH + "channellist",
  CONFERENCE_LIST_API: BASE_URL + PATH + "conference/list",
  USER_PROFILE_DATA_API: NODE_URL + PATH + "teacherProfile",
  BYPASS_LOGIN_API: BASE_URL + PATH + "reactlogin/bypass",
  EMPLOYEE_APPLY_LEAVE_DETAILS: NODE_URL + PATH + "employeeleave/apply/details",
  EMPLOYEE_LEAVE_LIST_API:
    NODE_URL + PATH + "employeeleave/list/received-request",
  UPDATE_PROFILE: NODE_URL + PATH + "profile/update",
  CHANGE_PASSWORD: BASE_URL + PATH + "profile/changepassword",
  MY_NOTIFICATION_LIST_API: BASE_URL + PATH + "notification",
  MARK_ALL_NOTIFICATIONS_AS_READ:
    BASE_URL + PATH + "notification/markallasread",
  NOTIFICATION_SETTINGS_LIST: BASE_URL + PATH + "notificationsettings",
  UPDATE_NOTIFICATION_SETTING: BASE_URL + PATH + "notificationsettings/update",
  STUDENT_LEAVES_LIST: NODE_URL + PATH + "studentleave",
  STUDENT_LEAVE_APPROVE: BASE_URL + PATH + "studentleave/approve",
  STUDENT_LEAVE_REJECT: BASE_URL + PATH + "studentleave/reject",
  CREATE_STUDENT_LEAVE: BASE_URL + PATH + "studentleave/create",
  TIMETABLE: BASE_URL + PATH + "timetable",
  TIMETABLE_CREATE: NODE_URL + PATH + "create/timetable",
  WEEKLY_TIMETABLE: BASE_URL + PATH + "timetable/weekly",
  MY_TIMETABLE: NODE_URL + PATH + "timetable/mytimetable",
  SHIFT_LIST: NODE_URL + PATH + "shift_list",
  GRADES_LIST: NODE_URL + PATH + "grade_list",
  TIMETABLE_CREATE_VIEW: NODE_URL + PATH + "timetable/view",
  // BY_CLASS_TIMETABLE: BASE_URL + PATH + "timetable/class-timetable",
  BY_CLASS_TIMETABLE: NODE_URL + PATH + "timetable/breakview",
  ATTENDANCE_LIST: NODE_URL + PATH + "student/list",
  ABSENT_STUDENT: NODE_URL + PATH + "student/absent",
  TAKE_ATTENDANCE: NODE_URL + PATH + "student/takeattendance",
  PRESENT_STUDENT: NODE_URL + PATH + "student/present",
  MY_LEAVE_LIST: NODE_URL + PATH + "employeeleave/list",
  E_LEARNING_DIRECTORY: NODE_URL + PATH + "edirectory/list",
  ALL_RESOURCES_LIST: NODE_URL + EDIRECTORY + "publishlistt",
  PENDING_LIST: E_BASE_URL + EDIRECTORY + "resource/pendinglist",
  MY_RESOURCES_LIST: E_BASE_URL + EDIRECTORY + "MyresourseList",
  E_LEARNING_CREATE: NODE_URL + EDIRECTORY + "create",
  E_LEARNING_DETAILS: NODE_URL + EDIRECTORY + "Resource-details",
  SUBJECT_IN_SYLLABUS: E_BASE_URL + EDIRECTORY + "subject",
  LESSONS_IN_SYLLABUS: E_BASE_URL + EDIRECTORY + "syllabus/creating",
  SYLLABUS_COMPLETE_API: NODE_URL + PATH + "syllabus/completed",
  SUBJECTLIST: NODE_URL + PATH + "timetable",
  E_LEARNING_TYPE: NODE_URL + EDIRECTORY + "typecategory",
  MY_RESOURCES_DELET: NODE_URL + "api/teacher/resource/delete",
  PROFILE_THEME: NODE_URL + PATH + "user/theme",
  USERT_LANGUAGE: E_BASE_URL + EDIRECTORY + "user/language",
  TYPE_CATEGORY: NODE_URL + EDIRECTORY + "typecategory",
  /// Filter Api ///
  FILTER_ANANCMENT: E_BASE_URL + EDIRECTORY + "annoucement/list",
  FILTER_ASSISSMENT: E_BASE_URL + EDIRECTORY + "assessment/list",

  //. . . . . . . . . . .OFFLINE ASSESSMENT. . . . . .  . //
  OFFLINE_CREATE: NODE_URL + PATH + "offlineassessment/Create",
  OFFLINEASSESSMENT_LIST: NODE_URL + PATH + "offlineassessment/list",
  VIEW_OFFLINEASSESSMENT: NODE_URL + PATH + "offlineassessment/view",
  ACADEMIC_YEAR_LIST: NODE_URL + PATH + "offlineassessment/academicyearlist",
  ACADEMIC_WISE_DIVISION:
    NODE_URL + PATH + "offlineassessment/GradeDivisionAcademicWise",
  TERM_LIST: NODE_URL + PATH + "offlineassessment/term/list",
  GRADE_LIST: NODE_URL + PATH + "classes/list",
  SUBJECT_LIST: NODE_URL + PATH + "subject/list",
  PUBLISH_SUBJECTWISE:
    NODE_URL + PATH + "offlineassessment/marklist/subjectwise/publish",
  PUBLISH_OFFLINEASSESSMENT: NODE_URL + PATH + "marklist/gradewise/publish",
  SUBJECT_WISE_MARKLIST:
    NODE_URL + PATH + "offlineassessment/subjectwise/marklist",
  EDIT_SUBJECT_WISE_MARKLIST:
    NODE_URL + PATH + "offlineassessment/subjectwise/marklist/edit",
  GRADE_VIEW: NODE_URL + PATH + "offlineassessment/grade/view",
  SUBJECTWISE_LISTING_MAIN:
    NODE_URL + PATH + "offlineassessment/subjectwise/list",
  OFFLINEASSESSMENT_EDIT: NODE_URL + PATH + "OfflineAssessmentEdit",
  GRADEWISE_LIST: NODE_URL + PATH + "offlineassessment/grade/list",
  GRADEWISE_LIST_VIEW: NODE_URL + PATH + "offlineassessment/gradeview",
  GRADEE_LIST_ADD: NODE_URL + PATH + "offlineassessment/grade/add",
  GRADEE_LIST_EDIT: NODE_URL + PATH + "offlineassessment/grade/edit",
  GRADEE_LIST_DELETE: NODE_URL + PATH + "offlineassessment/grade/delete",
  OFFLINE_ASSESSMENT_GRADEWISE_EDIT:
    NODE_URL + PATH + "offlineassessment/gradewise/marklist/edit",
  OFFLINEASSESSMENT_DELETE: NODE_URL + PATH + "offlineassessment/delete",
  //Diary
  DIARY_LIST: NODE_URL + PATH + "diary/list",
  DIARY_VIEW: NODE_URL + PATH + "diary/review",
  DIARY_CREATE: NODE_URL + PATH + "diary/create",
  DIARY_REVIEW: NODE_URL + PATH + "diary/review-comments",
  DIARY_COMMENT_CREATE: NODE_URL + PATH + "diary/crate-comments",
  //Syllabus
  YEAR_LIST: NODE_URL + PATH + "offlineassessment/academicyearlist",
  CREATE_SYLLABUS_DATA: NODE_URL + PATH + "syllabus/lessonplan/create",
  SYLLABUS_CREATE: NODE_URL + PATH + "syllabus/curriculum/create",
  SYLLABUS_VIEW: NODE_URL + PATH + "syllabus/curriculum/view",
  SYLLABU_TABLE_LIST: NODE_URL + PATH + "syllabus/lessonplan/list",
  SYLLABUS_EDIT: NODE_URL + PATH + "syllabus/curriculum/edit",
  EDIT_SYLLABUS_DATA: NODE_URL + PATH + "syllabus/lessonplan/edit",
  SYLLABUS_DELETE_DATA: NODE_URL + PATH + "syllabus/lessonplan/delete",
  EDIT_VIEW_DATA: NODE_URL + PATH + "syllabus/lessonplan/view",
  TABLE_EDIT_VIEW: NODE_URL + PATH + "syllabus/lessonplan/edit",
  STUDENT_LIST_IN_STUDENTMANAG: NODE_URL + PATH + "students",
  STUDENT_LIST_VIEW: NODE_URL + PATH + "students/view",
  LESSON_PLAN_LIST: NODE_URL + PATH + "syllabus/lessonplan/lesson/topic/create",
  LESSON_PLAN_DELETE:
    NODE_URL + PATH + "syllabus/lessonplan/lessonplan/view/delete",
  LESSONS_PLAN_EDIT:
    NODE_URL + PATH + "syllabus/lessonplan/lessonplan/view/edit",

  //Academic Calendar
  ACADEMICCALENDAR: NODE_URL + PATH + "academic/calendar",
  EVALUTION_LIST: NODE_URL + PATH + "offlineassessment/evaluvationlist",
  CREATE_EVALUTION: NODE_URL + PATH + "offlineassessment/evaluvationadd",
  DELETE_EVALUTION: NODE_URL + PATH + "offlineassessment/evaluvationdelete",
  EVAULTION_ADD: NODE_URL + PATH + "offlineassessment/evaluvationadd",
  EVALUTION_VIEW: NODE_URL + PATH + "offlineassessment/evaluvationview",
  EVALUTION_EDIT: NODE_URL + PATH + "offlineassessment/evaluvationedit",
  GRADEE_LIST: NODE_URL + PATH + "offlineassessment/grade/list",
  //Promotion
  PROMOTION_SUBMIT: NODE_URL + PATH + "students/promote",
  PROMOTION_PROPOSAL_LIST: NODE_URL + PATH + "proposed/classlist",
  PROMOTION_CLASS_LIST: NODE_URL + PATH + "promote/class_list",
  PROMOTION_STUDENT_LIST: NODE_URL + PATH + "promote/student_list",
  //Timetable Create
  TEACHER_LIST: NODE_URL + PATH + "teacher_list",
  SUBJECT_LIST_TIMETABLE: NODE_URL + PATH + "subject_list",
  //formative Assessment
  FORMATIVE_LIST: NODE_URL + PATH + "formative/list",
  FORMATIVE_SUBJECT_LIST: NODE_URL + PATH + "formative/class_wise_list",
  FORMATIVE_CREATE: NODE_URL + PATH + "formative/create",
  FORMATIVE_EDIT: NODE_URL + PATH + "formative/edit",
  FORMATIVE_VIEW: NODE_URL + PATH + "formative/view",
  EVALUATION_SET_AS_DEFAULT:
    NODE_URL + PATH + "formative/EvalutionSetAsDefault",
  GRADEING_SET_AS_DEFAULT: NODE_URL + PATH + "formative/GradingSetAsDefault",
  SETTING_AS_DEFAULT: NODE_URL + PATH + "formative/settings",
  STUDENTS_REPORT: NODE_URL + PATH + "report/formative",
  FORMATIVE_ASSESSMENT_DELETE: NODE_URL + PATH + "formative/assessmentDelete",
  SETTING_VIEW: NODE_URL + PATH + "formative/settings/view",
};

export default ApiConfig;
