import React from 'react'
//  import AssignmentCardData from '../../../api/assignment_card_data.json'
// import subjecticon from '../../../assets/images/assignment/subtopicicon.svg'
import { Link } from "react-router-dom";
//  import {Modal} from "react-bootstrap"
import {
    Edit3,
    Trash2,
    PlusCircle
  } from "react-feather";

function Assignment() {
 
    return (
        <div class="page-body">
          {/* Container-fluid starts */}
          
          {/* Container-fluid Ends */}
        </div>
    )
}

export default Assignment
