import React from 'react'
import { Trash2 } from 'react-feather'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next"; 

function ConfirmationModel(props) {
  const { t } = useTranslation();

  const toastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const onHandleConfirmation = (isConfirm) => {
    let response = {
      isConfirm: isConfirm,
      id: props.id ? props.id : 0,
    }
    toast.success(response.message, toastOptions)

    props.callback(response)
  }
  const displayStyle = {
    display: 'block',
  }
  return (
    <div
      class="modal fade show testingll"
      id="deleteWarning"
      style={displayStyle}
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content br-15">
          <div class="modal-body">
            <div class="warning-message">
              <Trash2 />
              <h6>{t('Are you sure you want to delete')}?</h6>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => onHandleConfirmation(false)}
            >
              Cancel
            </button>
            <button
              // style={{ width: '100%', height: '100%' }}
              class="d-button"
              data-bs-dismiss="modal"
              onClick={() => onHandleConfirmation(true)}
            >
              {/* <div class="trash"> */}
              {/* <div class="top">
                  <div class="paper"></div>
                </div> */}
              {/* <div class="box"></div>
                <div class="check">
                  <svg viewBox="0 0 8 6">
                    <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                  </svg>
                </div> */}
              {/* </div> */}
              <span>{t('Delete')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModel
