import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export default function AddEvalution() {
  const [evalutionName, setEvalutionName] = useState("");
  const [evcode, setEvCode] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const navic = useNavigate();
  const createEvaluationData = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      evaluation_type_name: evalutionName,
      evaluation_type_code: evcode,
    });
    simplePostCall(ApiConfig.EVAULTION_ADD, newRequestBody)
      .then((data) => {
        if (!evalutionName || !evcode) {
          setError(true);
          return;
        } else {
          toast.success("created Successfully");
          navic("/EvaluationType");

          console.log(data);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <div>
      <div className="main-sectionn ">
        <div className="col-md-12">
          <div className="announcementCreatee">
            <label className="edit-head">Create Evalution</label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 row">
            <div className="form-group col-md-12">
              <label for="task-title">{t("Evaluation Name")}</label>
              <input
                className="form-control "
                type="text"
                onChange={(e) => {
                  setEvalutionName(e.target.value);
                }}
              />
              {error && !evalutionName ? (
                <p className="val-form">Evaluation Name is required...</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label for="task-title">{t("Evaluation Code")}</label>
              <input
                className="form-control "
                type="text"
                onChange={(e) => {
                  setEvCode(e.target.value);
                }}
              />
              {error && !evcode ? (
                <p className="val-form">Evaluation Code is required...</p>
              ) : null}
            </div>
          </div>
          <div className="create-but-1">
            <button
              className="btn btn-primary  crbt"
              type="button"
              onClick={createEvaluationData}
            >
              {t("Create")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
