import React from "react";
import "../../assets/css/skeleton.css";

export default function ListSkeleton() {
  return (
    <div class="container loading-skeleton ">
      <div class="row gy-2">
        <div className="row gx-1 col-md-12">
          <div class="row stats-card col-md-8 ms-1">
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
            <div class="stats-card col-6">
              <div class="card bg-light"></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light" style={{ height: "625px" }}></div>
          </div>
        </div>
      </div>
      {/* <div className="card"></div> */}
    </div>
  );
}
