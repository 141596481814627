import React, { useEffect, useState } from "react";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

function MySetting() {
  const { t } = useTranslation();

  const [state, setState] = useState({
    isloading: false,
    notificationSettings: [],
    settings_sms_gateway_status: 0,
    settings_whatsapp_status: 0,
  });

  function getSettingsData() {
    setState({ ...state });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.NOTIFICATION_SETTINGS_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          setState({
            ...state,
            isloading: false,
            notificationSettings: data.notification_settings,
            settings_sms_gateway_status: data.settings_sms_gateway_status,
            settings_whatsapp_status: data.settings_whatsapp_status,
          });
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  function onInputChange(value, field, type) {
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      value: value,
      field: field,
      type: type,
    });

    console.log("newRequestBody---", newRequestBody);

    simplePostCall(ApiConfig.UPDATE_NOTIFICATION_SETTING, newRequestBody)
      .then((data) => {
        if (data.result) {
          getSettingsData();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  useEffect(() => {
    document.title = "Edsys | Settings";
    getSettingsData();
  }, []);
  return (
    <>
      {state.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="row notif-setting">
            <h4>{t("My Notification Settings")}</h4>
            <div className="col-md-12">
              <div className="card br-15">
                <div className="card-body row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive">
                      <table className="table table-styling">
                        <thead>
                          <tr>
                            <th scope="col">{t("Type")}</th>
                            <th scope="col">{t("Push Notification")}</th>
                            <th scope="col">{t("Email")}</th>
                            {state.settings_sms_gateway_status ? (
                              <th scope="col">{t("SMS")}</th>
                            ) : null}
                            {state.settings_whatsapp_status ? (
                              <th scope="col">{t("Whatsapp")}</th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {state.notificationSettings.length > 0 ? (
                            state.notificationSettings.map(
                              (notification_setting, index) => {
                                console.log(
                                  "notification_setting",
                                  notification_setting,
                                );
                                return (
                                  <tr key={index}>
                                    <th scope="row">
                                      {notification_setting.label}
                                    </th>
                                    <td>
                                      <div className="switch-md icon-state">
                                        <label className="switch ">
                                          <input
                                            type="checkbox"
                                            name={
                                              notification_setting.field +
                                              "_notification_" +
                                              index
                                            }
                                            id={
                                              notification_setting.field +
                                              "_notification_" +
                                              index
                                            }
                                            checked={
                                              notification_setting.push_notification
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              onInputChange(
                                                !notification_setting.push_notification,
                                                notification_setting.field,
                                                "notification",
                                              )
                                            }
                                          />
                                          <span className="switch-state"></span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="switch-md icon-state">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name={
                                              notification_setting.field +
                                              "_email_" +
                                              index
                                            }
                                            id={
                                              notification_setting.field +
                                              "_email_" +
                                              index
                                            }
                                            checked={
                                              notification_setting.email
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              onInputChange(
                                                !notification_setting.email,
                                                notification_setting.field,
                                                "email",
                                              )
                                            }
                                          />
                                          <span className="switch-state"></span>
                                        </label>
                                      </div>
                                    </td>
                                    {state.settings_sms_gateway_status ? (
                                      <td>
                                        <div className="switch-md icon-state">
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              name={
                                                notification_setting.field +
                                                "_sms_" +
                                                index
                                              }
                                              id={
                                                notification_setting.field +
                                                "_sms_" +
                                                index
                                              }
                                              checked={
                                                notification_setting.sms
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                onInputChange(
                                                  !notification_setting.sms,
                                                  notification_setting.field,
                                                  "sms",
                                                )
                                              }
                                            />
                                            <span className="switch-state"></span>
                                          </label>
                                        </div>
                                      </td>
                                    ) : null}
                                    {state.settings_whatsapp_status ? (
                                      <td>
                                        <div className="switch-md icon-state">
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              name={
                                                notification_setting.field +
                                                "_whatsapp_" +
                                                index
                                              }
                                              id={
                                                notification_setting.field +
                                                "_whatsapp_" +
                                                index
                                              }
                                              checked={
                                                notification_setting.whatsapp
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                onInputChange(
                                                  !notification_setting.whatsapp,
                                                  notification_setting.field,
                                                  "whatsapp",
                                                )
                                              }
                                            />
                                            <span className="switch-state"></span>
                                          </label>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              },
                            )
                          ) : (
                            <tr>
                              <td colSpan={3}>{t("No data found")}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  );
}
export default MySetting;
