import React from "react";
import "../../assets/css/skeleton.css";

export default function CommunicationSkeleton() {
  return (
    <div className="card">
      <div class="container loading-skeleton">
        <div class="container px-1 mb-4">
          <div class="row d-flex gx-5 justify-content-between">
            <div class=" col-md-5">
              <div class="title p-3 border bg-light"></div>
            </div>
            <div class=" col-md-4">
              <div class="title p-4 border bg-light"></div>
            </div>
          </div>
        </div>
        <div class="container px-1 mb-4">
          <div class="row gx-3">
            <div class=" col-md-3">
              <div class="title p-3 border bg-light"></div>
            </div>
            <div class=" col-md-3">
              <div class="title p-3 border bg-light"></div>
            </div>
            <div class=" col-md-3">
              <div class="title p-3 border bg-light"></div>
            </div>
            <div class=" col-md-3">
              <div class="title p-3 border bg-light"></div>
            </div>
          </div>
        </div>

        <div class="row gy-2">
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
          <div class="col-md-4">
            <div class="card bg-light"></div>
          </div>
         
        </div>
      </div>
    </div>
  );
}
