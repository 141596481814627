import React from "react";
import course_data from "../api/data.json";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";

function CourseCountCard(props) {
  var icon_color_array = [
    "cico red",
    "cico green",
    "cico yellow",
    "cico grey",
    "cico dark-blue",
    "cico purple",
  ];
  return (
    <>
      {props.counts_data && props.counts_data.length > 0
        ? props.counts_data.map((count_block, index) => {
            return (
              <div className="col-md-6" key={index}>
                <Link
                  to={
                    count_block.element_name === "Total Dairy's"
                      ? "/MyDiary"
                      : count_block.element_name === "staff leave"
                      ? "/ApproveLeaves"
                      : count_block.element_name === "Pending Leave Requests"
                      ? "/StudentLeaves"
                      : count_block.element_name ===
                        "Pending Leave Cancellation"
                      ? "/StudentLeaves"
                      : count_block.element_name === "My E-Learning Resources"
                      ? "/E_Learning"
                      : count_block.element_name ===
                        "Total E-Learning Resources"
                      ? "/E_Learning"
                      : count_block.element_name ===
                        "Offline Mark List Approval "
                      ? "/OfflineAssesmentHome"
                      : count_block.element_name ===
                        "Offline Assessment Approval"
                      ? "/OfflineAssesmentHome"
                      : "none"
                  }
                >
                  <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="media d-flex">
                          <div className="media-body">
                            <div className="row">
                              <div className="col-7">
                                <h3 className="counter">{count_block.count}</h3>
                              </div>
                              <div className="col-5">
                                <div
                                  className={icon_color_array[index]}
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <i
                                    className={
                                      "icofont danger font-large-2 float-right " +
                                      count_block.icon
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="goto">
                          <div style={{ display: "flex", alignItems: "end" }}>
                            <h5 style={{ fontSize: "13px", width: "190px" }}>
                              {count_block.element_name}
                            </h5>
                          </div>
                          <Link
                            to={
                              count_block.element_name === "Total Dairy's"
                                ? "/MyDiary"
                                : count_block.element_name === "staff leave"
                                ? "/ApproveLeaves"
                                : count_block.element_name ===
                                  "Pending Leave Requests"
                                ? "/StudentLeaves"
                                : count_block.element_name ===
                                  "Pending Leave Cancellation"
                                ? "/StudentLeaves"
                                : count_block.element_name ===
                                  "My E-Learning Resources"
                                ? "/E_Learning"
                                : count_block.element_name ===
                                  "Total E-Learning Resources"
                                ? "/E_Learning"
                                : count_block.element_name ===
                                  "Offline Mark List Approval "
                                ? "/OfflineAssesmentHome"
                                : count_block.element_name ===
                                  "Offline Assessment Approval"
                                ? "/OfflineAssesmentHome"
                                : "none"
                            }
                          >
                            <ArrowRight />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        : ""}
    </>
  );
}

export default CourseCountCard;
