import React, { useEffect, useState } from "react";
import Period from "./Period";
import Lecture from "./Lecture";
import Weekend from "./Weekend";

const TimeTableGridView = (props) => {
  const { Periods, Lectures } = props.data;
  console.log("Lectures", Lectures);
  const { Monday, Tuesday, Wednesday, Thursday, Friday } = Lectures;
  const weekDaysColor = {
    MondayColor: [{ bgColor: "monday" }],
    TuesdayColor: [{ bgColor: "tuesday" }],
    WednesdayColor: [{ bgColor: " wednesday" }],
    ThursdayColor: [{ bgColor: "thursday" }],
    FridayColor: [{ bgColor: "friday" }],
  };

  const {
    MondayColor,
    TuesdayColor,
    WednesdayColor,
    ThursdayColor,
    FridayColor,
  } = weekDaysColor;

  const weekDays = [
    { Id: 0, Name: "Periods" },
    { Id: 1, Name: "Monday" },
    { Id: 2, Name: "Tuesday" },
    { Id: 3, Name: "Wednesday" },
    { Id: 4, Name: "Thursday" },
    { Id: 5, Name: "Friday" },
    { Id: 6, Name: "Saturday" },
    { Id: 7, Name: "Sunday" },
  ];

  // console.log("weekDaysColor",weekDaysColor)
  // useEffect(() => {
  //   let ColorData = weekDaysColor.map((cartData) => {
  //     return cartData.Name
  //   })

  //   setColor(ColorData)
  // }, [])

  const isBeforInterval = (startTime, interval) => {
    var st = Number(
      startTime.substring(0, startTime.indexOf(" ")).replace(":", ""),
    );
    var et = Number(
      interval.substring(0, interval.indexOf(" ")).replace(":", ""),
    );
    return st < et;
  };
  return (
    <div class="tt-box">
      <div class="tt-row">
        {weekDays
          ? weekDays.map((day, index) => {
              return (
                <div
                  class={
                    "tt-col" +
                    (day.Id !== 0 ? " " + day.Name.toLowerCase() : "")
                  }
                >
                  <div class="tt-title">
                    <div class="tt-title-inner ">
                      <div class="title">{day.Name}</div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {Periods
        ? Periods?.map((period, i) => {
            // debugger

            if (period.isInterval) {
              return (
                <div className="break-row">
                  <span className="break-time">
                    {period.startTime} - Break - {period.endTime}
                  </span>
                </div>
              );
            } else {
              // var filteredlist = Lectures.filter(
              //   console.log(filteredlist),
              //   (lecture) => lecture.Period === period.id,
              // )
              // if (Lectures.length > 0) {
              // const arrayLectures = [
              //   "monday",
              //   "tuesday",
              //   "wednesday",
              //  "thursday",
              //  "friday",
              // ]
              return (
                <div class="tt-row">
                  <Period data={period} />
                  {/* {Lectures
                    ? Lectures.Friday &&
                      Lectures.Friday.length &&
                      weekDays.map((lecture, j) => {
                        console.log(lecture)

                        // var day = weekDays.filter(
                        //   (day) => day.Id === lecture.weekDay,
                        // )
                        // if (day && day.length > 0) {

                        // if (lecture.name !== 'period') {
                        //   Lectures &&
                        //     Lectures.length &&
                        //     Lectures[lecture.name].map((day) => {
                        //       console.log(day)
                        //       return (
                        //         <Lecture
                        //           data={day}
                        //           // weekDayName={day[0].Name.toLowerCase()}
                        //         />
                        //       )
                        //     })
                        // }
                        // }
                      })
                    : null} */}
                  {/* {arrayLectures[i] &&
                    arrayLectures[i].map((ele) => {
                      console.log(ele) */}

                  <Lecture
                    Lectures={Lectures}
                    data={Monday[i]}
                    Color={MondayColor}
                  />
                  <Lecture
                    Lectures={Lectures}
                    data={Tuesday[i]}
                    Color={TuesdayColor}
                  />
                  <Lecture
                    Lectures={Lectures}
                    data={Wednesday[i]}
                    Color={WednesdayColor}
                  />
                  <Lecture
                    Lectures={Lectures}
                    data={Thursday[i]}
                    Color={ThursdayColor}
                  />
                  <Lecture
                    Lectures={Lectures}
                    data={Friday[i]}
                    Color={FridayColor}
                  />
                  {/* })} */}

                  {/* {lecturefield} */}

                  <Weekend />
                  <Weekend />
                </div>
              );
              // }
            }
          })
        : null}
    </div>
  );
};

export default TimeTableGridView;
