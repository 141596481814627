import React, { useState } from "react";
import Params from "../../config/Params";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

// Function to format date
function formatDate(dateString) {
  const options = { month: "short", year: "numeric", weekday: "short" };
  const date = new Date(dateString);

  // Get day and suffix
  const day = date.getDate();
  let suffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  // Format date without day, only month and year
  const monthYear = date.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });

  // Get weekday
  const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

  // Concatenate the formatted date parts
  return `${day}${suffix} ${monthYear}`;
}

const dateString = "2024-10-03";
const formattedDate = formatDate(dateString);
console.log(formattedDate, "formattedDate");
const LeavesModal = (props) => {
  const { t } = useTranslation();

  const leave_data = props.leave_data;
  const [state, setState] = useState({
    isloading: false,
  });

  function approveLeave(leave_id) {
    setState({ ...state, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      leave_id: leave_id,
    });

    simplePostCall(ApiConfig.EMPLOYEE_LEAVE_APPROVE_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
          props.getLeaveListForApprove();
          props.onClose();
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function cancelLeave(leave_id) {
    setState({ ...state, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      leave_id: leave_id,
    });

    simplePostCall(ApiConfig.EMPLOYEE_LEAVE_CANCEL_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
          props.getLeaveListForApprove();
          props.onClose();
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  {
    return (
      <>
        {state.isloading ? (
          <SpinnerCmp />
        ) : (
          <div
            className="modal fade modal-bookmark show testingll d-block"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ marginTop: "8vh" }}
          >
            <div
              className="modal-dialog modal-md modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="d-flex align-items-center">
                    <div className="modal-header">
                      <img
                        src={
                          leave_data.employee_profile_pic
                            ? leave_data.employee_profile_pic
                            : dashboardAvatar
                        }
                        alt=""
                      />
                    </div>
                    <div className="student-name flex-grow-1">
                      {leave_data.employee_name +
                        " " +
                        leave_data.employee_last_name}
                    </div>
                    <a href="#" className="no-leave" onClick={props.onClose}>
                      <b>x</b>
                    </a>
                  </div>
                </div>
                <div
                  className="modal-body modal-scroll"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <div className="row">
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("From")}</div>
                        <div className="al-input">
                          {formatDate(leave_data.employee_leave_from_date)}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("To")}</div>
                        <div className="al-input">
                          {formatDate(leave_data.employee_leave_to_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("Applied Date")}</div>
                        <div className="al-input">
                          {formatDate(leave_data.employee_leave_apply_date)}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("No. of Days")}</div>
                        <div className="al-input">
                          {leave_data.employee_leave_no_of_days}{" "}
                          {leave_data.employee_leave_no_of_days > 1
                            ? "Days"
                            : "Day"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("Leave Type")}</div>
                        <div className="al-input">
                          {leave_data.employee_leave_type}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="fixed-form">
                        <div className="al-label">{t("Leave Category")}</div>
                        <div className="al-input">
                          {leave_data.leave_category_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="fixed-form">
                        <div className="al-label">{t("Leave Reason")}</div>
                        <div className="al-text-area">
                          {leave_data.employee_leave_reason}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={props.onClose}
                    data-bs-dismiss="modal"
                  >
                    {t("Close")}
                  </button>

                  {leave_data.employee_leave_status == "pending" && (
                    <>
                      <button
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() =>
                          cancelLeave(leave_data.employee_leave_id)
                        }
                      >
                        {t("Request Cancellation")}
                      </button>
                      {/* <button className="btn btn-primary" type="submit" onClick={() => approveLeave(leave_data.employee_leave_id)}>Approve</button> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};
export default LeavesModal;
