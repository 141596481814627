import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { multipartPostCall, simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Create_E_Learning = () => {
  const location = useLocation();

  const { t } = useTranslation();

  let customer_id = JSON.stringify(Params.customer_id);
  const [curriculumState, setCurriculumState] = useState({
    isloading: false,
    curriculums: [],
  });
  const [typeState, setTypeState] = useState({
    isloading: false,
    catagryType: [],
    catagrydata: [],
  });
  const [type, setType] = useState({
    isloading: false,
    categoryType: [],
    categorydata: [],
  });

  const [gradeState, setGradeState] = useState({
    isloading: false,
    grades: [],
  });
  const [subjectState, setSubjectState] = useState({
    isloading: false,
    subjects: [],
  });
  const [lessonsState, setLessonsState] = useState({
    isloading: false,
    lessons: [],
  });
  const [topicsState, setTopicsState] = useState({
    isloading: false,
    topics: [],
  });
  const navigate = useNavigate();
  const [createState, setCreateState] = useState({
    resources_name: "",
    resources_description: "",
    resources_content: "",
    resources_type: 3,
    resources_category: 2,
    resources_curriculum: "",
    resources_class: "",
    resources_subject: "",
    hours: "",
    minutes: "",
    upload_file: "",
    resources_lessons: ["test3", "test4"],
    resources_topic: ["test1", "test2"],
    resources_submit: "submit",
    resources_id: "",
  });
  const [state, setState] = useState({
    selectedCurriculum: [],
    curriculum: "",
    class_division: "",
    selectedGrade: [],
    subject: "",
    selectedSubject: [],
    class_division_id: "",
    allTopics: [],
    lessons: [],
    selectedLessons: [],
    topics: [],
    selectedTopics: [],
    selectedType: "",
    selectedGradeType: [],

    selectedCatagry: "",
    selectedGradeCatagry: [],
  });
  console.log("state", state.class_division);
  console.log("state", state.selectedGrade.id);

  const handleCreateLearning = async (e) => {
    const durationString = `${createState.hours}`;
    const formData = new FormData();
    formData.append("customer_id", Params.customer_id);
    formData.append("api_key", Params.api_key);
    formData.append("user_id", Params.teacher_id);
    formData.append("resources_name", createState.resources_name);
    formData.append("resources_description", createState.resources_description);
    formData.append("resources_type", state.selectedType);
    formData.append("resources_category", state.selectedCatagry);
    formData.append("resources_curriculum", state.curriculum);
    formData.append("resources_class", state.class_division_id);
    formData.append("resources_subject", state.subject);
    if (
      state.selectedCatagry === "PDF" ||
      state.selectedCatagry === "Video" ||
      state.selectedCatagry === "Presentation" ||
      state.selectedCatagry === "Vimeo Upload"
    ) {
      formData.append(
        "upload_file",
        createState.upload_file,
        createState.upload_file.name,
      );
    } else {
      formData.append("resources_content", createState.resources_content);
    }
    formData.append("resources_lessons", state.lessons);
    formData.append("resources_topic", state.topics);
    formData.append("resources_submit", createState.resources_submit);
    formData.append("duration", durationString);
    formData.append("resources_id", createState.resources_id);
    console.log("formdata requestbody", formData);

    multipartPostCall(ApiConfig.E_LEARNING_CREATE, formData)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
          navigate("/E_Learning");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  //////////Start ///////

  useEffect(() => {
    console.log("DATAAASASASASAs", location);
    getCurriculumList();
    getGradeList();
    getCatagrytypeList();
    getCategoryList();
  }, []);
  //List for Type
  function getCatagrytypeList() {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
    });
    setTypeState({ ...typeState, isloading: true });

    simplePostCall(ApiConfig.E_LEARNING_TYPE, newRequestBody)
      .then((data) => {
        if (data.result) {
          var type = [];

          data.type.map((dataType, index) => {
            type.push({
              label: dataType.resource_type_name,
              value: dataType.resource_type_id,
            });
          });
          setTypeState({
            ...typeState,
            isloading: false,
            catagryType: type,
          });
        } else {
          setTypeState({ ...typeState, isloading: false });
        }
      })
      .catch((error) => {
        setTypeState({ ...typeState, isloading: false });
        console.log("api response", error);
      });
  }
  //list for Category after selecting type
  function getCategoryList(category_value) {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      resources_type: category_value,
    });
    setTypeState({ ...typeState, isloading: true });

    simplePostCall(ApiConfig.TYPE_CATEGORY, newRequestBody)
      .then((data) => {
        if (data.result) {
          var category = [];
          data.category.map((dataType, index) => {
            category.push({
              label: dataType.resource_type_name,
              value: dataType.resource_type_id,
            });
          });
          setType({
            isloading: false,
            categorydata: category,
          });
        } else {
          setTypeState({ ...typeState, isloading: false });
        }
      })
      .catch((error) => {
        setTypeState({ ...typeState, isloading: false });
        console.log("api response", error);
      });
  }
  function getCurriculumList() {
    setCurriculumState({ ...curriculumState, isloading: true });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          var curriculums = [];
          if (data.curriculum_list.length > 0) {
            data.curriculum_list.map((curriculum, index) => {
              curriculums.push({
                label: curriculum.curriculum_name,
                value: curriculum.curriculum_id,
              });
            });
          }

          setCurriculumState({
            ...curriculumState,
            isloading: false,
            curriculums: curriculums,
          });
        } else {
          setCurriculumState({ ...curriculumState, isloading: false });
        }
      })
      .catch((error) => {
        setCurriculumState({ ...curriculumState, isloading: false });
        console.log("api response", error);
      });
  }

  function onCurriculumChange(selectedOption) {
    setState({
      ...state,
      curriculum: selectedOption.value,
      selectedCurriculum: selectedOption,
    });
    getSubjects();
    getLessons("", selectedOption.value, "");
  }

  function getGradeList() {
    setGradeState({ ...gradeState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name + " " + grade.class_division_name,
                value: grade.class_division_id,
                id: grade.class_id,
              });
            });
          }
          console.log("DATAAASASASASAs", data);
          setGradeState({ ...gradeState, isloading: false, grades: grades });
        } else {
          setGradeState({ ...gradeState, isloading: false });
        }
      })
      .catch((error) => {
        setGradeState({ ...gradeState, isloading: false });
        console.log("api response", error);
      });
  }

  function onGradeChange(selectedOption) {
    console.log(selectedOption, "DATAAASASASASAs");
    setState({
      ...state,
      class_division: selectedOption.value,
      selectedGrade: selectedOption,
      class_division_id: selectedOption.id,
    });
    getSubjects(selectedOption.value);
    getLessons("", "", selectedOption.value);
  }
  function ontypeChange(selectedOption) {
    console.log(selectedOption, "selectedOption");
    setState({
      ...state,
      selectedType: selectedOption.value,
      selectedGradeType: selectedOption,
    });
    getCategoryList(selectedOption.value);
  }
  function onCatagryChange(selectedOption) {
    console.log(selectedOption, "selectedotptusskv");
    setState({
      ...state,
      selectedCatagry: selectedOption.label,
      selectedGradeCatagry: selectedOption,
    });
  }

  function getSubjects(selected_class_division) {
    setSubjectState({ ...subjectState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      class_id: selected_class_division
        ? selected_class_division
        : state.class_division,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var subjects = [];
          if (data.list?.length > 0) {
            data.list.map((subject, index) => {
              subjects.push({
                label: subject.subject_name,
                value: subject.subject_id,
                subsubject_code: subject.subject_code,
              });
            });
          }
          setSubjectState({
            ...subjectState,
            isloading: false,
            subjects: subjects,
          });
        } else {
          setSubjectState({ ...subjectState, isloading: false });
        }
      })
      .catch((error) => {
        setSubjectState({ ...subjectState, isloading: false });
        console.log("api response", error);
      });
  }

  function onSubjectChange(selectedSubjectOption) {
    console.log("selectedSubjectOption", selectedSubjectOption);
    setState({
      ...state,
      subject: selectedSubjectOption.value,
      selectedSubject: selectedSubjectOption,
    });

    getLessons(selectedSubjectOption.value, "", "", selectedSubjectOption);
  }

  function getLessons(
    subject_id = "",
    curriculum_id = "",
    class_division = "",
    selectedSubjectOption = [],
  ) {
    setLessonsState({ ...lessonsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      class_division_id: class_division ? class_division : state.class_division,
      subject_id: subject_id ? subject_id : state.subject,
      curriculum_id: curriculum_id ? curriculum_id : state.curriculum,
      // curriculum_id: "ccm23",
    });

    simplePostCall(ApiConfig.LESSONS_LIST_API_NEW_ELARNING, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          var lessons = [];
          var all_topics = [];
          if (data.lessons_data.length > 0) {
            data.lessons_data.map((lesson, index) => {
              lessons.push({
                label: lesson.lessons_name,
                value: lesson.lessons_id,
              });
              all_topics.push({
                lessons_id: lesson.lessons_id,
                lessons_topics: lesson.lessons_topics,
              });
            });
          }

          setLessonsState({
            ...lessonsState,
            isloading: false,
            lessons: lessons,
          });
          setState({
            ...state,
            selectedSubject: selectedSubjectOption,
            subject: subject_id,
            allTopics: all_topics,
          });
        } else {
          setLessonsState({ ...lessonsState, isloading: false });
        }
      })
      .catch((error) => {
        setLessonsState({ ...lessonsState, isloading: false });
        console.log("api response", error);
      });
  }

  function onLessonChange(selectedOption) {
    console.log(selectedOption, "selectedOptionssss");
    var selected_lessons = [];
    if (selectedOption?.length > 0) {
      selectedOption.map((lesson, index) => {
        selected_lessons.push(lesson.value);
      });
    }
    setState({
      ...state,
      lessons: selected_lessons,
      selectedLessons: selectedOption,
    });

    getTopics(selected_lessons);
  }

  function getTopics(selected_lessons) {
    var topics_list = [];
    if (state.allTopics.length > 0) {
      state.allTopics.map((topics, index) => {
        if (selected_lessons.indexOf(topics.lessons_id) > -1) {
          if (topics.lessons_topics.length > 0) {
            topics.lessons_topics.map((lessons_topic, index) => {
              topics_list.push({
                label: lessons_topic.topic_name,
                value: lessons_topic.topic_id,
              });
            });
          }
        }
      });
    }
    console.log("state.allTopics", state.allTopics);
    setTopicsState({ ...topicsState, topics: topics_list });
  }

  function onTopicChange(selectedOption) {
    var selected_topics = [];
    if (selectedOption.length > 0) {
      selectedOption.map((topic, index) => {
        selected_topics.push(topic.value);
      });
    }
    setState({
      ...state,
      topics: selected_topics,
      selectedTopics: selectedOption,
    });
  }
  const fileChangedHandler = (e) => {
    setCreateState({
      ...createState,
      upload_file: e.target.files[0],
    });
  };
  console.log("uploaded files", createState.upload_file);
  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="flex-grow-1">{t("Create New Resources")}</h5>
              {/* "Start" */}
              <div className="modal-body datetime-picker ">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Type")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedGradeType}
                      onChange={ontypeChange}
                      options={typeState.catagryType}
                    />
                  </div>
                  <div className="form-group col-md-12 ">
                    <label for="task-title">{t("Category")}</label>
                    {console.log("type defined", state.selectedGradeCatagry)}
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedGradeCatagry}
                      onChange={onCatagryChange}
                      options={type.categorydata}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Curriculum")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedCurriculum}
                      onChange={onCurriculumChange}
                      options={curriculumState.curriculums}
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label for="task-title">{t("Grade")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedGrade}
                        onChange={onGradeChange}
                        options={gradeState.grades}
                      />
                    </div>
                    <div className="form-group col">
                      <label for="task-title">{t("Subject")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedSubject}
                        defaultValue={state.selectedSubject}
                        onChange={onSubjectChange}
                        options={subjectState.subjects}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Lesson")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      //value={state.selectedSubject}
                      onChange={onLessonChange}
                      options={lessonsState.lessons}
                      isMulti={true}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Topic")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={state.selectedTopics}
                      onChange={onTopicChange}
                      options={topicsState.topics}
                      isMulti={true}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Name")}</label>
                    <input
                      className="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                      onChange={(e) => {
                        setCreateState({
                          ...createState,
                          resources_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="sub-task">{t("Description")}</label>
                    <textarea
                      className="form-control"
                      required=""
                      autocomplete="off"
                      onChange={(e) => {
                        setCreateState({
                          ...createState,
                          resources_description: e.target.value,
                        });
                      }}
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="sub-task">{t("Duration")}</label>
                    <div className="d-flex flex-row align-items-center">
                      <input
                        type="number"
                        className="form-control mr-2"
                        style={{ width: "80px" }} // Adjust width as needed
                        placeholder="Hours"
                        onChange={(e) => {
                          setCreateState({
                            ...createState,
                            hours: e.target.value,
                          });
                        }}
                      />
                      <span>Hours</span>
                      <input
                        type="number"
                        className="form-control ml-2"
                        style={{ width: "80px" }} // Adjust width as needed
                        placeholder="Minutes"
                        onChange={(e) => {
                          setCreateState({
                            ...createState,
                            minutes: e.target.value,
                          });
                        }}
                      />
                      <span>Minutes</span>
                    </div>
                  </div>

                  {state.selectedCatagry === "PDF" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload PDF")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                        https://www.youtube.com/
                      </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          required
                          type="file"
                          autocomplete="off"
                          onChange={(e) => {
                            fileChangedHandler(e);
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Video" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload Video")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="file"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            fileChangedHandler(e);
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Vimeo" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter Vimeo Url")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="url"
                          required
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateState({
                              ...createState,
                              resources_content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Youtube" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter URL")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="url"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateState({
                              ...createState,
                              resources_content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Google Drive" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter Drive Url")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="url"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateState({
                              ...createState,
                              resources_content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Presentation" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload PPT")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="file"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            fileChangedHandler(e);
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "TEXT" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter your text")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateState({
                              ...createState,
                              resources_content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "URL" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter URL")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="url"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setCreateState({
                              ...createState,
                              resources_content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : state.selectedCatagry === "Vimeo Upload" ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload Vimeo")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            fileChangedHandler(e);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={handleCreateLearning}
                  className="btn btn-primary"
                >
                  {t("Save and Publish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
};

export default Create_E_Learning;
