import React, { useState } from "react";
import { AppContext } from "./AppContext";

export const AppState = (props) => {
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = React.useState(false);
  const [state, setState] = useState(0);
  const [studentUser, setStudentUser] = useState([]);
  const [question, setQusation] = useState([]);
  const [Attended_list, setAttended_list] = useState([]);
  const [AssessmentId, seAssessmentId] = useState("");
  const [AssessmentIdQustion, seAssessmentIdQustion] = useState("");
  const [ChangeLang, SetChangeLang] = useState("");
  const [pandinge, setPandinge] = useState([]);
  const [assignmentDetailsState, setAssignmentDetailsState] = useState({
    isloading: false,
    assignment_id: "",
    assignment_title: "",
    assignment_description: "",
    curriculum: "",
    class_division: "",
    subject: "",
    students: [],
    submission_date: "",
    lessons: [],
    topics: [],
    submission_mode: "",
    assignment_type: "",
    review_by_user_id: "",
    selectedCurriculum: [],
    selectedGrade: [],
    selectedSubject: [],
    selectedLessons: [],
    selectedTopics: [],
    allTopics: [],
    selectedAssignmentType: [],
    selectedSubmissionMode: [],
    selectedReviewByUser: [],
    selectedStudents: [],
  });

  const [firstName, setFirstName] = useState("");

  return (
    <AppContext.Provider
      value={{
        sidebar,
        ChangeLang, SetChangeLang,
        question,
        setQusation,
        setStudentUser,
        AssessmentId,
        seAssessmentId,
        studentUser,
        setAttended_list,
        Attended_list,
        state,
        setState,
        pandinge,
        setPandinge,
        setSidebar,
        loading,
        setLoading,
        AssessmentIdQustion,
        seAssessmentIdQustion,
        assignmentDetailsState,
        setAssignmentDetailsState,
        firstName,
        setFirstName,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
