import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";
import assignment_card_data from "../../api/assignment_card_data.json";
import E_LearningModal from "../E_Learning/E_LearningModal";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import img from "../../assets/images/assignment/subtopicicon.svg";
import SpinnerCmp from "../../components/SpinnerCmp";
import ReactSelect from "react-select";
import filter from "../../assets/images/filter.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import "../../assets/css/elearning.css";
import { useNavigate } from "react-router-dom";

const Main_E_learning = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [Color, setColor] = useState("");
  const [LearningList, SetLearningList] = useState([]);
  const [nextPage, setNextPage] = useState(true);

  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);
  const [postedByoptions, setPostedByoptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  console.log("typeOptions", typeOptions);
  const [resourceNames, setResourceNames] = useState([]);
  useEffect(() => {
    setIsloading(true);
    getElearning(page);
  }, []);

  function getElearning(currentPage) {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      page_limit: 6,
      page: currentPage,
    };
    console.log("reqq", newRequestBody);
    simplePostCall(
      ApiConfig.E_LEARNING_DIRECTORY,
      JSON.stringify(newRequestBody),
    )
      .then((data) => {
        if (data.result) {
          setIsloading(false);
          let total = data.total_count ? data.total_count : 0;
          setTotalCourses(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            SetLearningList(data.data);
            setNextPage(data.next_page);

            setIsloading(false);
          } else {
            SetLearningList([...LearningList, ...coursesData]);
            setIsloading(false);
          }
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  useEffect(() => {
    let subjectData = assignment_card_data.map((cartData) => {
      return cartData.card_bgcolor;
    });

    setColor(subjectData);
  }, [assignment_card_data]);

  const postedBy_options = useMemo(
    () =>
      [...new Set(LearningList.map((item) => item.published_by))].map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [LearningList],
  );
  const type_options = useMemo(
    () =>
      [...new Set(LearningList.map((item) => item.type))].map((type) => {
        return { label: type, value: type };
      }),
    [LearningList],
  );
  const resourceName_options = useMemo(
    () =>
      [...new Set(LearningList.map((item) => item.resources_name))].map(
        (type) => {
          return { label: type, value: type };
        },
      ),
    [LearningList],
  );

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const onPageLoad = () => {
    setPage(page + 1);
    getElearning(page + 1);
  };

  // ======================================= PAGINATION ENDS ========================

  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <div className="row assignment-sec">
        {isloading ? (
          <ListSkeleton />
        ) : (
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">{t("E-Learning")}</h5>
                  <Link
                    to={"/Create_E_Learning"}
                    state={{ from: LearningList }}
                    className="btn btn-edsys-primary"
                  >
                    <PlusCircle className="me-2" />
                    <span>{t("Create New Resources")}</span>
                  </Link>
                  {/* {Modal Start} */}

                  {/* modal End */}
                </div>
              </div>
              <div className="card-body">
                <InfiniteScroll
                  style={{
                    overflow: "hidden",
                  }}
                  dataLength={LearningList.length}
                  next={onPageLoad}
                  hasMore={LearningList.length !== TotalCourses && nextPage > 1}
                  loader={
                    <h4 style={{ textAlign: "center", marginTop: "20px" }}></h4>
                  }
                  // endMessage={
                  //   <p style={{ textAlign: "center", marginTop: "20px",marginBottom:"15px" }}>
                  //     <b>No More data Found</b>
                  //   </p>
                  // }
                >
                  <div className="row">
                    {/* <div className="col-md-4">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          value={postedByoptions}
                          onChange={(e) => e && setPostedByoptions(e)}
                          options={postedBy_options}
                          placeholder="filter By Class..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          value={typeOptions}
                          onChange={(e) => e && setTypeOptions(e)}
                          options={type_options}
                          placeholder="filter By Type..."
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div class="card-body p-0">
                        <ReactSelect
                          class="js-example-basic-single form-select"
                          value={resourceNames}
                          onChange={(e) => e && setResourceNames(e)}
                          options={resourceName_options}
                          placeholder="filter By Topic..."
                        />
                      </div>
                      <div className="col text-end">
                        <div class="card-body p-0">
                          <img src={filter} alt="" />
                        </div>
                      </div>
                    </div> */}

                    {LearningList.map((postDetail, index) => {
                      let classIndex =
                        index < 6
                          ? index + 1
                          : Math.floor(Math.random() * 6) + 1;
                      return (
                        <div className="col-md-6 mt-4">
                          <div>
                            <div
                              className={
                                "card sub-" + classIndex + " stack-card"
                              }
                            >
                              <div className="card-header">
                                <div className="d-flex">
                                  <img
                                    className="flex-grow-0 subjectIcon"
                                    src={postDetail.subject_icon}
                                  />
                                  <h6 className="flex-grow-1">
                                    {postDetail.subject_name}
                                  </h6>
                                  <h6 className="flex-grow-2 class_name">
                                    {postDetail.class_name}
                                  </h6>
                                </div>
                              </div>

                              {/* <Link
                                // to="/E_LearningDetails"
                                

                              > */}
                              <div
                                className="card-body"
                                onClick={() => {
                                  nav("/E_LearningDetails", {
                                    state: postDetail,
                                  });
                                }}
                              >
                                <h6 className="bodyText">
                                  All Resources: {postDetail.all_resource_count}
                                </h6>
                                <h6 className="bodyText">
                                  My Resources: {postDetail.my_resource_count}
                                </h6>
                              </div>
                              {/* </Link> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <!-- Container-fluid Ends--> */}
    </>
  );
};

export default Main_E_learning;
