import { Link, useParams, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { multipartPostCall, simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const EditResource = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [createState, setCreateState] = useState({ hours: "", minutes: "" });
  const [editData, setEditData] = useState({}); //Resource Data from api

  const [selType, setSelType] = useState({}); //Selected Type
  const [types, setTypes] = useState([]); //Type List

  const [selCategory, setSelCategory] = useState({}); //Selected Category
  const [categories, setCategories] = useState([]); //Category List

  const [selCurriculum, setSelCurriculum] = useState({}); //Selected Curriculum
  const [curriculums, setCurriculums] = useState([]); //Curriculum List

  const [selGrade, setSelGrade] = useState({}); //Selected Grade
  const [grades, setGrades] = useState([]); //Grade List

  const [selSubject, setSelSubject] = useState({}); //Selected Subject
  const [subjects, setSubjects] = useState([]); //Subject List

  const [selLessons, setSelLessons] = useState([]); //Selected Lessons
  const [lessons, setLessons] = useState([]); //Lesson List

  const [selTopics, setSelTopics] = useState([]); //Selected Topics
  const [topics, setTopics] = useState([]); //Topic List

  const [resName, setResName] = useState("");
  const [desc, setDesc] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState({});

  const editElearningApi = async (saveType) => {
    const durationString = `${createState.hours}`;
    const formData = new FormData();
    formData.append("customer_id", Params.customer_id);
    formData.append("api_key", Params.api_key);
    formData.append("user_id", Params.teacher_id);
    formData.append("resources_name", resName);
    formData.append("resources_description", desc);
    formData.append("resources_type", selType.value);
    formData.append("resources_category", selCategory.value);
    formData.append("resources_curriculum", selCurriculum.value);
    formData.append("resources_class", selGrade.id);
    formData.append("resources_subject", selSubject.value);
    formData.append("duration", durationString);
    if (
      selCategory.value === 1 ||
      selCategory.value === 2 ||
      selCategory.value === 6 ||
      selCategory.value === 9
    ) {
      formData.append("upload_file", file);
    } else {
      formData.append("resources_content", content);
    }
    formData.append(
      "resources_lessons",
      selLessons.map((item) => item.value),
    );
    formData.append(
      "resources_topic",
      selTopics.map((item) => item.value),
    );
    formData.append("resources_submit", saveType);
    formData.append("resources_id", location.state.resources_id);
    console.log("formdata requestbody", formData);

    multipartPostCall(ApiConfig.E_LEARNING_CREATE, formData)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
          navigate("/E_Learning");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  //////////Start ///////

  useEffect(async () => {
    await getTypeListApi();
    await resourceDetailsApi(location.state.resources_id);
    await getCurriculumListApi(1);
    await getGradeListApi();
  }, []);

  const resourceDetailsApi = async (resId) => {
    let req = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      resource_id: resId,
    };

    try {
      const res = await simplePostCall(
        ApiConfig.E_LEARNING_DETAILS,
        JSON.stringify(req),
      );

      console.log("Edit Resource", res.datas);
      const data = res.datas[0];
      let type = { label: data.type, value: data.resources_type };

      setEditData(data);
      setSelType(type);

      await getCategoryListApi(type.value, data.resources_category);

      setSelCurriculum({
        label: data.curriculum_name,
        value: data.curriculum_id,
      });

      setSelGrade({
        label: data.class_name,
        value: data.class_division_id,
        id: data.resources_class,
      });

      await getSubjectsApi(data.class_division_id, data.resources_subject);

      setResName(data.resources_name);
      setDesc(data.resources_description);

      if (data.resources_content) {
        setContent(data.resources_content);
      }

      await getLessonsApi(
        data.class_division_id,
        data.resources_subject,
        data.curriculum_id,
      );

      let lessonsData = [];
      let topicsData = [];
      if (data.lesson.length > 0) {
        data.lesson.map((lesson) => {
          lessonsData.push({
            label: lesson.lesson_name,
            value: lesson.lesson_id,
          });
          lesson.topics?.map((topic) =>
            topicsData.push({
              label: topic.topic_name,
              value: topic.topic_id,
              lesson_id: topic.topic_lesson_id,
            }),
          );
        });
      }
      setSelLessons(lessonsData);
      setSelTopics(topicsData);
    } catch (err) {
      console.log("Edit Resource", err);
    }
  };

  //List for Type
  const getTypeListApi = async () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
    });

    simplePostCall(ApiConfig.E_LEARNING_TYPE, newRequestBody)
      .then((data) => {
        if (data.result) {
          var typesData = [];
          data.type.map((dataType, index) => {
            typesData.push({
              label: dataType.resource_type_name,
              value: dataType.resource_type_id,
            });
          });
          setTypes(typesData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  //list for Category after selecting type
  const getCategoryListApi = async (type_value, catId) => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      resources_type: type_value,
    });

    try {
      const response = await simplePostCall(
        ApiConfig.TYPE_CATEGORY,
        newRequestBody,
      );
      if (response.result) {
        var category = [];
        response.category.map((dataType) => {
          category.push({
            label: dataType.resource_type_name,
            value: dataType.resource_type_id,
          });
        });
        setCategories(category);
        if (catId) {
          const seleCate = category.find((cate) => cate.value === catId);
          setSelCategory(seleCate);
        }
      }
    } catch (error) {
      console.log("api response", error);
    }
  };

  const getCurriculumListApi = async (page) => {
    try {
      let req = {
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        page: page,
      };
      const data = await simplePostCall(
        ApiConfig.CURRICULUM_LIST_API,
        JSON.stringify(req),
      );

      if (data.result) {
        const curriculums = data.curriculum_list.map((curriculum) => ({
          label: curriculum.curriculum_name,
          value: curriculum.curriculum_id,
        }));

        if (page === 1) {
          console.log("Setting curriculum list for page 1");
          setCurriculums(curriculums);
        } else {
          console.log("Appending curriculum list for page", page);
          setCurriculums((prevList) => [...prevList, ...curriculums]);
        }

        // If there are more pages, recursively call curriculumListing with the next page
        if (data.next_page) {
          console.log("Fetching next page:", data.next_page);
          curriculumPagination(data.next_page);
        } else {
          console.log("Final curriculum list:", curriculums);
        }
      } else {
        setCurriculums([]);
      }
    } catch (error) {
      setCurriculums([]);
      console.log("api response", error);
    }
  };
  const curriculumPagination = (page) => {
    getCurriculumListApi(page);
  };

  const getGradeListApi = async () => {
    try {
      let newRequestBody = JSON.stringify({
        customer_id: Params.customer_id,
        api_key: Params.api_key,
        user_id: Params.teacher_id,
      });

      const data = await simplePostCall(
        ApiConfig.CLASSES_LIST_API,
        newRequestBody,
      );

      if (data.result) {
        let grades = [];
        if (data.list.length > 0) {
          grades = data.list.map((grade, index) => ({
            label: grade.class_name + " " + grade.class_division_name,
            value: grade.class_division_id,
            id: grade.class_id,
          }));
        }
        setGrades(grades);
      } else {
        setGrades([]);
      }
    } catch (error) {
      setGrades([]);
      console.log("api response", error);
    }
  };

  const getSubjectsApi = async (gradeId, subjectId) => {
    try {
      let newRequestBody = JSON.stringify({
        customer_id: Params.customer_id,
        api_key: Params.api_key,
        user_id: Params.teacher_id,
        class_id: gradeId,
      });

      const data = await simplePostCall(
        ApiConfig.SUBJECT_LIST_BY_SELECTED_GRADE_API,
        newRequestBody,
      );

      if (data.result) {
        let subjects = [];
        if (data.list?.length > 0) {
          console.log("Subs", data.list);
          subjects = data.list.map((subject, index) => ({
            label: subject.subject_name,
            value: subject.subject_id,
            subject_code: subject.subject_code,
          }));
          setSubjects(subjects);
          if (subjectId) {
            const selSub = subjects.find((sub) => sub.value === subjectId);
            setSelSubject(selSub);
          }
        }
      } else {
        setSubjects([]);
      }
    } catch (error) {
      setSubjects([]);
      console.log("api response", error);
    }
  };

  const getLessonsApi = async (gradeId, subjectId, curriculumId) => {
    try {
      let newRequestBody = JSON.stringify({
        customer_id: Params.customer_id,
        api_key: Params.api_key,
        class_division_id: gradeId,
        subject_id: subjectId,
        curriculum_id: curriculumId,
      });

      const data = await simplePostCall(
        ApiConfig.LESSONS_LIST_API_NEW_ELARNING,
        newRequestBody,
      );

      console.log("LessonData", data);

      if (data.result) {
        const parsedLesson = data.lessons_data?.map((lesson) => ({
          label: lesson.lessons_name,
          value: lesson.lessons_id,
          topics: lesson.lessons_topics?.map((topic) => ({
            label: topic.topic_name,
            value: topic.topic_id,
            lesson_id: topic.topic_lesson_id,
          })),
        }));
        setLessons(parsedLesson);
        console.log("ParsedLessons", parsedLesson);
        console.log("topics", selTopics);
      } else {
        setLessons([]);
        setTopics([]);
      }
    } catch (error) {
      setLessons([]);
      setTopics([]);
      console.log("api response", error);
    }
  };

  function ontypeChange(selectedOption) {
    console.log(selectedOption, "selectedType");
    if (selectedOption.value !== selType.value) {
      setSelCategory({});
      setSelType(selectedOption);
      getCategoryListApi(selectedOption.value);
    }
  }

  function onCategoryChange(selectedOption) {
    if (selectedOption.value !== selCategory.value) {
      console.log(selectedOption, "selectedCategory");
      setSelCategory(selectedOption);
    }
  }

  function onCurriculumChange(selectedOption) {
    if (selectedOption.value !== selCurriculum.value) {
      setSelCurriculum(selectedOption);
      setLessons([]);
      setTopics([]);
      setSelLessons([]);
      setSelTopics([]);
      getLessonsApi(selGrade.value, selSubject.value, selectedOption.value);
    }
  }

  function onGradeChange(selectedOption) {
    console.log(selectedOption, "DATAAASASASASAs", selGrade);
    if (selectedOption.value !== selGrade.value) {
      setSelGrade(selectedOption);
      setSelSubject({});
      getSubjectsApi(selectedOption.value);
      setLessons([]);
      setTopics([]);
      setSelLessons([]);
      setSelTopics([]);
    }
  }

  function onSubjectChange(selectedSubjectOption) {
    if (selectedSubjectOption?.value !== selSubject?.value) {
      console.log("selectedSubjectOption", selectedSubjectOption);
      setSelSubject(selectedSubjectOption);
      setLessons([]);
      setTopics([]);
      setSelLessons([]);
      setSelTopics([]);
      getLessonsApi(
        selGrade.value,
        selectedSubjectOption.value,
        selCurriculum.value,
      );
    }
  }

  function onLessonChange(selectedOption) {
    console.log(selectedOption, "selectedOptionssss");
    let newSelTopics = [];
    let newTopicsList = [];
    selTopics.map((topic) => {
      selectedOption.map((lesson) => {
        if (topic.lesson_id === lesson.value) {
          newSelTopics.push(topic);
        }
      });
    });
    setSelTopics(newSelTopics);
    setSelLessons(selectedOption);
    selectedOption?.map((lesson) => {
      console.log(lesson);
      lesson.topics?.map((topic) => {
        newTopicsList.push(topic);
      });
    });
    setTopics(newTopicsList);
    console.log("newTopics", newTopicsList);
  }

  function onTopicChange(selectedOption) {
    setSelTopics(selectedOption);
  }

  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="flex-grow-1">
                Edit Resource
                {/* {t("Create New Resources")} */}
              </h5>
              {/* "Start" */}
              <div className="modal-body datetime-picker ">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Type")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      // value={state.selectedGradeType}
                      value={selType}
                      onChange={ontypeChange}
                      options={types}
                    />
                  </div>
                  <div className="form-group col-md-12 ">
                    <label for="task-title">{t("Category")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={selCategory}
                      onChange={onCategoryChange}
                      options={categories}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Curriculum")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={selCurriculum}
                      onChange={onCurriculumChange}
                      options={curriculums}
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label for="task-title">{t("Grade")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={selGrade}
                        onChange={onGradeChange}
                        options={grades}
                      />
                    </div>
                    <div className="form-group col">
                      <label for="task-title">{t("Subject")}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={selSubject}
                        // defaultValue={state.selectedSubject}
                        onChange={onSubjectChange}
                        options={subjects}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Lesson")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={selLessons}
                      onChange={onLessonChange}
                      options={lessons}
                      isMulti={true}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Topic")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      value={selTopics}
                      onChange={onTopicChange}
                      onFocus={() => {
                        let newTopics = [];
                        selLessons.map((selLes) => {
                          lessons.map((less) => {
                            if (selLes.value == less.value) {
                              // console.log(less.topics)
                              less.topics.map((topic) => {
                                newTopics.push(topic);
                              });
                            }
                          });
                        });
                        setTopics(newTopics);
                        // console.log(topics, selLessons, lessons);
                      }}
                      options={topics}
                      isMulti={true}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Name")}</label>
                    <input
                      className="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      value={resName}
                      autocomplete="off"
                      onChange={(e) => {
                        setResName(e.target.value);
                        // setCreateState({
                        //   ...createState,
                        //   resources_name: e.target.value,
                        // });
                      }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="sub-task">{t("Description")}</label>
                    <textarea
                      className="form-control"
                      required=""
                      value={desc}
                      autocomplete="off"
                      onChange={(e) => {
                        setDesc(e.target.value);
                        // setCreateState({
                        //   ...createState,
                        //   resources_description: e.target.value,
                        // });
                      }}
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="sub-task">{t("Duration")}</label>
                    <div className="d-flex flex-row align-items-center">
                      <input
                        type="number"
                        className="form-control mr-2"
                        style={{ width: "80px" }} // Adjust width as needed
                        placeholder="Hours"
                        onChange={(e) => {
                          setCreateState({
                            ...createState,
                            hours: e.target.value,
                          });
                        }}
                      />
                      <span>Hours</span>
                      <input
                        type="number"
                        className="form-control ml-2"
                        style={{ width: "80px" }} // Adjust width as needed
                        placeholder="Minutes"
                        onChange={(e) => {
                          setCreateState({
                            ...createState,
                            minutes: e.target.value,
                          });
                        }}
                      />
                      <span>Minutes</span>
                    </div>
                  </div>
                  {selType.value === 1 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload PDF")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                        https://www.youtube.com/
                      </span> */}
                        <input
                          className="form-control"
                          // value={content}
                          id="task-title"
                          required
                          type="file"
                          autocomplete="off"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 2 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload Video")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          // value={content}
                          type="file"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 3 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter Vimeo Url")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          value={content}
                          type="url"
                          required
                          autocomplete="off"
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 4 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter URL")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="url"
                          required=""
                          autocomplete="off"
                          value={content}
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 5 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter Drive Url")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          value={content}
                          type="url"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 6 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload PPT")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          // value={content}
                          type="file"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 7 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter your text")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          value={content}
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            // setCreateState({
                            //   ...createState,
                            //   resources_content: e.target.value,
                            // });
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 8 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Enter URL")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          value={content}
                          type="url"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            // setCreateState({
                            //   ...createState,
                            //   resources_content: e.target.value,
                            // });
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : selType.value === 9 ? (
                    <div className="form-group col-md-12">
                      <label for="task-title">{t("Upload Vimeo")}</label>
                      <div className="input-group mb-3">
                        {/* <span className="input-group-text" id="basic-addon3">
                    https://www.youtube.com/
                  </span> */}
                        <input
                          className="form-control"
                          id="task-title"
                          type="text"
                          required=""
                          autocomplete="off"
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => editElearningApi("save")}
                  className="btn btn-secondary"
                >
                  {t("Save as Draft")}
                </button>
                <button
                  onClick={() => editElearningApi("submit")}
                  className="btn btn-primary"
                >
                  {t("Save as Publish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
};

export default EditResource;
