import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { FaBookDead, FaPlus } from "react-icons/fa";
import { FaBookAtlas, FaBookJournalWhills, FaMinus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Bookmark, BookOpen, ExternalLink, Plus } from "react-feather";
import { BiBookAlt, BiBookmark, BiSolidBookAlt } from "react-icons/bi";
import ReactTooltip from "react-tooltip";

export default function ViewSyllabusData() {
  const [tableDataStore, setTabledataStore] = useState([]);
  const [showCreateFull, setShowCreateFull] = useState(false);
  const [academicYear, setAcademicYear] = useState("");
  const [classId, setClassId] = useState("");
  const [subject, setSubject] = useState("");
  const [hide, setHide] = useState(false);
  const [hideInside, setHideInside] = useState(false);
  const [createLesson, setCreateLesson] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const getData = location.state;
  const navic = useNavigate();
  const [storedata, setstoredata] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [datastores, setDatastores] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [lessonId, setLessonId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [edit, setEdit] = useState(false);
  const [editIds, setEditIds] = useState("");

  useEffect(() => {
    SyllabusDataView();
  }, []);

  const SyllabusDataView = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_id: getData,
    });
    {
      console.log(getData, "getData");
    }
    simplePostCall(ApiConfig.EDIT_VIEW_DATA, RequestBody)
      .then((data) => {
        console.log(data, "data Sednded");
        if (data.list) {
          // setLoading(false);
          console.log(data.list, "SAMPPLE");
          setTabledataStore(data?.list[0]);
          setAcademicYear(data?.list[0]?.academic_year_id);
          setClassId(data?.list[0]?.class_id);
          setSubject(data?.list[0]?.subject_id);
        } else {
          // setLoading(false);
          console.log("NO DATA in view");
        }
        if (data.data) {
          setDatastores(data.data);
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  //  <---------- LESSON VIEW CREATE ------------>
  const Lessonviews = (e) => {
    console.log(e, "working");
    if (e == "lesson") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        syllabus_id: getData,
        lesson_name: createLesson,
        lesson_duration: duration,
        lesson_description: "",
      });
    } else if (e == "topic") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        lesson_id: lessonId,
        topic_name: createLesson,
        topic_duration: duration,
        topic_description: "",
      });
    } else {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        topic_id: topicId,
        sub_topic_name: createLesson,
        sub_topic_duration: duration,
        sub_topic_description: description,
      });
    }
    simplePostCall(ApiConfig.LESSON_PLAN_LIST, RequestBody)
      .then((data) => {
        console.log(data, "messages");
        toast.success(data.message);

        setShowCreateFull(false);
        setCreateLesson("");
        setDuration("");
        SyllabusDataView();
        setShowCreateFull(false);
        hide(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  //  <---------- LESSON VIEW CREATE ------------>
  const LessonEdit = (e) => {
    console.log(e, "working");
    if (e == "lesson") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        syllabus_id: getData,
        lesson_id: editIds,
        lesson_name: createLesson,
        lesson_duration: duration,
        lesson_description: "",
      });
    } else if (e == "topic") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        topic_id: editIds,
        topic_name: createLesson,
        topic_duration: duration,
        topic_description: "",
      });
    } else {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        sub_topic_id: editIds,
        sub_topic_name: createLesson,
        sub_topic_duration: duration,
        sub_topic_description: description,
      });
    }
    simplePostCall(ApiConfig.LESSONS_PLAN_EDIT, RequestBody)
      .then((data) => {
        console.log(data, "messages");
        toast.success(data.message);
        setCreateLesson("");
        setDuration("");
        setShowCreateFull(false);

        SyllabusDataView();
        setShowCreateFull(false);
        hide(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  function deleteData() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_id: storedata,
    });
    simplePostCall(ApiConfig.SYLLABUS_DELETE_DATA, newRequestBody)
      .then((data) => {
        // setState({ ...state, subject: data.list[0].id });
        toast.success(data.message);
        navic("/NewSyllabus");
        // SyllabusListTable();
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  function deleteLesson(e) {
    if (e == "lesson") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        lesson_id: deleteId,
      });
    } else if (e == "topic") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        topic_id: deleteId,
      });
    } else {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.teacher_id,
        sub_topic_id: deleteId,
      });
    }
    simplePostCall(ApiConfig.LESSON_PLAN_DELETE, RequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
          SyllabusDataView();
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const toggleCreateFull = () => {
    setShowCreateFull(!showCreateFull);
  };
  const toggle = () => {
    setHide(!hide);
  };
  const toggleInside = () => {
    setHideInside(!hideInside);
  };
  // <------------------------->
  const handleDragStart = (event, index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault(); // Required to allow dropping
  };

  const handleDragEnter = (event, index) => {
    event.preventDefault(); // Required to allow dropping
  };

  const handleDragLeave = (event) => {
    // Reset any styles or effects on leaving the drop target
  };

  const handleDrop = (event, newIndex) => {
    event.preventDefault();

    // Retrieve the index of the dragged item
    const oldIndex = draggedItem;

    // If the dragged item index is the same as the dropped index, return
    if (oldIndex === newIndex) {
      return;
    }

    // Clone the datastores array
    const newDatastores = [...datastores];

    // Remove the dragged item from its old position
    const draggedItemData = newDatastores.splice(oldIndex, 1)[0];

    // Insert the dragged item at the new position
    newDatastores.splice(newIndex, 0, draggedItemData);

    // Update the state with the new order
    setDatastores(newDatastores);
  };
  const handleEdit = (name, duration, description, id, status) => {
    if (status == "lesson") {
      setCreateLesson(name);
      setDuration(duration);
      setDescription(description);
      setShowCreateFull(true);
      setEditIds(id);
    } else if (status == "topic") {
      setCreateLesson(name);
      setDuration(duration);
      setDescription(description);
      setHide(true);
      setEditIds(id);
    } else {
      setCreateLesson(name);
      setDuration(duration);
      setDescription(description);
      setHideInside(true);
      setEditIds(id);
    }
  };
  const handleLessoncreate = (item, e) => {
    console.log(e, "workinglessonss", item);
    if (e == "lesson") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        user_id: Params.teacher_id,
        academic_year: academicYear,
        grad_id: classId,
        subject_id: subject,
        assessment_offline_type: "formative",
        lesson_id: item.lessons_id,
      });
    } else if (e == "topic") {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        user_id: Params.teacher_id,
        academic_year: academicYear,
        grad_id: classId,
        subject_id: subject,
        assessment_offline_type: "formative",
        topic_id: item.topic_id,
      });
    } else {
      var RequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        user_id: Params.teacher_id,
        academic_year: academicYear,
        grad_id: classId,
        subject_id: subject,
        assessment_offline_type: "formative",
        sub_topic_id: item.sub_topic_id,
      });
    }
    console.log(RequestBody, "RequestBody");

    simplePostCall(ApiConfig.FORMATIVE_CREATE, RequestBody)
      .then((data) => {
        console.log(data, "messages");
        if (data.message == "Offline Assessment created successfully") {
          toast.success(data.message);
          navic("/StudentMarkListEdit", {
            state: {
              offlineId: data.data.assessment_offline_id,
              SemExam: data.data.Exam_name,
              academicYear: {
                label: data.data.Academic_year_name,
                value: data.data.academic_year,
              },
              assesmentIds: data.data.assessment_id,
              grade: {
                value: data.data.grade_id,
                label: data.data.class_name,
                // value: assessment.grade_id,
              },

              subject: {
                label: data.data.subject__name,
                value: data.data.subject_id,
              },
            },
          });
        } else {
          navic("/StudentMarkListEdit", {
            state: {
              offlineId: data.data.assessment_offline_id,
              SemExam: data.data.Exam_name,
              academicYear: {
                label: data.data.Academic_year_name,
                value: data.data.academic_year,
              },
              assesmentIds: data.data.assessment_id,
              grade: {
                value: data.data.grade_id,
                label: data.data.class_name,
                // value: assessment.grade_id,
              },

              subject: {
                label: data.data.subject__name,
                value: data.data.subject_id,
              },
            },
          });
        }
      })
      .catch((err) => {
        // setLoading(false);
        console.log("api res fail", err);
      });
  };
  return (
    <div className="main-sectionn ">
      <div className="col-md-12">
        <div className="announcementCreatee">
          <label className="edit-head">Lessons, Topics & Subtopics</label>
          <div className="View-Buttons">
            <button
              className="View-Buttons1"
              type="submit"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => setstoredata(getData)}
            >
              Delete
            </button>
            <button
              className="View-Buttons2"
              type="button"
              onClick={() =>
                navic("/syllabus-Edit", {
                  state: getData,
                })
              }
            >
              Update
            </button>
            <button className="View-Buttons3 " type="button">
              Export
            </button>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete this item?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => deleteData()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalone"
        data-bs-backdrop="static"
        tabIndex="-1"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-5" id="staticBackdropLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this Lesson?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => deleteLesson("lesson")}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modaltwo"
        data-bs-backdrop="static"
        tabIndex="-1"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-5" id="staticBackdropLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this Topic?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => deleteLesson("topic")}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalthree"
        data-bs-backdrop="static"
        tabIndex="-1"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fs-5" id="staticBackdropLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this SubTopic?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => deleteLesson()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Syllabus Name</th>
              <td scope="col">{tableDataStore.syllabus_name}</td>
            </tr>
            <tr>
              <th scope="col">Academic Year Name</th>
              <td scope="col">{tableDataStore.academic_year_name}</td>
            </tr>
            <tr>
              <th scope="col">Curriculum Name</th>
              <td scope="col">{tableDataStore.curriculum_name}</td>
            </tr>
            <tr>
              <th scope="col">Class Name</th>
              <td scope="col">{tableDataStore.class_name}</td>
            </tr>
            <tr>
              <th scope="col">Subject Name</th>
              <td scope="col">{tableDataStore.subject_name}</td>
            </tr>
          </thead>
        </table>
      </div>
      {console.log(datastores, "datastores")}

      {showCreateFull && (
        <div className="CreateFull">
          <div className="createLess">
            <label>Lesson</label>
            <FaMinus onClick={toggleCreateFull} />
          </div>

          <div>
            <input
              type="text"
              class="form-control"
              placeholder="Enter lesson"
              aria-label="Enter lesson"
              aria-describedby="basic-addon1"
              value={createLesson ? createLesson : ""}
              onChange={(e) => {
                setCreateLesson(e.target.value);
              }}
            />
          </div>
          <div className="createLess">
            <label>Duration(minutes)</label>
          </div>
          {/* {console.log(createLesson)} */}
          <div>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Minutes"
              aria-label="Enter Minutes"
              aria-describedby="basic-addon1"
              value={duration ? duration : ""}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            />
          </div>
          {/* {console.log(duration)} */}
        </div>
      )}
      {showCreateFull && (
        <button
          className="add_but"
          onClick={() => (edit ? LessonEdit("lesson") : Lessonviews("lesson"))}
        >
          Add
        </button>
      )}
      {/* <---accordium satrt----> */}

      <div class="accordion accordion-flush" id="accordionFlushExample">
        {datastores.map((item, index) => (
          <>
            <div
              class="accordion-item"
              draggable="true"
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={(event) => handleDragOver(event, index)}
              onDragEnter={(event) => handleDragEnter(event, index)}
              onDragLeave={(event) => handleDragLeave(event)}
              onDrop={(event) => handleDrop(event, index)}
            >
              <h2 class="accordion-header ah-1" id={`flush-heading-${index}`}>
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapse-${index}`}
                  onClick={() => setLessonId(item.lessons_id)}
                >
                  <div className="acc-but1">
                    <div className="acc1">
                      <label className="acc-text">
                        Lesson: {item.lessons_name}
                      </label>
                      <label className="acc-text">
                        Duration: {item.lessons_duration}
                      </label>
                    </div>
                    <div className="acc-de">
                      <div onClick={() => handleLessoncreate(item, "lesson")}>
                        <ReactTooltip effect="solid" />
                        <FaBookAtlas
                          data-tip="Create Exam"
                          data-place="top"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </div>
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modalone"
                        onClick={() => {
                          let lessonId = item.lessons_id;
                          setDeleteId(lessonId);
                          // deleteLesson("lesson");
                        }}
                      >
                        <RiDeleteBin6Line
                          data-tip="Delete"
                          data-place="top"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setEdit(true);
                          handleEdit(
                            item.lessons_name,
                            item.lessons_duration,
                            "",
                            item.lessons_id,
                            "lesson",
                          );
                          setHide(false);
                        }}
                      >
                        <MdModeEdit
                          data-tip="Edit"
                          data-place="top"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div
                id={`flush-collapse-${index}`}
                class="accordion-collapse collapse"
                aria-labelledby={`flush-heading-${index}`}
                data-bs-parent={`#flush-collapse-${index}`}
              >
                <div class="accordion-body ac-body">
                  {/* 2layer */}
                  <div
                    class="accordion accordion-flush"
                    id={`innerAccordion-${index}`}
                  >
                    {item.topic?.map((items, innerIndex) => (
                      <div class="accordion-item" key={innerIndex}>
                        <div
                          class="accordion-header ah-1"
                          id={`flush-heading-${index}-${innerIndex}`}
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${index}-${innerIndex}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse-${index}-${innerIndex}`}
                            onClick={() => setTopicId(items.topic_id)}
                          >
                            {/* {console.log(item.topic_id, "hiiiii")} */}
                            <div className="acc-but1">
                              <div className="acc1">
                                <label className="acc-text">
                                  Topic: {items.topic_name}
                                </label>
                                <label className="acc-text">
                                  Duration: {items.topic_duration}
                                </label>
                              </div>
                              <div className="acc-de">
                                <div
                                  onClick={() =>
                                    handleLessoncreate(items, "topic")
                                  }
                                >
                                  <ReactTooltip effect="solid" />
                                  <FaBookAtlas
                                    data-tip="Create Exam"
                                    data-place="top"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </div>
                                <div
                                  data-bs-toggle="modal"
                                  data-bs-target="#modaltwo"
                                  onClick={() => {
                                    let topicId = items.topic_id;
                                    setDeleteId(topicId);
                                  }}
                                >
                                  <RiDeleteBin6Line
                                    data-tip="Delete"
                                    data-place="top"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    setEdit(true);
                                    handleEdit(
                                      items.topic_name,
                                      items.topic_duration,
                                      "",
                                      items.topic_id,
                                      "topic",
                                    );
                                    setShowCreateFull(false);
                                  }}
                                >
                                  <MdModeEdit
                                    data-tip="Edit"
                                    data-place="top"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </button>
                          <div
                            id={`flush-collapse-${index}-${innerIndex}`}
                            class="accordion-collapse collapse"
                            aria-labelledby={`flush-heading-${index}-${innerIndex}`}
                            data-bs-parent={`#innerAccordion-${index}`}
                          >
                            {/* {console.log(items.subtopic, "item.topic")} */}
                            {items?.subtopic.length < 0
                              ? null
                              : items.subtopic?.map((itemss, innerIndex) => (
                                  <div class="accordion-item" key={innerIndex}>
                                    {/* {console.log(itemss, "lastlist")} */}
                                    <div class="accordion-body ac-body">
                                      <div className="acc-but1">
                                        <div className="acc1">
                                          <label className="acc-text">
                                            Sub Topic: {itemss.sub_topic_name}
                                          </label>
                                          <label className="acc-text">
                                            Duration:
                                            {itemss.sub_topic_duration}
                                          </label>
                                          <label>
                                            Description:{" "}
                                            {itemss.sub_topic_description}
                                          </label>
                                        </div>
                                        <div className="acc-de">
                                          <div
                                            onClick={() =>
                                              handleLessoncreate(itemss, "")
                                            }
                                          >
                                            <ReactTooltip effect="solid" />
                                            <FaBookAtlas
                                              data-tip="Create Exam"
                                              data-place="top"
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalthree"
                                            onClick={() => {
                                              let subTopicId =
                                                itemss.sub_topic_id;
                                              setDeleteId(subTopicId);
                                              console.log(
                                                subTopicId,
                                                "subTopicId",
                                              );
                                              // deleteLesson("lesson");
                                            }}
                                          >
                                            <RiDeleteBin6Line
                                              data-tip="Delete"
                                              data-place="top"
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            onClick={() => {
                                              setEdit(true);
                                              handleEdit(
                                                itemss.sub_topic_name,
                                                itemss.sub_topic_duration,
                                                itemss.sub_topic_description,
                                                itemss.sub_topic_id,
                                                "subtopic",
                                              );
                                            }}
                                          >
                                            <MdModeEdit
                                              data-tip="Edit"
                                              data-place="top"
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            {!hideInside && (
                              <button
                                className="view_plus"
                                onClick={toggleInside}
                              >
                                {/* <inside button/> */}

                                <FaPlus />
                              </button>
                            )}
                          </div>

                          {hideInside && (
                            <div className="CreateFull" key={items.topic_id}>
                              <div className="createLess">
                                <label>Sub Topic</label>
                                <FaMinus onClick={toggleInside} />
                              </div>

                              <div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Sub Topic"
                                  aria-label="Enter Topic"
                                  aria-describedby="basic-addon1"
                                  value={createLesson ? createLesson : ""}
                                  onChange={(e) => {
                                    setCreateLesson(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="createLess">
                                <label>Duration(minutes)</label>
                              </div>
                              {/* {console.log(createLesson)} */}
                              <div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Minutes"
                                  aria-label="Enter Minutes"
                                  aria-describedby="basic-addon1"
                                  value={duration ? duration : ""}
                                  onChange={(e) => {
                                    setDuration(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="createLess">
                                <label>Description</label>
                              </div>
                              {/* {console.log(createLesson)} */}
                              <div>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Description"
                                  aria-label="Enter Minutes"
                                  aria-describedby="basic-addon1"
                                  value={description ? description : ""}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                />
                              </div>
                              {/* {console.log(duration)} */}
                            </div>
                          )}
                          {hideInside && (
                            <button
                              className="add_but"
                              onClick={() =>
                                edit
                                  ? LessonEdit("subtopic")
                                  : Lessonviews("subtopic")
                              }
                            >
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {!hide && (
                      <button className="view_plus" onClick={toggle}>
                        {/* <inside button/> */}

                        <FaPlus />
                      </button>
                    )}
                  </div>

                  {hide && (
                    <div className="CreateFull">
                      <div className="createLess">
                        <label>Topic</label>
                        <FaMinus onClick={toggle} />
                      </div>

                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Topic"
                          aria-label="Enter Topic"
                          aria-describedby="basic-addon1"
                          value={createLesson ? createLesson : ""}
                          onChange={(e) => {
                            setCreateLesson(e.target.value);
                          }}
                        />
                      </div>
                      <div className="createLess">
                        <label>Duration(minutes)</label>
                      </div>
                      {/* {console.log(createLesson)} */}
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Minutes"
                          aria-label="Enter Minutes"
                          aria-describedby="basic-addon1"
                          value={duration ? duration : ""}
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                        />
                      </div>
                      {/* {console.log(duration)} */}
                    </div>
                  )}
                  {hide && (
                    <button
                      className="add_but"
                      onClick={() =>
                        edit ? LessonEdit("topic") : Lessonviews("topic")
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      {!showCreateFull && (
        <button className="view_plus" onClick={toggleCreateFull}>
          <FaPlus />
        </button>
      )}
      {/* ))} */}
    </div>
  );
}
