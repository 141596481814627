import React, { useContext, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import Avatar from "../../../assets/images/Avatar.png";

const Lecture = (props) => {
  const { firstName } = useContext(AppContext);
  const data = props.data;
  const Color = props.Color;
  const Lectures = props.Lectures;
  console.log("firstName", firstName);
  console.log("Lectures", Lectures.Subject);
  console.log("PropsData", data);
  const base_url = "https://app.edsys.in:3000/";

  return (
    <div class={"tt-col tt-content " + Color[0].bgColor}>
      {/* {data &&
        data.length &&
        data.map((element) => {
          return ( */}
      <div class="tt-desc tt-desc-inner">
        <img src={data.Image ? base_url + data.Image : Avatar} />
        {data.class_name && (
          <div class="title">
            {firstName === data.teacher_name
              ? data.class_name + "-" + data.Division
              : data.Subject
              ? data.Subject
              : "-"}
          </div>
        )}
        {data.Subject === "No Duty" || data.Subject === "Not Scheduled" ? (
          <div class="title">{" - "}</div>
        ) : (
          ""
        )}

        {Lectures.class_name && (
          <div class="title">
            {firstName === data.teacher_name
              ? Lectures.class_name + "-" + Lectures.class_division_name
              : data.teacher_name}
          </div>
        )}
        <div class="duration">
          <p class="status">{data.Subject ? data.Subject : "No Duty"}</p>
        </div>
      </div>
      {/* )
        })} */}
    </div>
  );
};
export default Lecture;
