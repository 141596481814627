import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";

export const GradeListEdit = () => {
  const [newdata, setNewData] = useState({});
  const [slabList, setSlabList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation();
  const navigate = useNavigate();
  const getData = location.state;

  const CreateGradeEdit = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: getData,
    });

    simplePostCall(ApiConfig.GRADEWISE_LIST_VIEW, RequestBody)
      .then((data) => {
        if (data && data.list && data.list.length > 0) {
          setNewData(data.list[0]);
          setSlabList(data.list[0].grade_slab);
        } else {
          console.log("Error while fetching");
        }
        setLoading(false); // Data fetched, stop loading
      })
      .catch((err) => {
        console.log("API response failed", err);
        setLoading(false); // Stop loading even if there's an error
      });
  };

  useEffect(() => {
    CreateGradeEdit();
  }, []);

  const handleSlabChange = (index, field, value) => {
    const updatedSlabs = slabList.map((slab, i) =>
      i === index ? { ...slab, [field]: value } : slab,
    );
    setSlabList(updatedSlabs);
  };

  const handleMouseMove = (index, event) => {
    if (event.buttons !== 1) return;
    updateProgress(index, event);
  };

  const handleClick = (index, event) => {
    updateProgress(index, event);
  };

  const updateProgress = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const width = rect.width;
    const newProgress = Math.min(100, Math.max(0, (offsetX / width) * 100));
    const newSlabs = [...slabList];
    newSlabs[index].assessment_grading_slab_end_percentage = newProgress;
    setSlabList(newSlabs);

    if (index < slabList.length - 1) {
      newSlabs[index + 1].assessment_grading_slab_start_percentage =
        newProgress;
    }
  };

  const handleInputChange = (index, event) => {
    const newValue = Math.min(100, Math.max(0, Number(event.target.value)));
    const newSlabs = [...slabList];
    newSlabs[index].assessment_grading_slab_end_percentage = newValue;
    setSlabList(newSlabs);

    if (index < slabList.length - 1) {
      newSlabs[index + 1].assessment_grading_slab_start_percentage = newValue;
    }
  };

  const handleUpdate = () => {
    const updatedData = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: getData,
      grade_name: newdata.assessment_grading_name,
      grade: slabList.map((slab) => ({
        grade_slab_name: slab.assessment_grading_slab_name,
        start_percentage: slab.assessment_grading_slab_start_percentage,
        end_percentage: slab.assessment_grading_slab_end_percentage,
        grade_slab_code: slab.assessment_grading_slab_code,
        grade_slab_id: slab.assessment_grading_slab_id,
      })),
    };

    simplePostCall(ApiConfig.GRADEE_LIST_EDIT, JSON.stringify(updatedData))
      .then((response) => {
        if (response) {
          toast.success("Updated Successfully");
          navigate("/maingradelist");
        } else {
          console.log("Error while updating");
        }
      })
      .catch((err) => {
        console.log("API response failed", err);
      });
  };

  const handleDeleteSlab = (slabId) => {
    setSlabList(
      slabList.filter((slab) => slab.assessment_grading_slab_id !== slabId),
    );
    toast.success("Slab deleted successfully");
  };

  return (
    <div>
      <div className="evaluation">
        <div className="evalHead">
          <h5 className="labelCreate">Edit Grade List</h5>
        </div>
        <div className="row">
          <div className="col-md-12 totalGradecol">
            <div className="row">
              <div className="col-md-6">
                <h6>Grading Name</h6>
                {loading ? (
                  <Skeleton count={1} height={40} />
                ) : (
                  <textarea
                    className="gradeTextarea"
                    placeholder="Enter the Grade Name"
                    value={newdata.assessment_grading_name || ""}
                    onChange={(e) =>
                      setNewData({
                        ...newdata,
                        assessment_grading_name: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>
            {loading
              ? [...Array(3)].map((_, index) => (
                  <div key={index} className="row">
                    <div className="col-md-6">
                      <h6>
                        <Skeleton width={50} height={40} />
                      </h6>
                      <Skeleton count={1} height={40} />
                    </div>
                    <div className="col-md-6">
                      <h6>
                        <Skeleton width={50} height={40} />
                      </h6>
                      <Skeleton count={1} height={40} />
                    </div>
                    <div className="col-md-6">
                      <h6>
                        <Skeleton width={50} height={40} />
                      </h6>
                      <Skeleton count={1} height={40} />
                    </div>
                  </div>
                ))
              : slabList.map((slab, index) => (
                  <div key={index} className="row ">
                    <div className="closebuttt">
                      <h5 className="gradetext">
                        Edit Grading slab {index === 0 ? null : index}
                      </h5>

                      {index === 0 ? null : (
                        <div className="removeSlabButton">
                          <button
                            className="gradeClose"
                            onClick={() =>
                              handleDeleteSlab(slab.assessment_grading_slab_id)
                            }
                          >
                            <IoCloseSharp />
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <h6>Grade Slab Name </h6>
                      <textarea
                        className="gradeTextarea"
                        placeholder="Enter the Grade slab Name"
                        value={slab.assessment_grading_slab_name || ""}
                        onChange={(e) =>
                          handleSlabChange(
                            index,
                            "assessment_grading_slab_name",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>Update Grading List</h6>
                      <textarea
                        className="gradeTextarea"
                        placeholder="Enter the Grade"
                        value={slab.assessment_grading_slab_code || ""}
                        onChange={(e) =>
                          handleSlabChange(
                            index,
                            "assessment_grading_slab_code",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <h6>Edit Grading Percentage and Ending Percentage</h6>
                      <div>
                        <div
                          className="progress"
                          onMouseMove={(e) => handleMouseMove(index, e)}
                          onClick={(e) => handleClick(index, e)}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${slab.assessment_grading_slab_end_percentage}%`,
                            }}
                            aria-valuenow={
                              slab.assessment_grading_slab_end_percentage
                            }
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {Math.round(
                              slab.assessment_grading_slab_end_percentage,
                            )}
                            %
                          </div>
                        </div>
                        <input
                          type="number"
                          value={Math.round(
                            slab.assessment_grading_slab_end_percentage,
                          )}
                          onChange={(e) => handleInputChange(index, e)}
                          min="0"
                          max="100"
                          style={{
                            marginTop: "10px",
                            padding: "5px",
                            width: "60px",
                            display: "flex",
                            textAlign: "center",
                            border: "1px solid #dacece",
                            color: "#76787d",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            <div className="subButtt">
              <button className="btn btn-primary crbt" onClick={handleUpdate}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradeListEdit;
