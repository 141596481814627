import React, { useState, useEffect, useContext } from "react";
import "../../src/assets/css/main.css";
import "../../src/assets/css/style.css";
import "../assets/css/dark.css";
import { simplePostCall } from "../api/ApiServices";
import Params from "../config/Params";
import ApiConfig from "../api/ApiConfig";
import Logo from "../assets/images/logo/logo.png";
import DarkLogo from "../assets/images/logo/dark-logo.png";
import SpinnerCmp from "./SpinnerCmp";
import { useTranslation } from "react-i18next";

import {
  Maximize,
  Bell,
  Unlock,
  AlignRight,
  LogOut,
  Settings,
  ArrowRight,
  Moon,
} from "react-feather";
import dashboardAvatar from "../assets/images/dashboard/1.png";
import "./ExternalScripts";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authContext from "../screens/login/GetUserStatus";
import { AppContext } from "../context/AppContext";

function Header() {
  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState("");

  const handleLangChange = (evt) => {
    const lang = evt.target.value;

    handleLanguage(lang);
    setLanguage(lang);
  };
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const { setAuthenticated } = useContext(authContext);
  const { setFirstName } = useContext(AppContext);
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);
  const [dark, setDark] = useState(true);

  const [FullScreen, setFullScreen] = useState(false);
  const [profileDataState, setProfileDataState] = useState({
    isloading: false,
    USER_NAME:
      localStorage.getItem("USER_NAME") === null
        ? []
        : localStorage.getItem("USER_NAME"),
    USER_EMAIL:
      localStorage.getItem("USER_EMAIL") === null
        ? []
        : localStorage.getItem("USER_EMAIL"),
    USER_GENDER:
      localStorage.getItem("USER_GENDER") === null
        ? []
        : localStorage.getItem("USER_GENDER"),
    USER_STAFF_ID:
      localStorage.getItem("USER_STAFF_ID") === null
        ? []
        : localStorage.getItem("USER_STAFF_ID"),
    USER_PROFILE_PIC:
      localStorage.getItem("USER_PROFILE_PIC") === null
        ? []
        : localStorage.getItem("USER_PROFILE_PIC"),
    USER_PROFILE_THEME:
      localStorage.getItem("USER_THEME") === null
        ? []
        : localStorage.getItem("USER_THEME"),
  });
  const [notificationDataState, setNotificationDataState] = useState({
    isloading: true,
    unreadCount: 0,
    notifications: [],
  });

  useEffect(() => {
    if (profileDataState.USER_THEME === "dark") {
      if (profileDataState.USER_THEME === "dark") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.remove("dark-only");
      }
    } else {
      document.body.classList.remove("dark-only");
    }
  }, [profileDataState]);

  const handleClick = () => {
    if (click == true) {
      document.getElementById("page_container").style.marginLeft = "290px";
      document.getElementById("the_footer").style.marginLeft = "290px";
      setClick(false);
    } else {
      document.getElementById("page_container").style.marginLeft = "0px";
      document.getElementById("the_footer").style.marginLeft = "0px";
      setClick(true);
    }
  };

  function DarkMode() {
    if (dark) {
      handleTheme("dark");
    } else {
      handleTheme("light");
    }
  }

  const handleTheme = async (Status) => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      theme: Status,
    });
    simplePostCall(ApiConfig.PROFILE_THEME, newRequestBody)
      .then((data) => {
        if (data.result) {
          getUserProfileData();
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleLanguage = async (lang) => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      language: lang,
    });

    simplePostCall(ApiConfig.USERT_LANGUAGE, newRequestBody)
      .then((data) => {
        if (data.result) {
          getUserProfileData();
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  function MyFullScreen() {
    if (FullScreen === true) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
  }

  function getUserProfileData() {
    setProfileDataState({ ...profileDataState });

    simplePostCall(ApiConfig.USER_PROFILE_DATA_API, JSON.stringify(Params))
      .then((data) => {
        if (data.result) {
          localStorage.setItem("USER_NAME", data.profile_data.first_name);
          localStorage.setItem("USER_EMAIL", data.profile_data.email);
          localStorage.setItem("USER_GENDER", data.profile_data.gender);
          localStorage.setItem("USER_STAFF_ID", data.profile_data.staff_id);
          localStorage.setItem("USER_THEME", data.profile_data.user_theme);

          localStorage.setItem(
            "USER_PROFILE_PIC",
            data.base_url + data.profile_data.profile_pic,
          );

          setProfileDataState({
            ...profileDataState,
            isloading: false,
            USER_NAME: data.profile_data.name,
            USER_EMAIL: data.profile_data.email,
            USER_GENDER: data.profile_data.gender,
            USER_STAFF_ID: data.profile_data.staff_id,
            USER_THEME: data.profile_data.user_theme,

            USER_PROFILE_PIC: data.base_url + data.profile_data.profile_pic,
          });
          let profileLang = data.profile_data.user_language;
          setLanguage(profileLang);
          setFirstName(data.profile_data.first_name);

          setNotificationDataState({
            ...notificationDataState,
            isloading: false,
            notifications: data.notification_list,
            unreadCount: data.unread_notification_count,
          });
        } else {
          setProfileDataState({ ...profileDataState, isloading: false });
          setProfileDataState({ ...notificationDataState, isloading: false });
        }
      })
      .catch((error) => {
        setProfileDataState({ ...profileDataState, isloading: false });
        setProfileDataState({ ...notificationDataState, isloading: false });
        console.log("api response", error);
      });
  }

  function logOut() {
    setAuthenticated(false);
    localStorage.setItem("is_logged_in", JSON.stringify(false));
    if (localStorage.getItem("loggedin_url")) {
      var loggedin_url = localStorage.getItem("loggedin_url");
      localStorage.clear();

      window.location.replace(loggedin_url + "/login/logout");
    }
  }

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <div>
      <ToastContainer autoClose={3000} theme={dark ? "light" : "dark"} />
      {/* Page Header Start */}
      <div className="page-main-header  ">
        <div className="main-header-right row m-0">
          <div className="main-header-left">
            <div className="logo-wrapper">
              <Link to="/">
                <a>
                  <img className="img-fluid" src={Logo} alt="" />
                </a>
              </Link>
            </div>
            <div className="dark-logo-wrapper">
              <img className="img-fluid" src={DarkLogo} alt="" />
            </div>
            <div className={"toggle-sidebar"} onClick={handleClick}>
              <AlignRight />
            </div>
          </div>
          {/* <div className="left-menu-header col">
            <ul> */}
          {/* <li>
                <form className="form-inline search-form">
                  <div className="search-bg">
                    <i className="fa fa-search"></i>
                    <input
                      className="form-control-plaintext"
                      placeholder="Search here....."
                    />
                  </div>
                </form>
                <span className="d-sm-none mobile-search search-bg">
                  <i className="fa fa-search"></i>
                </span>
              </li> */}
          {/* </ul>
          </div> */}
          <div className="nav-right col pull-right right-menu p-0">
            <ul className="nav-menus">
              <li>
                <a
                  type="button"
                  className="text-dark"
                  onClick={() => {
                    setFullScreen(!FullScreen);
                    MyFullScreen();
                  }}
                >
                  <Maximize />
                </a>
              </li>
              <li className="onhover-dropdown">
                <div className="notification-box">
                  <Bell />
                  {notificationDataState.unreadCount > 0 ? (
                    <span className="dot-animated"></span>
                  ) : null}
                </div>
                <ul className="notification-dropdown onhover-show-div">
                  {notificationDataState.unreadCount > 0 ? (
                    <li>
                      <p className="f-w-700 mb-0">
                        You have new Notifications
                        <span className="pull-right badge badge-primary badge-pill">
                          {notificationDataState.unreadCount}
                        </span>
                      </p>
                    </li>
                  ) : null}
                  {notificationDataState.notifications.length > 0 ? (
                    <>
                      {notificationDataState.notifications.map(
                        (notification, index) => {
                          var notiClasses = [
                            "noti-primary",
                            "noti-secondary",
                            "noti-success",
                            "noti-danger",
                          ];
                          var notiBodyClasses = [
                            "bg-light-primary",
                            "bg-light-secondary",
                            "bg-light-success",
                            "bg-light-danger",
                          ];
                          return (
                            <li key={index} className={notiClasses[index]}>
                              <Link to="/Assignment" className="d-flex">
                                <div className="media">
                                  <span
                                    className={
                                      "notification-bg " +
                                      notiBodyClasses[index]
                                    }
                                  >
                                    <Bell />
                                  </span>
                                  <div className="media-body">
                                    {notification.notification_status !=
                                    "read" ? (
                                      <p>{notification.notification_text}</p>
                                    ) : (
                                      <span>
                                        {notification.notification_text}
                                      </span>
                                    )}
                                    <span>
                                      {
                                        notification.notification_display_datetime
                                      }
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          );
                        },
                      )}
                      <li>
                        <Link to="/Notification" className="d-flex">
                          <div className="flex-grow-1">
                            {t("See all notifications")}
                          </div>
                          <div className="p-0">
                            <ArrowRight />
                          </div>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <li>
                      <p className="f-w-700 mb-0">You have no notifications</p>
                    </li>
                  )}
                </ul>
              </li>
              <li
                onClick={() => {
                  setDark(!dark);
                  DarkMode();
                }}
              >
                <div className="mode">
                  <Moon type="button" />
                </div>
              </li>
              {/* <li className="onhover-dropdown">
                <MessageSquare />
                <ul className="chat-dropdown onhover-show-div">
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User1}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Ain Chavez</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">32 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User2}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Erica Hughes</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">58 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User4}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Kori Thomas</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">1 hr ago</p>
                    </div>
                  </li>
                  <li className="text-center">
                    {" "}
                    <a className="f-w-700" href="#">
                      See All{" "}
                    </a>
                  </li>
                </ul>
              </li> */}
              <li>
                <a className="text-dark" href="#">
                  <Link to="/LoginIn">
                    <Unlock />
                  </Link>
                </a>
              </li>
              <li className="lang-list">
                <a className="" href="#">
                  {/* <ChangeLang /> */}
                  <select
                    className="lang_select"
                    onChange={handleLangChange}
                    value={language}
                  >
                    <option value="en">English </option>
                    <option value="ar">Arabic </option>
                    <option value="ta">Tamil</option>
                  </select>
                </a>
              </li>
            </ul>
          </div>

          <div className="d-lg-none mobile-toggle pull-right w-auto">
            <i data-feather="more-horizontal"></i>
          </div>
          <li className="onhover-dropdown p-0 con">
            <button
              onClick={logOut}
              className="btn btn-primary-light"
              type="button"
              style={{ width: "137px", marginRight: "6px" }}
            >
              <a
                href=""
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <LogOut /> {t("Log out")}
              </a>
            </button>
          </li>
        </div>
      </div>
      {/* <li className="onhover-dropdown p-0 pro1 con">
        <button
          onClick={logOut}
          className="btn btn-primary-light"
          type="button"
        >
          <a href="">
            <LogOut /> {t("Log out")}
          </a>
        </button>
      </li> */}
      <div className="page-body-wrapper sidebar-icon">
        {/* <!-- Page Sidebar Start--> */}

        <header
          className={click ? "main-nav close_icon" : "main-nav"}
          id="main_nav"
          onClick={closeMobileMenu}
        >
          <div className="newModal">
            {profileDataState.isloading ? (
              <SpinnerCmp
                customStyle={{
                  textAlign: "center",
                  marginTop: "57px",
                  paddingBottom: "50px",
                }}
              />
            ) : (
              <div className="sidebar-user text-center">
                <Link to="/UserProfile">
                  <a className="setting-primary d-flex">
                    <Link to="/MySetting">
                      <Settings />
                    </Link>
                  </a>

                  <img
                    className="img-90 rounded-circle"
                    src={
                      profileDataState.USER_PROFILE_PIC
                        ? profileDataState.USER_PROFILE_PIC
                        : dashboardAvatar
                    }
                    alt=""
                    style={{ width: "90px !important", height: "90px" }}
                    onError={(e) => {
                      e.target.src = dashboardAvatar;
                    }}
                  />
                  <a>
                    <h6 className="mt-3 f-14 f-w-600">
                      {profileDataState.USER_NAME}
                    </h6>
                  </a>
                  <p className="mb-0 font-roboto">
                    {profileDataState.USER_EMAIL}
                  </p>
                  {/* <ul>
                  <li>
                    <span>{profileDataState.USER_GENDER}</span>
                    <p>Gender</p>
                  </li>
                  <li>
                    <span>{profileDataState.USER_STAFF_ID}</span>
                    <p>Staff ID</p>
                  </li>
                </ul> */}
                </Link>
              </div>
            )}

            <Sidebar />
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
