import React from "react";

export default function TablesSkeleton() {
  return (
    <>
      <div className="card br-15 mt-4">
        <div class="loading-skeleton">
          <div className="card-body">
            <div className="col-12">
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
              <div class=" mt-3">
                <div class="title p-3 border bg-light"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
