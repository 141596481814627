import React, { useState, useEffect } from "react";
import LeavesModal from "./LeavesModal";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import filter from "../../assets/images/filter.svg";
import { useTranslation } from "react-i18next";
import TablesSkeleton from "../../components/skeletons/TablesSkeleton";

function EmpLeaves() {
  const { t } = useTranslation();

  const [leaveList, setLeaveList] = useState([]);
  const [leavedata, setLeaveData] = useState([]);
  console.log("leavedata", leavedata);
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const handleModal = (value) => {
    setLeaveData(value);
    setIsTodoModelShow(true);
  };
  const handleModalClose = (value) => {
    setLeaveData(value);
    setIsTodoModelShow(false);
  };

  useEffect(() => {
    getLeaves();
  }, []);

  function getLeaves() {
    setIsloading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      employee_id: Params.teacher_id,
      academic_year_id: "",
    };
    simplePostCall(ApiConfig.MY_LEAVE_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        // if (data.result) {
        setIsloading(false);
        setLeaveList(data.list);
        // }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const category = [
    { value: "Planned", label: "Planned" },
    { value: "Unplanned", label: "Unplanned" },
  ];
  const status = [
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  // Function to format date
  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}`;
  }

  const dateString = "2024-10-03";
  const formattedDate = formatDate(dateString);
  console.log(formattedDate, "formattedDate");

  return (
    <>
      {isloading ? (
        <TablesSkeleton />
      ) : (
        <div className="approve-leave">
          {/* <!-- Container-fluid starts--> */}
          <div className="row">
            <div className="col-12">
              <div
                className="card br-15 mt-4"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <div className="card-body p-0">
                  <Table responsive="sm">
                    <thead className="position-sticky top-0 start-50 bg-light br-15">
                      <tr>
                        <th>{t("S.No")}</th>
                        <th>{t("From Date")}</th>

                        <th>{t("To Date")}</th>
                        <th>{t("Leave Category")}</th>
                        <th>{t("Leave Status")}</th>
                        <th className="no-leave ms-auto">
                          {t("Leave Number Of Days")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className="position-sticky bg-light"
                        style={{ top: "35px" }}
                      >
                        {/* <td>
                          <img src={filter} alt="" />
                        </td> */}
                        {/* <td>
                          <input
                            className="datepicker-here form-control digits"
                            type="date"
                            data-language="en"
                            placeholder="MM-DD-YYYY"
                            data-position="top left"
                            // onChange={onInputChangeHandle}
                            name="employee_leave_from_date"
                            // value={state.employee_leave_from_date}
                          />
                        </td>
                        <td>
                          <input
                            className="datepicker-here form-control digits"
                            type="date"
                            data-language="en"
                            placeholder="MM-DD-YYYY"
                            data-position="top left"
                            // onChange={onInputChangeHandle}
                            name="employee_leave_from_date"
                            // value={state.employee_leave_from_date}
                          />
                        </td> */}
                        {/* <td>
                          <Select
                            class="js-example-basic-single form-select"
                            // value={state.selectedCurriculum}
                            // onChange={onCurriculumChange}
                            options={category}
                          />
                        </td>
                        <td>
                          <Select
                            class="js-example-basic-single form-select"
                            // value={state.selectedCurriculum}
                            // onChange={onCurriculumChange}
                            options={status}
                          />
                        </td>
                        <td></td> */}
                      </tr>
                      {leaveList && leaveList?.length > 0 ? (
                        leaveList?.map((leave, index) => (
                          <tr
                            key={"leave" + index}
                            className="student-rank-list"
                            onClick={() => handleModal(leave)}
                          >
                            <td>{index + 1}</td>
                            {console.log(
                              "leave.employee_leave_from_date",
                              leave.employee_leave_from_date,
                            )}
                            <td>
                              {formatDate(leave.employee_leave_from_date)}
                            </td>
                            <td>{formatDate(leave.employee_leave_to_date)}</td>
                            <td>{leave.employee_leave_category}</td>
                            <td>{leave.employee_leave_status}</td>
                            <td className="td_text_center">
                              {leave.employee_leave_no_of_days}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="student-rank-list"
                        >
                          <div className="d-flex align-items-center">
                            <p>{t("No records found")}..!</p>
                          </div>
                        </div>
                      )}
                    </tbody>
                  </Table>

                  {/* Modal start */}
                  {isTodoModelShow ? (
                    <LeavesModal
                      onClose={handleModalClose}
                      leave_data={leavedata}
                      getLeaveListForApprove={getLeaves}
                    />
                  ) : null}
                  {/* modal End */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </div>
      )}
    </>
  );
}

export default EmpLeaves;
