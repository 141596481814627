import React, { useState, useEffect, useContext } from "react";
import shape from "../../assets/images/authentication/shape.svg";
import edsysLogo from "../../assets/images/logo/edsysLogo.svg";
import shape1 from "../../assets/images/authentication/shape-1.svg";
import { Link } from "react-router-dom";
import { Base64 } from "js-base64";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import authContext from "./GetUserStatus";
import SpinnerCmp from "../../components/SpinnerCmp";

function Login() {
  const { setAuthenticated } = useContext(authContext);
  const [state, setState] = useState({
    isProcessing: false,
    customer_id: "",
    api_key: "",
    teacher_id: "",
  });

  {
    /* bypass login start */
  }
  function byPassLogin() {
    setState({ ...state, isProcessing: true });
    var current_url = window.location.href;
    const url_array = current_url.split("?auth_key=");
    if (url_array.length > 1) {
      const appUrl = url_array[0];
      const encodedAuthKey = url_array[1];
      const decodedAuthKey = Base64.decode(encodedAuthKey);

      const params_array = decodedAuthKey.split("&");
      console.log(params_array, "params_array");
      const auth_key = params_array[0];
      const user_id = params_array[1];
      const customer_id = params_array[2];
      const loggedin_url = params_array[3];

      localStorage.setItem("loggedin_url", loggedin_url);

      let requestBody = JSON.stringify({
        auth_key: auth_key,
        user_id: user_id,
        customer_id: customer_id,
      });

      localStorage.setItem("is_logged_in", JSON.stringify(false));
      simplePostCall(ApiConfig.BYPASS_LOGIN_API, requestBody)
        .then((data) => {
          setState({ ...state, isProcessing: false, isLoggedIn: true });
          if (data.result) {
            parseData(data);

            localStorage.setItem(
              "CUSTOMER_ID",
              JSON.stringify(data.customer_id),
            );
            localStorage.setItem("API_KEY", JSON.stringify(data.api_key));
            localStorage.setItem("TEACHER_ID", JSON.stringify(data.user_id));

            setAuthenticated(true);
            localStorage.setItem("is_logged_in", JSON.stringify(true));

            window.location.replace(appUrl);
          } else {
            console.log("not working");
            window.location.replace(data.url + "/logout");
          }
        })
        .catch((error) => {
          console.log("login api response", error);
          setState({ ...state, isProcessing: false });
        });
    } else {
      /* uncomment Below line for Production Otherwise you can use Static credentials */
      window.location.replace("https://stage.edsys.in/login/logout");
      /* for testing */
      // localStorage.setItem("CUSTOMER_ID", JSON.stringify(1005));
      // localStorage.setItem("API_KEY", JSON.stringify("79a1facf283e8992b05c78814e79de1d"));
      // localStorage.setItem("TEACHER_ID", JSON.stringify(16144));
      // setAuthenticated(true);
      // localStorage.setItem("is_logged_in", JSON.stringify(true));
      // window.location.replace(window.location.href);
      // /* for testing */
      // localStorage.setItem("CUSTOMER_ID", JSON.stringify(1005));
      // localStorage.setItem("API_KEY", JSON.stringify("b93b364f889a0cb2392d9b9808a2b536"));
      // localStorage.setItem("TEACHER_ID", JSON.stringify(29726));
      // setAuthenticated(true);
      // localStorage.setItem("is_logged_in", JSON.stringify(true));
      // window.location.replace(window.location.href);

      // /* for development purpose only not for production */

      // Sarin Pradeep ID //
      // localStorage.setItem("CUSTOMER_ID", JSON.stringify(1005));
      // localStorage.setItem(
      //   "API_KEY",
      //   JSON.stringify("d7dd7748dbe74d176608120b670c34e0"),
      // );
      // localStorage.setItem("TEACHER_ID", JSON.stringify(24836));
      // setAuthenticated(true);
      // localStorage.setItem("is_logged_in", JSON.stringify(true));

      // window.location.replace(window.location.href);
    }
  }
  {
    /* bypass login ends */
  }

  function parseData(data) {
    setState({
      ...state,
      customer_id: data.customer_id,
      api_key: data.api_key,
      teacher_id: data.user_id,
    });
  }

  const customStyle = {
    textAlign: "center",
    marginTop: "30vh",
    height: "500px",
  };

  useEffect(() => {
    document.title = "Edsys | Sign In ";
    byPassLogin();
  }, []);
  return (
    <>
      <section>
        {state.isProcessing ? (
          <div>
            <h6>Please wait...</h6>
            {/* <SpinnerCmp customStyle={customStyle} /> */}
          </div>
        ) : (
          <div className="container-fluid p-0" style={{ display: "none" }}>
            <div className="row">
              <div className="col-12">
                <div className="login-card">
                  <img className="shape-top" src={shape} />
                  <img className="login-logo" src={edsysLogo} />
                  <img className="shape-center" src={shape1} />
                  <form className="theme-form login-form">
                    <h4>Login</h4>
                    <h6>Welcome back! Log in to your account.</h6>
                    <div className="form-group">
                      <label>Email Address</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icon-email"></i>
                        </span>
                        <input
                          className="form-control"
                          type="email"
                          required=""
                          placeholder="Test@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="icon-lock"></i>
                        </span>
                        <input
                          className="form-control"
                          type="password"
                          name="login[password]"
                          required=""
                          placeholder="*********"
                        />
                        <div className="show-hide">
                          <span className="show"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox">
                        <input id="checkbox1" type="checkbox" />
                        <label for="checkbox1">Remember password</label>
                      </div>
                      <a className="link" href="forget-password.html">
                        Forgot password?
                      </a>
                    </div>
                    <div className="form-group">
                      <Link to="/">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Sign in
                        </button>
                      </Link>
                    </div>

                    <p>
                      Don't have account?{" "}
                      <Link to="/LoginIn">
                        <a className="ms-2">Create Account</a>{" "}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
export default Login;
