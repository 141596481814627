import React, { useEffect, useState } from "react";
import {
    Video,
    FileText,
    Image,
    File,
    Headphones,
    Trash2,
    Edit3,
    Eye,
    PlusCircle,
} from "react-feather";
import { useLocation, Link } from "react-router-dom";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next"; 

function ViewPushNotification() {
  const { t } = useTranslation();

    const [notificationDataState, setNotificationDataState] = useState(
        {
            isloading: false,
            notification_data: useLocation().state,
        }
    );
    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    useEffect(() => {
        document.title = "Edsys | Email ";
    }, []);
    return (
        <>
            {/* Container-fluid starts */}
            <div className="row announcement assignment-sec">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="d-flex">
                                <h5 className="flex-grow-1">{t("View Push Notification")}</h5>
                                <Link to="/push-notification" className="btn btn-primary">
                                    {t("Back")}
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {notificationDataState.isloading ? (
                                    <SpinnerCmp loading={notificationDataState.isloading} />
                                ) : (
                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="card br-15">
                                                <div className="card-body">
                                                    <div className="details-banner">
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Notification Range")}</th>
                                                                <td className="communication_table_th_td">{notificationDataState.notification_data.notification_parent_range}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Notification Range Value")}</th>
                                                                <td className="communication_table_th_td">{notificationDataState.notification_data.notification_parent_range_value}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Students")}</th>
                                                                <td className="communication_table_th_td">{notificationDataState.notification_data.notification_parent_student}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Message")}</th>
                                                                <td className="communication_table_th_td">
                                                                    <div dangerouslySetInnerHTML={{ __html: notificationDataState.notification_data.notification_parent_message }}></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="communication_table_th_td">{t("Date")}</th>
                                                                <td className="communication_table_th_td">
                                                                    <div dangerouslySetInnerHTML={{ __html: notificationDataState.notification_data.notification_parent_datetime }}></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container-fluid Ends */}
        </>
    );
}

export default ViewPushNotification;
