import React, { useState, useEffect } from "react";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import ApiConfig from "../../../../api/ApiConfig";
import Params from "../../../../config/Params";
import { simplePostCall } from "../../../../api/ApiServices";
import { Eye, PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";
import QuestionModal from "./QuestionModal";
import { toast } from "react-toastify";
import CreateQuestionModal from "./CreateQuestionModal";
import { useTranslation } from "react-i18next";

export default function AssignmentStepTwo(props) {
  const { t } = useTranslation();

  const [availableQuestionsState, setAvailableQuestionsState] = useState({
    isloading: false,
    availableQuestions: [],
  });
  const [state, setState] = useState({
    isloading: false,
    assignment_id: props.assignmentDetails.assignment_id,
    isQuestionModalShow: false,
    isCreateQuestionModalShow: false,
    showAvailableQuestions: false,
    clickedQuestionID: "",
  });
  const [assignmentState, setAssignmentState] = useState({
    isloading: false,
    assignment: [],
    questions: [],
  });

  function getAvailableQuestions() {
    setAvailableQuestionsState({ ...availableQuestionsState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assignment_id: state.assignment_id,
      page_limit: 200,
    });
    simplePostCall(
      ApiConfig.AVAILABLE_QUESTIONS_FOR_ASSIGNMENT_API,
      newRequestBody,
    )
      .then((data) => {
        if (data.result) {
          setAvailableQuestionsState({
            ...availableQuestionsState,
            isloading: false,
            availableQuestions: data.data,
          });
        } else {
          setAvailableQuestionsState({
            ...availableQuestionsState,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        setAvailableQuestionsState({
          ...availableQuestionsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }

  function openCloseQuestionModel(clickedQuestionID) {
    setState({
      ...state,
      isQuestionModalShow: !state.isQuestionModalShow,
      clickedQuestionID: clickedQuestionID,
    });
  }

  function showAvailableQuestions() {
    if (!state.showAvailableQuestions == true) {
      /* scroll to div starts */
      document.getElementById("availableQuestionsDiv").scrollIntoView({
        behavior: "smooth",
      });
      /* scroll to div ends */
    }
    setState({
      ...state,
      showAvailableQuestions: !state.showAvailableQuestions,
    });
  }

  function getAssignmentDetails() {
    setAssignmentState({ ...assignmentState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assignment_id: state.assignment_id,
    });
    simplePostCall(ApiConfig.ASSIGNMENT_DETAILS_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setAssignmentState({
            ...assignmentState,
            isloading: false,
            assignment: data.assignment_details,
            questions: data.task_under_assignment,
          });
        } else {
          setAssignmentState({ ...assignmentState, isloading: false });
        }
      })
      .catch((error) => {
        setAssignmentState({ ...assignmentState, isloading: false });
        console.log("api response", error);
      });
  }

  function addQuestionToAssignment(question_id) {
    setAssignmentState({ ...assignmentState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assignment_id: state.assignment_id,
      question_id: [JSON.stringify(question_id)],
    });
    simplePostCall(ApiConfig.ADD_QUESTION_TO_ASSIGNMENT, newRequestBody)
      .then((data) => {
        if (data.result) {
          setAssignmentState({ ...assignmentState, isloading: false });
          toast.success(data.message);
          getAssignmentDetails();
          getAvailableQuestions();
        } else {
          setAssignmentState({ ...assignmentState, isloading: false });
          toast.error(data.message);
          getAssignmentDetails();
          getAvailableQuestions();
        }
      })
      .catch((error) => {
        setAssignmentState({ ...assignmentState, isloading: false });
        console.log("api response", error);
      });
  }

  function openCloseCreateQuestionModel() {
    setState({
      ...state,
      isCreateQuestionModalShow: !state.isCreateQuestionModalShow,
    });
  }

  function publishAssignment() {
    setState({ ...state, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assignment_id: state.assignment_id,
    });
    simplePostCall(ApiConfig.PUBLISH_ASSIGNMENT_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setState({ ...state, isloading: false });
          toast.success(data.message);
          setTimeout(function () {
            window.location.replace(
              "/AssignmentDetails/" + state.assignment_id,
            );
          }, 1000);
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    getAvailableQuestions();
    getAssignmentDetails();
  }, []);

  return (
    <>
      {availableQuestionsState.isloading ||
      assignmentState.isloading ||
      state.isloading ? (
        <SpinnerCmp
          loading={
            availableQuestionsState.isloading ||
            assignmentState.isloading ||
            state.isloading
          }
        />
      ) : (
        <>
          <ReactTooltip />
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ float: "left" }}
              >
                {t("Add Tasks")}
              </h5>
              <div style={{ float: "right" }}>
                <button
                  className="btn btn-primary"
                  onClick={showAvailableQuestions}
                >
                  Available Questions
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-secondary"
                  onClick={openCloseCreateQuestionModel}
                >
                  {t("Create Task")}
                </button>
              </div>
            </div>

            <div
              class="modal-body datetime-picker"
              id="questionsUnderAssignment"
            >
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%", float: "left" }}>
                  <tr>
                    <th>{t("Assignment Title")}:</th>
                    <td>{assignmentState.assignment.assignment_title}</td>
                    <th>{t("Grade")}:</th>
                    <td>{assignmentState.assignment.assignment_class}</td>
                    <th>{t("Subject")}:</th>
                    <td>{assignmentState.assignment.assignment_subject}</td>
                  </tr>
                </table>
              </div>
              <br />
              <br />
              <div className="row edsys-table">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h6>{t("Questions Under Current Assignment")}</h6>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingBottom: "10px" }}
                    >
                      {assignmentState.questions &&
                      assignmentState.questions.length > 0 ? (
                        <table
                          className="table scrollable_table"
                          style={{
                            display:
                              assignmentState.questions.length > 0
                                ? ""
                                : "none",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t("Task Title")} </th>
                              <th scope="col">{t("Lesson Name")}</th>
                              <th scope="col">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assignmentState.questions &&
                            assignmentState.questions.length > 0
                              ? assignmentState.questions.map(
                                  (added_question, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{added_question.question_title}</td>
                                        <td>
                                          {added_question.lesson_name
                                            ? added_question.lesson_name
                                            : "(not set)"}
                                        </td>
                                        <td>
                                          <span
                                            data-tip="View Question"
                                            onClick={() =>
                                              openCloseQuestionModel(
                                                added_question.question_id,
                                              )
                                            }
                                          >
                                            <Eye className="assignment_table_icon" />
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )
                              : null}
                          </tbody>
                        </table>
                      ) : (
                        <span
                          style={{
                            display:
                              assignmentState.questions.length > 0
                                ? "none"
                                : "",
                            marginLeft: "1%",
                          }}
                        >
                          {t("No questions found")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="availableQuestionsDiv">
              <div
                class="modal-body datetime-picker"
                style={{ display: state.showAvailableQuestions ? "" : "none" }}
              >
                <div className="row edsys-table">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h6>{t("Available Questions For This Assignment")}</h6>
                      </div>
                      <div
                        className="table-responsive"
                        style={{ paddingBottom: "10px" }}
                      >
                        <table
                          className="table scrollable_table"
                          style={{
                            display: "block",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t("Task Title")} </th>
                              <th scope="col">{t("Lesson Name")}</th>
                              <th scope="col">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {availableQuestionsState.availableQuestions &&
                            availableQuestionsState.availableQuestions.length >
                              0 ? (
                              availableQuestionsState.availableQuestions.map(
                                (available_question, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>
                                        {available_question.question_title}
                                      </td>
                                      <td>
                                        {available_question.lesson_name
                                          ? available_question.lesson_name
                                          : "(not set)"}
                                      </td>
                                      <td
                                        style={{
                                          padding: "10px",
                                        }}
                                      >
                                        <span
                                          data-tip="View Question"
                                          onClick={() =>
                                            openCloseQuestionModel(
                                              available_question.question_id,
                                            )
                                          }
                                        >
                                          <Eye className="assignment_table_icon" />
                                        </span>

                                        <span
                                          style={{
                                            padding: "10px",
                                          }}
                                          data-tip="Add Question to Assignment"
                                          onClick={() =>
                                            addQuestionToAssignment(
                                              available_question.question_id,
                                            )
                                          }
                                        >
                                          <PlusSquare className="assignment_table_icon" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                },
                              )
                            ) : (
                              <tr>
                                <th scope="row">{t("No tasks found")}</th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                class="btn btn-primary"
                type="button"
                onClick={() => publishAssignment()}
              >
                {t("Publish")}
              </button>
            </div>
          </div>
          <br />

          {state.isQuestionModalShow ? (
            <QuestionModal
              onClose={openCloseQuestionModel}
              questionID={state.clickedQuestionID}
              assignmentDetails={assignmentState.assignment}
            />
          ) : null}
          {state.isCreateQuestionModalShow ? (
            <CreateQuestionModal
              onClose={openCloseCreateQuestionModel}
              getAvailableQuestions={getAvailableQuestions}
              getAssignmentDetails={getAssignmentDetails}
              assignmentId={state.assignment_id}
            />
          ) : null}
        </>
      )}
    </>
  );
}
