import React, { useEffect, useState } from "react";
import "./GradewiseList.css";
import { BookOpen, PlusCircle } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CusGrade from "./CusGrade";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import "../../screens/OfflineAssesment/Dropstyle.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Select from "react-select";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import moment from "moment";

const GradewiseList = () => {
  const navigation = useNavigate();
  const location = useLocation();
  console.log(location, "locations");

  const [gradeMarkList, setGradeMarkList] = useState([]);
  const [examList, setExamList] = useState([]);

  const [assessmentId, setAssessmentId] = useState("");
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [desigination, setDesigination] = useState("");
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });

  useEffect(() => {
    // AcademicYrListApi();
    GradeListApi();
    // TermListApi(location.state?.academicYear.value);
    console.log(location, "statein locationss ");
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location?.state?.academicYear,
      grade: location?.state?.grade,
      term: location?.state?.term,
    }));
    GradeViewApi(location.state?.grade?.value);
  }, []);

  const GradeViewApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      assessment_offline_id: location.state?.offlineId,
      user_id: Params.teacher_id,
      // assessment_offline_id: 4,
      class_division_id: classId,
      // class_division_id: 36
    });
    simplePostCall(ApiConfig.GRADE_VIEW, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          const dataList = data.data;
          const students = [];
          const exams = [];
          console.log(dataList[0].assessment_id, "dataList.teacher_status");
          setDesigination(dataList[0].teacher_status);
          setAssessmentId(dataList[0].assessment_id);
          dataList?.map((assessment) => {
            console.log(assessment, "assessment");
            let newSubject = {
              assessment_subject_id: assessment.assessment_subject_id,
              assessment_subject_name: assessment.assessment_subject_name,
              assessment_max_mark: assessment.assessment_max_mark,
              assessment_title: assessment.assessment_title,
              internals: [],
            };
            assessment.assessment_student?.map((student) => {
              const existingStudent = students?.find(
                (item) => item.student_id === student.student_id,
              );

              newSubject.subject_max_mark = student.assessment_max_mark;
              student.internal_exam?.map((iExam) => {
                const isExists = newSubject.internals.some(
                  (item) =>
                    item.evaluation_type_code === iExam.evaluation_type_code,
                );
                if (!isExists) {
                  newSubject.internals.push({
                    evaluation_type_code: iExam.evaluation_type_code,
                    max_mark: iExam.assessment_max_mark,
                  });
                }
              });
              //checking student already in students array and adding subject to student else adding student and subject
              if (existingStudent?.student_id === student.student_id) {
                existingStudent.exams = [
                  ...existingStudent.exams,
                  {
                    assessment_subject_id: assessment.assessment_subject_id,
                    assessment_subject_name: assessment.assessment_subject_name,
                    student_id: student.student_id,
                    assessment_id: student.assessment_id,
                    result_id: student.result_id,
                    student_mark: student.student_mark,
                    internal_exam: student.internal_exam,
                    student_profile: student.student_profile,
                  },
                ];
              } else {
                let newStudent = {};
                newStudent.student_id = student.student_id;
                newStudent.student_Name = student.student_Name;
                newStudent.student_profile = student.student_profile;
                newStudent.feedback = student.feedback;
                newStudent.exams = [
                  {
                    assessment_subject_id: assessment.assessment_subject_id,
                    assessment_subject_name: assessment.assessment_subject_name,

                    result_id: student.result_id,
                    student_mark: student.student_mark,
                    internal_exam: student.internal_exam,
                  },
                ];
                students.push(newStudent);
              }
            });
            exams.push(newSubject);
          });
          console.log("Students Array \n", students, "\nExams\n", exams);
          setExamList(exams);
          setGradeMarkList(students);
        }
        console.log(examList, "examList");
        console.log(gradeMarkList, "gradeMarkList");
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  // console.log(internalExams, "Internalsss");

  const handleYearChange = (year) => {
    console.log("Seleected Acad Year", year);
    // setAcademicYear(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: year,
      term: null,
    }));
    setTermList([]);
    TermListApi(year.value);
  };

  const handleGradeChange = (grade) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: grade,
      subject: null,
    }));
  };

  const handleTermChange = (term) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      term: term,
    }));
    console.log("Selected Term", term);
  };

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_assessment_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const currentDate = moment().format("YYYY-MM-DD");
  console.log(currentDate, "currentDate");

  const ExamDates = location?.state?.StartingDate;
  console.log(ExamDates, "ExamDates");
  const shouldShowButton = moment(ExamDates).isSameOrBefore(currentDate);
  const handlePublish = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      assessment_class_class_division_id: formData.grade?.value,
      assessment_class_assessment_id: assessmentId,
    });
    simplePostCall(ApiConfig.PUBLISH_OFFLINEASSESSMENT, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
        } else {
          toast.success(data.message);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  const base_url = "https://app.edsys.in/";
  return (
    <div className="card pl pr p-4">
      <div className="card-header pb-0">
        <div className="d-flex ">
          <h5 className="col-md -6">View Grade wise List</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
            }}
          >
            {/* {shouldShowButton && ( */}
            <div>
              {/* {console.log(desigination, "desigination")} */}
              <div
                onClick={() => {
                  desigination === true
                    ? navigation(`/EditGradewiseList`, {
                        state: {
                          AcademicYear: {
                            label: location?.state?.academicYear?.label,
                            value: location?.state?.academicYear?.value,
                          },
                          Term: {
                            label: location?.state?.term?.label,
                            value: location?.state?.term?.value,
                          },
                          Grade: {
                            label: location?.state?.grade?.label,
                            value: location?.state?.grade?.value,
                          },
                          OfflineId: location.state?.offlineId,
                        },
                      })
                    : toast.error("You're Not Authorised");
                }}
                className="link"
              >
                <a type="button" className="btn btn-edsys-primary btn1">
                  <div className="d-flex">
                    <PlusCircle className="me-2" />
                    <h6 style={{ marginTop: "2px" }}>Edit Grade Wise List</h6>
                  </div>
                </a>
              </div>
            </div>
            {/* )} */}
            {/* <div onClick={() => navigation(-1)}>
              <a
                type="button"
                className="btn btn-edsys-primary btn1"
                style={{ marginRight: 10 }}
              >
                <BookOpen className="me-2" />
                <span style={{ marginTop: 20 }}>Subject Wise MarkList</span>
              </a>
            </div> */}
            <div style={{ marginRight: 10 }}>
              <div onClick={() => navigation(-1)} className="link">
                <a type="button" className="btn btn-edsys-primary btn1">
                  <div className="d-flex">
                    <BookOpen className="me-2" />
                    <h6 style={{ marginTop: "2px" }}>Subject Wise MarkList</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container row">
        {/* Academic Year Select */}
        <div className="col">
          <p className="DropTxt"> Academic year</p>
          {/* <Select
            class="js-example-basic-single form-select"
            name="academicYear"
            value={formData.academicYear}
            onChange={handleYearChange}
            options={academicYearList}
          /> */}
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
              fontFamily: "bold",
              color: "grey",
              fontSize: 17,
            }}
          >
            {formData.academicYear.label}
          </div>
        </div>

        {/* Term Select */}
        <div className="col">
          <p className="DropTxt"> Term</p>
          {/* <Select
            class="js-example-basic-single form-select"
            name="terms"
            value={formData.term}
            options={termList}
            onChange={handleTermChange}
          /> */}
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
              fontFamily: "bold",
              color: "grey",
              fontSize: 17,
            }}
          >
            {formData.term.label}
          </div>
        </div>

        {/* Grade Division Select */}
        <div className="col">
          <p className="DropTxt">Select Class Divison</p>
          <Select
            class="js-example-basic-single form-select"
            name="grade"
            value={formData.grade}
            onChange={handleGradeChange}
            options={gradeList}
          />
        </div>

        {/* Submit Button */}
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <button
            className="view-mark-list-button col-md-3"
            onClick={() => GradeViewApi(formData.grade?.value)}
          >
            View Mark List
          </button>
        </div>
      </div>

      {/* Display Table */}
      {gradeMarkList?.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="custom-table">
              <thead className="thead">
                <tr>
                  <th rowspan="2">Sl. No</th>
                  <th rowspan="2">Student Name</th>
                  {examList?.map((sub) => (
                    <th colspan={sub.internals?.length + 1}>
                      {sub.assessment_subject_name} ({sub.assessment_title})
                      {/* ({sub.assessment_max_mark}) */}
                    </th>
                  ))}
                  <th rowSpan="2">Feedback</th>
                </tr>

                <tr>
                  {examList.map((sub) =>
                    sub.internals?.length > 0 ? (
                      <React.Fragment>
                        {sub.internals.map((exam) => (
                          <th>
                            {exam.evaluation_type_code} ({exam.max_mark})
                          </th>
                        ))}
                        <th>Mark ({sub.subject_max_mark})</th>
                      </React.Fragment>
                    ) : (
                      <th>Mark ({sub.subject_max_mark})</th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {gradeMarkList.map((student, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "start" }}>
                      <img
                        className="rounded-circle"
                        src={
                          student.student_profile
                            ? base_url + student.student_profile
                            : dashboardAvatar
                        }
                        alt="image"
                        style={{
                          height: "30px",
                          width: "30px", // Corrected typo: should be "px" instead of "x"
                          marginRight: "10px",
                        }}
                        onError={(e) => {
                          e.target.src = dashboardAvatar;
                        }}
                      />

                      {student.student_Name}
                    </td>
                    {student.exams.map((exam) =>
                      exam.internal_exam?.length > 0 ? (
                        <React.Fragment>
                          {exam.internal_exam.map((internal) => (
                            <td>{internal.student_result}</td>
                          ))}
                          <td>{exam.student_mark}</td>
                        </React.Fragment>
                      ) : (
                        <td>{exam.student_mark}</td>
                      ),
                    )}
                    <td>{student.feedback}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <button
              className="view-mark-list-button col-md-4"
              onClick={handlePublish}
            >
              Publish
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Marklist Found
        </div>
      )}
    </div>
  );
};

export default GradewiseList;
