import React from 'react'
import Script from 'react-load-script'



function ExternalScripts() {
    return (
        <div>
            <Script url="../assets/js/jquery-3.5.1.min.js" />
           

     {/* feather icon js */}
    <script src="../assets/js/feather.min.js"></script>
    <script src="../assets/js/feather-icon.js"></script>
     {/* Sidebar jquery */}
    <script src="../assets/js/sidebar-menu.js"></script>
    <script src="../assets/js/config.js"></script>
     {/* Bootstrap js */}
    <script src="../assets/js/popper.min.js"></script>
    <script src="../assets/js/bootstrap.min.js"></script>
     {/* Plugins JS start */}
    <script src="../assets/js/jquery.counterup.min.js"></script>
    <script src="../assets/js/counter-custom.js"></script>
    <script src="../assets/js/custom-card.js"></script>
    <script src="../assets/js/notify/bootstrap-notify.min.js"></script>
    <script src="../assets/js/default.js"></script>
    <script src="../assets/js/notify/index.js"></script>
   
   
     {/* Plugins JS Ends */}
    {/* Theme js */}
    <script src="../assets/js/script.js"></script>
    {/* login js */}
     {/* Plugin used */}
        </div>
    )
}

export default ExternalScripts
