import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import "../../assets/css/CreateExam.css";
import "../../assets/css/OfflineAssesment.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #f3e7f3;
    height: 5px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #adc925;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  // flex: 1; /* Ensure each step takes equal space */
  // display: flex;
  // justify-content: center;
  // align-items: center;
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ step }) =>
    step === "completed" ? "#adc925" : "#ffffff"};
  border: 3px solid
    ${({ step }) => (step === "completed" ? "#adc925" : "#adc925")};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 19px;
  color: #78266a;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: #79266a;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #79266a;
  color: #ffffff;
  cursor: pointer;
  padding: 16px;
  width: 90px;
  :active {
    transform: scale(0.98);
    border: #79266a;
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${({ completed }) =>
    completed ? "white" : "white"}; /* White tick color for completed step */
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
  {
    label: "Step1",
    step: 1,
  },
  {
    label: "Step2",
    step: 2,
  },
  {
    label: "Step3",
    step: 3,
  },
];

const EditOfflineAssessment = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [selectedClass, setSelectedClass] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [studentList, setStudentList] = useState([]);
  const [selectedStud, setSelectedStud] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [gradename, setGradeName] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    terms: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    evaluation: "",
    gradingName: "",
    gradingId: "",
    grading: "",
    students: [],
  });
  const [errors, setErrors] = useState({});

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  useEffect(() => {
    console.log("Propsss", location.state);
    setFormData((prevFormData) => ({
      ...prevFormData,
      examName: location.state?.name,
      academicYear: location.state?.academicYear,
      startDate: location.state?.startDate,
      endDate: location.state?.endDate,
      terms: location.state?.term,
      gradingName: location.state?.gradingName,
      // gradingId: location.state?.gradingId,
    }));
    EvaluationListApi();
    AcademicYrListApi();
    TermListApi(location.state?.academicYear?.value);
    ViewOfflineAssessmentApi();
    GradeListApi();
    GradingApi();
    StudentListApi();
  }, []);
  const ViewOfflineAssessmentApi = async () => {
    try {
      let newRequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        status: location.state?.status,
        assessment_offline_id: location.state?.offlineId,
      });

      const data = await simplePostCall(
        ApiConfig.VIEW_OFFLINEASSESSMENT,
        newRequestBody,
      );

      if (data.result) {
        let grades = [];
        let subjects = [];

        data.data?.map((assessment) => {
          if (
            !grades.some(
              (item) =>
                item.class_division_id ===
                assessment.assessment_class_class_division_id,
            )
          ) {
            //grades from assessmentViewApi for listing the Grades
            grades.push({
              class_division_id: assessment.assessment_class_class_division_id,
              class_namea: assessment.assessment_class,
              value: assessment.assessment_class_class_division_id,
              label: assessment.assessment_class,
            });
            // console.log(grades, "grades");
            //subjects from api for showing Selected subjects and its date,time,mark and evaluations
            //pushing the grades for showing Selected Grades
            subjects.push({
              gradeId: assessment.assessment_class_class_division_id,
              gradeName: assessment.assessment_class,
              subjects: [],
            });
            console.log(subjects, "subjects");
          }
          //pushing  Selected subjects
          if (subjects.length > 0) {
            const findedGrade = subjects.find(
              (grade) =>
                grade.gradeId === assessment.assessment_class_class_division_id,
            );
            if (
              !findedGrade.subjects.some(
                (sub) =>
                  sub.subject_id === String(assessment.assessment_subject_id),
              )
            ) {
              findedGrade.subjects.push({
                subject_id: String(assessment.assessment_subject_id),
                subject_name: assessment.assessment_subject_name,
                evaluations: [],
              });
              console.log(findedGrade, "findedGrade");
            }
          }
          //seperating the mark for exam and in else is for evaluations
          if (
            assessment.assessment_offline_name === assessment.assessment_title
          ) {
            const findedGrade = subjects.find(
              (grade) =>
                grade.gradeId === assessment.assessment_class_class_division_id,
            );
            const findedSubject = findedGrade.subjects.find(
              (sub) =>
                sub.subject_id === String(assessment.assessment_subject_id),
            );

            findedSubject.assessment_id = assessment.assessment_id;
            findedSubject.startDate = assessment.assessment_start_date;
            findedSubject.startTime = assessment.assessment_start_time;
            findedSubject.maxMark = assessment.assessment_max_mark;
            findedSubject.duration = assessment.assessment_duration;
            console.log(findedSubject, "findedSubject");
          } else {
            //seperating the mark for evaluations
            const findedGrade = subjects.find(
              (grade) =>
                grade.gradeId === assessment.assessment_class_class_division_id,
            );
            const findedSubject = findedGrade.subjects.find(
              (sub) =>
                sub.subject_id === String(assessment.assessment_subject_id),
            );
            findedSubject.evaluations.push({
              assessment_id: assessment.assessment_id,
              evaluation_type_code: assessment.assessment_title,
              max_marks: assessment.assessment_max_mark,
              startDate: assessment.assessment_start_date,
              duration: assessment.assessment_duration,
              startTime: assessment.assessment_start_time,
            });
          }
        });
        console.log("Subjects from assess", subjects);
        console.log("Grades from viewAssessment", grades);
        setSelectedGrades(grades);
        setSelectedSubjects(subjects);
      } else {
        console.error("API returned false result:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const EvaluationListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.EVALUTION_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message EvaluationList");
          console.log(data.data, "Message EvaluationList");
          setEvaluationList(data.data);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  function onGradeChange(grading) {
    console.log(grading, "grading");
    // setSelectedGrade(grading.value);
    if (formData.gradingName?.value !== grading.value) {
      setErrors((prevState) => ({
        ...prevState,
        gradingName: "",
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        gradingName: grading,
        gradingId: grading.assessment_grading_id,
      }));
    }
  }
  // <-------Grading Api----------->
  const GradingApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.GRADEE_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "garding Appi");
        if (data.list) {
          var gradelists = [];
          data.list.map((grades) => {
            gradelists.push({
              label: grades.assessment_grading_name,
              value: grades.assessment_grading_id,
              ...grades,
            });
          });
          setGradeName({ ...gradename, gradelists: gradelists });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleYearChange = (year) => {
    if (formData.academicYear?.value !== year.value) {
      console.log("Seleected Acad Year", year);
      // setAcademicYear(value);
      setErrors((prevState) => ({
        ...prevState,
        academicYear: "",
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        academicYear: year,
        terms: null,
      }));
      setTermList([]);
      setSelectedSubjects([]);
      setSelectedGrades([]);
      TermListApi(year.value);
    }
  };

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  const handleTermChange = (term) => {
    if (formData.terms?.value !== term.value) {
      setErrors((prevState) => ({
        ...prevState,
        terms: "",
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        terms: term,
      }));
      setSelectedSubjects([]);
      setSelectedGrades([]);
      console.log("Selected Term", term);
      GradeListApi();
    }
  };

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      search: "",
      offline_assessment_id: location.state?.offlineId,
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  useEffect(() => {
    StudentListApi();
  }, [selectedClass]);
  const StudentListApi = (grade) => {
    const selectednew = selectedGrades.map((el) => el.value);
    console.log(selectednew, "selectednew");
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      section_ids: selectedClass.length > 0 ? selectedClass : selectednew,
      // search: "",
    });
    simplePostCall(
      ApiConfig.STUDENTS_LIST_BY_SELECTED_CLASSES_API,
      newRequestBody,
    )
      .then((data) => {
        if (data.result == true) {
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.student_name,
              value: item.student_id,
              ...item,
            };
          });
          setStudentList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };
  console.log(selectedGrades, "selectedGrades");
  const handleGradeChange = (grade) => {
    let temp = [grade];

    const allClass = temp.map((el) => el.value);
    setSelectedClass((prev) => [...prev, ...allClass]);

    const isGradeSelected = selectedGrades.some(
      (item) => item.class_division_id === grade.class_division_id,
    );
    console.log(selectedGrades, "selectedGrades");
    if (!isGradeSelected) {
      setSelectedGrades((prevState) => [...prevState, grade]);
      SubjectListApi(grade.value);
    } else {
      const updatedGrades = selectedGrades.filter(
        (item) => item.value !== grade.value,
      );
      const updatedSubjects = selectedSubjects.filter(
        (item) => item.gradeId !== grade.value,
      );

      const newGrades = gradeList.map((item) => {
        if (item.value === grade.value) {
          console.log("Removed item", item);
          item.subjects = [];
        }
        return item;
      });
      console.log("Removed Subject and Updated List", newGrades);

      setGradeList(newGrades);
      setSelectedGrades(updatedGrades);
      setSelectedSubjects(updatedSubjects);
    }
  };

  const SubjectListApi = (classId) => {
    let ids = [];
    ids.push(String(classId));
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: ids,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.list[0].result) {
          const selGrade = gradeList.find((item) => item.value === classId);
          if (selGrade) {
            selGrade.subjects = data.list[0].subject;
            setGradeList(
              gradeList.map((item) =>
                item.value === classId ? selGrade : item,
              ),
            );
            console.log("SELGrade", gradeList);
          }
        } else {
          console.log(data.list[0]);
        }
      })

      .catch((error) => {
        console.log("api response", error);
        const grades = selectedGrades.filter((item) => item.value !== classId);
        setSelectedGrades(grades);
      });
  };
  const handleSubjectChange = (subject, gradeInd) => {
    const selectedGrade = gradeList[gradeInd];
    const selectedSubjectsCopy = [...selectedSubjects];
    console.log(selectedSubjects, "selectedSubjects");
    const gradeSubjectsIndex = selectedSubjectsCopy.findIndex(
      (item) => item.gradeId === selectedGrade.value,
    );

    if (gradeSubjectsIndex === -1) {
      selectedSubjectsCopy.push({
        gradeId: selectedGrade.value,
        gradeName: selectedGrade.label,
        subjects: [{ ...subject, checked: true }],
      });
    } else {
      const subjects = selectedSubjectsCopy[gradeSubjectsIndex].subjects;
      const subjectIndex = subjects.findIndex(
        (sub) => sub.subject_id === subject.subject_id,
      );

      if (subjectIndex === -1) {
        subjects.push({ ...subject, checked: true });
      } else {
        subjects.splice(subjectIndex, 1);
      }

      selectedSubjectsCopy[gradeSubjectsIndex].subjects = subjects;
    }

    setSelectedSubjects(
      selectedSubjectsCopy.filter((item) => item.subjects.length > 0),
    );

    const allSubjectsChecked =
      selectedGrade.subjects.length ===
      selectedSubjectsCopy[gradeSubjectsIndex]?.subjects.length;
    setSelectAllState({ ...selectAllState, [gradeInd]: allSubjectsChecked });
  };

  const nextStep = () => {
    // Perform validation before proceeding to the next step
    const validationErrors = {};
    // Step 1 validation checks (if any)
    if (activeStep === 1) {
      if (!formData.examName.trim()) {
        validationErrors.examName = "Exam name is required";
      }
      if (!formData.academicYear?.value) {
        validationErrors.academicYear = "Academic year is required";
      }
      if (!formData.terms?.value) {
        validationErrors.terms = "Terms are required";
      }
      // if (!formData.gradingName?.value) {
      //   validationErrors.gradeName = "Terms are required";
      // }
      if (!formData.startDate.trim()) {
        validationErrors.startDate = "Starting date is required";
      }
      if (!formData.endDate.trim()) {
        validationErrors.endDate = "Ending date is required";
      }
      if (selectedGrades.length > 0) {
        console.log(selectedGrades);
        selectedGrades.map((item) => {
          SubjectListApi(item.class_division_id);
        });
      }
    }

    // Step 2 validation checks
    if (activeStep === 2) {
      // Example validation check for selectedGrade and selectedSubject
      if (Object.keys(selectedSubjects).length === 0) {
        validationErrors.subjectGrade =
          "Please select grade and at least one subject.";
      }
    }

    if (activeStep === 3) {
      selectedSubjects.forEach((subject, index) => {
        if (!subject.startTime.trim()) {
          validationErrors[`startTime-${index}`] = "Start time is required";
        }
        if (!subject.endTime.trim()) {
          validationErrors[`endTime-${index}`] = "End time is required";
        }
        if (!subject.duration.trim()) {
          validationErrors[`duration-${index}`] = "Duration is required";
        }
        if (!subject.maxMark.trim()) {
          validationErrors[`maxMark-${index}`] = "Max mark is required";
        }
        if (!subject.evaluation.trim()) {
          validationErrors[`evaluation-${index}`] =
            "Evaluation mark is required";
        }
      });
    }

    // If there are validation errors, set the errors state and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("Errrrors", validationErrors);
      return; // Do not proceed to the next step
    }
    if (activeStep === totalSteps) {
      // Navigate to the "/OfflineAssessment" route
      navigation("/OfflineAssesmentHome");
    } else {
      setActiveStep(activeStep + 1);
    }

    // If no validation errors, proceed to the next step
    console.log(selectedSubjects);
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  // Function to handle form input changes
  const handleInputChange = (e) => {
    console.log("EE", e.target);
    const { name, value } = e.target;
    console.log("e.target.value", value);
    console.log("e.target.name", name);
    console.log("formData before updaterrr:", formData);
    setErrors((prevState) => ({ ...prevState, [name]: "" }));

    // Update formData with the new value
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const subjectChecked = (subjectId, gradeId) => {
    const selectedGrade = selectedSubjects.find(
      (item) => item.gradeId === gradeId,
    );

    return selectedGrade
      ? selectedGrade?.subjects?.some((sub) => sub.subject_id == subjectId)
      : false;
  };

  const handleEvaluationChange = (item, subject, type) => {
    console.log("type:", type, "\nsubject:", subject);
    console.log(item);

    // Clone the selectedSubjects array
    const selSelectedSubjects = [...selectedSubjects];

    // Find the grade and subject
    const selGrade = selSelectedSubjects.find(
      (grade) => grade.gradeId === item.gradeId,
    );
    const selSubject = selGrade.subjects.find(
      (sub) => sub.subject_id === subject.subject_id,
    );

    // Ensure evaluations array exists for the subject
    if (!selSubject.evaluations) {
      selSubject.evaluations = [];
    }

    // Check if the evaluation type is already included
    const isEvalIncluded = selSubject.evaluations.some(
      (evaluation) =>
        evaluation.evaluation_type_code === type.evaluation_type_code,
    );
    console.log("Is Included", isEvalIncluded);

    // Toggle inclusion of the evaluation type
    if (isEvalIncluded) {
      selSubject.evaluations = selSubject.evaluations.filter(
        (evaluation) =>
          evaluation.evaluation_type_code !== type.evaluation_type_code,
      );
    } else {
      selSubject.evaluations.push(type);
      console.log(type);
      console.log(evaluationList);
    }

    // Update the state with the modified selectedSubjects array
    setSelectedSubjects(selSelectedSubjects);
  };

  const renderSubjectDetailsInputs = () => {
    return selectedSubjects.map((item, index) => (
      <div key={index}>
        {item.subjects.map((subject) => (
          <div>
            {console.log(subject, "subjects inside")}
            <h6>{subject.subject_name + " (" + item.gradeName + ")"}</h6>
            {/* <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor={`startTime-${index}`}>
                  {console.log(index, "index")} Start Date
                </label>
                <input
                  className={`datepicker-here form-control digits ${
                    errors[`startTime-${index}`] ? "is-invalid" : ""
                  }`}
                  type="date"
                  data-language="en"
                  placeholder="MM-DD-YYYY"
                  data-position="top left"
                  name="startTime"
                  min={formData.startDate}
                  max={formData.endDate}
                  value={subject.startDate}
                  onChange={(e) => {
                    const selSubs = structuredClone(selectedSubjects);
                    const selSubject = selSubs
                      .find((gra) => gra.gradeId === item.gradeId)
                      .subjects.find(
                        (sub) => sub.subject_id === subject.subject_id,
                      );
                    selSubject.startDate = e.target.value;
                    console.log(selSubject);
                    setSelectedSubjects(selSubs);
                  }}
                  required
                />
                {errors[`startTime-${index}`] && (
                  <span className="invalid-feedback">
                    {errors[`startTime-${index}`]}
                  </span>
                )}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor={`end-time-${index}`}>Starting Time</label>
                <input
                  className={`timepicker-here form-control digits ${
                    errors[`endTime-${index}`] ? "is-invalid" : ""
                  }`}
                  // className="form-control"
                  type="time"
                  name="endTime"
                  value={subject.startTime}
                  onChange={(e) => {
                    const selSubs = structuredClone(selectedSubjects);
                    const selSubject = selSubs
                      .find((gra) => gra.gradeId === item.gradeId)
                      .subjects.find(
                        (sub) => sub.subject_id === subject.subject_id,
                      );
                    selSubject.startTime = e.target.value;
                    console.log(selSubject);
                    setSelectedSubjects(selSubs);
                  }}
                />
                {errors[`endTime-${index}`] && (
                  <span className="invalid-feedback">
                    {errors[`endTime-${index}`]}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor={`duration-${index}`}>Duration</label>
                <input
                  className={`form-control ${
                    errors[`duration-${index}`] ? "is-invalid" : ""
                  }`}
                  // className="form-control"
                  type="text"
                  name="duration"
                  value={subject.duration}
                  // onChange={(e) => handleSubjectInputChange(index, e)}
                  onChange={(e) => {
                    const selSubs = structuredClone(selectedSubjects);
                    const selSubject = selSubs
                      .find((gra) => gra.gradeId === item.gradeId)
                      .subjects.find(
                        (sub) => sub.subject_id === subject.subject_id,
                      );
                    selSubject.duration = e.target.value;
                    console.log(selSubject);
                    setSelectedSubjects(selSubs);
                  }}
                />
                {errors[`duration-${index}`] && (
                  <span className="invalid-feedback">
                    {errors[`duration-${index}`]}
                  </span>
                )}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor={`max-mark-${index}`}> Max Mark</label>
                <input
                  className={`form-control ${
                    errors[`maxMark-${index}`] ? "is-invalid" : ""
                  }`}
                  type="text"
                  name="maxMark"
                  value={subject.maxMark}
                  onChange={(e) => {
                    const selSubs = structuredClone(selectedSubjects);
                    const selSubject = selSubs
                      .find((gra) => gra.gradeId === item.gradeId)
                      .subjects.find(
                        (sub) => sub.subject_id === subject.subject_id,
                      );
                    selSubject.maxMark = e.target.value;
                    console.log(selSubject);
                    setSelectedSubjects(selSubs);
                  }}
                />
                {errors[`maxMark-${index}`] && (
                  <span className="invalid-feedback">
                    {errors[`maxMark-${index}`]}
                  </span>
                )}
              </div>
            </div> */}

            <div className="grade-checkboxes">
              {evaluationList?.map((type) => (
                <div>
                  <div
                    className="subject-checkbox"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                      marginBottom: 10,
                    }}
                  >
                    <label className="col-md-5">
                      <input
                        // key={type.evaluation_type_code}
                        type="checkbox"
                        checked={selectedSubjects
                          .find((gra) => gra.gradeId === item.gradeId)
                          .subjects.find(
                            (sub) => sub.subject_id === subject.subject_id,
                          )
                          .evaluations?.some(
                            (evType) =>
                              evType.evaluation_type_code ===
                              type.evaluation_type_code,
                          )}
                        onChange={() =>
                          handleEvaluationChange(item, subject, type)
                        }
                        style={{ width: "30px", marginLeft: "10px" }}
                      />
                      {type.evaluation_type_name}({type.evaluation_type_code})
                    </label>
                    {subject.evaluations?.map((evItem) => {
                      if (
                        evItem.evaluation_type_code ===
                        type.evaluation_type_code
                      ) {
                        return (
                          // <input
                          //   className={`form-control ${
                          //     errors[`evaluation-${index}`] ? "is-invalid" : ""
                          //   }`}
                          //   type="number"
                          //   name="evaluation"
                          //   value={evItem.max_marks}
                          //   onChange={(e) => {
                          //     // Clone the selectedSubjects array
                          //     const selSelectedSubjects =
                          //       structuredClone(selectedSubjects);

                          //     // Find the grade subject and evaluations of respective input field
                          //     const selGrade = selSelectedSubjects.find(
                          //       (grade) => grade.gradeId === item.gradeId,
                          //     );
                          //     const selSubject = selGrade.subjects.find(
                          //       (sub) => sub.subject_id === subject.subject_id,
                          //     );
                          //     const selEvaluation = selSubject.evaluations.find(
                          //       (evaluation) =>
                          //         evaluation.evaluation_type_code ===
                          //         type.evaluation_type_code,
                          //     );
                          //     selEvaluation.max_marks = e.target.value;

                          //     setSelectedSubjects(selSelectedSubjects);
                          //   }}
                          // />
                          <div>
                            <div className="row">
                              {console.log(evItem, "selectedSubjects")}
                              <div className="form-group col-md-6">
                                <label htmlFor={`startTime-${index}`}>
                                  {console.log(index, "index")} Start Date
                                </label>
                                <input
                                  className={`datepicker-here form-control digits ${
                                    errors[`startTime-${index}`]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                  name="startTime"
                                  min={formData.startDate}
                                  max={formData.endDate}
                                  value={evItem.startDate}
                                  onChange={(e) => {
                                    const selSelectedSubjects =
                                      structuredClone(selectedSubjects);
                                    const selGrade = selSelectedSubjects.find(
                                      (gra) => gra.gradeId === item.gradeId,
                                    );

                                    const selSubject = selGrade.subjects.find(
                                      (sub) =>
                                        sub.subject_id === subject.subject_id,
                                    );
                                    const selEvaluation =
                                      selSubject.evaluations.find(
                                        (evaluation) =>
                                          evaluation.evaluation_type_code ===
                                          type.evaluation_type_code,
                                      );
                                    selEvaluation.startDate = e.target.value;
                                    console.log(selEvaluation);
                                    setSelectedSubjects(selSelectedSubjects);
                                  }}
                                  required
                                />
                                {errors[`startTime-${index}`] && (
                                  <span className="invalid-feedback">
                                    {errors[`startTime-${index}`]}
                                  </span>
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor={`end-time-${index}`}>
                                  Starting Time
                                </label>
                                <input
                                  className={`timepicker-here form-control digits ${
                                    errors[`endTime-${index}`]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  // className="form-control"
                                  type="time"
                                  name="endTime"
                                  value={evItem.startTime}
                                  onChange={(e) => {
                                    const selSelectedSubjects =
                                      structuredClone(selectedSubjects);
                                    const selGrade = selSelectedSubjects.find(
                                      (gra) => gra.gradeId === item.gradeId,
                                    );
                                    const selSubject = selGrade.subjects.find(
                                      (sub) =>
                                        sub.subject_id === subject.subject_id,
                                    );
                                    const selEvaluation =
                                      selSubject.evaluations.find(
                                        (evaluation) =>
                                          evaluation.evaluation_type_code ===
                                          type.evaluation_type_code,
                                      );
                                    selEvaluation.startTime = e.target.value;
                                    console.log(
                                      selSelectedSubjects,
                                      "selSelectedSubjects",
                                    );
                                    setSelectedSubjects(selSelectedSubjects);
                                  }}
                                />
                                {errors[`endTime-${index}`] && (
                                  <span className="invalid-feedback">
                                    {errors[`endTime-${index}`]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor={`duration-${index}`}>
                                  Duration
                                </label>
                                <input
                                  className={`form-control ${
                                    errors[`duration-${index}`]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  // className="form-control"
                                  type="text"
                                  name="duration"
                                  value={evItem.duration}
                                  // onChange={(e) => handleSubjectInputChange(index, e)}
                                  onChange={(e) => {
                                    const selSelectedSubjects =
                                      structuredClone(selectedSubjects);
                                    const selGrade = selSelectedSubjects.find(
                                      (gra) => gra.gradeId === item.gradeId,
                                    );
                                    const selSubject = selGrade.subjects.find(
                                      (sub) =>
                                        sub.subject_id === subject.subject_id,
                                    );
                                    const selEvaluation =
                                      selSubject.evaluations.find(
                                        (evaluation) =>
                                          evaluation.evaluation_type_code ===
                                          type.evaluation_type_code,
                                      );
                                    selEvaluation.duration = e.target.value;
                                    console.log(selSubject);
                                    setSelectedSubjects(selSelectedSubjects);
                                  }}
                                />
                                {errors[`duration-${index}`] && (
                                  <span className="invalid-feedback">
                                    {errors[`duration-${index}`]}
                                  </span>
                                )}
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor={`max-mark-${index}`}>
                                  {" "}
                                  Max Mark
                                </label>
                                <input
                                  className={`form-control ${
                                    errors[`maxMark-${index}`]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="text"
                                  name="maxMark"
                                  value={evItem.max_marks}
                                  onChange={(e) => {
                                    const selSelectedSubjects =
                                      structuredClone(selectedSubjects);
                                    const selGrade = selSelectedSubjects.find(
                                      (gra) => gra.gradeId === item.gradeId,
                                    );
                                    const selSubject = selGrade.subjects.find(
                                      (sub) =>
                                        sub.subject_id === subject.subject_id,
                                    );
                                    const selEvaluation =
                                      selSubject.evaluations.find(
                                        (evaluation) =>
                                          evaluation.evaluation_type_code ===
                                          type.evaluation_type_code,
                                      );
                                    selEvaluation.max_marks = e.target.value;
                                    console.log(
                                      selSelectedSubjects,
                                      "selSelectedSubjects",
                                    );
                                    setSelectedSubjects(selSelectedSubjects);
                                  }}
                                />
                                {errors[`maxMark-${index}`] && (
                                  <span className="invalid-feedback">
                                    {errors[`maxMark-${index}`]}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  //.............Edit OfflineAssessment.............//
  // console.log(selectedSubjects, "selectedSubjects123");
  const SubmitEdit = async (e) => {
    e.preventDefault();
    let assessmentData = [];
    selectedSubjects.map((grade) => {
      grade.subjects.map((sub) => {
        let assessment = {
          grade_division: grade.gradeId,
          subject: sub.subject_id,

          evaluvation: sub.evaluations
            ? sub.evaluations?.map((item) => ({
                assessment_id: item.assessment_id ? item.assessment_id : 0,
                evaluation_type_code: item.evaluation_type_code,
                evaluation_max_mark: item.max_marks,
                date: item.startDate,
                time: item.startTime,
                duration: item.duration,
              }))
            : [],
        };
        assessmentData.push(assessment);
      });
    });
    console.log(assessmentData);

    // Construct the request body using form data
    const requestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_id: location.state?.offlineId,
      name: formData.examName,
      academic_year: formData.academicYear?.value,
      term: formData.terms?.value,
      start_date: formData.startDate,
      end_date: formData.endDate,
      status: "",
      grading_id: formData.gradingName?.value,
      assessment: assessmentData,
      students: selectedStud,
    };
    console.log(requestBody, "requestbody");
    // toast.error("EditApi not done");
    try {
      const response = await fetch(ApiConfig.OFFLINEASSESSMENT_EDIT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const responseData = await response.json();

      if (responseData.result) {
        toast.success(responseData.message);
        navigation("/OfflineAssesmentHome");
      } else {
        toast.warn(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };
  const DraftEdit = async (e) => {
    e.preventDefault();
    let assessmentData = [];
    selectedSubjects.map((grade) => {
      grade.subjects.map((sub) => {
        let assessment = {
          grade_division: grade.gradeId,
          subject: sub.subject_id,

          evaluvation: sub.evaluations
            ? sub.evaluations?.map((item) => ({
                assessment_id: item.assessment_id ? item.assessment_id : 0,
                evaluation_type_code: item.evaluation_type_code,
                evaluation_max_mark: item.max_marks,
                date: item.startDate,
                time: item.startTime,
                duration: item.duration,
              }))
            : [],
        };
        assessmentData.push(assessment);
      });
    });
    console.log(assessmentData);

    // Construct the request body using form data
    const requestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      offline_id: location.state?.offlineId,
      name: formData.examName,
      academic_year: formData.academicYear?.value,
      term: formData.terms?.value,
      start_date: formData.startDate,
      end_date: formData.endDate,
      students: selectedStud,
      status: "draft",
      grading_id: formData.gradingName?.value,
      assessment: assessmentData,
    };
    console.log(requestBody, "requestbody");
    // toast.error("EditApi not done");
    try {
      const response = await fetch(ApiConfig.OFFLINEASSESSMENT_EDIT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const responseData = await response.json();

      if (responseData.result) {
        toast.success(responseData.message);
        navigation("/OfflineAssesmentHome");
      } else {
        toast.warn(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };
  const [selectAllState, setSelectAllState] = useState({});
  const handleSelectAllChange = (gradeInd, isChecked) => {
    const updatedSelectAllState = { ...selectAllState, [gradeInd]: isChecked };
    setSelectAllState(updatedSelectAllState);

    const selectedGrade = gradeList[gradeInd];
    const updatedSubjects = selectedGrade.subjects.map((subject) => ({
      ...subject,
      checked: isChecked,
    }));

    const updatedSelectedSubjects = isChecked
      ? [
          ...selectedSubjects.filter(
            (item) => item.gradeId !== selectedGrade.value,
          ),
          {
            gradeId: selectedGrade.value,
            gradeName: selectedGrade.label,
            subjects: updatedSubjects,
          },
        ]
      : selectedSubjects.filter((item) => item.gradeId !== selectedGrade.value);

    setSelectedSubjects(updatedSelectedSubjects);
  };
  const OnStudentChange = (e) => {
    console.log(e, "element in Student");
    let temp = e;
    const selectedStud = temp.map((el) => el.value);
    const selectedLabel = temp.map((el) => el.label);
    console.log(selectedStud, "selectedStudee");
    setSelectedStud(selectedStud);
    setFormData((prevFormData) => ({
      ...prevFormData,
      students: selectedLabel,
    }));
  };
  return (
    <div className="container-fluid">
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="flex-grow-1">Edit Offline Assesment</h5>
            </div>
            <MainContainer>
              <StepContainer width={width}>
                {steps.map(({ step, label }) => (
                  <StepWrapper key={step}>
                    <StepStyle
                      step={activeStep >= step ? "completed" : "incomplete"}
                    >
                      {activeStep > step ? (
                        <CheckMark>L</CheckMark>
                      ) : (
                        <StepCount>{step}</StepCount>
                      )}
                    </StepStyle>
                    <StepsLabelContainer>
                      <StepLabel key={step}>{label}</StepLabel>
                    </StepsLabelContainer>
                  </StepWrapper>
                ))}
              </StepContainer>

              {activeStep === 1 && (
                <div className="col-md-12">
                  <div className="margin_top">
                    <div style={{ marginTop: "40px" }}>
                      <form
                        className="form-bookmark needs-validation"
                        id="bookmark-form"
                        noValidate
                      >
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleDataList">Name</label>

                            <input
                              className={`form-control ${
                                errors.examName ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="examName"
                              value={formData.examName}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.examName && (
                              <div className="invalid-feedback">
                                {errors.examName}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleDataList">
                              Academic Year
                            </label>
                            <Select
                              class="js-example-basic-single form-select"
                              name="academicYear"
                              value={formData.academicYear}
                              onChange={handleYearChange}
                              options={academicYearList}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.academicYear
                                    ? "red"
                                    : "#ced4da",
                                }),
                              }}
                            />
                          </div>
                          {errors.academicYear && (
                            <div className="invalid-feedback">
                              {errors.academicYear}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="task-title">Starting Date</label>
                            <input
                              className={`datepicker-here form-control digits ${
                                errors.startDate ? "is-invalid" : ""
                              }`}
                              type="date"
                              data-language="en"
                              placeholder="MM-DD-YYYY"
                              data-position="top left"
                              name="startDate"
                              value={formData.startDate}
                              min={new Date().toISOString().split("T")[0]}
                              max={formData.endDate}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.startDate && (
                              <div className="invalid-feedback">
                                {errors.startDate}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="task-title">Ending Date</label>
                            <input
                              className={`datepicker-here form-control digits ${
                                errors.endDate ? "is-invalid" : ""
                              }`}
                              type="date"
                              data-language="en"
                              placeholder="MM-DD-YYYY"
                              data-position="top left"
                              name="endDate"
                              value={formData.endDate}
                              min={
                                formData.startDate
                                  ? formData.startDate
                                  : new Date().toISOString().split("T")[0]
                              }
                              onChange={handleInputChange}
                              required
                            />
                            {errors.endDate && (
                              <div className="invalid-feedback">
                                {errors.endDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row f1">
                          <div className="form-group col-md-6">
                            <label htmlFor="sub-task">Terms</label>
                            <Select
                              class="js-example-basic-single form-select"
                              name="terms"
                              value={formData.terms}
                              onChange={handleTermChange}
                              options={termList}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.terms ? "red" : "#ced4da",
                                }),
                              }}
                            />
                            {errors.terms && (
                              <div className="invalid-feedback">
                                {errors.terms}
                              </div>
                            )}
                          </div>
                          <div
                            className="form-group col-md-6"
                            style={{ width: "47%" }}
                          >
                            <label htmlFor="sub-task">Grading</label>

                            <Select
                              class="js-example-basic-single form-select"
                              name="gradingName"
                              value={formData.gradingName}
                              onChange={onGradeChange}
                              options={gradename.gradelists}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.terms ? "red" : "#ced4da",
                                }),
                              }}
                            />
                            {console.log(formData, "formadtasvsv")}
                            {errors.terms && (
                              <div className="invalid-feedback">
                                {errors.terms}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {activeStep === 2 && (
                <div className="margin_top1">
                  <h6 className="grade">Select Grade</h6>
                  <div className="grade-checkboxes">
                    {gradeList?.map((grade, gradeInd) => (
                      <Fragment key={grade.class_division_id}>
                        <div className="grade-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              checked={selectedGrades.some(
                                (item) =>
                                  item.class_division_id ===
                                  grade.class_division_id,
                              )}
                              onChange={() => handleGradeChange(grade)}
                            />
                            {"   "}
                            <span className="grade-name">
                              {grade.class_name}
                            </span>
                          </label>
                        </div>
                        {selectedGrades.some(
                          (item) =>
                            item.class_division_id === grade.class_division_id,
                        ) && (
                          <div className="subject-section">
                            <h6 className="grade">Select Subject</h6>
                            <div
                              className="row"
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div className="select-all-checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectAllState[gradeInd] || false}
                                    onChange={(e) =>
                                      handleSelectAllChange(
                                        gradeInd,
                                        e.target.checked,
                                      )
                                    }
                                  />
                                  {"   "}
                                  Select All
                                </label>
                              </div>
                              {grade.subjects?.map((subject) => (
                                <div
                                  key={subject.subject_id}
                                  className="row col-md-4"
                                >
                                  {console.log(subject, "1234567890")}
                                  <div className="subject-checkbox">
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={subjectChecked(
                                          subject.subject_id,
                                          grade.value,
                                        )}
                                        value={selectedSubjects}
                                        onChange={() =>
                                          handleSubjectChange(subject, gradeInd)
                                        }
                                      />
                                      {"   "}
                                      {subject.subject_name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                  {selectedSubjects.length === 0 && (
                    <div className="error-message">{errors.subjectGrade}</div>
                  )}
                  <div style={{ width: "40%" }}>
                    <h6 className="grade">Select Students</h6>
                    <Multiselect
                      className="grade"
                      options={studentList}
                      displayValue={"label"}
                      style={{
                        chips: { background: "#73276b" },
                      }}
                      onSelect={OnStudentChange}
                    />
                  </div>
                </div>
              )}

              {activeStep === 3 && (
                <div className="col-sm-12">
                  <div className="margin_top">
                    <div className="card-body header-card">
                      <form
                        className="form-bookmark needs-validation"
                        id="bookmark-form"
                        noValidate
                        onSubmit={SubmitEdit}
                      >
                        {renderSubjectDetailsInputs()}
                      </form>
                    </div>
                  </div>
                </div>
              )}

              <ButtonsContainer>
                <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
                  Previous
                </ButtonStyle>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <ButtonStyle
                  onClick={activeStep === totalSteps ? DraftEdit : DraftEdit}
                  className={errors.subjectGrade ? "error-button" : ""}
                >
                  {activeStep === totalSteps ? "Draft" : "Draft"}
                </ButtonStyle>
                <ButtonStyle
                  onClick={activeStep === totalSteps ? SubmitEdit : nextStep}
                  className={errors.subjectGrade ? "error-button" : ""}
                >
                  {activeStep === totalSteps ? "Submit" : "Next"}
                </ButtonStyle>
              </ButtonsContainer>
            </MainContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOfflineAssessment;
