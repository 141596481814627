import React, { useEffect, useState } from "react";
import { Video, ExternalLink } from "react-feather";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Params from "../config/Params";
import SpinnerCmp from "../components/SpinnerCmp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChannelListSkeleton from "../components/skeletons/ChannelListSkeleton";

function VideoConference() {
  const { t } = useTranslation();

  const [channelListState, setChannelListState] = useState({
    isloading: false,
    channelList: [],
    jitsi_domain: "",
  });

  const channelClassStyle = {
    paddingTop: "5%",
    paddingBottom: "5%",
    fontSize: "24px",
  };

  function getChannelList() {
    setChannelListState({ ...channelListState, isloading: true });

    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CONFERENCE_LIST_API, requestBody)
      .then((data) => {
        if (data.result) {
          setChannelListState({
            ...channelListState,
            isloading: false,
            channelList: data.class_details,
            jitsi_domain: data.jitsi_domain,
          });
        } else {
          setChannelListState({ ...channelListState, isloading: false });
        }
      })
      .catch((error) => {
        setChannelListState({ ...channelListState, isloading: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    document.title = "Edsys | Channel List ";
    getChannelList();
  }, []);
  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      <h4>{t("Video Conference")}</h4>
      {channelListState.isloading ? (
        <ChannelListSkeleton />
      ) : (
        <div class="row channel-list mt-4">
          {channelListState.channelList.length > 0 ? (
            channelListState.channelList.map((channel, index) => {
              return (
                <div class="col-md-3" key={index}>
                  <div class="card br-15">
                    <div class="card-body">
                      <div class="channel-video">
                        <Link
                          to={"/conference"}
                          state={{
                            class_division_id: channel.class_division_id,
                            jitsi_domain: channelListState.jitsi_domain,
                          }}
                          className="video-conf"
                        >
                          <div>
                            <Video />
                          </div>
                          {t("Go To Classroom")}
                        </Link>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="channel-class" style={channelClassStyle}>
                        {channel.class_name +
                          " - " +
                          channel.class_division_name}
                      </div>
                      <div class="video-title">
                        {/*channel.class_name + " " + channel.class_division_name*/}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <p>{t("No data found")}</p>
            </div>
          )}
        </div>
      )}
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
}

export default VideoConference;
