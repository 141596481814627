// SyllabusSkeleton.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SyllabusssSkeleton = () => {
  return (
    <div className="SyllabusCreate">
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="labelCreate">
                <Skeleton width={200} height={30} />
              </label>
              {/* <div className="createButt">
                <div className="CreateB"></div>
              </div> */}
            </div>
          </div>
          <div className="tablesec">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton width={150} />
                  </th>
                  <td scope="col">
                    <Skeleton width={200} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">
                    <Skeleton width={150} />
                  </th>
                  <td scope="col">
                    <Skeleton width={200} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="buttcrea">
              <Skeleton width={100} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusssSkeleton;
