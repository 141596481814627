import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
export default function LessonTopicAssessmentSettings() {
  const [maxMark, setMaxMark] = useState("");
  const [duration, setDuration] = useState("");
  const [view, setView] = useState([]);
  const [errors, setError] = useState(false);
  const [gradename, setGradeName] = useState([]);
  const [evalution, setEvaluation] = useState([]);
  const [formData, setFormData] = useState({
    duration: "",
    maxMark: "",
    evaluationName: "",
    evalutionId: "",
    evalutionCode: "",
    gradingName: "",
    gradingId: "",
    grading: "",
  });
  const { t } = useTranslation();
  const navic = useNavigate();
  useEffect(() => {
    GradingApi();
    EvalutionList();
    ViewSettingApi();
  }, []);
  const createEvaluationData = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grading_id: formData.gradingId,
      evalution_id: formData.evalutionId,
      offline_assessment_default_maxmark: duration,
      offline_assessment_default_duration: maxMark,
    });
    simplePostCall(ApiConfig.SETTING_AS_DEFAULT, newRequestBody)
      .then((data) => {
        if (!setMaxMark || !duration) {
          setError(true);
          return;
        } else {
          toast.success("created Successfully");
          navic("/LessonTopicAssessmentHome");

          console.log(data);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const GradingApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.GRADEE_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "garding Appi");
        if (data.list) {
          var gradelists = [];
          data.list.map((grades) => {
            gradelists.push({
              label: grades.assessment_grading_name,
              value: grades.assessment_grading_id,
              ...grades,
            });
          });
          setGradeName({ ...gradename, gradelists: gradelists });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const ViewSettingApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.SETTING_VIEW, newRequestBody)
      .then((data) => {
        console.log(data, "garding Appi");
        setFormData({
          gradingName: data.data[0].assessment_grading_name,
          gradingId: data.data[0].assessment_grading_id,
          evaluationName: data.data[0].evaluation_type_name,
          evalutionId: data.data[0].evaluation_type_id,
        });
        setMaxMark(data.data[0].offline_assessment_default_maxmark);
        setDuration(data.data[0].offline_assessment_default_duration);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  function onGradeChange(grading) {
    console.log(grading, "grading");
    if (formData.gradingName?.value !== grading.value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gradingName: grading,
        gradingId: grading.assessment_grading_id,
      }));
    }
  }
  const EvalutionList = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.EVALUTION_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "Evalution Data");
        if (data.result) {
          var evaluationList = [];
          data.data?.map((grades) => {
            evaluationList.push({
              label: grades.evaluation_type_name,
              value: grades.evaluation_type_id,
              evaluation_type_code: grades.evaluation_type_code,
              ...grades,
            });
          });
          setEvaluation({ ...evalution, evaluationList: evaluationList });
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
      });
  };
  function onEvaluatuionChange(evalu) {
    console.log(evalu, "evalatuoion");
    if (formData.gradingName?.value !== evalu.value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        evaluationName: evalu,
        evalutionId: evalu.evaluation_type_id,
        evalutionCode: evalu.evaluation_type_code,
      }));
    }
  }
  console.log(formData.gradingName, "formData.gradingName");

  return (
    <div>
      <div className="main-sectionn ">
        <div className="col-md-12">
          <div className="announcementCreatee">
            <label className="edit-head">setting as default</label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 row">
            <div className="form-group col-md-12">
              <label for="task-title">{t("Grading")}</label>
              <Select
                class="js-example-basic-single form-select"
                name="gradingName"
                value={formData.gradingName}
                placeholder={formData.gradingName}
                onChange={onGradeChange}
                options={gradename.gradelists}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.terms ? "red" : "#ced4da",
                  }),
                }}
              />
              {errors && !formData.gradingName ? (
                <p className="val-form">Grading name is required...</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label for="task-title">{t("Evaluation Type")}</label>
              <Select
                class="js-example-basic-single form-select"
                name="gradingName"
                value={formData.evaluationName}
                placeholder={formData.evaluationName}
                onChange={onEvaluatuionChange}
                options={evalution.evaluationList}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: errors.terms ? "red" : "#ced4da",
                  }),
                }}
              />

              {errors && !formData.evaluationName ? (
                <p className="val-form">Evaluation Name is required...</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label for="task-title">{t("Duration")}</label>
              <input
                className="form-control "
                type="text"
                placeholder={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              />
              {errors && !duration ? (
                <p className="val-form">Duration is required...</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label for="task-title">{t("Max Mark")}</label>
              <input
                placeholder={maxMark}
                className="form-control "
                type="text"
                onChange={(e) => {
                  setMaxMark(e.target.value);
                }}
              />
              {errors && !maxMark ? (
                <p className="val-form">Duration is required...</p>
              ) : null}
            </div>
          </div>
          <div className="create-but-1">
            <button
              className="btn btn-primary  crbt"
              type="button"
              onClick={createEvaluationData}
            >
              {t("Set as Default")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
