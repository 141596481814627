import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import book_1 from "../assets/images/book_1.svg";
import book_2 from "../assets/images/book_2.svg";
import book_3 from "../assets/images/book_3.svg";
import book_4 from "../assets/images/book_4.svg";
import book_5 from "../assets/images/book_5.svg";
import book_6 from "../assets/images/book_6.svg";
import book_7 from "../assets/images/book_7.svg";
import book_8 from "../assets/images/book_8.svg";
import book_9 from "../assets/images/book_9.svg";
import search_icon from "../assets/images/search_icon.svg";

function Library() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title"]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const books = [
    {
      title: "Mathematics Class 9",
      img: book_1,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "All in One Mathematics",
      img: book_2,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Sura's Mathematics",
      img: book_3,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Smart English",
      img: book_4,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Digest",
      img: book_5,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "My English Navneet",
      img: book_6,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "History of Goa",
      img: book_7,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Science and Technology",
      img: book_8,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Class 9",
      img: book_9,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
  ];
  function search(books) {
    return books.filter((items) => {
      return searchParam.some((newItem) => {
        return (
          items[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  if (error) {
    return <p>{error.message}</p>;
  } else {
    return (
      <>
        <div class="library announcementnew">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="announcement-top">
                  <label>Library</label>
                  <div className="search-wrapper">
                    <input
                      type="text"
                      placeholder="Search Book"
                      name="search-form"
                      id="search-form"
                      className="search-input"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <img src={search_icon} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <TabView>
                    <TabPanel header="My Books">
                      <div className="my-books">
                        {q === "" ? (
                          ""
                        ) : (
                          <p className="search-result">Search Results</p>
                        )}
                        <div className="row">
                          {search(books).map((item) => (
                            <>
                              {/* <span></span> */}
                              <div className="col-md-6 col-lg-4 col-sm-12">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="bc-img-box">
                                        <img src={item.img} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <p className="bc-title">{item.title}</p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className={item.statusClass1}>
                                        Issued Date 23/02/2023
                                      </p>
                                      <p className={item.statusClass2}>
                                        Due Date 23/02/2023
                                        <span className={item.fineClass}>
                                          {item.fine}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                          {/* <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                 <img src={item.img} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                               <p className="bc-title">{item.title}</p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className={item.statusClass1}>
                                      Issued Date 23/02/2023
                                    </p>
                                <p className={item.statusClass2}>
                                      Due Date 23/02/2023
                                      <span className={item.fineClass}>
                                        {item.fine}
                                      </span>
                                    </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_2} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-gray">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-red">
                                  Expired Date 23/02/2023
                                  <span className="red">Fine : Rs 100</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_3} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-gray">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-red">
                                  Expired Date 23/02/2023{" "}
                                  <span className="red">Fine : Rs 100</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_4} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_5} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_6} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_7} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_8} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="book-card">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="bc-img-box">
                                  <img src={book_9} alt="" />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <p className="bc-title">ICSE Mathematics 9th</p>
                                <p className="bc-author">By Manjit Singh</p>
                                <p className="date-green">
                                  Issued Date 23/02/2023
                                </p>
                                <p className="date-green">
                                  Due Date 23/02/2023
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        {q === "" ? (
                          ""
                        ) : (
                          <div className="row">
                            <p className="recommended">Recommended Books</p>
                            <div className="col-md-4">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_4} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      Smart English 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-green"></p>
                                    <p className="date-green">
                                      Available{" "}
                                      <span className="available">2/24</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_5} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      Smart English 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-green"></p>
                                    <p className="date-green">
                                      Available{" "}
                                      <span className="available">2/24</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_6} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      Smart English 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-green"></p>
                                    <p className="date-green">
                                      Available{" "}
                                      <span className="available">2/24</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </TabPanel>

                    <TabPanel header="Book History">
                      <div className="my-books">
                        <div className="row">
                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_1} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_2} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023{" "}
                                    <span className="red">Fine : Rs 100</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_3} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023{" "}
                                    <span className="red">Fine : Rs 100</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_4} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_5} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_6} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_7} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_8} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="book-card">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="bc-img-box">
                                    <img src={book_9} alt="" />
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <p className="bc-title">
                                    ICSE Mathematics 9th
                                  </p>
                                  <p className="bc-author">By Manjit Singh</p>
                                  <p className="date-gray">
                                    Issued Date 23/02/2023
                                  </p>
                                  <p className="date-gray">
                                    Due Date 23/02/2023
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Library;
