import React, { useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import TImeTableSkeleton from "../../components/skeletons/TImeTableSkeleton";
import { useTranslation } from "react-i18next";
import TimeTableGridInput from "./gridView/TimeTableGridInput";
import { Button } from "react-bootstrap";
import Select from "react-select";

const TimeTableGenerate = (props) => {
  const { t } = useTranslation();

  const [isloading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [classList, setClassList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [errors, setErrors] = useState({});
  const [shiftId, setShiftId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [pageAfter, setPageAfter] = useState({
    shift_id: "",
    grade_id: "",
    academic_year: "",
  });
  const [timeTableByClass, setTimeTableByClass] = useState({
    TimetableByClass: [],
    PeriodsByClass: [],
  });

  const [timetableState, setTimetableState] = useState({
    myTimetable: [],
    Periods: [],
  });
  const [state, setState] = useState({
    title: "",
    description: "",
    subject: "",
    class_division_id: "",
    class_id: "",
    lesson_id: "",
    question_type: "",
    content: "",
    submission_date: "",
    submission_mode: "",
    students: "1",
    curriculum: "ccm22",
    type: "writing",
    classLabel: "",
  });
  const Periods = timetableState?.Periods;

  const WeeklyPeriods = timetableState?.myTimetable;

  const Periods_By_class = timeTableByClass.PeriodsByClass;
  const timeTable_By_class = timeTableByClass.TimetableByClass;

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  const dataGridByClass = {
    Periods: Periods_By_class,
    Lectures: timeTable_By_class,
  };

  useEffect(() => {
    document.title = "Edsys | Timetable ";

    getClassList();
  }, []);
  useEffect(() => {
    getShiftList();
  }, []);

  function getTimetableData(division_id) {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      division_id: division_id,
      shift_id: shiftId,
    });
    simplePostCall(ApiConfig.TIMETABLE_CREATE_VIEW, requestBody)
      .then((data) => {
        if (data.result) {
          setIsLoading(false);

          var periods_list = [];
          if (data.periods.length > 0) {
            data.periods.map((period, index) => {
              periods_list.push({
                id: period.periods_id,
                PeriodId: period.periods_name,
                startTime: period.periods_start_time,
                endTime: period.periods_end_time,
                // isInterval: period.periods_type == "break" ? true : false,
              });
            });
          }

          setTimetableState({
            ...timetableState,
            myTimetable: data.my_timetable,
            Periods: periods_list,
          });
          setPageAfter((prevPageAfter) => ({
            ...prevPageAfter,
            academic_year: data.academic_year,
          }));
          console.log(data, "timestatee");
        } else {
          setShow(false);
          toast.error(data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("api response", error);
      });
  }

  const getClassList = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          setClassList(data.list);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const getShiftList = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
    });
    simplePostCall(ApiConfig.SHIFT_LIST, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          const newData = data.list?.map((item) => {
            return {
              label: item.shift_name,
              value: item.shift_id,
              ...item,
            };
          });
          setShiftList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const getGradeList = (id) => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      shift_id: id,
    });
    simplePostCall(ApiConfig.GRADES_LIST, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          const newData = data.list?.map((item) => {
            return {
              label: item.grade,
              value: item.division_id,
              class_id: item.class_id,
              ...item,
            };
          });
          setGradeList(newData);
          setShow(true);
          setIsLoading(false);
        } else {
          setShow(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleShiftChange = (id) => {
    setState({ classLabel: id.label });
    setShiftId(id.value);
    getGradeList(id.value);
    setPageAfter((prevPageAfter) => ({
      ...prevPageAfter,
      shift_id: id.value,
    }));
  };
  const handleGradeChange = (id) => {
    setGradeId(id.value);
    getTimetableData(id.value);
    setPageAfter((prevPageAfter) => ({
      ...prevPageAfter,
      grade_id: id.value,
    }));
  };
  return (
    <>
      {isloading ? (
        <TImeTableSkeleton />
      ) : (
        <div className=" timetable">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex">
                <h5 className="mb-2 flex-grow-1">{t("Timetable")}</h5>
              </div>
              {/* <ul class="nav nav-pills" id="pills-tab" role="tablist"> */}
              <div className="row" style={{ zIndex: 1 }}>
                <div style={{ width: "500px" }}>
                  <Select
                    class="js-example-basic-single form-select"
                    name="academicYear"
                    onChange={handleShiftChange}
                    options={shiftList}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.terms ? "red" : "#ced4da",
                      }),
                    }}
                  />
                </div>
                <div style={{ width: "500px" }}>
                  <Select
                    class="js-example-basic-single form-select"
                    name="academicYear"
                    onChange={handleGradeChange}
                    options={gradeList}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.terms ? "red" : "#ced4da",
                      }),
                    }}
                  />
                </div>
              </div>
              {/* </ul> */}
              {show ? (
                <div class="card mt-2 br-15">
                  <div class="card-body">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <TimeTableGridInput
                          data={dataGrid}
                          myTimetable={timetableState.myTimetable}
                          classroom={classList}
                          pageAfter={pageAfter}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimeTableGenerate;
