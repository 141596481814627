import React, { useEffect, useState } from "react";
import { Bell } from "react-feather";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import { toast } from "react-toastify";
import SpinnerCmp from "../../components/SpinnerCmp";
import Select from "react-select";
import { useTranslation } from "react-i18next";

function Notification() {
  const { t } = useTranslation();

  const [state, setState] = useState({
    notification_type: "",
    notification_date: "",
    notification_like: "",
    selectedNotificationType: [],
    selectedNotificationLike: [],
  });
  const [notificationState, setNotificationState] = useState({
    isloading: false,
    notifications: [],
  });
  const [notificationTypeState, setNotificationTypeState] = useState({
    isloading: false,
    notificationTypes: [],
  });
  const [notificationLikeState, setNotificationLikeState] = useState({
    isloading: false,
    notificationLikes: [],
  });

  function getNotificationData(
    isFirstLoad = false,
    notification_type = "",
    notification_date = "",
    notification_like = "",
  ) {
    setNotificationState({ ...notificationState, isloading: true });
    if (isFirstLoad) {
      setNotificationTypeState({ ...notificationTypeState, isloading: true });
      setNotificationLikeState({ ...notificationLikeState, isloading: true });
    }
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      notification_type: notification_type
        ? notification_type
        : state.notification_type,
      notification_date: notification_date
        ? notification_date
        : state.notification_date,
      notification_like: notification_like
        ? notification_like
        : state.notification_like,
    });
    console.log("newRequestBody", newRequestBody);
    simplePostCall(ApiConfig.MY_NOTIFICATION_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setNotificationState({
            ...notificationState,
            isloading: false,
            notifications: data.notification_list,
          });
          if (isFirstLoad) {
            setNotificationTypeState({
              ...notificationTypeState,
              isloading: false,
              notificationTypes: data.notification_types,
            });
            setNotificationLikeState({
              ...notificationLikeState,
              isloading: false,
              notificationLikes: data.notification_like_list,
            });
          }
        } else {
          setNotificationState({ ...notificationState, isloading: false });
          if (isFirstLoad) {
            setNotificationTypeState({
              ...notificationTypeState,
              isloading: false,
            });
            setNotificationLikeState({
              ...notificationLikeState,
              isloading: false,
            });
          }
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setNotificationState({ ...notificationState, isloading: false });
        console.log("api response", error);
      });
  }

  function onNotificationTypeChange(selectedOption) {
    setState({
      ...state,
      selectedNotificationType: selectedOption,
      notification_type: selectedOption.value,
    });
    getNotificationData(false, selectedOption.value);
  }

  function onNotificationLikeChange(selectedOption) {
    setState({
      ...state,
      selectedNotificationLike: selectedOption,
      notification_like: selectedOption.value,
    });
    getNotificationData(false, "", "", selectedOption.value);
  }

  function onNotificationDateChange(selected_date) {
    setState({ ...state, notification_date: selected_date });
    getNotificationData(false, "", selected_date, "");
  }

  function clearAll() {
    window.location.replace("/Notification");
  }

  function markAllAsRead() {
    setNotificationState({ ...notificationState, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      notification_type: state.notification_type,
      notification_date: state.notification_date,
      notification_like: state.notification_like,
    });
    console.log("newRequestBody", newRequestBody);
    simplePostCall(ApiConfig.MARK_ALL_NOTIFICATIONS_AS_READ, newRequestBody)
      .then((data) => {
        if (data.result) {
          getNotificationData(false);
          toast.success(data.message);
        } else {
          setNotificationState({ ...notificationState, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setNotificationState({ ...notificationState, isloading: false });
        console.log("api response", error);
      });
  }

  useEffect(() => {
    document.title = "Edsys | Notifications";
    getNotificationData(true);
  }, []);
  console.log("notificationState", notificationState);
  return (
    <>
      {notificationLikeState.isloading || notificationTypeState.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="row">
            <div className="col-md-12 xl-100">
              <div className="card br-15">
                <div className="card-body" style={{ minHeight: "500px" }}>
                  <h5
                    style={{
                      cursor: "pointer",
                    }}
                    className="mb-3"
                  >
                    <span className="bell fa fa-bell"></span>
                    {t("Notifications")}
                    {/* <small
                      className="notification_clear_all_filter_button"
                      onClick={clearAll}
                    >
                      {t("Clear Filters")}
                    </small> */}
                    <small
                      className="notification_mark_all_as_read_button"
                      onClick={markAllAsRead}
                      style={{ marginRight: "1%" }}
                    >
                      {t("Mark All As Read")}
                    </small>
                  </h5>
                  {/* <div className="row notification_searchbar">
                    <div className="mb-3 col-md-4">
                      <label for="exampleInputName1">{t('Notification Date')}</label>
                      <input
                        className="form-control"
                        id="exampleInputName1"
                        type="date"
                        value={state.notification_date}
                        onChange={(e) =>
                          onNotificationDateChange(e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label for="exampleInputName1">{t('Notification Type')}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedNotificationType}
                        onChange={onNotificationTypeChange}
                        options={notificationTypeState.notificationTypes}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label for="exampleInputName1">{t('Notification Like')}</label>
                      <Select
                        class="js-example-basic-single form-select"
                        value={state.selectedNotificationLike}
                        onChange={onNotificationLikeChange}
                        options={notificationLikeState.notificationLikes}
                      />
                    </div>
                  </div> */}

                  {notificationState.isloading == true ? (
                    <SpinnerCmp />
                  ) : (
                    <ul className="notification-sec">
                      {notificationState.notifications.length > 0 ? (
                        notificationState.notifications.map(
                          (notification, index) => {
                            console.log(notification);
                            var liClassName = "blue";
                            if (
                              notification.notification_type == "danger" ||
                              notification.notification_status === "read"
                            ) {
                              liClassName = "red";
                            } else if (
                              notification.notification_type == "info"
                            ) {
                              liClassName = "blue";
                            } else if (
                              notification.notification_type == "alert"
                            ) {
                              liClassName = "green";
                            } else if (
                              notification.notification_type == "emergency"
                            ) {
                              liClassName = "yellow";
                            } else if (
                              notification.notification_type == "message"
                            ) {
                              liClassName = "byzantium";
                            }
                            return (
                              <li className={liClassName}>
                                <div className="d-flex">
                                  <div className="bellico">
                                    <Bell />
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{ width: "94%" }}
                                  >
                                    <div className="message flex-grow-1">
                                      {notification.notification_status ===
                                        "read" || "remove" ? (
                                        <b style={{ color: "red" }}>
                                          {notification.notification_text}
                                        </b>
                                      ) : (
                                        notification.notification_text
                                      )}
                                    </div>
                                    <div className="notify-date">
                                      {
                                        notification.notification_display_datetime
                                      }
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          },
                        )
                      ) : (
                        <li style={{ borderRadius: "15px" }}>
                          <div className="d-flex">
                            <div className="d-flex">
                              <div className="message flex-grow-1">
                                {t("No data found")}
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  );
}
export default Notification;
