import React, { useEffect, useState } from "react";

import {
  Link,
  useHistory,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PlusCircle } from "react-feather";

import assignment_card_data from "../../api/assignment_card_data.json";
import AssessmentDetails from "../academics/Assesment/AssessmentDetails";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Select from "react-select";
import CusDrop from "./CusDrop";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import "../../screens/OfflineAssesment/Dropstyle.css";
import ApiConfig from "../../api/ApiConfig";
function ViewOfflineAssesment() {
  const [assignmentId, setAssignmentId] = useState("");
  const [assessmentStudents, setAssessmentStudents] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [errors, setErrors] = useState({});
  const [listMsg, setListMsg] = useState("");
  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    term: "",
    termId: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
    grade: "",
    subject: "",
  });
  const nav = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    setAssignmentId(location.state.offlineId);
    ViewOfflineAssessmentApi();
    AcademicYrListApi();
    GradeListApi();
    TermListApi(location.state.academicYear.value);
    SubjectListApi(location.state.grade.value);
    console.log(location.state);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: location.state.academicYear,
      grade: location.state.grade,
      term: location.state.term,
      subject: location.state.subject,
    }));
    await ViewOfflineAssessmentApi();
  }, []);

  const AcademicYrListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ACADEMIC_YEAR_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          // console.log(data.message, "Message academicyrlist");
          const newData = data.data?.map((item) => {
            return {
              label: item.academic_year_name,
              value: item.academic_year_id,
              ...item,
            };
          });
          setAcademicYearList(newData);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleYearChange = (year) => {
    console.log("Seleected Acad Year", year);
    // setAcademicYear(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicYear: year,
      term: null,
    }));
    setTermList([]);
    TermListApi(year.value);
  };

  const GradeListApi = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      search: "",
    });
    simplePostCall(ApiConfig.GRADE_LIST, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          console.log(data.message, "Message GradeList");
          console.log(data.list, "GradeList");
          const newData = data.list?.map((item) => {
            return {
              label: item.class_namea,
              value: item.class_division_id,
              ...item,
            };
          });
          setGradeList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleGradeChange = (grade) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grade: grade,
      subject: null,
    }));
    setSubList([]);
    SubjectListApi(grade.value);
  };

  const TermListApi = (academic_year) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      terms_academic_year: academic_year,
    });
    simplePostCall(ApiConfig.TERM_LIST, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          console.log("Term Data", data.data);
          const newData = data.data?.map((item) => {
            return { label: item.terms_name, value: item.terms_id, ...item };
          });
          setTermList(newData);
        } else {
          console.error("API returned false result:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching term list:", error);
      });
  };

  const handleTermChange = (term) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      term: term,
    }));
    console.log("Selected Term", term);
  };

  const SubjectListApi = (classId) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: classId,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log("Subjects:", data.list);
          const newData = data.list?.map((item) => {
            return { label: item.subject_name, value: item.id, ...item };
          });
          setSubList(newData);
        }
      })

      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleSubjectChange = (subject) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: subject,
    }));
    console.log("Selected Subject", subject);
  };

  const ViewOfflineAssessmentApi = async () => {
    try {
      let newRequestBody = JSON.stringify({
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        assessment_offline_id: assignmentId
          ? assignmentId
          : location.state.offlineId,
        assessment_subject: formData.subject.value
          ? formData.subject.value
          : location.state.subject.value,
        term_id: formData.term.value
          ? formData.term.value
          : location.state.term.value,
        assessment_division_id: formData.grade.value
          ? formData.grade.value
          : location.state.grade.value,
        academic_year: formData.academicYear.value
          ? formData.academicYear.value
          : location.state.academicYear.value,
      });
      console.log("Inside apiii");

      const data = await simplePostCall(
        ApiConfig.VIEW_OFFLINEASSESSMENT,
        newRequestBody,
      );

      if (data.result === true) {
        console.log(data.message, "Message View");
        console.log(data.data, "ViewAPIII");

        const students = [];
        data.data?.forEach((assessment) => {
          assessment.assessment_student.forEach((student) => {
            students.push(student);
          });
        });
        setAssessmentStudents(students);
        console.log(students, "StuddentViewAPIII");
      } else {
        console.error("API returned false result:", data.message);
        setListMsg(data.message);
        setAssessmentStudents([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1"> View Offline Assesment</h5>
                {/* <Link to="/EditOfflineAssessment"> */}
                <a
                  type="button"
                  className="btn btn-edsys-primary "
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() => {
                    nav("/EditOfflineAssessment", {
                      state: {
                        offlineId: assignmentId,
                        academicYear: formData.academicYear,
                        grade: formData.grade,
                        term: formData.term,
                        subject: formData.subject,
                      },
                    });
                  }}
                >
                  <PlusCircle className="me-2" />
                  <span style={{}}>Edit Offline Assessment</span>
                </a>
                {/* </Link> */}
              </div>
            </div>
            <div className="offmarkcad col-md-12 row ">
              <div className="col-md-3">
                {/* <CusDrop  /> */}
                <div className="">
                  <div className="col">
                    <p className="DropTxt">Select Academic year</p>
                    <Select
                      class="js-example-basic-single form-select"
                      name="academicYear"
                      value={formData.academicYear}
                      onChange={handleYearChange}
                      options={academicYearList}
                    />
                    {errors.academicYear && (
                      <div className="invalid-feedback">
                        {errors.academicYear}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <p className="DropTxt">Select Grade Division</p>
                    <Select
                      class="js-example-basic-single form-select"
                      name="grade"
                      value={formData.grade}
                      onChange={handleGradeChange}
                      options={gradeList}
                    />
                    {/* {gradeList && gradeList.length > 0
                        ? gradeList.map((obj, index) => (
                            <option key={index} value={obj.class_division_id}>
                              {obj.class_namea}
                            </option>
                          ))
                        : null}
                    </select> */}
                    {errors.grade && (
                      <div className="invalid-feedback">{errors.grade}</div>
                    )}
                  </div>

                  <div className="col">
                    <p className="DropTxt">Select Exam/Term</p>

                    <Select
                      class="js-example-basic-single form-select"
                      name="terms"
                      value={formData.term}
                      options={termList}
                      onChange={handleTermChange}
                    />
                    {/* {termList && termList?.length > 0
                        ? termList?.map((obj, index) => (
                            <option key={index} value={obj.terms_id}>
                              {obj.terms_name}
                            </option>
                          ))
                        : null}
                    </select> */}
                    {errors.terms && (
                      <div className="invalid-feedback">{errors.terms}</div>
                    )}
                  </div>
                  <div className="col">
                    <p className="DropTxt">Select Subject</p>

                    <Select
                      class="js-example-basic-single form-select"
                      name="subject"
                      value={formData.subject}
                      onChange={handleSubjectChange} // Update the onChange handler
                      options={subList}
                    />
                    {/* {subList && subList.length > 0
                        ? subList.map((obj, index) => (
                            <option key={index} value={obj.id}>
                              {obj.subject_name}
                            </option>
                          ))
                        : null}
                    </select> */}
                    {errors.subject && (
                      <div className="invalid-feedback">{errors.subject}</div>
                    )}
                  </div>

                  <button
                    className="view-mark-list-button col-md-12"
                    onClick={() => ViewOfflineAssessmentApi()}
                  >
                    View Mark List
                  </button>
                </div>

                {/*********/}
                <p className="p-10 "></p>
              </div>

              <div className="col-md-9">
                {assessmentStudents.length > 0 ? (
                  <div className="table">
                    <div className="thead">
                      <div className="tr tableheadstyle">
                        <div className="thsl">Sl. No</div>

                        <div className="thsn" style={{ marginLeft: 10 }}>
                          Student Name
                        </div>
                        <div className="thm">Marks</div>
                        <div className="thm"> Total Marks</div>
                      </div>
                    </div>

                    <div className="tbody">
                      {assessmentStudents?.map((student, index) => (
                        <div className="tr" key={index}>
                          <div className="tdsl">{index + 1}</div>

                          <div className="tdsn" style={{ marginLeft: 10 }}>
                            <img
                              className="rounded-circle"
                              src={dashboardAvatar}
                              alt="image"
                              style={{
                                height: "25px",
                                width: "25x",
                                marginRight: "10px",
                              }}
                            />
                            {student.student_Name}
                          </div>
                          <div className="tdm">{student.result_mark}</div>
                          <div className="tdm">
                            {student.assessment_max_mark}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <h5 className="flex-grow-9">{listMsg}</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOfflineAssesment;
