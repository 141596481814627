// SyllabusSkeleton.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SyllabusTable = () => {
  return (
    <div className="SyllabusCreate">
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="labelCreate">
                <Skeleton width={200} height={30} />
              </label>
              <div className="createButt">
                <Skeleton width={150} height={40} />
              </div>
            </div>
          </div>
          <div className="tableCreate">
            <Skeleton height={50} />
            <table className="table table-dark table-borderless newTable">
              <thead className="tableHead">
                <tr>
                  <th>
                    <Skeleton width={30} />
                  </th>
                  <th>
                    <Skeleton width={30} />
                  </th>
                  <th>
                    <Skeleton width={30} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td className="textcol">
                      <Skeleton width={30} />
                    </td>
                    <td className="textcol">
                      <Skeleton width="100%" />
                    </td>
                    <td className="iconSec">
                      <Skeleton width={100} height={30} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusTable;
