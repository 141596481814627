import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import No_List from "../../api/No_list.json";
import { BookOpen, Edit, Trash2 } from "react-feather";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import ResourceSkeleton from "../../components/skeletons/ResourceSkeleton";

const Pending_Resources = (props) => {
  const selectedType = props.state && props.state.selectedType;

  const [isModalShow, SetisModalShow] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  console.log(pendingList)
  const [isloading, setIsloading] = useState(false);
  const [page, setPage] = useState(1);
  const [TotalCourses, setTotalCourses] = useState(0);
 

  const Showmodal = () => {
    SetisModalShow(true);
  };

  useEffect(() => {
    getPendingResourceList(page);
  }, [selectedType]);
  function getPendingResourceList(currentPage) {
    setIsloading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      resources_type: selectedType ? selectedType : "",
      page_no: currentPage,
      rowlimit: 6,
    };
    simplePostCall(ApiConfig.PENDING_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        console.log(data.list);
        if (data.result) {
          setIsloading(false);
          let total = data.total_data ? data.total_data : 0;
          setTotalCourses(total);
          let coursesData = data.list ? data.list : [];
          let LearningData = coursesData.map((eachteam, index) => {
         
            return {
              ...eachteam,
              published_by: eachteam.published_by || "",
              resource_category_name: eachteam.resource_category_name || "",
              resources_name: eachteam.resources_name || "",
            };
          });

          if (currentPage === 1) {
            setPendingList(data.list);
            setIsloading(false);
          } else {
            setPendingList([...pendingList, ...LearningData]);
            setIsloading(false);
          }
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const onPageLoad = () => {
    setPage(page + 1);
    getPendingResourceList(page + 1);
  };
  return (
    <>
      <InfiniteScroll
        style={{
          overflow: "hidden",
        }}
        dataLength={pendingList.length}
        next={onPageLoad}
        hasMore={pendingList.length !== TotalCourses}
        loader={
          <h4 style={{ textAlign: "center", marginTop: "20px" }}>
            Loading....
          </h4>
        }
     
      >
        {isloading ? (
          <ResourceSkeleton loading={isloading} />
        ) : (
    <div className="row">
      {pendingList &&
        pendingList.map((postDetail, index) => {
          return (
            <div className="col-md-4">
              <div className="card br-15">
                <div className="card-header pb-0">
                  <div className="d-flex">
                    <h5 className="title flex-grow-1 cursor-pointer-on">
                      {postDetail.resources_name}
                    </h5>
                    <Edit
                      type="button"
                      data-bs-toggle="modal"
                      onClick={Showmodal}
                      data-bs-target="#exampleModal2"
                      className="header-icon-svg"
                    />
                    <Trash2
                      type="button"
                      data-bs-toggle="modal"
                      data-original-title="test"
                      data-bs-target="#deleteWarning"
                      className="header-icon-svg"
                    />
                  </div>
                  <div
                    class="modal fade"
                    id="deleteWarning"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog show" role="document">
                      <div class="modal-content br-15">
                        <div class="modal-body">
                          <div class="warning-message">
                            <Trash2 />
                            <h6>Are you sure you want to delete?</h6>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn"
                            type="button"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button class="d-button" data-bs-dismiss="modal">
                            <div class="trash">
                              <div class="top">
                                <div class="paper"></div>
                              </div>
                              <div class="box"></div>
                              <div class="check">
                                <svg viewBox="0 0 8 6">
                                  <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
                                </svg>
                              </div>
                            </div>
                            <span>Delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {this.state.isModalShow ? <EditResource onClose={this.openCloseModal} /> : null} */}
                </div>
                <div className="card-body cursor-pointer-on">
                  <Link to={`/ImgVideoGallaryNew/${postDetail.resources_id}`} className="d-flex">
                    <div className="me-auto c-date">{postDetail.resources_created_date}</div>
                    <div className="type">Book</div>
                    <div className="type-icon">
                      <BookOpen />
                    </div>
                  </Link>
                  <div className="footer-blk">Published by</div>
                  <div className="footer-blk-detail">{postDetail.resources_description}</div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
        )}

    </InfiniteScroll>
    </>
  );
};

export default Pending_Resources;
