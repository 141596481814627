import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import dropdown_icons from "../../assets/images/dropdown_icons.svg";
import forum_img_1 from "../../assets/images/forum_img_1.svg";
import forum_img_2 from "../../assets/images/forum_img_2.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import follow_icon from "../../assets/images/follow_icon.svg";
import report_icon from "../../assets/images/report_icon.svg";
import block_icon from "../../assets/images/block_icon.svg";
import share_icon from "../../assets/images/share_icon.svg";
import forum_img_com from "../../assets/images/forum_img_com.svg";
import like_btn from "../../assets/images/like_btn.svg";
import dislike_btn from "../../assets/images/dislike_btn.svg";
import com_icon from "../../assets/images/com_icon.svg";
import delete_icon from "../../assets/images/delete_icon.svg";
import edit_icon from "../../assets/images/edit_icon.svg";
import Modal from "react-bootstrap/Modal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import add_btn from "../../assets/images/add_btn.svg";
import Form from "react-bootstrap/Form";

function Forum() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [validated, setValidated] = useState(false);
  const [time, setTime] = useState(null);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const [selectedDay, setSelectedDay] = useState(null);

  return (
    <>
      <div class="Forum library">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Forums</label>
                <div className="top-btn-wrapper">
                  <button onClick={() => setModalShow(true)}>
                    <img src={add_btn} alt="" />
                    Create Forum
                  </button>
                  <img src={filter_icon} alt="" onClick={handleShow} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="books-main-wrapper">
                <TabView>
                  <TabPanel header="All Forums">
                    <div className="forum-item">
                      <div className="forum-header">
                        <div className="forum-img">
                          <img src={forum_img_1} alt="" />
                        </div>
                        <div className="forum-title-box">
                          <div className="forum-title-wrapper">
                            <p className="ft-main-title">Lorem I ,jjklljljlpsum dolor</p>
                            <p className="ft-sub-title">Gathering</p>
                            <p className="ft-date">12 JUN 2018</p>
                          </div>
                          <div className="forum-dropdown">
                            <Dropdown className="Common_dropDown">
                              <Dropdown.Toggle
                                className="dropdown-btn"
                                id="dropdown-basic"
                              >
                                <img src={dropdown_icons} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <img src={share_icon} alt="" />
                                  Share
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={follow_icon} alt="" />
                                  Follow
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={report_icon} alt="" />
                                  Report
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={block_icon} alt="" />
                                  Block
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="forum-body">
                        <div className="text-wrapper">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation.Ut enim ad minim veniam, quis
                            nostrud exercitation. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore...{" "}
                            <Link to="/ForumView">Read More</Link>
                          </p>
                        </div>
                        <div className="img-wrapper">
                          <img src={forum_img_com} alt="" />
                          <img src={forum_img_com} alt="" />
                          <img src={forum_img_com} alt="" />
                        </div>
                      </div>
                      <div className="forum-footer">
                        <div className="like-btn">
                          <img src={like_btn} alt="" />
                          <span>10</span>
                        </div>
                        <div className="dislike-btn">
                          <img src={dislike_btn} alt="" />
                          <span>5</span>
                        </div>
                        <div className="comments-btn">
                          <img src={com_icon} alt="" />
                          <span>18</span>
                        </div>
                      </div>
                    </div>
                    <div className="forum-item">
                      <div className="forum-header">
                        <div className="forum-img">
                          <img src={forum_img_2} alt="" />
                        </div>
                        <div className="forum-title-box">
                          <div className="forum-title-wrapper">
                            <p className="ft-main-title">Lorem Ipsum dolor</p>
                            <p className="ft-sub-title">Gathering</p>
                            <p className="ft-date">12 JUN 2018</p>
                          </div>
                          <div className="forum-dropdown">
                            <Dropdown className="Common_dropDown">
                              <Dropdown.Toggle
                                className="dropdown-btn"
                                id="dropdown-basic"
                              >
                                <img src={dropdown_icons} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <img src={share_icon} alt="" />
                                  Share
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={follow_icon} alt="" />
                                  Follow
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={report_icon} alt="" />
                                  Report
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <img src={block_icon} alt="" />
                                  Block
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="forum-body">
                        <div className="text-wrapper">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation.Ut enim ad minim veniam, quis
                            nostrud exercitation. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore...{" "}
                            <Link to="/ForumView">Read More</Link>
                          </p>
                        </div>
                      </div>
                      <div className="forum-footer">
                        <div className="like-btn">
                          <img src={like_btn} alt="" />
                          <span>10</span>
                        </div>
                        <div className="dislike-btn">
                          <img src={dislike_btn} alt="" />
                          <span>5</span>
                        </div>
                        <div className="comments-btn">
                          <img src={com_icon} alt="" />
                          <span>18</span>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel header="My Forums">
                    <div className="forum-item">
                      <div className="forum-header">
                        <div className="forum-img">
                          <img src={forum_img_1} alt="" />
                        </div>
                        <div className="forum-title-box">
                          <div className="forum-title-wrapper">
                            <p className="ft-main-title">Lorem Ipsum dolor</p>
                            <p className="ft-sub-title">Gathering</p>
                            <p className="ft-date">12 JUN 2018</p>
                          </div>
                          <div className="forum-dropdown">
                            <Dropdown className="Common_dropDown">
                              <Dropdown.Toggle
                                className="dropdown-btn"
                                id="dropdown-basic"
                              >
                                <img src={dropdown_icons} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <img src={share_icon} alt="" />
                                  Share
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setModalShow1(true)}>
                                  <img src={edit_icon} alt="" />
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setModalShow2(true)}>
                                  <img src={delete_icon} alt="" />
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="forum-body">
                        <div className="text-wrapper">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation.Ut enim ad minim veniam, quis
                            nostrud exercitation. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore...{" "}
                            <Link to="/ForumView">Read More</Link>
                          </p>
                        </div>
                        <div className="img-wrapper">
                          <img src={forum_img_com} alt="" />
                          <img src={forum_img_com} alt="" />
                          <img src={forum_img_com} alt="" />
                        </div>
                      </div>
                      <div className="forum-footer">
                        <div className="like-btn">
                          <img src={like_btn} alt="" />
                          <span>10</span>
                        </div>
                        <div className="dislike-btn">
                          <img src={dislike_btn} alt="" />
                          <span>5</span>
                        </div>
                        <div className="comments-btn">
                          <img src={com_icon} alt="" />
                          <span>18</span>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="forum-item">
                      <div className="forum-header">
                        <div className="forum-img">
                          <img src={forum_img_2} alt="" />
                        </div>
                        <div className="forum-title-box">
                          <div className="forum-title-wrapper">
                            <p className="ft-main-title">Lorem Ipsum dolor</p>
                            <p className="ft-sub-title">Gathering</p>
                            <p className="ft-date">12 JUN 2018</p>
                          </div>
                          <div className="forum-dropdown">
                            <Dropdown className="Common_dropDown">
                              <Dropdown.Toggle
                                className="dropdown-btn"
                                id="dropdown-basic"
                              >
                                <img src={dropdown_icons} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <img src={share_icon} alt="" />
                                  Share
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setModalShow1(true)}>
                                  <img src={edit_icon} alt="" />
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setModalShow2(true)}>
                                  <img src={delete_icon} alt="" />
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="forum-body">
                        <div className="text-wrapper">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation.Ut enim ad minim veniam, quis
                            nostrud exercitation. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore...{" "}
                            <Link to="/ForumView">Read More</Link>
                          </p>
                        </div>
                      </div>
                      <div className="forum-footer">
                        <div className="like-btn">
                          <img src={like_btn} alt="" />
                          <span>10</span>
                        </div>
                        <div className="dislike-btn">
                          <img src={dislike_btn} alt="" />
                          <span>5</span>
                        </div>
                        <div className="comments-btn">
                          <img src={com_icon} alt="" />
                          <span>18</span>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Filter By</p>
              <p>Clear</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select-wrapper">
            <ul>
              <li>
                <input type="radio" id="f-option" name="selector" />
                <label for="f-option">Most Recent</label>

                <div class="check"></div>
              </li>

              <li>
                <input type="radio" id="s-option" name="selector" />
                <label for="s-option">New</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>

              <li>
                <input type="radio" id="t-option" name="selector" />
                <label for="t-option">Most Liked</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input type="radio" id="i-option" name="selector" />
                <label for="i-option">Most Commented</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input type="radio" id="j-option" name="selector" />
                <label for="j-option">Most Shared</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      {/* Filter Modal End */}

      {/* Create Forum Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Create Forum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Title Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Category</Form.Label>
                    <Form.Select>
                      <option> Select Subject </option>
                      <option> English</option>
                      <option> Mathematics</option>
                      <option> Geography</option>
                      <option> Civics</option>
                      <option> Science</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a subject
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Sub-Type</Form.Label>
                    <Form.Control type="text" placeholder=""></Form.Control>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Forum Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Recipients</Form.Label>
                    <Form.Select>
                      <option> Recipients </option>
                      <option> Recipients 2</option>
                      <option> Recipients 3</option>
                      <option> Recipients 4</option>
                      <option> Recipients 5</option>
                      <option> Recipients 6</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
            >
              Publish
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Create Forum Modal End */}

      {/* Edit Forum Modal Start */}
      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Edit Forum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Title Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Category</Form.Label>
                    <Form.Select>
                      <option> Select Subject </option>
                      <option> English</option>
                      <option> Mathematics</option>
                      <option> Geography</option>
                      <option> Civics</option>
                      <option> Science</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a subject
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Sub-Type</Form.Label>
                    <Form.Control type="text" placeholder=""></Form.Control>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Forum Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Recipients</Form.Label>
                    <Form.Select>
                      <option> Recipients </option>
                      <option> Recipients 2</option>
                      <option> Recipients 3</option>
                      <option> Recipients 4</option>
                      <option> Recipients 5</option>
                      <option> Recipients 6</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow1(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Edit Forum Modal End */}

      {/* Delete Modal Start */}
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete  Modal End */}
    </>
  );
}

export default Forum;
