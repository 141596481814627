import React ,{useEffect}from 'react';
import { Edit3, Eye, MoreVertical, PlusCircle, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import subtopicicon from '../../../assets/images/assignment/subtopicicon.svg'

 function AddQuestion() {
  useEffect(() => {
    document.title = "Edsys | Add Question"
  }, [])
  return (
    <div class="page-body">
    {/* <!-- Container-fluid starts--> */}
    <div class="row add-ques">
      <div class="col-12">
          <div class="card sub-1 stack-card">
              <div class="card-header pb-0 br-15">
                  <div class="d-flex">
                      <img class="flex-grow-0" src={subtopicicon} />
                      <h6 class="flex-grow-1">Physics</h6>
                      <a href="#avail-ques" class="avail-qns">Available Questions</a>
                      <Link to="/CreateQuestion" class="create-qns">Create Question</Link>
                  </div>
              </div>
              <div class="card-body">
                  <div class="d-flex mb-1">
                      <div class="flex-grow-1">
                          <div class="lesson">Lesson 1</div>
                          <h5>Assessment Title 01</h5>
                      </div>
                      <div class="">
                          <div class="class">6th D</div>
                          <div class="syllabus">CBSE</div>
                      </div>
                  </div>
                  <p class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<span id="dots">...</span><span id="more">ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span><span class="r-more" onclick="myFunction()" id="myBtn">(Read more)</span></p>
              </div>
              <div class="card-footer">
                  <div class="row">
                      <div class="col-3">
                          <div class="footer-blk-4 br-1">
                              <div class="title">
                                  Start Date
                              </div>
                              <div class="info">
                                  21-11-2021
                              </div>
                          </div>
                      </div>
                      <div class="col-3">
                          <div class="footer-blk-4 br-1">
                              <div class="title">
                                  End Date
                              </div>
                              <div class="info">
                                  15-12-2021
                              </div>
                          </div>
                      </div>
                      <div class="col-3">
                          <div class="footer-blk-4 br-1">
                              <div class="title">
                                  Start Time
                              </div>
                              <div class="info">
                                  10:00am
                              </div>
                          </div>
                      </div>
                      <div class="col-3">
                          <div class="footer-blk-4">
                              <div class="title">
                                  End Time
                              </div>
                              <div class="info">
                                  04:00pm
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> 
      <div class="col-md-12">
          <div class="card br-15">
              <div class="card-header d-flex br-15 pb-0">
                <h6>Questions</h6>
              </div>
              <div class="card-body">
                      <div class="assessment-block">
                          <table class="table">
                              <tbody>
                                <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Questions Title</th>
                                      <th>Questions Type</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                <tr>
                                  <th>1</th>
                                  <td>Question Title 01</td>
                                  <td>Question Type 01</td>
                                  <td>
                                      <div class="ass-dropdown">
                                          <div class="option"><MoreVertical /></div>
                                          <div class="ass-dropdown-content">
                                            <a href="#"><Eye /> <span>View Question</span></a>
                                            <a href="#"><Edit3 /> <span>Update Question</span></a>
                                            <a href="#"><Trash2 /><span>Delete Question</span></a>
                                          </div>
                                      </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>2</th>
                                  <td>Question Title 02</td>
                                  <td>Question Type 02</td>
                                  <td>
                                      <div class="ass-dropdown">
                                          <div class="option"><MoreVertical /></div>
                                          <div class="ass-dropdown-content">
                                            <a href="#"><Eye /> <span>View Question</span></a>
                                            <a href="#"><Edit3 /> <span>Update Question</span></a>
                                            <a href="#"><Trash2 /><span>Delete Question</span></a>
                                          </div>
                                      </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
        
                          </div>
                    </div>
            </div>
      </div>
      <div class="col-md-12">
          <div class="card br-15">
              <div class="card-header d-flex br-15 pb-0">
                <h6 id="avail-ques">Available Questions</h6>
              </div>
              <div class="card-body">
                <div class="assessment-block">
                    <table class="table">
                        <tbody>
                          <tr>
                            <th>1</th>
                            <td>Question Title 01</td>
                            <td>
                                <div class="ass-dropdown">
                                    <div class="option"><MoreVertical /></div>
                                    <div class="ass-dropdown-content">
                                      <a href="#"><Eye /> <span>View Question</span></a>
                                      <a href="#"><PlusCircle /> <span>Add Question</span></a>
                                      <a href="#"><Trash2 /><span>Delete Question</span></a>
                                    </div>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <th>2</th>
                            <td>Question Title 02</td>
                            <td>
                                <div class="ass-dropdown">
                                    <div class="option"><MoreVertical /></div>
                                    <div class="ass-dropdown-content">
                                      <a href="#"><Eye /> <span>View Question</span></a>
                                      <a href="#"><PlusCircle /> <span>Add Question</span></a>
                                      <a href="#"><Trash2 /><span>Delete Question</span></a>
                                    </div>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <th>3</th>
                            <td>Question Title 03</td>
                            <td>
                                <div class="ass-dropdown">
                                    <div class="option"><MoreVertical /></div>
                                    <div class="ass-dropdown-content">
                                      <a href="#"><Eye /> <span>View Question</span></a>
                                      <a href="#"><PlusCircle /> <span>Add Question</span></a>
                                      <a href="#"><Trash2 /><span>Delete Question</span></a>
                                    </div>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <th>4</th>
                            <td>Question Title 04</td>
                            <td>
                                <div class="ass-dropdown">
                                    <div class="option"><MoreVertical /></div>
                                    <div class="ass-dropdown-content">
                                      <a href="#"><Eye /> <span>View Question</span></a>
                                      <a href="#"><PlusCircle /> <span>Add Question</span></a>
                                      <a href="#"><Trash2 /><span>Delete Question</span></a>
                                    </div>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <th>5</th>
                            <td>Question Title 05</td>
                            <td>
                                <div class="ass-dropdown">
                                    <div class="option"><MoreVertical /></div>
                                    <div class="ass-dropdown-content">
                                      <a href="#"><Eye /> <span>View Question</span></a>
                                      <a href="#"><PlusCircle /> <span>Add Question</span></a>
                                      <a href="#"><Trash2 /><span>Delete Question</span></a>
                                    </div>
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
  
                    </div>
              </div>
            </div>
      </div>
    </div>
    {/* <!-- Container-fluid Ends--> */}
  </div>
  )
}
export default  AddQuestion