import React from "react";

export default function TImeTableSkeleton() {
  return (
    <>
      <div class="loading-skeleton">
        <div class=" col-md-1">
          <div class="title p-3 border bg-light"></div>
        </div>
        <div className="row col-12">
          <div class=" col-md-2 mt-2">
            <div class="title p-3 border bg-light"></div>
          </div>
          <div class=" col-md-2 mt-2">
            <div class="title p-3 border bg-light"></div>
          </div>
          <div class=" col-md-2 mt-2">
            <div class="title p-3 border bg-light"></div>
          </div>
          <div class=" col-md-2 mt-2">
            <div class="title p-3 border bg-light"></div>
          </div>
          <div class=" col-md-2 mt-2">
            <div class="title p-3 border bg-light"></div>
          </div>
        </div>
      </div>
      <div className="card border-0 br-15 mt-2">
        <div class="container loading-skeleton">
          <div class="d-flex mt-3 flex-wrap justify-content-evenly">
            {/* week cards Start */}
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card bg-light"></div>
            </div>
            {/* week cards End */}
            {/* Period Card Start */}
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            <div class="timetable_card">
              <div class="card timetable_inner_card period_card bg-light"></div>
            </div>
            {/* Period Card End */}
          </div>
        </div>
      </div>
    </>
  );
}
