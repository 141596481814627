import React, { useEffect, useState } from "react";
import watch_icon from "../../assets/images/watch_icon.svg";
import video_icon_white from "../../assets/images/video_icon_white.svg";
import schedule_icon_white from "../../assets/images/schedule_icon_white.svg";
import { Link } from "react-router-dom";
import add_btn from "../../assets/images/add_btn.svg";
import video_thumb from "../../assets/images/video_thumb.svg";
import play_btn from "../../assets/images/play_btn.svg";
import views_icon from "../../assets/images/views_icon.svg";
import edit_icon from "../../assets/images/edit_icon.svg";
import inactive_edit_icon from "../../assets/images/inactive_edit_icon.svg";
import delete_icon from "../../assets/images/delete_icon.svg";
import inactive_delete_icon from "../../assets/images/inactive_delete_icon.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Calendar } from "primereact/calendar";

function ScheduledPrograms() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const [time, setTime] = useState(null);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <div class="SchoolTvChannel announcementnew">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Scheduled Program- 10th D</label>
                <button onClick={() => setModalShow(true)}>
                  <img src={add_btn} alt="" />
                  Create Program
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={inactive_edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={inactive_delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="video-card">
                    <div className="video-thumbnail">
                      <Link to="/VideoPlaylist">
                        <img src={video_thumb} alt="" />
                        <span>
                          <img src={play_btn} alt="" />
                        </span>
                        <div className="thumb-overlay"></div>
                      </Link>
                    </div>
                    <div className="video-title">
                      <p className="main-title">Video Title</p>
                      <div className="views">
                        <img src={views_icon} alt="" />
                        <p className="view-count">Views 125 </p>
                      </div>
                    </div>
                    <div className="video-time-stamp">
                      <div className="date-time-wrapper">
                        <p className="date">23/02/2023</p>
                        <p className="time">11:00 AM - 11:30 AM</p>
                      </div>
                      <div className="action-btn-wrapper">
                        <img src={edit_icon} alt="" onClick={() => setModalShow1(true)}/>
                        <img src={delete_icon} alt="" onClick={() => setModalShow2(true)}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create Create Program Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Create Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Select Subject <span>*</span>
                    </Form.Label>
                    <Form.Select required>
                      <option> Select Subject </option>
                      <option> English</option>
                      <option> Mathematics</option>
                      <option> Geography</option>
                      <option> Civics</option>
                      <option> Science</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a subject
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Select Program/ Video</Form.Label>
                    <Form.Select required>
                      <option defaultValue>
                        Select Announcement Content Type
                      </option>
                      <option value="Text"> Plain Text</option>
                      <option value="Image"> Image</option>
                      <option value="Audio"> Audio</option>
                      <option value="Pdf"> PDF</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please choose a content type
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Date <span>*</span>
                    </Form.Label>
                    <Form.Control type="date" required placeholder="Select Date" ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select date
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <label htmlFor="calendar-timeonly" className="font-bold mb-2">
                    Start Time
                  </label>
                  <div>
                    <Calendar
                      id="calendar-timeonly"
                      value={time}
                      onChange={(e) => setTime(e.value)}
                      timeOnly
                      required
                      placeholder="HH : MM : SS"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="calendar-timeonly" className="font-bold mb-2">
                    End Time
                  </label>
                  <div>
                    <Calendar
                      id="calendar-timeonly"
                      value={time}
                      onChange={(e) => setTime(e.value)}
                      timeOnly
                      required
                      placeholder="HH : MM : SS"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
            >
              Schedule
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Create Program Modal End */}


       {/* Edit Program Modal Start */}
       <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Edit Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Select Subject <span>*</span>
                    </Form.Label>
                    <Form.Select required>
                      <option> Select Subject </option>
                      <option> English</option>
                      <option> Mathematics</option>
                      <option> Geography</option>
                      <option> Civics</option>
                      <option> Science</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a subject
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Select Program/ Video</Form.Label>
                    <Form.Select required>
                      <option defaultValue>
                        Select Announcement Content Type
                      </option>
                      <option value="Text"> Plain Text</option>
                      <option value="Image"> Image</option>
                      <option value="Audio"> Audio</option>
                      <option value="Pdf"> PDF</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please choose a content type
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Date <span>*</span>
                    </Form.Label>
                    <Form.Control type="date" required placeholder="Select Date" ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select date
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <label htmlFor="calendar-timeonly" className="font-bold mb-2">
                    Start Time
                  </label>
                  <div>
                    <Calendar
                      id="calendar-timeonly"
                      value={time}
                      onChange={(e) => setTime(e.value)}
                      timeOnly
                      required
                      placeholder="HH : MM : SS"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="calendar-timeonly" className="font-bold mb-2">
                    End Time
                  </label>
                  <div>
                    <Calendar
                      id="calendar-timeonly"
                      value={time}
                      onChange={(e) => setTime(e.value)}
                      timeOnly
                      required
                      placeholder="HH : MM : SS"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Edit Program Modal End */}




      {/* Delete Announcement Modal Start */}
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Announcement Modal End */}
    </>
  );
}

export default ScheduledPrograms;
