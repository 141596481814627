import React from "react";
import Skeleton from "react-loading-skeleton";

const StudentDetskeleton = () => {
  return (
    <div className="StudentList">
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <Skeleton width={100} height={30} />
              <div className="createButt">
                <div className="crbutt">
                  <Skeleton width={120} height={30} />
                </div>
              </div>
            </div>
          </div>
          <div className="tableCreate">
            <div className="table-responsive">
              <table className="table table-dark table-borderless newTable">
                <thead className="tableHead">
                  <tr>
                    <th>
                      <Skeleton width={20} />
                    </th>
                    <th>
                      <Skeleton width={20} />
                    </th>
                    <th>
                      <Skeleton width={20} />
                    </th>
                    <th>
                      <Skeleton width={20} />
                    </th>
                    <th>
                      <Skeleton width={20} />
                    </th>
                    <th>
                      <Skeleton width={20} />
                    </th>

                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Array(5)
                    .fill()
                    .map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton width={20} height={20} />
                        </td>
                        <td>
                          <Skeleton circle={true} height={40} width={40} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={50} height={20} />
                        </td>
                        <td>
                          <Skeleton width={50} height={20} />
                        </td>

                        <td>
                          <Skeleton width={20} height={20} />
                        </td>
                        <td>
                          <Skeleton width={50} height={20} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentDetskeleton;
