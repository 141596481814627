import React from 'react'
const E_LearningModal = (props) => {
  const displayStyle = {
    display: 'block',
  }
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Create Resource
            </h5>
            <button
              className="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {' '}
            </button>
          </div>
          <div className="modal-body datetime-picker modal-scroll">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label for="task-title">Category</label>
                  <select className="js-example-basic-single form-select">
                    <option value="task">Pdf</option>
                    <option value="task">YouTube</option>
                  </select>
                </div>
                <div className="form-group col-md-12 ">
                  <label for="task-title">Category</label>
                  <select className="js-example-basic-single form-select">
                    <option value="task">Reference Book</option>
                    <option value="task">Book</option>
                  </select>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">Curriculum</label>
                  <select className="js-example-basic-single form-select">
                    <option value="task">CBSE</option>
                    <option value="task">ICSE</option>
                  </select>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label for="task-title">Grade</label>
                    <select className="js-example-basic-single form-select">
                      <option value="task">10A</option>
                      <option value="task">10B</option>
                      <option value="task">10C</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label for="task-title">Subject</label>
                    <select
                      className="js-example-disabled-results form-select"
                      id="bm-collection"
                    >
                      <option value="general">Physics</option>
                      <option value="general">Chemistry</option>
                      <option value="general">Biolody</option>
                      <option value="general">Mathematics</option>
                      <option value="general">Social Studies</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">Lesson</label>
                  <select className="js-example-basic-single form-select">
                    <option value="lesson1">Lesson 1</option>
                    <option value="lesson2">Lesson 2</option>
                    <option value="lesson3">Lesson 3</option>
                    <option value="lesson4">Lesson 4</option>
                    <option value="lesson5">Lesson 5</option>
                  </select>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">Topic</label>
                  <select className="js-example-basic-single form-select">
                    <option value="task">Assignment Title-1</option>
                    <option value="task">Assignment Title-2</option>
                    <option value="task">Assignment Title-3</option>
                    <option value="task">Assignment Title-4</option>
                  </select>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">Name</label>
                  <input
                    className="form-control"
                    id="task-title"
                    type="text"
                    required=""
                    autocomplete="off"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label for="sub-task">Description</label>
                  <textarea
                    className="form-control"
                    required=""
                    autocomplete="off"
                  >
                    {' '}
                  </textarea>
                </div>
                <div className="form-group col-md-12">
                  <label for="task-title">Enter YouTube URL</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon3">
                      https://www.youtube.com/
                    </span>
                    <input
                      className="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {/* <button className="btn btn-secondary" id="Bookmark" type="submit">
              Save as Draft
            </button> */}
            <button
              className="btn btn-primary"
              type="button"
              onClick={props.onClose}
              data-bs-dismiss="modal"
            >
              Save and Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default E_LearningModal
