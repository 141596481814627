
import React, { Component } from "react";
import { HashLoader } from "react-spinners";

class SpinnerCmp extends Component {
    render() {
        return (
            <>
                <div
                    style={this.props.customStyle ? this.props.customStyle : {
                        textAlign: "center",
                        marginTop: "30vh",
                        display: this.props.loading === false ? "none" : "block",
                    }}
                >
                    <HashLoader color={"#adc927"} loading={this.props.loading} />
                </div>
            </>
        );
    }
}

export default SpinnerCmp;
