import React, { useState, useEffect } from "react";
import clockonicon from "../../assets/images/punch/clockonicon.svg";
import clockofficon from "../../assets/images/punch/clockofficon.svg";
import Clock from "react-live-clock";
import Moment from "react-moment";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import moment from "moment";
import SpinnerCmp from "../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";

function Punch() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState();
  const [status, setStatus] = useState(0);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const [state, setState] = useState({
    attendanceDetails: {},
    punchInTime: "-- : -- : --",
    punchOutTime: "-- : -- : --",
  });
  const [currentLocationState, setCurrentLocationState] = useState({
    current_latitude: 0,
    current_longitude: 0,
  });
  const [intervalState, setIntervalState] = useState({
    interval: null,
  });
  var showdate = new Date();
  var displaytodaysdate =
    showdate.getDate() +
    "/" +
    (showdate.getMonth() + 1) +
    "/" +
    showdate.getFullYear();
  var dt = showdate.toDateString();

  function doPunchInOut() {
    setIsLoading(true);
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      date: moment().format("YYYY-MM-DD") /* 2019-12-17 */,
      time: moment().format("HH:mm:ss") /* 17:19:24 */,
      latitude: currentLocationState.current_latitude,
      longitude: currentLocationState.current_longitude,
      device_id: "",
      device_type: "web",
      flag: state.attendanceDetails.result == false ? 1 : 2,
    });
    simplePostCall(ApiConfig.EMPLOYEE_MARK_ATTENDANCE_API, newRequestBody)
      .then((data) => {
        setIsLoading(false);
        if (data.result == true) {
          getEmployeeAttendanceDetails();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
        setIsLoading(false);
      });
  }
  function timer(totalSeconds = 0, runInterval = false) {
    var hoursLabel = document.getElementById("hours_timer");
    var minutesLabel = document.getElementById("minutes_timer");
    var secondsLabel = document.getElementById("seconds_timer");
    //var totalSeconds = 0;

    if (runInterval == true) {
      var interval = setInterval(setTime, 1000);
      setIntervalState({ interval: interval });
    } else {
      clearInterval(intervalState.interval);
      hoursLabel.innerHTML = "00";
      minutesLabel.innerHTML = "00";
      secondsLabel.innerHTML = "00";
    }

    function setTime() {
      ++totalSeconds;
      secondsLabel.innerHTML = pad(totalSeconds % 60);

      var minutes = parseInt(totalSeconds / 60);
      var minutes_disp = parseInt((totalSeconds / 60) % 60);
      minutesLabel.innerHTML = pad(minutes_disp) + ":";

      var hours = parseInt(minutes / 60);
      hoursLabel.innerHTML = pad(hours) + ":";
    }

    function pad(val) {
      var valString = val + "";
      if (valString.length < 2) {
        return "0" + valString;
      } else {
        return valString;
      }
    }
  }

  function getEmployeeAttendanceDetails() {
    setIsLoading(true);
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      date: date,
    });
    console.log(
      newRequestBody,
      ApiConfig.EMPLOYEE_ATTENDANCE_DETAILS_API,
      "EMPLOYEE_ATTENDANCE_DETAILS_API",
    );
    simplePostCall(ApiConfig.EMPLOYEE_ATTENDANCE_DETAILS_API, newRequestBody)
      .then((data) => {
        setIsLoading(false);
        setState({
          attendanceDetails: data,
          punchInTime: "-- : -- : --",
          punchOutTime: "-- : -- : --",
        });

        if (data.result == true) {
          if (data.details.length == 1) {
            var totalpunchedtime = data.details[0].totalpunchedtime;
            var totalpunchedtime_array = totalpunchedtime.split(":"); // split it at the colons
            // minutes are worth 60 seconds. Hours are worth 60 minutes.
            var seconds =
              +totalpunchedtime_array[0] * 60 * 60 +
              +totalpunchedtime_array[1] * 60 +
              +totalpunchedtime_array[2];

            timer(seconds, true);
            setState({
              attendanceDetails: data,
              punchInTime: data.details[0].check_in_out,
              punchOutTime: "-- : -- : --",
            });
          } else if (data.details.length > 1) {
            timer(seconds, false);
            var totalpunchedtime = data.details[1].totalpunchedtime;
            var totalpunchedtime_array = totalpunchedtime.split(":"); // split it at the colons

            document.getElementById("hours_timer").innerHTML =
              totalpunchedtime_array[0] + ":";
            document.getElementById("minutes_timer").innerHTML =
              totalpunchedtime_array[1] + ":";
            document.getElementById("seconds_timer").innerHTML =
              totalpunchedtime_array[2] + "";

            setState({
              attendanceDetails: data,
              punchInTime: data.details[0].check_in_out,
              punchOutTime: data.details[1].check_in_out,
            });
            setStatus(data.details[1].status);
          } else {
            document.getElementById("hours_timer").innerHTML = "00:";
            document.getElementById("minutes_timer").innerHTML = "00:";
            document.getElementById("seconds_timer").innerHTML = "00";
          }
        }
      })
      .catch((error) => {
        console.log("api response", error);
        setIsLoading(false);
      });
  }

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;
      setCurrentLocationState({
        current_latitude: lat,
        current_longitude: lon,
      });
    });
  }
  useEffect(() => {
    getEmployeeAttendanceDetails();
    getCurrentLocation();
  }, []);

  const handlePunchInOut = () => {
    if (state.attendanceDetails.details.length <= 1) {
      doPunchInOut();
    }
  };
  return (
    <div class="col-md-5 text-center">
      {isLoading ? (
        <SpinnerCmp loading={isLoading} />
      ) : (
        <div class="punch-tile">
          <h4>{t("Attendance")}</h4>
          <div class="clock">
            <h1>{dt}</h1>
            <Clock format={"HH : mm : ss"} ticking={true} timezone={"IST"} />
          </div>
          <div
            class="punch-button"
            onClick={() => {
              handlePunchInOut();
            }}
          >
            <div class="total-hrs">{t("TOTAL HOURS")}</div>
            <div class="start-time">
              <span id="hours_timer">00:</span>
              <span id="minutes_timer">00:</span>
              <span id="seconds_timer">00</span>
            </div>
            <div
              className={
                state.attendanceDetails.result == false
                  ? "punch-btn"
                  : "punch-out-btn"
              }
              style={{
                backgroundColor: status === 2 ? "#ccc" : "",
                color: status === 2 ? "#000" : "",
                border: "none",
              }}
            >
              {state.attendanceDetails.result == false
                ? `${t("Punch In")}`
                : status === 2
                ? `${t("Punched Out")}`
                : `${t("Punch Out")}`}
            </div>
          </div>
          <div class="row work-duration">
            <div class="col-6 punch-o">
              <img class="p-in-img" src={clockonicon} />
              <div class="p-in">{t("Punch In")}</div>
              <div className="p-in-time">{state.punchInTime}</div>
            </div>
            <div class="col-6 ms-auto text-end punch-o">
              <img class="p-out-img" src={clockofficon} />
              <div class="p-out">{t("Punch Out")}</div>
              <div className="p-out-time">{state.punchOutTime}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Punch;
