import React from "react";
import TodoMenus from "./To_do_Menus";

export class ToDo extends React.Component {
  //#region constructor
  constructor(props) {
    super(props);
    this.state = {};
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | To-do";
  }
  //#endregion

  //#region custom Methods
  //#endregion

  //#region Render
  render() {
    return (
      <div>
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="email-wrap bookmark-wrap">
            <div className="row">
              <TodoMenus />
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    );
  }
  //#endregion
}
export default ToDo;
